import React, { useContext, useEffect } from 'react';
import { AppContext } from './../../AppContext';
import {
  Typography,
  Dialog,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import ImageButton from '../Generic/ImageButton';
import DecoratedImageButton from '../Generic/DecoratedImageButton';

import sensor from '../../img/settings_remote-24px.svg';
import router from '../../img/router-24px.svg';
import location from '../../img/place-24px.svg';
import alert from '../../img/announcement-24px - blue.svg';
import user from '../../img/people-24px.svg';

/**
 * returns the typography color based in the
 * theme dark mode and the tone given for the colors
 * @param {Object, int, int} theme, lightTone=700, darkTone=300
 */
const textColor = ({ theme, lightTone = 700, darkTone = 300 }) => {
  if (theme.palette.type === 'light') {
    return grey[lightTone];
  } else {
    return grey[darkTone];
  }
};

const useStyles = makeStyles(theme => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
  },
  gatewaySelect: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
  },
  fieldPadding: {
    paddingBottom: theme.spacing(4),
  },
  textFieldPadding: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  leftButton: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  rightButton: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: '1rem',
    },
  },
  formContainer: {
    backgroundColor:
      theme.palette.type === 'light'
        ? '#F4F5F5'
        : theme.palette.background.default,
  },
  cardText: {
    textAlign: 'center',
    color: textColor({ theme, lightTone: 500, darkTone: 400 }),
  },
  cardValueText: {
    color: textColor({ theme, lightTone: 500, darkTone: 400 }),
  },
  titleContainer: {
    backgroundColor: theme.palette.background.paper,
  },
  titleText: {
    fontWeight: 'bold',
    color: theme.palette.type === 'light' ? '#747474' : '#FFF',
  },
  cardContainer: {
    backgroundColor:
      theme.palette.type === 'light'
        ? '#F4F5F5'
        : theme.palette.background.default,
    overflowX: 'hidden',
    overflowY: 'auto',
    height: `100%`,
  },
}));

/**
 * Account Settings Form Modal
 * @param {Object} props
 * @props
 * openState: Boolean
 * handleClose: Function
 * handleSave: Function
 */
const OnBoardModal = props => {
  const log = window.log('OnBoardModal');

  const {
    appState,
    setAppState,
    setLogoutState,
    setOpenLocationModal,
    setOpenGatewayModal,
    setOpenSensorModal,
    setOpenUserModal,
    setOpenAlertModal,
    setFeatureCount,
    snack,
  } = useContext(AppContext);
  const { openState = false, handleClose, featureCount } = props;
  log('featureCount:', featureCount);

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [fullWidth, setFullWidth] = React.useState(false);
  const [maxWidth, setMaxWidth] = React.useState('md');
  const [buttonText, setButtonText] = React.useState('ADD GATEWAY');
  const classes = useStyles();

  const buttonsArray = [
    {
      onClick: () => {
        setOpenLocationModal(true);
      },
      image: location,
      action: 'ADD',
      text: 'LOCATION',
      completed:
        appState &&
        appState.auth &&
        appState.auth.userInfo &&
        appState.auth.userInfo.features &&
        appState.auth.userInfo.features.addLocation,
    },
    {
      onClick: () => {
        setOpenUserModal(true);
      },
      image: user,
      action: 'ADD',
      text: 'USER',
      completed:
        appState &&
        appState.auth &&
        appState.auth.userInfo &&
        appState.auth.userInfo.features &&
        appState.auth.userInfo.features.addUser,
    },
    {
      onClick: () => {
        setOpenGatewayModal(true);
      },
      image: router,
      action: 'ADD',
      text: 'GATEWAY',
      completed:
        appState &&
        appState.auth &&
        appState.auth.userInfo &&
        appState.auth.userInfo.features &&
        appState.auth.userInfo.features.addGateway,
    },
    {
      onClick: () => {
        setOpenSensorModal(true);
      },
      image: sensor,
      action: 'ADD',
      text: 'SENSOR',
      completed:
        appState &&
        appState.auth &&
        appState.auth.userInfo &&
        appState.auth.userInfo.features &&
        appState.auth.userInfo.features.addSensor,
    },

    {
      onClick: () => {
        setOpenAlertModal(true);
      },
      image: alert,
      action: 'ADD',
      text: 'ALERT',
      completed:
        appState &&
        appState.auth &&
        appState.auth.userInfo &&
        appState.auth.userInfo.features &&
        appState.auth.userInfo.features.addAlert,
    },
  ];

  const handleFinishLaterClick = async () => {
    let changes = {
      ...appState.auth.userInfo.features,
      finishLater: true,
    };

    try {
      const data = {
        themePreferences: appState.auth.userInfo.themePreferences
          ? JSON.stringify(appState.auth.userInfo.themePreferences)
          : null,
        features: JSON.stringify(changes),
        companyId: appState.auth.userInfo.companyId,
      };
      const response = await fetch(
        process.env.REACT_APP_API_URL + `/company/edit`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
          body: JSON.stringify(data),
        },
      );
      const json = await response.json();

      if (json.success) {
        let cp = { ...appState };
        cp.auth.userInfo.features = JSON.parse(json.data.features);
        let featureCount = 0;
        if (!cp.auth.userInfo.features.addLocation) {
          featureCount++;
        }
        if (!cp.auth.userInfo.features.addUser) {
          featureCount++;
        }
        if (!cp.auth.userInfo.features.addGateway) {
          featureCount++;
        }
        if (!cp.auth.userInfo.features.addSensor) {
          featureCount++;
        }
        if (!cp.auth.userInfo.features.addAlert) {
          featureCount++;
        }
        setFeatureCount(featureCount);
        setAppState(cp);
        handleClose();
      } else {
        snack(json.errors[0], 'error');
        json.errors.forEach(err => {
          if (err.type === 'token') {
            snack(err.msg, 'error');
            setLogoutState(true);
          }
        });
      }
    } catch (err) {
      log(err);
    }
  };

  const handleNextStep = () => {
    if (!appState.auth.userInfo.features.addLocation) {
      setOpenLocationModal(true);
    } else if (!appState.auth.userInfo.features.addUser) {
      setOpenUserModal(true);
    } else if (!appState.auth.userInfo.features.addGateway) {
      setOpenGatewayModal(true);
    } else if (!appState.auth.userInfo.features.addSensor) {
      setOpenSensorModal(true);
    } else if (!appState.auth.userInfo.features.addAlert) {
      setOpenAlertModal(true);
    } else {
      handleFinishLaterClick();
    }
  };

  useEffect(() => {
    const features = appState.auth.userInfo?.features;
    if (features) {
      if (!features.addLocation) {
        setButtonText('ADD LOCATION');
      } else if (!features.addUser) {
        setButtonText('ADD USER');
      } else if (!features.addGateway) {
        setButtonText('ADD GATEWAY');
      } else if (!features.addSensor) {
        setButtonText('ADD SENSOR');
      } else if (!features.addAlert) {
        setButtonText('ADD ALERT');
      } else if (
        features.addLocation &&
        features.addUser &&
        features.addGateway &&
        features.addSensor &&
        features.addAlert
      ) {
        setButtonText('COMPLETE');
      }
    }
  }, [
    appState.auth.userInfo &&
      appState.auth.userInfo.features &&
      appState.auth.userInfo.features,
  ]);

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      open={openState}
      aria-labelledby='responsive-dialog-title'
    >
      <Card style={{ overflow: 'auto', height: '100%' }}>
        <CardContent className={classes.cardContainer}>
          <Grid container spacing={4}>
            <Grid className={classes.titleContainer} item xs={12}>
              <Typography className={classes.titleText} align='center'>
                ONBOARDING
              </Typography>
            </Grid>

            <Grid
              item
              container
              xs={12}
              style={
                fullScreen
                  ? {}
                  : {
                      paddingLeft: theme.spacing(6),
                      paddingRight: theme.spacing(6),
                    }
              }
              className={classes.formContainer}
            >
              <Grid item xs={12}>
                <p className={classes.cardText}>
                  Let's finish setting up your system. Select “{buttonText}” to
                  move to the next step.
                </p>
              </Grid>
              <Grid item xs={12} container justify='space-evenly'>
                {buttonsArray.map((button, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      sm={4}
                      md={2}
                      container
                      justify='center'
                      alignItems='flex-end'
                    >
                      <DecoratedImageButton
                        onboardModal={true}
                        buttonWithText={true}
                        onClick={button.onClick}
                        image={button.image}
                        action={button.action}
                        text={button.text}
                        title={
                          button.completed
                            ? 'Completed'
                            : button.text === 'ALERT'
                            ? 'Optional'
                            : 'Not Completed'
                        }
                        titleColor={button.completed ? '#59C754' : '#FF6A6A'}
                        border={button.completed}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
          <CardActions className={classes.formContainer}>
            <Grid
              container
              style={{
                paddingLeft: theme.spacing(8),
                paddingRight: theme.spacing(8),
                marginBottom: theme.spacing(4),
                marginTop: theme.spacing(5),
              }}
              spacing={1}
            >
              <Grid item xs={12} sm={6}>
                <Button
                  className={classes.leftButton}
                  onClick={handleFinishLaterClick}
                  size='medium'
                  variant='outlined'
                  fullWidth={true}
                >
                  FINISH LATER
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} container justify='flex-end'>
                <Button
                  className={classes.rightButton}
                  onClick={handleNextStep}
                  size='medium'
                  variant='contained'
                  color='primary'
                  fullWidth={true}
                >
                  {buttonText}
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </CardContent>
      </Card>
    </Dialog>
  );
};

export default OnBoardModal;
