import _ from 'lodash';
import { Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';

import { AlertContext } from '../AlertContext';
import { GenericAssigned } from './GenericAssigned';

export function AssignedLists({ cards }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { allUsers, alertInfo } = useContext(AlertContext);

  const [assignedUsers, setAssignedUsers] = useState([]);
  const [disabledSensorArr, setDisabledSensorArr] = useState([]);

  useEffect(() => {
    const sensorUsers = JSON.parse(alertInfo.alert).users.selectedUsers;

    // need to configure user with first name, last name, disabled notifications
    _.forEach(sensorUsers, user => {
      // Custom Recipient
      if (user.name) {
        const nameArr = user.name.split(' ');
        user['fname'] = nameArr[0];
        user['lname'] = nameArr[1] || '';
      }
      // User
      if (user.id) {
        const foundUser = _.find(allUsers, { id: user.id });
        if (foundUser) {
          user['fname'] = foundUser.fname || '';
          user['lname'] = foundUser.lname || '';
        }
      }

      user['disabledNotifcations'] =
        user['disabledNotifcations'] === undefined ||
        user['disabledNotifcations'] === false
          ? false
          : true;
    });

    if (JSON.parse(alertInfo.alert)?.disabledSensors) {
      setDisabledSensorArr(JSON.parse(alertInfo.alert).disabledSensors);
    }

    setAssignedUsers(sensorUsers);
  }, [allUsers, alertInfo]);

  return (
    <Grid
      container
      item
      xs={12}
      sm={4}
      spacing={1}
      className={`${
        cards === 1
          ? classes.sensorTableContainerHoriz
          : classes.sensorTableContainer
      }`}
    >
      <GenericAssigned
        listType={'sensor'}
        enabledSensorArr={alertInfo.sensorsAssignedToAlert}
        disabledSensorArr={disabledSensorArr}
      />
      <GenericAssigned listType={'user'} userArr={assignedUsers} />
    </Grid>
  );
}

const useStyles = makeStyles(theme => ({
  sensorTableContainer: {
    alignContent: 'stretch',
    marginTop: 20,
    marginLeft: 20,
    gap: 10,
    height: `calc(60vh - 24px)`,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 'revert',
      marginTop: 0,
    },
  },
  sensorTableContainerHoriz: {
    height: 365,
    minWidth: '100%',
    marginTop: 20,
    flexDirection: 'column',
    alignContent: 'stretch',
    gap: 10,
  },
}));
