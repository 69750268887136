import React from 'react';
import { Grid, Divider } from '@material-ui/core';

export function CenteredHeading(props) {
  const { text, textStyle, marginTop = '34px' } = props;

  const classes = {
    centeredHeading: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      margin: `${marginTop} 47px 0 47px`,
    },
    noOfSensors: {
      margin: '0 20px',
      fontSize: '14px',
    },
    dividerStyle: { flex: '4' },
  };

  return (
    <Grid item style={classes.centeredHeading}>
      <Divider style={classes.dividerStyle} />
      <p className={textStyle} style={classes.noOfSensors}>
        {text}
      </p>
      <Divider style={classes.dividerStyle} />
    </Grid>
  );
}
