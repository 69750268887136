import React from "react";
import { SvgIcon } from "@material-ui/core";

const CodeIcon = (props) => {
  const { outlinecolor, filledcolor } = props;

  return (
    <SvgIcon
      width="39.376"
      height="29.512"
      viewBox="0 0 39.376 29.512"
      {...props}
    >
      <rect fill={outlinecolor} width="39.376" height="29.512" rx="6.708" />
      <g transform="translate(3.653 7.049)">
        <path
          fill={filledcolor}
          d="M337.174,430.279V422.23c0-2.312,1.2-3.682,3.468-3.682s3.468,1.37,3.468,3.682v1.584h-2.226V422.08c0-.985-.471-1.392-1.177-1.392s-1.177.407-1.177,1.392v8.37c0,.985.471,1.37,1.177,1.37s1.177-.385,1.177-1.37V428.16h2.226v2.119c0,2.312-1.2,3.682-3.468,3.682S337.174,432.591,337.174,430.279Z"
          transform="translate(-337.174 -418.548)"
        />
        <path
          fill={filledcolor}
          d="M360.982,430.193v-7.878c0-2.4,1.263-3.768,3.575-3.768s3.575,1.37,3.575,3.768v7.878c0,2.4-1.263,3.767-3.575,3.767S360.982,432.591,360.982,430.193Zm4.8.15v-8.178c0-1.07-.471-1.477-1.22-1.477s-1.22.407-1.22,1.477v8.178c0,1.07.471,1.477,1.22,1.477S365.777,431.414,365.777,430.343Z"
          transform="translate(-352.761 -418.548)"
        />
        <path
          fill={filledcolor}
          d="M386.278,419.168h3.6c2.355,0,3.511,1.306,3.511,3.7v7.578c0,2.4-1.156,3.7-3.511,3.7h-3.6Zm3.553,12.844c.749,0,1.2-.385,1.2-1.456v-7.792c0-1.07-.45-1.455-1.2-1.455h-1.2v10.7Z"
          transform="translate(-369.323 -418.954)"
        />
        <path
          fill={filledcolor}
          d="M411.449,419.168h6.422v2.141H413.8v3.96h3.233v2.141H413.8v4.6h4.068v2.14h-6.422Z"
          transform="translate(-385.802 -418.954)"
        />
      </g>
    </SvgIcon>
  );
};

export default CodeIcon;
