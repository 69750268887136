import _ from 'lodash';
import React from 'react';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import {
  Grid,
  Checkbox,
  FormGroup,
  FormLabel,
  Typography,
  FormControl,
  FormControlLabel,
} from '@material-ui/core';

import ColorPicker from '../../Generic/ColorPicker';

export function SettingsCheckBoxes({
  label,
  single = false,
  setCurrentValue,
  checkBoxData = [],
  colorPicker = false,
  currentValue = true,
  isMobile = false
}) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const configCheckBoxes = () =>
    checkBoxData.map((box, index) => (
      <Grid
        item
        xs={6}
        container
        key={index}
        className={classes.checkBoxControl}
      >
        <FormControlLabel
          control={
            <Checkbox
              className={classes.size}
              onChange={() =>
                setCurrentValue(
                  single ? { type: box.name, value: box.check } : box.check,
                )
              }
              name={box.name}
              checked={single ? box.check : currentValue === box.check}
              icon={<CheckBoxOutlineBlank className={classes.sizeIcon} />}
              checkedIcon={<CheckBox className={classes.sizeIcon} />}
              value={box.check}
            />
          }
          label={
            <Typography className={classes.labelText}>{box.label}</Typography>
          }
        />
      </Grid>
    ));

  return (
    <Grid item xs={12} container className={classes.formContainer}>
      <FormControl component='fieldset' style={{ width: '100%' }}>
        <FormLabel className={classes.formControlLabel}>{label}</FormLabel>
        <FormGroup className={classes.checkBoxContainer}>
          {colorPicker ? (
            <ColorPicker
              primaryColor={currentValue}
              handlePrimaryColorChange={setCurrentValue}
              isMobile={isMobile}
            />
          ) : (
            configCheckBoxes()
          )}
        </FormGroup>
      </FormControl>
    </Grid>
  );
}

const useStyles = makeStyles(theme => ({
  formContainer: { flex: 1 },
  formControlLabel: {
    fontWeight: 'bold',
    fontSize: 12,
  },
  checkBoxContainer: {
    flexDirection: 'row',
  },
  size: {
    width: 40,
    height: 40,
  },
  sizeIcon: {
    fontSize: 25,
  },
  labelText: {
    color: '#747474',
    fontSize: 11,
  },
}));
