import React from 'react';

import GenericList from './GenericList';
import DeviceContainer from './DeviceContainer';
import { navigate } from 'hookrouter';
/**
 * Device Status list
 *
 * @props
 * title: String
 * devices: Array[Object]
 *
 */
const DevicesStatusList = props => {
  const log = window.log('DevicesStatusList');

  const {
    title, // Title for the Generic List
    devices, // Array of devices
    entity, // Navigation and list type
    dashboardTable,
    actionTitle = true,
    listHeight,
    mobileLayout = false, // boolean to shot the tiles with the mobile layout
    ...other
  } = props;

  const sortByTime = (a, b) => {
    // value = ''
    if (a.lastMessageTime < b.lastMessageTime) {
      return 1;
    }
    if (a.lastMessageTime > b.lastMessageTime) {
      return -1;
    }
    return 0;
  };

  if (devices?.length > 1) {
    devices.sort(sortByTime);
  }

  log('Looking at devices ', devices);

  const handleNavigateToSensors = () => {
    navigate('/device/all');
  };

  const handleNavigateToAlerts = () => {
    navigate('/alert/all');
  };

  if (entity === 'alerts') {
    return (
      <GenericList
        {...other}
        listHeight={listHeight}
        title={title}
        paginate
        actionTitle={actionTitle && 'View All Alerts'}
        onClick={handleNavigateToAlerts}
        dashboardTable={dashboardTable}
        useItemBuilder={true}
        data={devices}
        emptyBuilder={() => (
          <DeviceContainer
            index={0}
            key={0}
            deviceName={'No Resolved Alerts'}
            noResolvedAlerts={true}
            mobileLayout={mobileLayout}
          />
        )}
        itemBuilder={(u, index) => (
          <DeviceContainer
            alertStatus={u.status}
            index={index}
            key={u.id}
            deviceName={u.deviceName}
            locationName={u.locationName}
            gatewayName={u.gatewayName}
            lastMessageTime={u.lastMessageTime}
            alertName={u.alertName}
            entity={entity}
            mobileLayout={mobileLayout}
          />
        )}
      ></GenericList>
    );
  } else {
    return (
      <GenericList
        {...other}
        listHeight={listHeight}
        title={title}
        paginate
        actionTitle={actionTitle && "View All Sensors"}
        onClick={handleNavigateToSensors}
        data={devices}
        emptyBuilder={() => (
          <DeviceContainer
            index={0}
            key={0}
            deviceName={'No Offline Sensors'}
            noOfflineSensors={true}
            mobileLayout={mobileLayout}
          />
        )}
        itemBuilder={(u, index) => (
          <DeviceContainer
            alertStatus={u.status}
            index={index}
            key={u.id}
            deviceName={u.deviceName}
            locationName={u.locationName}
            gatewayName={u.gatewayName}
            lastMessageTime={u.lastMessageTime}
            mobileLayout={mobileLayout}
          />
        )}
        useItemBuilder={true}
      ></GenericList>
    );
  }
};

export default DevicesStatusList;
