import React from 'react';
import grey from '@material-ui/core/colors/grey';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Box, Card, Grid, IconButton, Typography } from '@material-ui/core';

/**
 * returns the typography color based in the
 * theme dark mode and the tone given for the colors
 * @param {Object, int, int} theme, lightTone=700, darkTone=300
 */
const textColor = ({ theme, lightTone = 700, darkTone = 300 }) => {
  if (theme.palette.type === 'light') {
    return grey[lightTone];
  } else {
    return grey[darkTone];
  }
};

/**
 * Tile component for
 * dashboard top area
 *
 * @props
 * numberOfEntities: integer
 * entityName: String
 * icon: React Component
 * underlineColor: String (Color)
 */
export default function Tile(props) {
  const {
    icon, // icon to render
    onClick,
    multiple, // add 2 rows of text,
    dateValue,
    scaleFont, // scale font to fit long text
    entityName, // string , title of the tile
    secondEntry, //second row message
    extentionMethod,
    numberOfEntities, // number of entities to be shown
    padding = "24px 36px 25px 32px", // string, card padding
    height = "154px", // string ,card height,
    iconSize = "32px",
  } = props;

  const useStyles = makeStyles(theme => ({
    card: {
      cursor: onClick !== undefined ? 'pointer' : '',
      // padding: "24px 36px 25px 32px",
      padding: padding,
      height: height,
      // margin: theme.spacing(1, 1),
      borderRadius: "10px",
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      position: 'relative',
      [theme.breakpoints.down("sm")]: scaleFont && {
        borderRadius: "13px !important"
      },
    },
    titleContainer: {
      margin: theme.spacing(0, 0, 1, 0),
    },
    cardTitle: {
      textTransform: 'uppercase',
      fontSize: 14,
      fontWeight: 600,
      color: theme.palette.type === 'light' ? '#747474' : '#FFF',
    },
    underLine: {
      backgroundColor: theme.palette.primary.main,
      height: '1px',
      width: '3rem',
    },
    icon: {
      margin: theme.spacing(0, 0, 0, 0),
      '& svg': {
        height: `${iconSize} !important`,
        width: `${iconSize} !important`
      }
    },
    numberStyles: {
      color: textColor({ theme }),
      whiteSpace: 'nowrap',
      fontSize: dateValue ? 35 : 53,
      [theme.breakpoints.down('lg')]: scaleFont && {
        fontSize: dateValue ? 32 : 53,
      },
      [theme.breakpoints.down('1442')]: scaleFont && {
        fontSize: dateValue && '2.3vw',
      },
      [theme.breakpoints.down('1239')]: scaleFont && {
        fontSize: dateValue && '2vw',
      },
      [theme.breakpoints.down('md')]: scaleFont && {
        fontSize: !dateValue && '3.2vw',
      },
      [theme.breakpoints.down('sm')]: scaleFont && {
        fontSize: dateValue ? '3.5vw' : 30,
      },
      [theme.breakpoints.down('xs')]: scaleFont && {
        fontSize: dateValue ? 22 : 30,
      },
    },
    secondEntry: {
      color: textColor({ theme }),
      whiteSpace: 'nowrap',
      fontSize: 25,
      [theme.breakpoints.down('lg')]: scaleFont && {
        fontSize: 22,
      },
      [theme.breakpoints.down('1442')]: scaleFont && {
        fontSize: '1.1vw',
      },
      [theme.breakpoints.down('1239')]: scaleFont && {
        fontSize: '1vw',
      },
      [theme.breakpoints.down('md')]: scaleFont && {
        fontSize: '1.6vw',
      },
      [theme.breakpoints.down('sm')]: scaleFont && {
        fontSize: '1.75vw',
      },
      [theme.breakpoints.down('xs')]: scaleFont && {
        fontSize: 18,
      },
    },
    extentionButton: {
      position: 'absolute',
      top: 5,
      right: 5,
      padding: 5,
    },
  }));

  const classes = useStyles();

  return (
    <Card elevation={1} className={classes.card} onClick={onClick}>
      <Grid container>
        <Grid item xs={11}>
          <div className={classes.titleContainer}>
            <Box fontWeight='fontWeightMedium'>
              <Typography className={classes.cardTitle}>{entityName}</Typography>
            </Box>
            <Box className={classes.underLine} />
          </div>
        </Grid>
        <Grid item xs={1}>
          {extentionMethod && (
            <IconButton
              aria-label='snapshot_modal'
              className={classes.extentionButton}
              onClick={extentionMethod}
            >
              <KeyboardArrowRightIcon
                style={{
                  fontSize: 40,
                  color: grey[400],
                }}
              />
            </IconButton>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        display='flex'
        justify='space-between'
        alignItems={multiple ? 'center' : 'flex-end'}
        direction='row'
        style={{ flexWrap: 'nowrap' }}
      >
        <Grid item className={classes.icon}>
          {icon}
        </Grid>
        <Grid
          item
          className={classes.numberStyles}
          fontWeight='fontWeightMedium'
        >
          <Box fontWeight='fontWeightMedium' id={`${entityName}_amount`}>
            {numberOfEntities}
          </Box>
          {multiple && secondEntry ? (
            <Box className={classes.secondEntry}>{secondEntry}</Box>
          ) : null}
        </Grid>
      </Grid>
    </Card>
  );
}