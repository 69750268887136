import _ from 'lodash';
import React, { memo, useEffect, useState, useContext } from 'react';

import { AlertContext } from '../AlertContext';
import SelectSensor from '../AlertModalComponents/SelectSensor';

// function is called in memo at bottom of file
// rendered so many times, affected user experience
function UserAlertSettingsContent(props) {
  const { selectedUsers, setValueChanged } = props;
  const { allUsers } = useContext(AlertContext);

  const [selectedUserIndexArr, setSelectedUserIndexArr] = useState([]); // Used by the data table to preselect sensors already set for alert

  useEffect(() => {
    if (!_.isEmpty(allUsers)) {
      const indexArr = [];
      const userArr = [];
      _.forEach(selectedUsers, user => {
        indexArr.push(_.findIndex(allUsers, ['id', user.id]));
        userArr.push(user);
      });

      setSelectedUserIndexArr(indexArr);
      setValueChanged(userArr);
    }
  }, [allUsers]);

  const mapRowsSelected = allRowsSelected => {
    const userArr = [];
    const selectedArr = allRowsSelected.map(row => {
      const userObj = {
        id: allUsers[row.dataIndex].id,
        email: allUsers[row.dataIndex].email,
        phone: allUsers[row.dataIndex].phoneNumber,
      };
      userArr.push(userObj);
      return row.dataIndex;
    });
    setSelectedUserIndexArr(selectedArr);
    setValueChanged(userArr);
  };

  const selectUserTableOptions = {
    selectableRows: 'multiple',
    rowsSelected: selectedUserIndexArr,
    textLabels: {
      selectedRows: {
        text: 'Users(s) Selected',
      },
      body: {
        noMatch: 'No matching users',
      },
    },
    onRowSelectionChange: (curr, all) => mapRowsSelected(all),
  };

  return (
    <SelectSensor
      header='Select Users'
      desc='Select one or more users to be notified by the alert'
      data={allUsers}
      options={{
        ...selectUserTableOptions,
      }}
      columns='users'
    />
  );
}

export const UserAlertSettings = memo(UserAlertSettingsContent);
