import React, { useEffect, useState, useContext } from 'react';
import { Typography } from '@material-ui/core';
import moment from 'moment-timezone';
import { AppContext } from '../../AppContext';

const LastUpdated = ({ liveData, className }) => {
  const { appState } = useContext(AppContext);
  const [lastUpdatedInterval, setLastUpdatedInterval] = useState(null);
  const [lastMessageTime, setLastMessageTime] = useState(null);
  const [lastUpdatedTime, setLastUpdatedTime] = useState(0);

  useEffect(() => {
    if (!liveData) return;
    if (liveData.dateReceived && !liveData.socket) {
      setLastMessageTime(liveData.dateReceived);
    } else if (liveData.socket) {
      if (lastUpdatedInterval) {
        clearInterval(lastUpdatedInterval);
      }
      setLastUpdatedTime(1);
      const startInterval = setInterval(() => {
        setLastUpdatedTime(prevState => prevState + 1);
      }, 1000);
      setLastUpdatedInterval(startInterval);
    }
  }, [liveData]);

  //Turn off interval
  useEffect(() => {
    return () => {
      clearInterval(lastUpdatedInterval);
    };
  }, []);

  const renderTime = () => {
    if (!lastMessageTime) return;
    let userTimeZone = '';
    let time = '';
    if (
      Intl &&
      Intl.DateTimeFormat() &&
      Intl.DateTimeFormat().resolvedOptions()
    ) {
      userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    if (appState.userSettings?.timeFormat === '12hr') {
      time = moment
        .tz(lastMessageTime, userTimeZone)
        .format('ddd MMM DD YYYY hh:mm A z');
    } else {
      time = moment
        .tz(lastMessageTime, userTimeZone)
        .format('ddd MMM DD YYYY kk:mm z');
    }

    return time;
  };

  return liveData && liveData.dateReceived ? (
    <Typography className={className}>
      Updated {lastUpdatedTime ? lastUpdatedTime + 's ago' : renderTime()}
    </Typography>
  ) : null;
};

export default LastUpdated;
