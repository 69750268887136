/**
 * converts temp to F or leave in C
 */
export function convertToF(temp, type = 'C', withUnit = false) {
  temp = +temp; //double check value is number
  const unitType = ` °${type}`;
  if (type === 'F' && temp !== 0) temp = temp * (9 / 5) + 32;
  temp = temp.toFixed(2);
  return isNaN(temp) ? null : withUnit ? temp + unitType : +temp;
}

// Fahrenheit to celcius
export const convertToC = f => (f - 32) * (5 / 9);

/**
 * converts pressure to PSI or leave in hPa
 */
export function convertToPSI(pressure, type = 'hPa', withUnit = false) {
  pressure = +pressure; //double check value is number
  const unitType = ` ${type}`;
  if (type === 'PSI') pressure = pressure * 0.0145037738;
  pressure = pressure.toFixed(2);
  return isNaN(pressure) ? null : withUnit ? pressure + unitType : +pressure;
}

// psi to hPa
export const convertToHPa = psi => psi * 68.9475729318;
