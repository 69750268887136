import React, { useContext } from 'react';
import MuiPhoneNumber from 'material-ui-phone-number';
import {
  Grid,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  makeStyles,
  FormControl,
  FormHelperText,
} from '@material-ui/core';

import { AppContext } from '../../../AppContext';
import { permissions as userPermissions } from '../../../permissions';
import ModalFormHeader from '../../common/ModalComponents/ModalFormHeader';

const useStyles = makeStyles(theme => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
  },
  selectMenu: {
    maxHeight: 58,
  },
}));

/**
 * Enter User Info
 * @param {Object} props
 * @props
 * userInfo: {
 *  fname: string,
 *  lname: string,
 *  email: string,
 *  permissions: number,
 *  id: number
 * }
 * errors: {
 *  fnameError: bool,
 *  lnameError: bool,
 *  emailError: bool,
 *  permissionsError: bool
 * }
 * handleUserInfoChange: () => {}
 */
export default function EnterUserInfo(props) {
  const {
    isEdit,
    handleUserInfoChange,
    userInfo: { fname, lname, email, permissions, id, phoneNo },
    errors: { fnameError, lnameError, emailError, permissionsError },
  } = props;

  const { appState } = useContext(AppContext);

  const classes = useStyles();

  /**
   * @returns {{disabled: bool, menuItemsAllowed: [<MenuItem>]}}
   * controlSelect takes current permissions level
   * returns list allowed to from select menu
   */
  const controlSelect = () => {
    let disabled = false;
    let menuItemsAllowed;
    const uP = appState.auth.userInfo.perm; // uP = userPerm

    // if user is editing their own profile
    // else subusers profile
    if (isEdit && appState.auth.userInfo.id === id) {
      const usersMenuItem = (
        <MenuItem value={permissions}>
          {userPermissions[permissions].title}
        </MenuItem>
      );
      menuItemsAllowed = [usersMenuItem];
    } else {
      menuItemsAllowed = userPermissions[uP].permission.map(permLevel => (
        <MenuItem value={permLevel} key={permLevel}>
          {userPermissions[permLevel].title}
        </MenuItem>
      ));
    }

    if (isEdit) {
      // is User
      if (appState.auth.userInfo.id === id) disabled = true;
      // is Admin
      else if (uP === 0 && permissions > 2) disabled = true;
      // is advanced
      else if (uP === 2 && permissions === 0) disabled = true;
      // is basic
      else if (uP === 1) disabled = true;
      // User permission is greater than user
      else if (uP !== 0 && uP < permissions) disabled = true;
    }

    return { disabled, menuItemsAllowed };
  };

  return (
    <Grid container item xs={12} spacing={2}>
      <ModalFormHeader
        header={
          !isEdit
            ? 'Enter Name and Email of the new user'
            : 'Please Update the Information Below'
        }
        desc={
          !isEdit &&
          'Once completed your new user will receive and invitation email for them to finish the sign up process.'
        }
      />
      <Grid item xs={12}>
        <TextField
          name='firstName'
          error={fnameError}
          className={classes.textField}
          fullWidth
          variant='outlined'
          placeholder='First Name*'
          label='First Name*'
          value={fname}
          onChange={e => handleUserInfoChange('fname', e.target.value)}
          style={{
            width: '105%',
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name='lastName'
          error={lnameError}
          className={classes.textField}
          fullWidth
          variant='outlined'
          placeholder='Last Name*'
          label='Last Name*'
          value={lname}
          onChange={e => handleUserInfoChange('lname', e.target.value)}
          style={{
            width: '105%',
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name='email'
          error={emailError}
          className={classes.textField}
          fullWidth
          variant='outlined'
          placeholder='Email*'
          label='Email*'
          value={email}
          onChange={e => handleUserInfoChange('email', e.target.value)}
          style={{
            width: '105%',
          }}
        />
      </Grid>
      {isEdit && (
        <Grid item xs={12}>
          <MuiPhoneNumber
            className={classes.textField}
            fullWidth
            variant='outlined'
            value={phoneNo}
            placeholder='Phone Number'
            label='Phone Number'
            defaultCountry={'us'}
            preferredCountries={['us']}
            disableAreaCodes={true}
            autoFormat={true}
            onChange={e => handleUserInfoChange('phoneNo', e)}
            countryCodeEditable={false}
            inputProps={{
              autoComplete: 'new-password',
            }}
            style={{
              width: '105%',
            }}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <FormControl
          variant='outlined'
          fullWidth
          className={classes.textField}
          style={{
            width: '105%',
            marginBottom: '10px',
          }}
        >
          <InputLabel id='demo-simple-select-outlined-label'>
            Permission Level*
          </InputLabel>
          <Select
            error={permissionsError}
            className={classes.selectMenu}
            labelid='demo-simple-select-outlined-label'
            id='demo-simple-select-outlined'
            name='permissions'
            fullWidth
            placeholder='Permission Level*'
            label='Permission Level*'
            value={permissions}
            onChange={e => handleUserInfoChange('permissions', e.target.value)}
            disabled={controlSelect().disabled}
          >
            {controlSelect().menuItemsAllowed}
          </Select>
        </FormControl>
        {permissions === 0 && (
          <FormHelperText>* Account Admin - Full Account Access</FormHelperText>
        )}
        {permissions === 1 && (
          <FormHelperText>
            * Account Basic User - View Only Access plus Resolve/Snooze Alerts
          </FormHelperText>
        )}
        {permissions === 2 && (
          <FormHelperText>
            * Account Advanced User - Create/View/Edit Access (No Subscription
            or Payment Access)
          </FormHelperText>
        )}
        {permissions === 99 && (
          <FormHelperText>
            * System Master Admin - Full System Access (DCS Employee Only)
          </FormHelperText>
        )}
        {appState.auth.userInfo.id === id && (
          <FormHelperText>
            ** Users cannot change their own Permission Level
          </FormHelperText>
        )}
      </Grid>
    </Grid>
  );
}
