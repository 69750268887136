/* eslint-disable react-hooks/exhaustive-deps, no-unused-vars*/

import _ from 'lodash';
import MUIDataTable from 'mui-datatables';
import { AppContext } from '../../../AppContext';
import BuildIcon from '@material-ui/icons/Build';
import SnoozeIcon from '@material-ui/icons/Snooze';
import React, { useState, Fragment, useContext } from 'react';
import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { navigate } from 'hookrouter';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  table: {
    margin: theme.spacing(4, 0),
    '& tr:nth-of-type(odd)': {
      backgroundColor:
        theme.palette.type === 'light'
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
    },
    '& tr:nth-of-type(even)': {
      backgroundColor:
        theme.palette.type === 'light' ? 'white' : theme.palette.grey[800],
    },
    '& .MuiToolbar-root': {
      display: 'flex'
    }
  },
  descriptionText: {
    fontSize: 12,
    color: theme.palette.type === 'light' ? '#747474' : '#FFF',
    // marginTop: "25px",
  },
  icon: {
    margin: theme.spacing(0, 0, 1.5, 0),
  },
  leadingIcon: {
    marginRight: theme.spacing(1),
  },
  trailingValue: {
    marginRight: theme.spacing(3),
  },
  snoozeIcon: {
    color: theme.palette.warning.main,
  },
  titleText: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.type === 'light' ? '#747474' : '#FFF',
  },
  valueName: {
    fontSize: 13,
    fontWeight: 600,
    color: theme.palette.type === 'light' ? '#747474' : '#FFF',
  },
  customHeadRender: {
    // borderBottom: "1px solid rgba(224, 224, 224, 1)",
    // backgroundColor: theme.palette.background.paper,
    display: 'none',
  },
  customBodyRender_box: { cursor: 'pointer', marginLeft: 20 },
}));


/**
 * HeaderAccountList for
 * the list in the dashboard
 *
 * @props
 * alerts: Array[Object]
 */
const HeaderAccountList = props => {
  const log = window.log('HeaderAccountList');
  const classes = useStyles();

  const {
    title, // String with the title of the List
    accounts, // Alerts array with the alerts objects
    closeMenu,
  } = props;

  const { appState, setAppState, snack } = useContext(AppContext);

  const tableOptions = {
    // count: customCount,
    // customFooter: customFooter ? () => customFooter() : null,
    onRowClick: (rowData, { dataIndex }) => {
      const accountInfo = accounts[dataIndex];
      switchAccount(accountInfo.label.id);
    },
    selectableRows: 'none',
    print: false,
    filter: false,
    download: false,
    jumpToPage: false,
    viewColumns: false,
    rowsPerPage: 5,
    rowsPerPageOptions: [],
    responsive: 'standard',
    search: true,
    customSearch: (searchQuery, currentRow, columns) => {
      let isFound = false;
      currentRow.forEach(col => {
        if (
          (col.email && col.email.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (col.accountNumber && col.accountNumber.toLowerCase().includes(searchQuery.toLowerCase()))
        ) {
          isFound = true;
        }
      });

      return isFound;
    }
  };

  const columns = [
    {
      name: 'label',
      label: 'Accounts',
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <Box
              className={classes.customBodyRender_box}
              display='flex'
              key={`account_list_info__${tableMeta.rowIndex}`}
            >
              <Box>
                <Box className={classes.valueName}>
                  <Typography style={{ fontWeight: 'bold' }}>
                    {value.email} ({value.accountNumber}){value.isDemoAccount ? ' - DEMO' : ''}
                  </Typography>
                  <Typography >
                    {value.lastLogin ? 'Last Login: ' + moment(value.lastLogin).format('l LT') : ''}
                  </Typography>
                </Box>
              </Box>
            </Box>
          );
        },
        customHeadRender: columnMeta => (
          <th
            className={classes.customHeadRender}
            key={`account__manager__col__${columnMeta.index}`}
          />
        ),
      }
    }
  ];

  const switchAccount = async (id) => {
    try {
      if (appState.parentAccount && appState?.parentAccount?.auth?.userInfo?.id === id) {
        setAppState({ ...appState?.parentAccount, isSwitched: true });
      } else {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/switch-account?id=${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': appState.auth.token
          }
        });

        const json = await response.json();

        if (json.success) {
          setAppState({
            ...appState,
            auth: {
              loggedIn: true,
              ...json.data,
            },
            parentAccount: appState.parentAccount ? appState.parentAccount : appState,
            isSwitched: true,
          });
          snack('Successfully switched accounts', 'success');
        } else {
          snack('Unable to switch accounts', 'error');
        }
      }
    } catch (err) {
      log("switchAccount -> ", err)
    }

    redirect();
    closeMenu();
  }

  const redirect = () => {
    const path = window.location.pathname;
    if (path.includes('/admin')) {
      navigate('/');
    } else if (path.includes('/reports')) {
      navigate('/reports/all');
    }
  }

  return (
    <MUIDataTable
      className={classes.table}
      options={tableOptions}
      data={accounts}
      columns={columns}
      title={<Typography className={classes.titleText}>{title}</Typography>}
    />
  );
};

export default HeaderAccountList;
