import React, { useState, useEffect, useContext } from 'react';

import { Grid, Container, Button } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { A, navigate } from 'hookrouter';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import DashboardIcon from '../img/icons/DashboardIcon';
import LocationIcon from '@material-ui/icons/Room';
import GatewayIcon from '@material-ui/icons/Router';
import AlertIcon from '@material-ui/icons/Announcement';
import SensorIcon from '../img/icons/SensorIcon';

import { AppContext } from '../AppContext';

import LastAlertsList from './Dashboard/LastAlertsList.js';
import Tile from './Dashboard/Tile.js';
import DevicesStatusList from './Dashboard/DevicesStatusList.js';
import MapLayout from './common/MapLayout';
import HeaderBar from './Generic/HeaderBar';
import LoadingSpinner from './Generic/LoadingSpinner';
import TabPanel from './Generic/TabPanel';

// mobile components

import {
  MobileCustomTab,
  MobileCustomTabs,
  MobileTabsContainer,
  MobileTabPanelContainer,
} from './Generic/Mobile/MobileTabs';

import MobileBottomButton, {
  MobileBottomButtonSpacer,
} from './Generic/Mobile/MobileBottomButton';
import { FullViewContainer } from './Generic/Mobile/MobileContainers';

import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: '#fff' },
  tileContainer: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  headerContainer: {
    padding: 0,
    width: '100%',
    marginRight: 14,
    [theme.breakpoints.up('xs')]: {
      marginRight: 0,
    },
  },
  mobileTopSection: {
    padding: '0px 15px 25px 15px',
    background: theme.palette.primary.main,
    borderRadius: '0px 0px 15px 15px',
  },
  // mobileTableList: {
  //   // Total height minus the height of the header and the tabs
  //   height: 'calc(100vh)',
  //   overflow: 'scroll',
  //   borderRadius: '0px',
  //   marginTop: '0px',
  // },
  // mobileBottomButton: {
  //   width: '100%',
  //   position: 'absolute',
  //   bottom: 0,
  //   height: 45,
  //   borderRadius: "15px 15px 0px 0px"
  // }
}));

const Dashboard = props => {
  const log = window.log('Dashboard');
  const classes = useStyles();
  const {
    appState,
    setAppState,
    setLogoutState,
    openLocationModal,
    openGatewayModal,
    openSensorModal,
    openUserModal,
    openAlertModal,
    setOpenAddingModal,
    setContextLocationCount,
    setContextAlertCount,
    setContextGatewayCount,
    setContextSensorCount,
    alertsArray,
    alertCount,
    snack,
    fetchCompanyCounts,
    contextLocationCount,
    contextGatewayCount,
    contextSensorCount,
    contextOfflineSensor,
    contextResolvedAlerts,
  } = useContext(AppContext);

  const [state, setState] = useState({
    locationCount: '-',
    gatewayCount: '-',
    sensorCount: '-',
    activeAlertsArray: [],
    resolvedAlertsArray: [],
    devicesArray: [],
    loading: false,
    networkErr: false,
  });

  /**
   * Mobile specific components and states
   */

  const [mobileTabsValue, setMobileTabsValue] = useState(0);

  const mobileTabsChange = (event, newValue) => {
    setMobileTabsValue(newValue);
  };

  const isMobileSize = isWidthDown('xs', props.width);

  /**
   * End of Mobile specific components and states
   */

  const fetchAllAlertsPage = async () => {
    log('fetchAlertCounts: Inside of fetchAlertCounts');

    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/alert/allPage',
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
        },
      );

      const json = await response.json();

      if (json.success) {
        setState(prevState => {
          return { ...prevState, activeAlertsArray: json.data.alertsArray };
        });
      } else {
        json.errors.forEach(err => {
          snack(err.msg, 'error');
          if (err.type === 'token') setLogoutState(true);
        });
      }
    } catch (err) {
      snack('Network Error', 'error');
      setState({ ...state, networkErr: true });
    }
  };

  useEffect(() => {
    fetchAllAlertsPage();
  }, [
    openLocationModal,
    openGatewayModal,
    openSensorModal,
    openAlertModal,
    openUserModal,
    alertCount,
  ]);

  useEffect(() => {
    fetchCompanyCounts();
  }, [
    openLocationModal,
    openGatewayModal,
    openSensorModal,
    openAlertModal,
    openUserModal,
  ]);

  //After DCS Admin switches account, fetch that account's data
  useEffect(() => {
    if (!appState.isSwitched) return;
    fetchCompanyCounts();
    fetchAllAlertsPage();
    setAppState({ ...appState, isSwitched: false });
  }, [appState.isSwitched]);

  const openAddModal = () => {
    setOpenAddingModal(true);
  };

  const tableHeight = 'calc(100vh - 378px)';

  const mobileTilePadding = '12px 15px';

  return state.loading ? (
    <LoadingSpinner />
  ) : state.networkErr ? null : (
    <div>
      {isMobileSize ? (
        <FullViewContainer>
          {/* Tile Entity Components */}
          <Grid className={classes.mobileTopSection} container item>
            <Grid item container spacing={2}>
              <Grid
                onClick={() => navigate('/location/all')}
                className={classes.tileContainer}
                item
                xs={6}
                sm={6}
                md={3}
              >
                <Tile
                  padding={mobileTilePadding}
                  height='auto'
                  entityName='Locations'
                  underlineColor='blue'
                  numberOfEntities={contextLocationCount}
                  scaleFont={true}
                  icon={
                    <LocationIcon style={{ fontSize: 35, color: '#08B3FF' }} />
                  }
                />
              </Grid>

              <Grid
                onClick={() => navigate('/gateway/all')}
                className={classes.tileContainer}
                item
                xs={6}
                sm={6}
                md={3}
              >
                <Tile
                  padding={mobileTilePadding}
                  height='auto'
                  entityName='Gateways'
                  underlineColor='green'
                  numberOfEntities={contextGatewayCount}
                  scaleFont={true}
                  icon={
                    <GatewayIcon style={{ fontSize: 35, color: '#59C754' }} />
                  }
                />
              </Grid>

              <Grid
                onClick={() => navigate('/device/all')}
                className={classes.tileContainer}
                item
                xs={6}
                sm={6}
                md={3}
              >
                <Tile
                  padding={mobileTilePadding}
                  height='auto'
                  entityName='Sensors'
                  underlineColor='lightBlue'
                  numberOfEntities={contextSensorCount}
                  scaleFont={true}
                  icon={
                    <SensorIcon style={{ fontSize: 35, color: '#58A3B9' }} />
                  }
                />
              </Grid>
              <Grid
                onClick={() => navigate('/alert/all')}
                className={classes.tileContainer}
                item
                xs={6}
                sm={6}
                md={3}
              >
                <Tile
                  padding={mobileTilePadding}
                  height='auto'
                  entityName='Alerts'
                  underlineColor='red'
                  numberOfEntities={alertCount}
                  scaleFont={true}
                  icon={
                    <AlertIcon style={{ fontSize: 35, color: '#FF6A6A' }} />
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          {/* Table tabs selector */}
          <MobileTabsContainer>
            <MobileCustomTabs
              value={mobileTabsValue}
              centered
              onChange={mobileTabsChange}
              aria-label='alert_sensors_table_tabs'
              indicatorColor='primary'
              textColor='primary'
            >
              <MobileCustomTab label='Active alerts' />
              <MobileCustomTab label='Resolved alerts' />
              <MobileCustomTab label='Offline sensors' />
            </MobileCustomTabs>
          </MobileTabsContainer>

          <MobileTabPanelContainer>
            <TabPanel value={mobileTabsValue} index={0}>
              {/* Active alerts list */}
              <LastAlertsList
                listHeight={tableHeight}
                alerts={state.activeAlertsArray}
                actionTitle={false}
                mobileLayout={isMobileSize}
              />
            </TabPanel>
            <TabPanel value={mobileTabsValue} index={1}>
              {/* Resolved alerts */}
              <DevicesStatusList
                listHeight={tableHeight}
                className={classes.mobileTableList}
                devices={contextResolvedAlerts}
                entity={'alerts'}
                actionTitle={false}
                mobileLayout={isMobileSize}
              />
            </TabPanel>
            <TabPanel value={mobileTabsValue} index={2}>
              {/* Offline Devices */}
              <DevicesStatusList
                listHeight={tableHeight}
                className={classes.mobileTableList}
                devices={contextOfflineSensor}
                actionTitle={false}
                mobileLayout={isMobileSize}
              />
            </TabPanel>
          </MobileTabPanelContainer>
          <MobileBottomButton onClick={openAddModal} />
        </FullViewContainer>
      ) : (
        <div>
          <HeaderBar
            dashboardHeader={true}
            leadingIcon={
              <DashboardIcon
                style={{
                  fontSize: '1.75rem',
                  marginLeft: 7,
                }}
                color='primary'
              />
            }
            title='Dashboard'
            searchInput={false}
            buttonAddEvent={openAddModal}
          />
          {/* Tile Entity Components */}
          <Grid
            style={{ padding: 5, marginLeft: 6, marginRight: 6 }}
            item
            xs={12}
          >
            <Grid container spacing={3}>
              <Grid
                onClick={() => navigate('/location/all')}
                className={classes.tileContainer}
                item
                xs={12}
                sm={6}
                md={3}
              >
                <Tile
                  entityName='Locations'
                  underlineColor='blue'
                  numberOfEntities={contextLocationCount}
                  scaleFont={false}
                  iconSize='55px'
                  icon={
                    <LocationIcon style={{ fontSize: 55, color: '#08B3FF' }} />
                  }
                />
              </Grid>

              <Grid
                onClick={() => navigate('/gateway/all')}
                className={classes.tileContainer}
                item
                xs={12}
                sm={6}
                md={3}
              >
                <Tile
                  entityName='Gateways'
                  underlineColor='green'
                  numberOfEntities={contextGatewayCount}
                  scaleFont={false}
                  iconSize='55px'
                  icon={
                    <GatewayIcon style={{ fontSize: 55, color: '#59C754' }} />
                  }
                />
              </Grid>

              <Grid
                onClick={() => navigate('/device/all')}
                className={classes.tileContainer}
                item
                xs={12}
                sm={6}
                md={3}
              >
                <Tile
                  entityName='Sensors'
                  underlineColor='lightBlue'
                  numberOfEntities={contextSensorCount}
                  scaleFont={false}
                  iconSize='55px'
                  icon={
                    <SensorIcon style={{ fontSize: 55, color: '#58A3B9' }} />
                  }
                />
              </Grid>
              <Grid
                onClick={() => navigate('/alert/all')}
                className={classes.tileContainer}
                item
                xs={12}
                sm={6}
                md={3}
              >
                <Tile
                  entityName='Alerts'
                  underlineColor='red'
                  numberOfEntities={alertCount}
                  scaleFont={false}
                  iconSize='55px'
                  icon={
                    <AlertIcon style={{ fontSize: 55, color: '#FF6A6A' }} />
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          {/* Map component To do */}
          <Container style={{ maxWidth: 'inherit', marginTop: 10 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={8}>
                <MapLayout
                  loading={state.loading}
                  mapCenter={{ lat: 37.376, lng: -122.034 }}
                  title='locations'
                />
              </Grid>
              {/* Latest alerts list */}
              <Grid item xs={12} sm={12} md={4}>
                <LastAlertsList
                  dashboardTable={true}
                  title='Active Alerts'
                  alerts={alertsArray}
                />
              </Grid>
              {/* Offline Devices */}
              <Grid item xs={12} sm={12} md={6}>
                <DevicesStatusList
                  title='Offline Sensors'
                  devices={contextOfflineSensor}
                />
              </Grid>
              {/* Resolved alerts */}
              <Grid item xs={12} sm={12} md={6}>
                <DevicesStatusList
                  title='Resolved Alerts'
                  devices={contextResolvedAlerts}
                  entity={'alerts'}
                />
                {/* </Grid> */}
              </Grid>
            </Grid>
          </Container>
        </div>
      )}
      {/* <a href="misensors://device/all">Mobile Link</a> */}
      {/* </Grid> */}
    </div>
  );
};

export default withWidth()(Dashboard);
