import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Box, Tab, Grid, Tabs, CardActions, Button } from '@material-ui/core';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

export function SettingsNav(props) {
  const { tabs, handleCancel, handleSubmit, isMobileSize = false } = props;
  const useStyles = makeStyles(theme => ({
    root: {
      height: '-webkit-fill-available',
      flexWrap: 'nowrap',
    },
    tab: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      fontSize: 12,
    },
    wrapper: {
      alignItems: 'start',
    },
    tabs: {
      borderTop: `1px solid ${theme.palette.divider}`,
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    tabPanel: {
      flexGrow: 1,
      backgroundColor:
        theme.palette.type === 'light'
          ? '#F4F5F5'
          : theme.palette.background.default,
    },
    tabPanelChild: {
      paddingLeft: 10,
      paddingTop: 10,
      padding: isMobileSize ? 0 : 32,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    buttonContainer: {
      justifyContent: 'space-between',
      paddingLeft: 0,
      flex: 2,
      alignItems: 'flex-end',
    },
    buttons: { width: 112, fontSize: 13 }
  }));
  
  
  const theme = useTheme();
  const classes = useStyles(theme);
  

  const [value, setValue] = useState(0);

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  const configTabs = () =>
    tabs.map((tab, index) => (
      <Tab
        key={index}
        label={tab.tabLabel}
        {...a11yProps(index)}
        className={classes.tab}
        classes={{ wrapper: classes.wrapper }}
      />
    ));

  const configPanels = () =>
    tabs.map((tab, index) => (
      <TabPanel
        container
        key={index}
        value={value}
        index={index}
        className={classes.tabPanel}
        boxClass={classes.tabPanelChild}
      >
        {tab.content}
        <CardActions container item className={classes.buttonContainer}>
          <Button
            color='primary'
            onClick={() => handleCancel()}
            size='large'
            variant='outlined'
            className={classes.buttons}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            size='large'
            variant='contained'
            color='primary'
            className={classes.buttons}
          >
            Save
          </Button>
        </CardActions>
      </TabPanel>
    ));

  return (
    <Grid container item className={classes.root}>
      <Tabs
        orientation='vertical'
        value={value}
        onChange={handleChange}
        className={classes.tabs}
      >
        {configTabs()}
      </Tabs>
      {configPanels()}
    </Grid>
  );
}

function TabPanel(props) {
  const { children, value, index, boxClass, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box className={boxClass}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

