import _ from 'lodash';
import React from 'react';

import { SettingsCheckBoxes } from './SettingsCheckBoxes';

export function LayoutTab(props) {
  const { pageDisplay, setPageDisplay } = props;

  const pageBoxes = [
    { name: 'listView', check: true, label: 'List View' },
    { name: 'cardView', check: false, label: 'Card View' },
  ];

  return (
    <SettingsCheckBoxes
      currentValue={pageDisplay}
      setCurrentValue={setPageDisplay}
      checkBoxData={pageBoxes}
      label='Page Display'
    />
  );
}
