import React, { useState, Fragment } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { ChromePicker } from 'react-color';

const useStyles = makeStyles(theme => ({
  textField: {
    marginTop: 11,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    width: 96,
    height: 25,
    overflow: 'hidden',
    border: '1px solid #9D9D9D',
  },
  gatewaySelect: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
  },
  fieldPadding: {
    paddingBottom: theme.spacing(4),
  },
  textFieldPadding: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  formControlLabel: {
    fontWeight: 'bold',
    fontSize: 18,
    marginBottom: theme.spacing(2),
  },
  size: {
    width: 50,
    height: 50,
  },
  sizeIcon: {
    fontSize: 50,
  },
  containerColor: {
    height: 23,
  },
  formControl: {
    width: '60%',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
  textContainer: {
    paddingLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    color: '#747474',
    font: 'Regular 11px/13px Open Sans',
  },
  popover: {
    position: 'absolute',
    zIndex: '100',
    transform: 'translate(108px,-62px) !important',
  },
  mobilePopover : {
    position: 'absolute',
    zIndex: '100',
    bottom: '10vh',
    left: '10vw'
  },
  pickerHolder: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'transparent',
    zIndex: 1,
  },
}));

const ColorPicker = props => {
  const { primaryColor, handlePrimaryColorChange, isMobile } = props;
  const classes = useStyles();

  const [state, setState] = useState({
    displayColorPicker: false,
    color: '#08B3FFFF',
  });

  const handleClick = () => {
    setState({
      ...state,
      displayColorPicker: !state.displayColorPicker,
    });
  };

  return (
    <Fragment>
      <Grid container className={classes.textField} onClick={handleClick}>
        <Grid
          item
          xs={3}
          style={{ backgroundColor: primaryColor }}
          className={classes.containerColor}
        />
        <Grid item xs={9} className={classes.textContainer}>
          <p style={{ textAlign: 'left', margin: 0, fontSize: 11 }}>Color</p>
        </Grid>
      </Grid>
      {state.displayColorPicker ? (
        <div className={isMobile ? classes.mobilePopover : classes.popover}>
          <ChromePicker
            color={primaryColor}
            onChangeComplete={handlePrimaryColorChange}
          />
        </div>
      ) : null}
      {state.displayColorPicker ? (
        <div onClick={handleClick} className={classes.pickerHolder} />
      ) : null}
    </Fragment>
  );
};

export default ColorPicker;
