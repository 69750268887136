import _ from 'lodash';
import { navigate } from 'hookrouter';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import SmsFailedIcon from '@material-ui/icons/SmsFailed';
import React, { useRef, useState, useEffect, useContext } from 'react';
import {
  Grow,
  Link,
  Badge,
  Popper,
  IconButton,
  TableFooter,
  ClickAwayListener,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { AppContext } from '../../../AppContext';
import ActiveAlertsList from '../../Alerts/AllAlertsComponents/ActiveAlertsList';
import LastAlertsList from "../../Dashboard/LastAlertsList.js";
import { defaultSensorProfile } from '../../common/ModalComponents/DefaultSensorProfile';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

const useStyles = makeStyles(theme => {
  return {
    popper: {
      top: '-15px !important', //This overides the MUI-Popper absolute value
      zIndex: '200',
      marginRight: '50px',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '12px',
        zIndex: '1250'
      },
    },
    footer: {
      width: '415px',
      display: 'flex',
      justifyContent: 'center',
      padding: '8px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    link: {
      color: '#0870FF',
      cursor: 'pointer',
    },
    triangle: {
      backgroundColor: theme.palette.type === 'light' ? '#FFF' : '#424242',
      transform: 'rotate(45deg)',
      alignSelf: 'flex-start',
      width: '15px',
      height: '15px',
      position: 'absolute',
      top: '25px',
      right: '52px',
      [theme.breakpoints.down('sm')]: {
        right: '48px',
        display: 'none'
      },
    },
  };
});

/**
 * Returns the Alerts list from the header
 * @param {*} props
 */
export default function HeaderAlerts(props) {
  const theme = useTheme();
  const classes = useStyles();
  const { dividerStyle, isSmallDevice } = props;
  const { alertCount, alertsArray } = useContext(AppContext);
  const isMobileSize = useMediaQuery(theme.breakpoints.down('sm'))

  const [openAlertsMenu, setOpenAlertsMenu] = useState(false);
  const [icons, setIcons] = useState([]);

  const anchorRef = useRef(null);
  useEffect(() => {
    // This extracts the icon value from default sensor profile
    // and puts it in the format ActiveAlertList is requiring
    const setIconsArr = () => {
      const tempIcons = [];
      _.forEach(
        defaultSensorProfile(theme, undefined, false, isSmallDevice ? 30 : 35),
        item => {
          const clonedItem = _.cloneDeep(item.icon);
          clonedItem['name'] = item.name;
          clonedItem.props.filledcolor = theme.palette.iconColorAlert.filled;
          tempIcons.push(clonedItem);
        },
      );
      setIcons(tempIcons);
    };
    setIconsArr();
  }, [theme.palette.type]);

  // When user clicks on Alert Icon in Header
  const handleToggleAlertsMenu = () => {
    setOpenAlertsMenu(!openAlertsMenu);
  };

  // When user clicks away from list component
  const handleCloseAlertsMenu = () => {
    setOpenAlertsMenu(false);
  };

  // When user clicks on link inside the footer
  const handleNav = () => {
    setOpenAlertsMenu(false);
    navigate('/alert/all');
  };

  // Overrides the table's footer
  const customFooter = () => {
    return (
      <TableFooter className={classes.footer}>
        <Link
          className={classes.link}
          onClick={() => {
            handleNav();
          }}
        >
          View All Alerts
        </Link>
      </TableFooter>
    );
  };

  return [
    <div className={dividerStyle} key='alert_divider' />,
    <div key='alert_icon'>
      <IconButton
        onClick={
          isSmallDevice ? handleToggleAlertsMenu : handleToggleAlertsMenu
        }
        aria-controls={openAlertsMenu ? 'menu-list-grow' : undefined}
        aria-haspopup='true'
        ref={anchorRef}
      >
        <Badge badgeContent={alertCount} color='error'>
          <SmsFailedIcon style={{ color: 'white' }} />
        </Badge>
      </IconButton>
      <Popper
        className={classes.popper}
        open={openAlertsMenu}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement='top'
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <ClickAwayListener onClickAway={handleCloseAlertsMenu}>
              <div>
                <div className={classes.triangle} />
                {isMobileSize ? (
                  <LastAlertsList
                    title='MOST RECENT ALERTS'
                    listHeight={'52vh'}
                    alerts={alertsArray.slice(alertsArray.length - 5)}
                    // alerts={alertsArray}
                    customCount={5}
                    actionTitle={true}
                    mobileLayout={isMobileSize}
                    paddingTop={null}
                    isMobile
                    customFooter={customFooter}
                  />
                )
                : 
                  <ActiveAlertsList
                    title='MOST RECENT ALERTS'
                    icons={icons}
                    alerts={alertsArray}
                    customFooter={customFooter}
                    customCount={5}
                    searchOn={false}
                    isHeader={true}
                  />
                }
                
              </div>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
    </div>,
  ];
}
