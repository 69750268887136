import _ from 'lodash';
import { Grid, Button } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  planButton: {
    fontSize: 13,
    width: 157,
    height: 48,
    padding: '10px 0',
    backgroundColor: theme.palette.type === 'light' ? '#FFFFFF' : '#424242',
    boxShadow: '0 0 5px #CCCCCC',
  },
  planButtonMobile: {
    fontSize: 13,
    width: '100%',
    height: 48,
    padding: '10px 0',
    backgroundColor: theme.palette.type === 'light' ? '#FFFFFF' : '#424242',
    boxShadow: '0 0 5px #CCCCCC',
  },
  currentButton: {
    border: '3px #59C754 solid !important',
    backgroundColor: `${
      theme.palette.type === 'light' ? '#FFFFFF' : '#424242'
    } !important`,
  },
  planButtonContainer: {
    margin: '15px 0',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  singleButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  linkButtonContainer: {
    marginTop: 5,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  linkButton: {
    fontSize: 10,
    padding: '5px 10px',
  },
  selectedButton: {
    color: '#08B3FF',
    border: '1px #08B3FF solid',
    backgroundColor: '#08B3FF1A',
  },
  pendingButton: {
    border: '3px #0B9EDE solid !important',
    backgroundColor: `${
      theme.palette.type === 'light' ? '#FFFFFF' : '#424242'
    } !important`,
  },
  title: { margin: '0 0 8px 0', fontSize: 9 },
  currentPlan: {
    color: '#59C754',
  },
  pendingPlan: {
    color: '#0B9EDE',
  },
}));

export function SelectPlan(props) {
  const {
    subLevels,
    selSubLevel,
    selectedPlan,
    setSelSubLevel,
    currentSubscription,
    isMobile
  } = props;

  const [currSubLevel, setCurrSubLevel] = useState();
  const [pendSubLevel, setPendSubLevel] = useState();

  const theme = useTheme();

  useEffect(() => {
    const { id } = currentSubscription.planInfo;
    const idArr = id.split('-');
    if (idArr[1]) setCurrSubLevel(idArr[1]);
    else setCurrSubLevel('BASIC');

    if (currentSubscription.pendingSubscription?.planId) {
      let pendSubLevelId = currentSubscription.pendingSubscription?.planId.split(
        '-',
      )[1];
      if (!pendSubLevelId) pendSubLevelId = 'BASIC';
      setPendSubLevel(pendSubLevelId);
    }
  }, [currentSubscription]);

  useEffect(() => {
    if (subLevels[selSubLevel].noOfSensors < selectedPlan.sensors) {
      selectHigherTierPlan();
    }
  }, [selectedPlan, selSubLevel]);

  const classes = useStyles(theme);

  /**
   * Auto selects higher tier based on selected sensors
   */
  const selectHigherTierPlan = () => {
    if (selSubLevel === 'BASIC') {
      if (selectedPlan.sensors < subLevels.PRO.noOfSensors) {
        setSelSubLevel('PREMIUM');
      } else {
        setSelSubLevel('PRO');
      }
    }
    if (selSubLevel === 'PREMIUM') {
      setSelSubLevel('PRO');
    }
  };

  const determineTitle = type => {
    let title = null;
    if (currSubLevel === type) {
      title = (
        <p className={`${classes.currentPlan} ${classes.title}`}>
          Current Plan
        </p>
      );
    }
    if (pendSubLevel === type && pendSubLevel !== currSubLevel) {
      title = (
        <p className={`${classes.pendingPlan} ${classes.title}`}>
          {`Pending (${currentSubscription.nextBillingDate})`}
        </p>
      );
    }
    return title;
  };

  const createButtons = () => {
    const subLevelTypes = ['BASIC', 'PREMIUM', 'PRO'];
    return _.map(subLevelTypes, type => (
      <div className={classes.singleButtonContainer} key={`sub_level_${type}`} style={isMobile? {width: '30vw'}:null}>
        {determineTitle(type)}
        <Button
          onClick={() => {
            setSelSubLevel(type);
          }}
          className={`${isMobile ? classes.planButtonMobile : classes.planButton} ${
            currSubLevel === type && classes.currentButton
          } ${selSubLevel === type && classes.selectedButton} ${
            pendSubLevel === type &&
            pendSubLevel !== currSubLevel &&
            classes.pendingButton
          }`}
        >
          {subLevels[type].name.toUpperCase()}
        </Button>
      </div>
    ));
  };

  const handleLink = () => {
    window.open('https://www.misensors.com/subscriptions', '_blank');
  };
  return (
    <Grid xs={12} item container style={{ margin: isMobile ? '0' : '0 47px' }}>
      <div className={classes.planButtonContainer}>{createButtons()}</div>
      <div className={classes.linkButtonContainer}>
        <Button
          variant='outlined'
          color='primary'
          className={classes.linkButton}
          onClick={() => handleLink()}
        >
          VIEW SUBSCRIPTION PLANS
        </Button>
      </div>
    </Grid>
  );
}
