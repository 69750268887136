import React, { useState, useContext, Fragment } from "react";
import { AppContext } from "../../AppContext";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  Button,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControl,
  FormHelperText,
  Box,
  Input,
  Chip,
  Typography
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import TermAndConditions from "../Generic/TermAndConditions";

import LoginLayout from "./LoginLayout";

import { A, navigate } from "hookrouter";

import withWidth, { isWidthDown } from '@material-ui/core/withWidth';



const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
    marginTop: 50,
  },
  centerGrid: {
    margin: ' 0 auto',
  },
  textField: {
    width: '100%',
    margin: '5px 0',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  resendEmailButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  formContainer: {
    width: '80%',
    justifyContent: 'center',
  },
  formContainerTerms: {
    width: '100%',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  button: {
    width: 200,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: ".5rem",
      borderRadius: '50px',
      fontWeight: 'bold'
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  termsContainer: {
    width: 'calc(100% + 19px)',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% + 8px)',
    },
  },
  mobileViewContainer: {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  mobileChips: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    width: '25vw'
  },
}));

const Signup = (props) => {
  const log = window.log("Signup");

  const { snack } = useContext(AppContext);
  const [formStep, setFormStep] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  const handleClose = () => {
    setOpenBackdrop(false);
  };
  const handleToggle = () => {
    setOpenBackdrop(!openBackdrop);
  };

  /**
    * Mobile specific components and states
    */

  const isMobileSize = isWidthDown('xs', props.width)

  const theme = useTheme();

  /**
   * End of Mobile specific components and states
   */


  const [state, setState] = useState({
    fname: '',
    lname: '',
    company: '',
    email: '',
    password: '',
    passwordConfirmation: '',
  });

  const [err, setErr] = useState({
    fname: false,
    lname: false,
    company: false,
    email: false,
    password: false,
    passwordConfirmation: false,
  });

  const classes = useStyles();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const nextStep = () => {
    const pwRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (
      state.email === '' ||
      state.password === '' ||
      state.passwordConfirmation === ''
    ) {
      setErr(prevState => {
        return {
          ...prevState,
          email: !state.email,
          password: !state.password,
          passwordConfirmation: !state.passwordConfirmation,
        };
      });
      handleClose();
      snack('Something went wrong. Please double check all fields.', 'error');
      return false;
    } else if (state.password !== state.passwordConfirmation) {
      setErr(prevState => {
        return {
          ...prevState,
          password: false,
          passwordConfirmation: false,
        };
      });
      handleClose();
      snack('Password does not match.', 'error');
      return false;
    } else if (!pwRegex.exec(state.password)) {
      //Password requirements - at least 8 characters, at least 1 uppercase, at least 1 lowercase, at least 1 special character
      setErr(prevState => {
        return {
          ...prevState,
          password: false,
          passwordConfirmation: false,
        };
      });
      handleClose();
      snack('Password requirements were not met.', 'error');
      return false;
    }
    setFormStep(1);
  };
  const attempt = async () => {
    handleToggle();
    try {
      const pwRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

      if (
        state.email === '' ||
        state.password === '' ||
        state.passwordConfirmation === ''
      ) {
        setErr(prevState => {
          return {
            ...prevState,
            email: !state.email,
            password: !state.password,
            passwordConfirmation: !state.passwordConfirmation,
          };
        });
        handleClose();
        snack('Something went wrong. Please double check all fields.', 'error');
        return false;
      } else if (state.password !== state.passwordConfirmation) {
        setErr(prevState => {
          return {
            ...prevState,
            password: false,
            passwordConfirmation: false,
          };
        });
        handleClose();
        snack('Password does not match.', 'error');
        return false;
      } else if (!pwRegex.exec(state.password)) {
        //Password requirements - at least 8 characters, at least 1 uppercase, at least 1 lowercase, at least 1 special character
        setErr(prevState => {
          return {
            ...prevState,
            password: false,
            passwordConfirmation: false,
          };
        });
        handleClose();
        snack('Password requirements were not met.', 'error');
        return false;
      }

      const response = await fetch(
        process.env.REACT_APP_API_URL + '/auth/signup',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...state,
            eulaConfirmed: agreeButtonState,
            sendPromotions: promotionsCheckState,
          }),
        },
      );
      const json = await response.json();

      if (json.success) {
        handleClose();
        // success
        snack('Sign up successful. Please verify your email.', 'success');
        // redirect to login page (root route when not auth)
        setFormStep(2);
      } else {
        handleClose();
        setFormStep(0);
        //handle errs
        let errorObj = {};
        json.errors.forEach(error => {
          // add error to err object
          errorObj = { ...errorObj, [error.type]: true };
          // err alert
          snack(error.msg, 'error');
        });
        // write new object at top level per hook rules
        setErr(errorObj);
      }
    } catch (err) {
      handleClose();
      log(err);
    }
  };

  const resendEmail = () => {
    log(state);
  };

  const subtitles = [
    'Welcome, create your new admin account below.',
    'Please read the following.',
    "Almost done! Let's confirm your email.\nJust click on the link in the email we sent you.",
  ];

  const [agreeButtonState, setAgreeButtonState] = useState(false);

  const reachBottomFunction = () => {
    setAgreeButtonState(true);
  };

  const checkFunction = () => {
    setPromotionsCheckState(!promotionsCheckState);
  };
  const [promotionsCheckState, setPromotionsCheckState] = useState(true);

  const formContents = [
    {
      form: (
        <Grid container>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              error={err.email}
              label='Email Address'
              value={state.email}
              className={classes.textField}
              onChange={e =>
                setState({ ...state, email: e.currentTarget.value })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.textField} variant='outlined'>
              <InputLabel htmlFor='outlined-adornment-password'>
                Password
              </InputLabel>
              <OutlinedInput
                id='outlined-adornment-password'
                className={classes.textField}
                fullWidth
                variant='outlined'
                label='Password'
                placeholder='Password'
                value={state.password}
                name='password'
                onChange={e =>
                  setState({ ...state, password: e.currentTarget.value })
                }
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    document.getElementById('submit').focus();
                    nextStep();
                  }
                }}
                error={err.password}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.textField} variant='outlined'>
              <InputLabel htmlFor='outlined-adornment-password-confirmation'>
                Password Confirmation
              </InputLabel>
              <OutlinedInput
                id='outlined-adornment-password-confirmation'
                className={classes.textField}
                fullWidth
                variant='outlined'
                label='Password Confirmation'
                placeholder='Password Confirmation'
                value={state.passwordConfirmation}
                name='password confirmation'
                onChange={e =>
                  setState({
                    ...state,
                    passwordConfirmation: e.currentTarget.value,
                  })
                }
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    document.getElementById('submit').focus();
                    nextStep();
                  }
                }}
                error={err.passwordConfirmation}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText id='component-error-text'>
                * Requirements: at least 8 characters, at least 1 uppercase, at
                least 1 lowercase, at least 1 number, at least 1 special
                character (@$!%*?&)
                <br />
                ** Didn't receive link? Please check your spam folder or
                quarantine emails
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      ),
      buttons: (
        <Grid
          container
          item
          xs={12}
          className={classes.buttons}
        >
          <A
            className={classes.button}
            href='/'
            style={{ textDecoration: 'none' }}
          >
            <Button
              variant='outlined'
              className={classes.button}
              size='large'
              color='primary'
            >
              Cancel
            </Button>
          </A>
          <Button
            id='submit'
            variant='contained'
            className={classes.button}
            size='large'
            color='primary'
            onClick={() => nextStep()}
          >
            Sign Up
          </Button>
          <Backdrop
            className={classes.backdrop}
            open={openBackdrop}
            onClick={handleClose}
          >
            <CircularProgress color='inherit' />
          </Backdrop>
        </Grid>
      ),
    },
    {
      form: (
        <Box className={classes.formContainerTerms}>
          <Grid className={classes.termsContainer} container spacing={1}>
            <TermAndConditions
              reachBottomFunction={reachBottomFunction}
              checked={promotionsCheckState}
              checkFunction={checkFunction}
              donwloadButton={true}
            />
          </Grid>
        </Box>
      ),
      buttons: (
        <Grid container item xs={12} className={classes.buttons}>
          <Button
            id='cancel'
            variant='outlined'
            className={classes.button}
            size='large'
            color='primary'
            onClick={() => setFormStep(0)}
          >
            cancel
          </Button>
          <Button
            id='submit'
            variant='contained'
            className={classes.button}
            size='large'
            color='primary'
            disabled={!agreeButtonState}
            onClick={() => attempt()}
          >
            I Agree
          </Button>
        </Grid>
      ),
    },
    {
      form: (
        <Grid container>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              error={err.email}
              label='Email To Confirm'
              value={state.email}
              className={classes.textField}
              onChange={e =>
                setState({ ...state, email: e.currentTarget.value })
              }
            />
          </Grid>
          <Grid item xs={12} className={classes.resendEmailButtons}>
            <Button
              className={classes.button}
              id='resentEmail'
              variant='outlined'
              color='primary'
              onClick={() => attempt()}
            >
              Resend Email
            </Button>
            <Backdrop
              className={classes.backdrop}
              open={openBackdrop}
              onClick={handleClose}
            >
              <CircularProgress color='inherit' />
            </Backdrop>
          </Grid>
        </Grid>
      ),
      buttons: (
        <Grid item xs={12} className={classes.resendEmailButtons}>
          <A
            className={classes.button}
            href='/'
            style={{ textDecoration: 'none' }}
          >
            <Button
              id='submit'
              variant='contained'
              className={classes.button}
              size='large'
              color='primary'
            >
              Close
            </Button>
          </A>
        </Grid>
      ),
    },
  ];

  const mobileFormContents = [
    // Step 0
    {
      form: (
        <Grid container>
          <Grid
            item
            container
            xs={12}
            justify="space-around"
            alignItems="center"
            style={{
              marginBottom: 30
            }}
          >
            <Chip
              onClick={() =>{
                navigate('/login')
              }}
              style={{
                color: theme.palette.text.secondary,
              }}
              color="white"
              label="Login"
              variant="outlined"
              className={classes.mobileChips}
            />
            <Chip
              style={{
                color: '#FFF',
              }}
              color="primary"
              label="Sign up"
              className={classes.mobileChips}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={err.email}
              label="Email Address"
              value={state.email}
              className={classes.textField}
              onChange={(e) =>
                setState({ ...state, email: e.currentTarget.value })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.textField} >
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <Input
                id="outlined-adornment-password"
                className={classes.textField}
                fullWidth
                label="Password"
                placeholder="Password"
                value={state.password}
                name="password"
                onChange={(e) =>
                  setState({ ...state, password: e.currentTarget.value })
                }
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    document.getElementById("submit").focus();
                    attempt();
                  }
                }}
                error={err.password}
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.textField} >
              <InputLabel htmlFor="outlined-adornment-password-confirmation">
                Password Confirmation
              </InputLabel>
              <Input
                id="outlined-adornment-password-confirmation"
                className={classes.textField}
                fullWidth
                label="Password Confirmation"
                placeholder="Password Confirmation"
                value={state.passwordConfirmation}
                name="password confirmation"
                onChange={(e) =>
                  setState({
                    ...state,
                    passwordConfirmation: e.currentTarget.value,
                  })
                }
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    document.getElementById("submit").focus();
                    attempt();
                  }
                }}
                error={err.passwordConfirmation}
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText id="component-error-text">
                Requirements: at least 8 characters, at least 1 uppercase, at
                least 1 lowercase, at least 1 number, at least 1 special
                character (@$!%*?&)
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      ),
      buttons: (
        <Grid
          container
          item
          xs={12}
          className={classes.buttons}
        >
          <Button
            variant="outlined"
            style={{width: "45%" }}
            onClick={() =>{navigate('/')}}
            className={classes.button}
            size="large"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            id="submit"
            variant="contained"
            style={{ width: "45%" }}
            className={classes.button}
            size="large"
            color="primary"
            onClick={() => nextStep()}
          >
            CREATE
          </Button>
          <Backdrop
            className={classes.backdrop}
            open={openBackdrop}
            onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Grid>
      ),
    },
    // Step 1
    {
      form: (
        <Box className={classes.formContainerTerms}>
          <Grid className={classes.termsContainer} container spacing={1}>
            <TermAndConditions
              reachBottomFunction={reachBottomFunction}
              checked={promotionsCheckState}
              checkFunction={checkFunction}
            />
          </Grid>
        </Box>
      ),
      buttons: (
        <Grid container item xs={12} className={classes.buttons}>
          <Button
            id="cancel"
            variant="outlined"
            className={classes.button}
            size="large"
            color="primary"
            onClick={() => setFormStep(0)}
          >
            cancel
          </Button>
          <Button
            id="submit"
            variant="contained"
            className={classes.button}
            size="large"
            color="primary"
            disabled={!agreeButtonState}
            onClick={() => attempt()}
          >
            I Agree
          </Button>
        </Grid>
      ),
    },
    // Step 2
    {
      form: (
        <Grid container>
          <Grid
            item
            xs={12}
            justify="center"
            style={{
              textAlign: 'center',
              marginBottom: '10px'
            }}
          >
            <Typography
              style={{
                fontWeight: 'bold'
              }}
            >
              CONFIRM EMAIL
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              textAlign: 'center',
              marginBottom: '50px'
            }}
          >
            <Typography>
              Almost done! Let’s confirm your email.
            </Typography>
            <Typography>
              Just click on the link in the email we sent you.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              marginBottom: '50px'
            }}
          >
            <TextField
              // variant="outlined"
              error={err.email}
              label="Email To Confirm"
              value={state.email}
              className={classes.textField}
              onChange={(e) =>
                setState({ ...state, email: e.currentTarget.value })
              }
            />
          </Grid>
        </Grid>
      ),
      buttons: (
        <Grid
          item
          xs={12}
          className={classes.resendEmailButtons}
          style={{justifyContent: 'space-around'}}
        >
          <Button
            onClick={() => { navigate('/') }}
            id="submit"
            style={{ width: "45%" }}
            variant="outlined"
            className={classes.button}
            size="large"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            className={classes.button}
            id="resentEmail"
            variant="contained"
            color="primary"
            size="large"
            style={{ width: "45%" }}

            onClick={() => attempt()}
          >
            Resend
          </Button>
          <Backdrop
            className={classes.backdrop}
            open={openBackdrop}
            onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Grid>
      ),
    },
  ];

  return (
    <Fragment>
      { isMobileSize ?
        <Box className={classes.mobileViewContainer}>
          <Grid container className={classes.formContainer}>
            {mobileFormContents[formStep].form}
            {mobileFormContents[formStep].buttons}
          </Grid>
        </Box>
      :
        <LoginLayout subtitle={subtitles[formStep]}>
          <Grid container className={classes.formContainer}>
            {formContents[formStep].form}
            {formContents[formStep].buttons}
          </Grid>
        </LoginLayout>
      }
    </Fragment>
  );
};

export default withWidth()(Signup);
