import React, { useContext } from 'react';

import {
  Box,
  Grid,
  Avatar,
  Typography,
  makeStyles,
  IconButton,
} from '@material-ui/core';

import { AppContext } from './../../AppContext';

import SingleSensorImage from '../../img/icons/single-sensor.svg';
import DeviceStatusIcon from './DeviceCardComponents/DeviceStatusIcon';

import grey from '@material-ui/core/colors/grey';

import LastUpdated from './LastUpdated';

import { Edit, DeleteForever } from "@material-ui/icons";
import { DeviceBatteryIcon } from './DeviceCardComponents/DeviceBatteryIcon';

export default function SingleDeviceHeaderMobile(props) {

  const { appState } = useContext(AppContext);


  const {
    name, // Name of the sensor (Title of the Card)
    sensorId, // Message of the Card (Address)
    gatewayName, //
    avatar,
    handleEditSensor,
    handleEditSensorImage,
    handleDeleteSensor,
    batteryVolt, // Voltage of the battery
    lastMessageTime,
    sensorData,
    locationName, //
  } = props;

  const useStyles = makeStyles(theme => ({
    mobileTopSection: {
      padding: "15px 15px 25px 15px",
      background: theme.palette.primary.main,
      borderRadius: "0px 0px 15px 15px"
    },
    avatarContainer: {
    },
    avatarLarge: {
      background: 'white',
      margin: 'auto',
      border: '1px solid #CCC',
      padding: !avatar ? '18px' : '0px',
      height: '75px',
      width: '75px'
    },
    editImageButton: {
      '& svg': {
        fontSize: 16,
      },
    },
    avatarImage: {
      objectFit: !avatar && 'contain',
    },
    title: {
      fontSize: 14,
      fontWeight: 'bold',
      color: 'white'
    },
    subTitle: {
      fontSize: 10,
      color: 'white'
    },
    icon: {
      fontSize: 25,
      color: "#FFF",
    },
  }))

  const classes = useStyles();

  return (
    <Grid
      className={classes.mobileTopSection}
      container
      justify='center'
      alignItems='center'
      xs={12}
    >
      <Grid
        className={classes.avatarContainer}
        item
        xs={4}
        sm={12}
        md={12}
        container
        justify='center'
      >
        <Grid xs={12} item display='flex' container justify='center'>
          <Box
            className={classes.avatarContainer}
            display='flex'
            justifyContent='center'
          >
            <DeviceBatteryIcon batteryVolt={batteryVolt} />
            <Avatar
              classes={{ img: classes.avatarImage }}
              alt='avatar'
              src={avatar ? avatar : SingleSensorImage}
              className={classes.avatarLarge}
            />
            <DeviceStatusIcon lastMessageTime={lastMessageTime} />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          display='flex'
          style={{
            marginTop: -10,
            backgroundColor: grey,
            textAlign: 'center',
          }}
        >
          <IconButton
            style={{ color: '#ffffff', backgroundColor: grey[400] }}
            size='small'
            className={classes.editImageButton}
            onClick={handleEditSensorImage}
          >
            <Edit
              style={{ fontSize: 13 }}
            />
          </IconButton>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          textAlign: 'center',
        }}
      >
        <Typography className={classes.title} gutterBottom>
          {name}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          textAlign: 'center',
        }}
      >
        <Typography className={classes.subTitle} gutterBottom>
          {`Sensor ID: ${sensorId || ''}`}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          textAlign: 'center',
        }}
      >
        <Typography className={classes.subTitle} gutterBottom>
          {`Location: ${locationName || ''}`}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          textAlign: 'center',
        }}
      >
        <LastUpdated className={classes.subTitle} liveData={sensorData} />
      </Grid>
      {appState.auth.userInfo.perm !== 1 &&
        appState.auth.userInfo.perm !== 53 && (
          <Grid
            container
            item
            xs={12}
            justify="space-between"
            style={{
              transform: 'translate(0px,-50px)',
              height: 0,
              overflow: 'visible'
            }}
          >
            <Grid
              item
            >
              <IconButton
                onClick={handleEditSensor}
                size="medium"
                style={{
                  marginTop: 14
                }}
              >
                <Edit className={classes.icon} />
              </IconButton>
            </Grid>

            <Grid
              item
            >
              <IconButton
                onClick={handleDeleteSensor}
                style={{
                  marginTop: 14
                }}
              >
                <DeleteForever className={classes.icon} />
              </IconButton>
            </Grid>
          </Grid>
        )}
    </Grid>
  )
};
