import _ from 'lodash';
import { Grid } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import React, { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import { SensorPlan } from './SensorPlan';
// import { listOfPlans } from './ListOfPlans';

/**
 * Change Sensor Plans
 * @param {Object} props
 */
export function ChangeSensorPlans(props) {
  const {
    subLevels,
    listOfPlans,
    selSubLevel,
    selectedPlan,
    setTotalPrice,
    setSelectedPlan,
    currentSubscription,
    isMobile
  } = props;

  /**
   * returns the typography color based in the
   * theme dark mode and the tone given for the colors
   * @param {Object, int, int} theme, lightTone=700, darkTone=300
   */
  const textColor = ({ theme, lightTone = 700, darkTone = 300 }) => {
    if (theme.palette.type === 'light') {
      return grey[lightTone];
    } else {
      return grey[darkTone];
    }
  };

  const theme = useTheme();
  const classes = {
    swipeableContainer: {
      alignItems: 'flex-end',
    },
    leftArrowBorder: {
      borderRight: `1px ${
        theme.palette.type === 'light'
          ? 'rgba(0, 0, 0, 0.12)'
          : 'rgba(255, 255, 255, 0.12)'
      } solid`,
    },
    rightArrowBorder: {
      borderLeft: `1px ${
        theme.palette.type === 'light'
          ? 'rgba(0, 0, 0, 0.12)'
          : 'rgba(255, 255, 255, 0.12)'
      } solid`,
    },
    arrowContainer: {
      width: 44,
      height: 123,
      marginBottom: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonContainer: {
      display: 'flex',
      alignItems: 'flex-end',
      width: 'auto',
      // justifyContent: 'space-evenly'
    },
    descriptor: {
      width: 'auto',
      height: 17,
      textAlign: 'center',
      margin: '0 0',
      fontSize: 12,
      color: textColor({ theme, lightTone: 500, darkTone: '#FFF' }),
    },
  };
  const [planIndex, setPlanIndex] = useState(0);
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    createSwipeableArr();
  }, [listOfPlans, selectedPlan, isMobile]);

  const createSwipeableArr = () => {
    const plansArr = listOfPlans.map(plan => (
      <SensorPlan
        key={plan.id}
        plan={plan}
        selSubLevel={selSubLevel}
        selectedPlan={selectedPlan}
        setTotalPrice={setTotalPrice}
        setSelectedPlan={setSelectedPlan}
        currentSubscription={currentSubscription}
        isMobile={isMobile}
      />
    ));
    let chunkAmount = isMobile ? 2: 4
    const chunkedArr = _.chunk(plansArr, chunkAmount);

    const mappedChunks = _.map(chunkedArr, (chunk, index) => (
      <Grid
        item
        xs={12}
        container
        style={{ paddingBottom: 5 }}
        key={`chunk_${index}`}
      >
        {chunk}
      </Grid>
    ));
    setSlides(mappedChunks);
  };

  const handleLeftArrow = () => {
    if (planIndex - 1 >= 0) {
      setPlanIndex(planIndex - 1);
    }
  };

  const handleRightArrow = () => {
    if (planIndex + 1 < slides.length) {
      setPlanIndex(planIndex + 1);
    }
  };

  const determineText = () => {
    let text = ' ';
    if (
      selectedPlan.sensors > subLevels.BASIC.noOfSensors &&
      selectedPlan.sensors < subLevels.PRO.noOfSensors
    ) {
      text = 'Only available on PREMIUM and PRO tiers';
    }
    if (selectedPlan.sensors > subLevels.PREMIUM.noOfSensors) {
      text = 'Only available on PRO tier';
    }
    return text;
  };

  return (
    <Grid xs={12} item container style={classes.swipeableContainer}>
      <Grid
        item
        xs={1}
        style={{ ...classes.arrowContainer, ...classes.leftArrowBorder }}
        onClick={handleLeftArrow}
      >
        <ArrowBackIosIcon
          style={{ color: planIndex === 0 ? '#CCCCCC' : '#08B3FF' }}
        />
      </Grid>
      <Grid item xs={10} style={{ padding: `0 8px 5px 8px` }}>
        <SwipeableViews
          index={planIndex}
          onChangeIndex={index => setPlanIndex(index)}
          slideStyle={classes.buttonContainer}
          enableMouseEvents
        >
          {slides}
        </SwipeableViews>
      </Grid>
      <Grid
        item
        xs={1}
        style={{ ...classes.arrowContainer, ...classes.rightArrowBorder }}
        onClick={handleRightArrow}
      >
        <ArrowForwardIosIcon
          style={{
            color: planIndex === slides.length - 1 ? '#CCCCCC' : '#08B3FF',
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <p style={classes.descriptor}>{determineText()}</p>
      </Grid>
    </Grid>
  );
}
