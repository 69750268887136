import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';

import LocationForm from '../../common/LocationForm/LocationForm';
import ModalFormHeader from '../../common/ModalComponents/ModalFormHeader';

const useStyles = makeStyles(theme => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
  },
}));

/**
 * Address
 * @param {Object} props
 */
export default function Address(props) {
  const classes = useStyles();

  const {
    info: { street, city, state, zipcode },
    setters: { setStreet, setCity, setState, setZipcode },
    errors: { streetError, cityError, stateError, zipcodeError },
  } = props;

  return (
    <Grid container item xs={12} spacing={2}>
      <ModalFormHeader
        header='Enter the address of your location'
        desc='Enter the Street, City, State and ZIP Code information for your location below.'
      />
      <LocationForm
        street={street}
        setStreet={setStreet}
        streetError={streetError}
        city={city}
        setCity={setCity}
        cityError={cityError}
        state={state}
        setState={setState}
        stateError={stateError}
        zipcode={zipcode}
        setZipcode={setZipcode}
        zipcodeError={zipcodeError}
        classes={classes}
      />
    </Grid>
  );
}
