import clsx from 'clsx';
import Bowser from 'bowser';
import localforage from 'localforage';
import UserIcon from '@material-ui/icons/People';
import LocationIcon from '@material-ui/icons/Room';
import { useRoutes, navigate } from 'hookrouter';
import { useTheme } from '@material-ui/core/styles';
import GatewayIcon from '@material-ui/icons/Router';
import { makeStyles } from '@material-ui/core/styles';
import AlertIcon from '@material-ui/icons/Announcement';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  Fragment,
} from 'react';
import {
  Box,
  Tab,
  Tabs,
  Button,
  Drawer,
  Divider,
  Typography,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  ListItemSecondaryAction,
} from '@material-ui/core';

import { AppContext } from '../../AppContext';

// Icons
import ReportsSVG from '../../icons/ReportsSVG';
import SensorIcon from '../../img/icons/SensorIcon';
import DashboardIcon from '../../img/icons/DashboardIcon';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
//Auth Import
import Login from '../Auth/Login';
import Signup from '../Auth/Signup';
import NotFoundPage from '../Auth/NotFoundPage';
import SuccessSignup from '../Auth/SuccessSignup';
import UserInvitation from '../Auth/UserInvitation';
import NotFoundPageLoggedIn from '../Auth/NotFoundPageLoggedIn';

import PaymentPage from '../Auth/PaymentPage';
import { UserSettings } from '../Users/UserSettings';
import ForgotPassword from '../Auth/ForgotPassword';
import OnBoardModal from '../GeneralModals/OnBoardModal';
import UniversalAddModal from '../GeneralModals/UniversalAddModal';

import Dashboard from '../Dashboard';
// Account Settings import
import AccountBilling from '../Users/AccountBilling';
import AccountSettings from '../Users/AccountSettings';

// Gateway import
import AddGateway from '../Gateways/AddGateway';
import AllGateways from '../Gateways/AllGateways';
import SingleGateway from '../Gateways/SingleGateway';
// Device import
import AllDevices from '../Devices/AllDevices';
import SingleDevice from '../Devices/SingleDevice';
// Alerts import
import AllAlerts from '../Alerts/AllAlerts';
import ManageAlert from '../Alerts/ManageAlert';
import { AlertProvider } from '../Alerts/AlertContext';
//  Users import
import AddSubUser from '../Users/AddSubUser';
import AllSubUsers from '../Users/AllSubUsers';
import SingleSubUser from '../Users/SingleSubUser';
//  Locations import
import AllLocations from '../Locations/AllLocations';
import SingleLocation from '../Locations/SingleLocation';
// Reports Import
import ReportView from '../Reports/ReportsView';
import AlertsFormModal from '../Alerts/AlertsFormModal';
import DeviceFormModal from '../Devices/DeviceFormModal';
import SubUserFormModal from '../Users/SubUserFormModal';
import GatewayFormModal from '../Gateways/GatewayFormModal';
import LocationFormModal from '../Locations/LocationFormModal';
import ReportSelectedView from '../Reports/ReportsSelectedView';

// Admin imports
import AdminDashboard from '../Admin/Dashboard';
import AllResellers from '../Admin/AllResellers';
import SingleReseller from '../Admin/SingleReseller';
//  List Items Import
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import AdminDashboardIcon from '../../img/icons/AdminDashboardIcon';

// Img imports
import appMobileIcon from '../../img/app_icon_rounded.png';
import ResellerInvitation from '../Auth/ResellerInvitation';
import OpenInBrowserOutlinedIcon from '@material-ui/icons/OpenInBrowserOutlined';

// Update Devices Import
import UpdateDevicesPage from '../UpdateDevices/UpdateDevices';
// Header imports
import Header from './Header/Header';

import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

const drawerWidth = 240;

const Content = props => {
  const {
    snack,
    appState,
    logoutState,
    setAppState,
    featureCount,
    getLocations,
    getAlertCount,
    versionNumber,
    setFeatureCount,
    openOnboardModal,
    openSettingsModal,
    updateUserProfile,
    fetchCompanyCounts,
    setOpenOnboardModal,
    setOpenSettingsModal,
  } = useContext(AppContext);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setDrawer(true);
    setOpen(!open);
  };
  // boolean to handle breakpoint
  const isDownSM = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    darkMode,
    setDarkMode,
    primaryColor,
    handleDarkModeChange,
    handlePrimaryColorChange,
  } = props;

  const [platformAlias, setPlatformAlias] = useState('');
  const [platformImageLocation, setPlatformImageLocation] = useState('');
  const [faviconImageLocation, setFaviconImageLocation] = useState('');
  const [selectedPage, setSelectedPage] = useState(0);

  const [themeLoaded, setThemeLoaded] = useState(false);

  const [timeFormat, setTimeFormat] = useState('12hr');
  const [tempType, setTempType] = useState('F');
  const [pressType, setPressType] = useState('PSI');
  const [pageDisplay, setPageDisplay] = useState(false); // true == listView, false == cardView, null == mixed
  const [presetThemeChoice, setPresetThemeChoice] = useState('0');
  const [drawerArr, setDrawerArr] = useState([
    { icon: <DashboardIcon />, name: 'Dashboard', href: '/', id: 'item-0' },
    {
      icon: <LocationIcon />,
      name: 'Locations',
      href: '/location/all',
      id: 'item-1',
    },
    {
      icon: <GatewayIcon />,
      name: 'Gateways',
      href: '/gateway/all',
      id: 'item-2',
    },
    {
      icon: <SensorIcon />,
      name: 'Sensors',
      href: '/device/all',
      id: 'item-3',
    },
    { icon: <UserIcon />, name: 'Users', href: '/user/all', id: 'item-4' },
    { icon: <AlertIcon />, name: 'Alerts', href: '/alert/all', id: 'item-5' },
  ]);
  const [updatedDrawerArr, setUpdatedDrawerArr] = useState(false);

  const [openUserMenu, setOpenUserMenu] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);

  const log = window.log('Content');

  /**
   * Mobile variables
   */
  const isMobileSize = isWidthDown('xs', props.width);

  /**
   * End Mobile variables
   */
  if (!isMobileSize && !updatedDrawerArr) {
    setUpdatedDrawerArr(true);
    setDrawerArr([
      ...drawerArr,
      {
        icon: <ReportsSVG />,
        name: 'Reports',
        href: '/reports/all',
        id: 'item-6',
      },
    ]);
  }

  useEffect(() => {
    if (pageDisplay !== null) {
      updateUserProfile('pageView.all', pageDisplay);
    }
  }, [pageDisplay]);

  const setStateFromStorage = async () => {
    const storedState = await localforage.getItem('appState');
    if (storedState !== null) {
      setAppState(storedState);
      if (storedState.auth.userInfo && storedState.auth.userInfo.features) {
        let count = Object.values(storedState.auth.userInfo.features).filter(
          item => item === false,
        ).length;

        if (!storedState.auth.userInfo.features.finishLater) {
          count = count - 1;
        }
        setFeatureCount(count);
      }

      // Temp bug fix for onboard modal not grabbing data which causes incorrect tracking
      if (storedState?.auth?.userInfo?.features === null) {
        setTimeout(() => {
          setStateFromStorage();
        }, 1000);
      }

      if (
        storedState.auth.userInfo?.features &&
        !storedState.auth.userInfo.features.finishLater
      ) {
        if (
          !storedState.auth.userInfo.features.addUser ||
          !storedState.auth.userInfo.features.addAlert ||
          !storedState.auth.userInfo.features.addSensor ||
          !storedState.auth.userInfo.features.addGateway ||
          !storedState.auth.userInfo.features.addLocation
        ) {
          setOpenOnboardModal(true);
        }
      }
    } else {
      setAppState({
        ...storedState,

        auth: {
          loggedIn: false,
        },
      });
    }
  };

  const returnThemeToDefault = () => {
    setPlatformAlias('');
    setPlatformImageLocation('');
    setFaviconImageLocation('');
    setPresetThemeChoice('0');
    setTimeFormat('12hr');
    setTempType('F');
    setTempType('PSI');
    handlePrimaryColorChange({
      hex: '#08b3ff',
    });
    setDarkMode(false);
  };

  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));
  log('isSmallDevice: ' + isSmallDevice);
  const [bottomDrawerState, setBottomDrawerstate] = React.useState(false);

  const browser = Bowser.getParser(window.navigator.userAgent);
  const browserInfo = browser.getBrowser();

  useEffect(() => {
    if (!window.ReactNativeWebView) {
      if (browserInfo.name === 'Safari') {
        return;
      }
      setBottomDrawerstate(
        /Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent),
      );
    }
  }, [isSmallDevice]);

  const fetchTheme = async () => {
    try {
      if (appState.auth.loggedIn) {
        log('About to run a fetch');
        //TODO: Add try catch block
        const response = await fetch(
          process.env.REACT_APP_API_URL + '/company/get',
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: appState.auth.token,
            },
          },
        );
        log('Looking at company login reponse ', response);

        const json = await response.json();

        if (json.success) {
          if (!json.data.company[0].is_enabled) {
            setAppState({
              auth: {
                loggedIn: false,
              },
            });
            snack('Account Is Disabled', 'error');

            navigate('/');
          } else {
            if (json.success) {
              const themePreferences = json.data.company[0].theme_preferences;
              updateThemePreferences(themePreferences);
            }
            setThemeLoaded(true);
          }
        } else {
          log('Json failure');
          if (json.errors.length) {
            for (const error of json.errors) {
              if (error.type === 'token') {
                setAppState({
                  auth: {
                    loggedIn: false,
                  },
                });
                navigate('/');
                break;
              }
            }
          }
        }
      }
    } catch (err) {
      log('------: fetchTheme -> err', err);
      snack('Network Error', 'error');
    }
  };
  let windowSizeSmall = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setStateFromStorage();
  }, []);

  useEffect(() => {
    if (logoutState) logout();
  }, [logoutState]);

  useEffect(() => {
    let isCancelled = false;

    if (appState.auth.loggedIn !== false) {
      if (!isCancelled) {
        getAlertCount();
        getLocations();
        fetchCompanyCounts();
        fetchTheme();
      }
    }

    if (appState.auth.loggedIn && windowSizeSmall === true) {
      setDrawer(false);
    }
    if (appState.auth.loggedIn && windowSizeSmall === false) {
      setDrawer(false);
    }
    return () => {
      isCancelled = true;
    };
  }, [appState.auth.loggedIn]);

  useEffect(() => {
    if (window.location.pathname.includes('android_asset')) {
      navigate(`/`);
    }
    if (window.location.pathname === '/' && selectedPage !== 0) {
      setSelectedPage(0);
    } else if (
      window.location.pathname.includes('location') &&
      selectedPage !== 1
    ) {
      setSelectedPage(1);
    } else if (
      window.location.pathname.includes('gateway') &&
      selectedPage !== 2
    ) {
      setSelectedPage(2);
    } else if (
      window.location.pathname.includes('device') &&
      selectedPage !== 3
    ) {
      setSelectedPage(3);
    } else if (
      window.location.pathname.includes('user') &&
      selectedPage !== 4
    ) {
      setSelectedPage(4);
    } else if (
      window.location.pathname.includes('alert') &&
      selectedPage !== 5
    ) {
      setSelectedPage(5);
    } else if (
      window.location.pathname.includes('reports') &&
      selectedPage !== 6
    ) {
      setSelectedPage(6);
    } else if (
      (window.location.pathname.includes('admin') ||
        window.location.pathname.includes('reseller')) &&
      selectedPage !== 7
    ) {
      setSelectedPage(7);
    }
  }, [selectedPage, window.location.pathname]);

  const updateThemePreferences = themePreferences => {
    if (themePreferences) {
      // Company specfic theme preferences
      if (themePreferences?.presetThemeChoice) {
        setPresetThemeChoice(themePreferences?.presetThemeChoice);
      }
      if (themePreferences?.platformAlias) {
        setPlatformAlias(themePreferences?.platformAlias);
      }
      if (themePreferences?.platformImageLocation) {
        setPlatformImageLocation(themePreferences?.platformImageLocation);
      }
      if (themePreferences?.faviconImageLocation) {
        setFaviconImageLocation(themePreferences?.faviconImageLocation);
      }
      // User specfic settings
      if (appState?.userSettings?.timeFormat) {
        setTimeFormat(appState?.userSettings?.timeFormat);
      }

      if (appState?.userSettings?.primaryColor) {
        handlePrimaryColorChange({
          hex: appState?.userSettings?.primaryColor,
        });
      }
      if (typeof appState?.userSettings?.darkMode === 'boolean') {
        setDarkMode(appState?.userSettings?.darkMode);
      }
    } else {
      returnThemeToDefault();
    }
  };

  //After DCS Admin switches account, fetch that account's data
  useEffect(() => {
    const themePreferences =
      appState?.auth?.userInfo?.profile?.themePreferences;
    updateThemePreferences(themePreferences);
  }, [appState.parentAccount]);

  const [drawer, setDrawer] = useState(false);

  const routeResultNonAuth = useRoutes({
    '/signup': () => <Signup />,
    '/reseller_signup': () => <ResellerInvitation />,
    '/reset': () => <ForgotPassword />,
    '/setpassword': () => <UserInvitation />,
    '/payment': () => <PaymentPage />,
    '/success_signup': () => (
      <SuccessSignup setOpenOnboardModal={setOpenOnboardModal} />
    ),
    '/alert/all': () => <Login />,
    //root
    '/login': () => <Login />,
    '/': () => <Login />,
    '/*': () => <NotFoundPage />,
  });
  const routeResultAuth = useRoutes({
    // User configuration
    '/current/user/configuration': () => (
      <AccountSettings
        platformImageLocation={platformImageLocation}
        setPlatformImageLocation={setPlatformImageLocation}
        faviconImageLocation={faviconImageLocation}
        setFaviconImageLocation={setFaviconImageLocation}
      />
    ),
    '/current/user/billing': () =>
      appState.auth.isDirect ? (
        <AccountSettings
          platformImageLocation={platformImageLocation}
          setPlatformImageLocation={setPlatformImageLocation}
          faviconImageLocation={faviconImageLocation}
          setFaviconImageLocation={setFaviconImageLocation}
        />
      ) : (
        <AccountBilling />
      ),
    // "/" ,
    // gateways
    '/gateway/add': () => <AddGateway />,
    '/gateway/all': () => <AllGateways />,
    '/gateway/:id': ({ id }) => <SingleGateway id={id} />,

    // devices
    '/device/all': () => <AllDevices />,
    '/device/:id': ({ id }) => <SingleDevice id={id} />,

    // alerts
    '/alert/all': () => <AllAlerts />,
    '/alert/:id': ({ id }) => (
      <AlertProvider>
        <ManageAlert id={id} drawer={drawer} />
      </AlertProvider>
    ), // single alert

    // users
    '/user/add': () => <AddSubUser />,
    '/user/all': () => <AllSubUsers reloadPage={reloadPage} />,
    '/user/:id': ({ id }) => <SingleSubUser id={id} />,

    // locations
    '/location/all': () => <AllLocations />,
    '/location/:id': ({ id }) => <SingleLocation id={id} />,

    // Reports
    '/reports/all': () => <ReportView />,
    '/reports/:category': ({ category }) => (
      <ReportSelectedView category={category} />
    ),

    // Admin TODO: Allow access to users if they have perm level = 2
    '/admin': () => {
      if (
        appState.auth.loggedIn &&
        appState.auth.userInfo &&
        appState.auth.userInfo.perm === 99
      ) {
        return <AdminDashboard />;
      }
      return (
        <NotFoundPageLoggedIn subtitle='Access Denied. Please contact your administrator.' />
      );
    },
    '/admin/reseller/all': () => {
      if (
        appState.auth.loggedIn &&
        appState.auth.userInfo &&
        appState.auth.userInfo.perm === 99
      ) {
        return <AllResellers />;
      }
      return (
        <NotFoundPageLoggedIn subtitle='Access Denied. Please contact your administrator.' />
      );
    },
    '/admin/reseller/:id': ({ id }) => {
      if (
        appState.auth.loggedIn &&
        appState.auth.userInfo &&
        appState.auth.userInfo.perm === 99
      ) {
        return <SingleReseller id={id} />;
      }
      return (
        <NotFoundPageLoggedIn subtitle='Access Denied. Please contact your administrator.' />
      );
    },
    // root
    '/update/devices': () => <UpdateDevicesPage />,
    '/': () => <Dashboard />,
    '/*': () => <NotFoundPageLoggedIn name='NotFoundPageLoggedIn' />,
  });

  const useStyles = makeStyles(theme => ({
    root: {
      top: 48,
    },
    appBar: {
      width: `calc(100% - 65px)`,
      marginLeft: appState.auth.loggedIn ? theme.spacing(7) + 1 : 0,
      [theme.breakpoints.up('sm')]: {
        marginLeft: appState.auth.loggedIn ? theme.spacing(8) + 1 : 0,
      },

      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeIn,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        width: `calc(100%)`,
      },
    },
    appBarShift: {
      marginLeft: drawerWidth,

      [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${drawerWidth}px)`,
      },

      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
    },
    accountIcon: {
      marginLeft: 'auto',
      marginRight: 5,
      color: theme.palette.primary.contrastText,
    },
    avatarMenuButton: {
      borderRadius: '25px',
    },
    avatarMenuButtonText: {
      borderRadius: '25px',
    },
    avatarMenuImage: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      border: `1px solid ${theme.palette.text.primary}`,
    },
    button: {
      color: theme.palette.secondary.main,
    },
    colorThemeIcon: {
      marginBottom: '.2rem',
    },
    dialogPaper: {
      minHeight: '90vh',
      maxHeight: '90vh',
    },
    '@global': {
      '.MuiDrawer-paper': {
        backgroundColor: darkMode ? theme.palette.background.default : '#FFF',
      },
      '.MuiDrawer-paperAnchorLeft': {
        [theme.breakpoints.down('sm')]: {
          width: drawerWidth,
        },
      },
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    drawerOpen: {
      width: appState.auth.loggedIn ? drawerWidth : 0,
    },
    drawerClose: {
      overflowX: 'hidden',
      width: appState.auth.loggedIn ? theme.spacing(7) + 1 : 0,
      [theme.breakpoints.up('sm')]: {
        width: appState.auth.loggedIn ? theme.spacing(8) + 1 : 0,
      },
      [theme.breakpoints.down('sm')]: {
        width: 0,
      },
    },
    content: {
      backgroundColor:
        theme.palette.type === 'light'
          ? '#F4F5F5'
          : theme.palette.background.default,
      flexGrow: 1,
      padding: appState.auth.loggedIn ? theme.spacing(3) : theme.spacing(0),
      marginLeft: appState.auth.loggedIn ? theme.spacing(7) + 1 : 0,
      [theme.breakpoints.up('sm')]: {
        marginLeft: appState.auth.loggedIn ? theme.spacing(8) + 1 : 0,
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        padding: appState.auth.loggedIn ? theme.spacing(1) : theme.spacing(0),
      },
      // for mobile
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
        padding: theme.spacing(0),
      },
    },
    contentShift: {
      marginLeft: appState.auth.loggedIn ? drawerWidth : 0,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
    },
    hide: {
      display: 'none',
    },
    text: {
      color: darkMode ? theme.palette.text.primary : '#FFF',
      fontSize: 14,
    },
    labelContainer: {
      width: 'auto',
      padding: 0,
    },
    iconLabelWrapper: {
      flexDirection: 'row',
      justifyContent: 'left',
      marginLeft: 12,
    },
    labelIconTab: {
      minHeight: 55,
      paddingLeft: 9,
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    navIcon: {
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    tabText: {
      marginLeft: 20,
      marginBottom: 12,
    },
    menuItem: {
      paddingLeft: 5,
    },
    navbarMenu: {
      textDecoration: 'none',
      color: theme.palette.primary.contrastText,
    },

    navTab: {
      '.labelIcon': {
        height: 55,
      },
      '&:hover': {
        backgroundColor: '#F4F5F5',
      },
    },
    removeImageButton: {
      marginLeft: '1rem',
    },
    themeSaveButton: {
      marginRight: 'auto',
      color: theme.palette.secondary.main,
    },
    wrapperDiv: {
      [theme.breakpoints.down('xs')]: {
        overflow: 'hidden',
        height: '100vh',
      },
      paddingTop: appState.auth.loggedIn && '55px',
    },
    listItemText: {
      color: '#747474',
      fontSize: 14,
      fontFamily: 'Open Sans',
      fontWeight: 500,
      lineHeight: 1.5,
    },
    listItemIcon: {
      paddingLeft: 6,
    },
    list: {
      width: 250,
    },
    fullList: {
      width: 'auto',
    },
    bottomSheetButton: {
      width: 100,
    },
    divider: {
      backgroundColor: '#ffffff',
      width: '1px',
      height: '1.75rem',
      marginLeft: 10,
      marginRight: 10,
    },
    versionContainer: {
      position: 'absolute',
      bottom: '10px',
      width: '100%',
    },
    versionText: {
      color: '#9e9e9e',
      fontSize: 12,
    },
  }));

  const actions = [
    { icon: <GatewayIcon />, name: 'Add Gateway', href: '/gateway/add' },
    { icon: <SensorIcon />, name: 'Add Sensor', href: '/device/add' },
    { icon: <AlertIcon />, name: 'Add Alert', href: '/alert/add' },
    { icon: <UserIcon />, name: 'Add User', href: '/user/add' },
    { icon: <LocationIcon />, name: 'Add Location', href: '/location/add' },
  ];

  const drawerItems = [
    { icon: <DashboardIcon />, name: 'Dashboard', href: '/' },
    { icon: <LocationIcon />, name: 'Locations', href: '/location/all' },
    { icon: <GatewayIcon />, name: 'Gateways', href: '/gateway/all' },
    { icon: <SensorIcon />, name: 'Sensors', href: '/device/all' },
    { icon: <UserIcon />, name: 'Users', href: '/user/all' },
    { icon: <AlertIcon />, name: 'Alerts', href: '/alert/all' },
  ];

  if (!isMobileSize) {
    drawerItems.push({
      icon: <ReportsSVG />,
      name: 'Reports',
      href: '/reports/all',
    });
  }

  const drawerSettingsitems = [
    {
      icon: <AccountBoxIcon />,
      name: 'Profile',
      onClick: () => {
        // navigate(`/current/user/configuration`);
        navigate(`/current/user/configuration`);
        setDrawer(false);
      },
    },
    {
      icon: <SettingsIcon />,
      name: 'Settings',
      onClick: () => {
        setOpenSettingsModal(true);
        setDrawer(false);
      },
    },
    {
      icon: <ExitToAppIcon />,
      name: 'Logout',
      onClick: () => {
        logout();
        setDrawer(false);
      },
    },
  ];

  const classes = useStyles();
  const closeBottomSheet = () => {
    setBottomDrawerstate(false);
  };
  var fallbackToStore = function () {
    const iOS =
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    if (iOS) {
      window.location.replace('https://apps.apple.com/app/id1418400508');
    }
    const android = navigator.userAgent.toLowerCase().indexOf('android') > -1;
    if (android) {
      window.location.replace('market://details?id=com.dcs.misensors2');
    }
  };
  var openApp = function () {
    let path = window.location.pathname;
    let params = window.location.search;
    let pathParams = `${path}${params}`;
    if (pathParams === '/' || pathParams === '') {
      window.location.replace('misensors://');
    } else {
      window.location.replace(`misensors:/${pathParams}`);
    }
  };

  var triggerAppOpen = function () {
    openApp();
    setTimeout(fallbackToStore, 250);
  };
  const list = anchor => (
    <div
      className={clsx(classes.fullList, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role='presentation'
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem>
          <ListItemIcon>
            <img
              src={appMobileIcon}
              alt='Mobile app icon'
              width='24'
              height='24'
            />
          </ListItemIcon>
          <ListItemText primary='MISensors App' />
          <ListItemSecondaryAction>
            <Button
              onClick={triggerAppOpen}
              variant='outlined'
              color='primary'
              className={classes.bottomSheetButton}
            >
              Open
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemIcon>{<OpenInBrowserOutlinedIcon />}</ListItemIcon>
          <ListItemText primary='Browser' />
          <ListItemSecondaryAction>
            <Button
              variant='outlined'
              color='primary'
              className={classes.bottomSheetButton}
              onClick={closeBottomSheet}
            >
              Continue
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </div>
  );

  const [err, setErr] = useState({
    platformAlias: false,
  });

  const logout = async () => {
    // clear local storage token
    await setAppState({
      auth: { loggedIn: false },
    });
    // close drawer
    handleDrawerClose();
    handleCloseUserMenu();
    // set default theme settings
    returnThemeToDefault();
    setThemeLoaded(false);
    // redirect to root (will be log in page since token is now gone)
    navigate('/');
  };

  const drawerLink = (href, index) => {
    // change nav indicator
    setSelectedPage(index);
    // reloads current page on click
    if (index === selectedPage) setReloadPage(!reloadPage);
    // close drawer if mobile
    if (windowSizeSmall) {
      setDrawer(false);
    }
    // navigate to link
    navigate(href, true);
  };

  const handleColorDialogClose = e => {
    if (!(e && typeof e === 'boolean') && appState?.userSettings) {
      const {
        userSettings,
        userSettings: { timeFormat, measurement, primaryColor },
      } = appState;

      if (userSettings.primaryColor !== primaryColor) {
        handlePrimaryColorChange({ hex: primaryColor });
      }

      if (userSettings.timeFormat !== timeFormat) {
        setTimeFormat(timeFormat);
      }

      if (userSettings.darkMode !== darkMode) {
        setDarkMode(userSettings.darkMode);
      }
    }
    setOpenSettingsModal(false);
  };

  const handleOnboardModalClose = () => {
    setOpenOnboardModal(false);
  };

  /**
   * This is user specific theme changes
   */
  const handleUserThemeChanges = async (setting = {}) => {
    try {
      await updateUserProfile('userSettings', {
        darkMode: setting?.darkMode || darkMode,
        timeFormat: setting?.timeFormat || timeFormat,
        primaryColor: setting?.primaryColor || primaryColor,
        tempType: setting?.tempType || appState.userSettings.tempType,
        pressType: setting?.pressType || appState.userSettings.pressType,
        nightlyReports:
          typeof setting?.nightlyReports === 'boolean'
            ? setting?.nightlyReports
            : appState.userSettings.nightlyReports,
        weeklyReports:
          typeof setting?.weeklyReports === 'boolean'
            ? setting?.weeklyReports
            : appState.userSettings.weeklyReports,
        advancedLux:
          typeof setting?.advancedLux === 'boolean'
            ? setting?.advancedLux
            : appState.userSettings.advancedLux,
      });
      snack('Settings successfully updated', 'success');
      handleColorDialogClose(true);
    } catch (err) {
      log(err);
    }
  };

  // This is company wide theme changes
  const handleSaveThemePreferences = async () => {
    const changes = {
      primaryColor,
      platformAlias,
      faviconImageLocation,
      platformImageLocation,
    };
    try {
      const data = {
        themePreferences: JSON.stringify(changes),
        companyId: appState.auth.userInfo.companyId,
      };

      const response = await fetch(
        process.env.REACT_APP_API_URL + `/company/edit`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
          body: JSON.stringify(data),
        },
      );
      const json = await response.json();

      if (json.success) {
        snack('Settings successfully updated', 'success');
        let cp = { ...appState };
        cp.userSettings = JSON.parse(json.data.themePreferences);
        log(cp);
        setAppState(cp);
        handleColorDialogClose(true);
      } else {
        //handle errs
        let errorObj = {};
        json.errors.forEach(error => {
          // add error to err object
          errorObj = { ...errorObj, [error.type]: true };
          // err alert
          snack(error.msg, 'error');
        });
        // write new object at top level per hook rules
        setErr(errorObj);
      }
    } catch (err) {
      log(err);
    }
  };

  const handleDrawerClose = () => {
    setDrawer(false);
    setOpen(false);
  };

  const handleCloseUserMenu = e => {
    setOpenUserMenu(false);
  };

  const a11yProps = index => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const isAdmin = () => {
    return appState.auth.loggedIn && appState?.auth?.userInfo?.perm === 99;
  };

  if (themeLoaded || appState.auth.loggedIn === false) {
    return (
      <div className={classes.wrapperDiv}>
        <Header
          drawer={drawer}
          setOpen={setOpen}
          darkMode={darkMode}
          setDrawer={setDrawer}
          setDarkMode={setDarkMode}
          setTempType={setTempType}
          setPressType={setPressType}
          setTimeFormat={setTimeFormat}
          setThemeLoaded={setThemeLoaded}
          setBottomDrawerstate={setBottomDrawerstate}
          setPresetThemeChoice={setPresetThemeChoice}
          setFaviconImageLocation={setFaviconImageLocation}
          handlePrimaryColorChange={handlePrimaryColorChange}
          setPlatformImageLocation={setPlatformImageLocation}
        />
        {appState.auth.loggedIn && (
          <>
            <AlertsFormModal />
            <DeviceFormModal />
            <SubUserFormModal />
            <GatewayFormModal />
            <LocationFormModal />
            <UniversalAddModal />
          </>
        )}
        <OnBoardModal
          featureCount={featureCount}
          openState={openOnboardModal}
          handleClose={handleOnboardModalClose}
        />
        <UserSettings
          err={err}
          classes={classes}
          appState={appState}
          darkMode={darkMode}
          drawerArr={drawerArr}
          timeFormat={timeFormat}
          pageDisplay={pageDisplay}
          setDarkMode={setDarkMode}
          primaryColor={primaryColor}
          setDrawerArr={setDrawerArr}
          setDrawer={setDrawer}
          openState={openSettingsModal}
          setTimeFormat={setTimeFormat}
          setPageDisplay={setPageDisplay}
          handleSave={handleUserThemeChanges}
          handleClose={handleColorDialogClose}
          handleDarkModeChange={handleDarkModeChange}
          handleColorDialogClose={handleColorDialogClose}
          handlePrimaryColorChange={handlePrimaryColorChange}
          handleSaveThemePreferences={handleSaveThemePreferences}
        />

        <SwipeableDrawer
          style={{
            zIndex: 5000,
          }}
          data-testid='drawer'
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: drawer,
            [classes.drawerClose]: !drawer,
          })}
          classes={{
            paper: isDownSM
              ? ''
              : clsx({
                  [classes.drawerOpen]: drawer,
                  [classes.drawerClose]: !drawer,
                }),
          }}
          variant={windowSizeSmall ? 'temporary' : 'permanent'}
          anchor={isMobileSize ? 'right' : 'left'}
          open={drawer}
          onOpen={() => {}}
          onClose={() => setDrawer(!drawer)}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <img
            data-testid='drawer_img'
            style={{
              marginLeft: drawer ? 'auto' : 5,
              marginRight: 'auto',
              marginTop: drawer ? 10 : 10,
              width: drawer ? 'auto' : 'auto',
              height: drawer ? 35 : 35,
              padding: drawer ? '6px 20px 5px 25px' : '6px 104px 5px 13px',
            }}
            src={
              drawer
                ? appState.auth.userInfo?.themePreferences
                    ?.platformImageLocation
                  ? appState.auth.userInfo.themePreferences
                      .platformImageLocation
                  : theme.palette.type === 'light'
                  ? 'https://misensors-images.s3.amazonaws.com/App+Image+Resources/MiSensorsLogo.svg'
                  : 'https://misensors-images.s3.amazonaws.com/App+Image+Resources/MiSensorsLogo+-+DarkMode.svg'
                : appState.auth.userInfo?.themePreferences?.faviconImageLocation
                ? appState.auth.userInfo.themePreferences.faviconImageLocation
                : theme.palette.type === 'light'
                ? 'https://misensors-images.s3.amazonaws.com/App+Image+Resources/MiSensorLogo_Icon.svg'
                : 'https://misensors-images.s3.amazonaws.com/App+Image+Resources/MiSensorLogo_Icon+-+DarkMode.svg'
            }
            alt='logo'
          />

          <Divider
            style={{
              marginTop: '10px',
              marginBottom: '5px',
              marginLeft: drawer && 24,
              marginRight: drawer && 24,
              backgroundColor: '#CCC',
              height: 1,
            }}
            variant='middle'
          />
          <Tabs
            value={selectedPage ? selectedPage : 0}
            orientation='vertical'
            aria-label='nav tabs'
            indicatorColor='primary'
            TabIndicatorProps={{ style: { left: 0, width: 5, height: 55 } }}
            classes={{
              indicator: classes.indicator,
            }}
          >
            {drawerArr.map((item, index) => (
              <Tab
                key={index}
                className={classes.navTab}
                onClick={() => drawerLink(item.href, index)}
                style={{
                  backgroundColor:
                    selectedPage === index && !darkMode
                      ? '#F4F5F5'
                      : selectedPage === index && darkMode && '#424242',
                }}
                classes={{
                  wrapper: classes.iconLabelWrapper,
                  labelIcon: classes.labelIconTab,
                }}
                icon={
                  <Typography
                    className={classes.navIcon}
                    style={{
                      color:
                        selectedPage === index
                          ? theme.palette.primary.main
                          : '#CCC',
                    }}
                  >
                    {item.icon}
                  </Typography>
                }
                label={
                  <Typography
                    style={{
                      color:
                        selectedPage === index
                          ? theme.palette.primary.main
                          : '',
                      textTransform: 'none',
                    }}
                    className={classes.tabText}
                  >
                    {item.name}
                  </Typography>
                }
                {...a11yProps(index)}
              />
            ))}
            {isAdmin() ? (
              <Tab
                key={7}
                className={classes.navTab}
                onClick={() => handleClick()}
                style={{
                  backgroundColor:
                    selectedPage === 7 && !darkMode
                      ? '#F4F5F5'
                      : selectedPage === 7 && darkMode && '#424242',
                  marginLeft: !drawer && 7,
                }}
                classes={{
                  wrapper: classes.iconLabelWrapper,
                  // labelContainer: classes.labelContainer,
                  labelIcon: classes.labelIconTab,
                }}
                icon={
                  <Typography
                    className={classes.navIcon}
                    style={{
                      color:
                        selectedPage === 7
                          ? theme.palette.primary.main
                          : '#CCC',
                    }}
                  >
                    <AdminDashboardIcon />
                  </Typography>
                }
                label={
                  <Typography
                    style={{
                      color:
                        selectedPage === 7 ? theme.palette.primary.main : '',
                      textTransform: 'none',
                    }}
                    className={classes.tabText}
                  >
                    Administration
                  </Typography>
                }
                {...a11yProps(7)}
              />
            ) : null}
          </Tabs>
          {isAdmin() ? (
            <Collapse in={open} timeout='auto' unmountOnExit>
              <List component='div' disablePadding>
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() => drawerLink('/admin', 7)}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary='Dashboard'
                    className={classes.listItemText}
                    style={{
                      fontWeight:
                        window.location.pathname === '/admin' && 'bold',
                    }}
                  />
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() => drawerLink('/admin/reseller/all', 7)}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary='Resellers'
                    className={classes.listItemText}
                    style={{
                      fontWeight:
                        window.location.pathname === '/admin/reseller/all' &&
                        'bold',
                    }}
                  />
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() => drawerLink('/admin', 7)}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary='Reports' />
                </ListItem>
              </List>
            </Collapse>
          ) : null}
          {/* Configuration tabs for Mobile */}
          {appState.auth.loggedIn && isMobileSize && (
            <Fragment>
              <Divider
                style={{
                  marginTop: '10px',
                  marginBottom: '5px',
                  marginLeft: drawer && 24,
                  marginRight: drawer && 24,
                  backgroundColor: '#CCC',
                  height: 1,
                }}
                variant='middle'
              />
              <Tabs
                value={2}
                orientation='vertical'
                aria-label='nav tabs'
                indicatorColor='primary'
                // TabIndicatorProps={{ style: { left: 0, width: 5, height: 55 } }}
                classes={{
                  indicator: classes.indicator,
                }}
              >
                <div style={{ paddingTop: 10 }}></div>
                {drawerSettingsitems.map((item, i) => {
                  const index = i + 8;
                  return (
                    <Tab
                      key={index}
                      className={classes.navTab}
                      onClick={item.onClick}
                      style={{
                        backgroundColor:
                          selectedPage === index && darkMode && '#424242',
                      }}
                      classes={{
                        wrapper: classes.iconLabelWrapper,
                        labelIcon: classes.labelIconTab,
                      }}
                      icon={
                        <Typography
                          className={classes.navIcon}
                          style={{
                            color: '#CCC',
                          }}
                        >
                          {item.icon}
                        </Typography>
                      }
                      label={
                        <Typography
                          style={{
                            color: '',
                            textTransform: 'none',
                          }}
                          className={classes.tabText}
                        >
                          {item.name}
                        </Typography>
                      }
                      {...a11yProps(index)}
                    />
                  );
                })}
              </Tabs>
            </Fragment>
          )}

          <Box
            className={classes.versionContainer}
            display='flex'
            justifyContent='center'
            alignItems='flex-end'
          >
            <p className={classes.versionText}>
              <a
                href='https://www.misensors.com/release-notes/'
                title='Click Here to see MiSensors Release Notes'
                target='_blank'
                rel='noopener noreferrer'
              >
                {versionNumber}
              </a>
            </p>
          </Box>
        </SwipeableDrawer>

        <main
          className={clsx(classes.content, {
            [classes.contentShift]: drawer,
          })}
        >
          {appState.auth.loggedIn ? routeResultAuth : routeResultNonAuth}
        </main>
        <div>
          <React.Fragment>
            <Drawer
              anchor={'bottom'}
              open={bottomDrawerState}
              onClose={closeBottomSheet}
            >
              {list('bottom')}
            </Drawer>
          </React.Fragment>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default withWidth()(Content);
