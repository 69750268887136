import _ from 'lodash';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Card,
  Dialog,
  Typography,
  CardContent,
  CardHeader,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Grid
} from '@material-ui/core';
import clsx from 'clsx';

import { AppContext } from '../../AppContext';

import { ThemeTab } from './UserSettingsComponents/ThemeTab';
import { LayoutTab } from './UserSettingsComponents/LayoutTab';
import { SensorTab } from './UserSettingsComponents/SensorTab';
import { SystemTab } from './UserSettingsComponents/SystemTab';
import { ReportsTab } from './UserSettingsComponents/ReportsTab';
import { SettingsNav } from './UserSettingsComponents/SettingsNav';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { UserSettingsMobile } from './UserSettingsMobile';
import { Header } from '../AppContent/Header/Header'



const drawerWidth = 240;


/**
 * Account Settings Form Modal
 * @param {Object} props
 * @props
 * openState: Boolean
 * handleClose: Function
 * handleSave: Function
 */


export function UserSettings(props) {
  const { updateUserProfile, appState } = useContext(AppContext);
  const {
    darkMode,
    openState,
    handleSave,
    timeFormat,
    handleClose,
    pageDisplay, // true == listview, false == cardview
    primaryColor,
    setTimeFormat,
    setPageDisplay,
    handleDarkModeChange,
    handlePrimaryColorChange,
    drawer,
    setDrawer,
  } = props;

  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));

  const classes = useStyles(theme);

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const fullWidth = false;
  const maxWidth = 'sm';

  const [tempType, setTempType] = useState('F');
  const [pressType, setPressType] = useState('PSI');
  const [nightlyReports, setNightlyReports] = useState(false);
  const [weeklyReports, setWeeklyReports] = useState(false);

  const [advancedLux, setAdvancedLux] = useState(false);

  const [defaultProfile, setDefaultProfile] = useState({
    tempType: 'F',
    darkMode: false,
    pressType: 'PSI',
    advancedLux: false,
    timeFormat: '12hr',
    weeklyReports: false,
    nightlyReports: false,
    primaryColor: '#08B3FF',
  });

  useEffect(() => {
    const { userSettings } = appState;
    const mergedSettings = _.merge(defaultProfile, userSettings);
    mergedSettings.pageDisplay = pageDisplay;
    setDefaultProfile(mergedSettings);
    setNightlyReports(mergedSettings.nightlyReports);
    setWeeklyReports(mergedSettings.weeklyReports);
    setTempType(mergedSettings.tempType);
    setPressType(mergedSettings.pressType);
    setAdvancedLux(mergedSettings.advancedLux);
  }, [openState]);

  const handleCancel = () => {
    handleDarkModeChange(defaultProfile.darkMode);
    // this breaks the page when pressing the cancel button on user settings
    handlePrimaryColorChange({ hex: defaultProfile.primaryColor });
    setPageDisplay(defaultProfile.pageDisplay);
    updateUserProfile('userSettings.advancedLux', defaultProfile.advancedLux);
    handleClose();
  };

  const handleSubmit = () => {
    handleSave({
      darkMode,
      timeFormat,
      primaryColor,
      advancedLux,
      pressType,
      tempType,
      nightlyReports,
      weeklyReports,
    });
  };

  const tabs = [
    {
      tabLabel: 'System',
      content: (
        <SystemTab timeFormat={timeFormat} setTimeFormat={setTimeFormat} />
      ),
    },
    {
      tabLabel: 'Sensor',
      content: (
        <SensorTab
          tempType={tempType}
          pressType={pressType}
          advancedLux={advancedLux}
          setTempType={setTempType}
          setPressType={setPressType}
          setAdvancedLux={setAdvancedLux}
        />
      ),
    },
    {
      tabLabel: 'Layout',
      content: (
        <LayoutTab pageDisplay={pageDisplay} setPageDisplay={setPageDisplay} />
      ),
    },
    {
      tabLabel: 'Theme',
      content: (
        <ThemeTab
          darkMode={darkMode}
          primaryColor={primaryColor}
          handleDarkModeChange={handleDarkModeChange}
          handlePrimaryColorChange={handlePrimaryColorChange}
        />
      ),
    },
  ];



  const mobileSections = [
    {
        group: 'System',
        items: [
            {
                name:'Time Format',
                value: timeFormat,
                handler: setTimeFormat,
                type: 'check',
                options: [
                  {
                    name: '12hr',
                    check: '12hr',
                    label: '12 Hour',
                  },
                  {
                    name: '24hr',
                    check: '24hr',
                    label: '24 Hour'
                  }
                ]
            }
        ]
    },
    {
        group: 'Sensor',
        items: [
            {
                name:'Temperature',
                value: tempType,
                handler: setTempType,
                type: 'check',
                options: [
                  {
                    name: 'fahrenheit',
                    check: 'F',
                    label: '°F',
                  },
                  {
                    name: 'celcius',
                    check: 'C',
                    label: '°C'
                  }
                ]
            },
            {
                name:'Pressure',
                value: pressType,
                handler: setPressType,
                type: 'check',
                options: [
                  {
                    name: 'psi',
                    check: 'PSI',
                    label: 'PSI',
                  },
                  {
                    name: 'hectopascal',
                    check: 'hPa',
                    label: 'hPa'
                  }
                ]
            },
            {
                name:'Light',
                //true for advanced
                value: advancedLux,
                handler: setAdvancedLux,
                type: 'check',
                options: [
                  {
                    name: 'simpleLux',
                    check: false,
                    label: 'Simple',
                  },
                  {
                    name: 'advancedLux',
                    check: true,
                    label: 'Advanced (Lumens)'
                  }
                ]
            }
        ]
    },
    {
        group: 'Layout',
        items: [
            {
                name:'Page Display',
                //true for list view
                value: pageDisplay,
                handler: setPageDisplay,
                type: 'check',
                options: [
                  {
                    name: 'listView',
                    check: true,
                    label: 'List View',
                  },
                  {
                    name: 'cardView',
                    check: false,
                    label: 'Card View'
                  }
                ]
            }
        ]
    },
    {
        group: 'Theme',
        items: [
            {
                name:'Color Mode',
                //true for dark mode
                value: darkMode,
                handler: handleDarkModeChange,
                type: 'check',
                options: [
                  {
                    name: 'light',
                    check: false,
                    label: 'Light',
                  },
                  {
                    name: 'dark',
                    check: true,
                    label: 'Dark'
                  }
                ]
            },
            {
                name:'Accent Color',
                //color is hex
                value: primaryColor,
                handler: handlePrimaryColorChange,
                type: 'colorPicker',
                options: [
                  {
                    name: 'Light',
                    label: 'Light',
                  }
                ]
            }
        ]
    }
];

if (
  appState?.auth?.userInfo?.perm === 0 ||
  appState?.auth?.userInfo?.perm === 99
) {
  tabs.push({
    tabLabel: 'Reports',
    content: (
      <ReportsTab
        nightlyReports={nightlyReports}
        setNightlyReports={setNightlyReports}
        weeklyReports={weeklyReports}
        setWeeklyReports={setWeeklyReports}
      />
    ),
  });
  mobileSections.push({
    group: 'Reports',
    items: [
      {
        name: 'Nightly Reports',
        //true for reports
        value: nightlyReports,
        handler: setNightlyReports,
        type: 'single',
        options: [
          {
            name: 'nightlyReports',
            check: nightlyReports,
            label: 'Nightly',
          }
        ]
      },
      {
        name: 'Weekly Reports',
        //true for reports
        value: weeklyReports,
        handler: setWeeklyReports,
        type: 'single',
        options: [
          {
            name: 'weeklyReports',
            check: weeklyReports,
            label: 'Weekly',
          }
        ]
      },
    ]
  })
}

const handleDrawerSelection = () => {
  setDrawer(!drawer)
  handleClose()
}

  const settingsHeader = () => {
    if(isSmallDevice){
      return(

      <AppBar
        elevation={0}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: drawer,
        })}
        style={{
          transition: 'margin-left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
        }}
      >
        <Toolbar
          style={{
            minHeight: '5vh'
          }}
          variant='regular'
        >
              <IconButton
                aria-label="go-back"
                onClick={()=>{handleClose()}}
              >
                <ArrowBackIosIcon
                  style={{ color: '#FFF'}}
                />
              </IconButton>
          <Typography
            className={classes.mobileTitle}
          >
            {'SETTINGS'}
          </Typography>
          {!drawer ? (
            <IconButton
              className={clsx(classes.menuButton, drawer)}
              disabled={!appState.auth.loggedIn}
              onClick={() => handleDrawerSelection()}
              edge='start'
              color='inherit'
              aria-label='menu'
            >
              <MenuIcon />
            </IconButton>
          ) : (
              <IconButton
                className={clsx(classes.menuButton, drawer)}
                disabled={!appState.auth.loggedIn}
                onClick={()=>{handleDrawerSelection()}}
                edge='start'
                color='inherit'
                aria-label='menu'
              >
                <ChevronLeftIcon />
              </IconButton>
            )}
        </Toolbar>
      </AppBar>

      )
    } else {
      return(
        <CardHeader
          title={
            <Typography className={classes.titleText} align='center'>
              ACCOUNT SETTINGS
            </Typography>
          }
        />
        )
    }
  }

  const singleSetHandler = e => {
    if (e.type === 'nightlyReports'){
        setNightlyReports(!nightlyReports);
    } else {
        setWeeklyReports(!weeklyReports);
    }
  };

  const settingsBody = () => {
    if(isSmallDevice){
      return(
        <UserSettingsMobile
          data={mobileSections}
          singleSetHandler={singleSetHandler}
          color={defaultProfile.primaryColor}
        />
      )
    } else {
      return(
      <SettingsNav
        tabs={tabs}
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        isMobileSize={isSmallDevice}
      />
        )
    }
  }

  const mobileFooter = () => {
    return (
        <Grid
          container
          direction={'row'}
          style={{paddingTop: '1vh'}}
        >
          <Grid item style={{textAlign: "center"}} xs={6}>
          <Button
            color='primary'
            onClick={() => handleClose()}
            size='large'
            variant='contained'
            className={classes.buttons}
          >
            Cancel
          </Button>
          </Grid>
          <Grid item style={{textAlign: "center"}} xs={6}>
          <Button
            onClick={()=> handleSubmit()}
            size='large'
            variant='contained'
            color='primary'
            className={classes.buttons}
          >
            Save
          </Button>
          </Grid>
        </Grid>
    )
  }

  return (
    <Dialog
      fullScreen={fullScreen} // commented temporarily because it breaks the layout
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={openState}
      // open
      aria-labelledby='responsive-dialog-title'
      onClose={handleClose}
    >
      <Card style={{ height: isSmallDevice ? '100%' : 560, width: isSmallDevice ? '100%' : 542 }}>
        {settingsHeader()}
        <CardContent id='lookHere' className={ isSmallDevice ? classes.cardContentMobile : classes.cardContent}>
          {settingsBody()}
        </CardContent>
        {isSmallDevice ? mobileFooter() : null}
      </Card>
    </Dialog>
  );
}

const useStyles = makeStyles(theme => ({
  cardContent: { padding: 0 + '!important', height: 'calc( 100% - 53px )' },
  cardContentMobile: {height: '90%', paddingTop: '10%', paddingLeft:'0px', overflow: 'scroll'},
  titleText: {
    fontWeight: 'bold',
    color: theme.palette.type === 'light' ? '#747474' : '#FFF',
  },
  appBar: {
    width: `calc(100% - 65px)`,
    marginLeft:theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(8) + 1,
    },

    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      width: `calc(100%)`,
    },
  },
  appBarShift: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  buttons: {
    width: 112,
    fontSize: 13,
    marginLeft: '5%',
    marginRight: '5%',
  },
  mobileTitle: {
    flexGrow: 1,
    textAlign: 'center',
    color: '#FFF',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  }
}));
