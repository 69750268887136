import _ from 'lodash';
import React from 'react';

import { SettingsCheckBoxes } from './SettingsCheckBoxes';

export function SensorTab(props) {
  const {
    tempType,
    pressType,
    advancedLux,
    setTempType,
    setPressType,
    setAdvancedLux,
  } = props;

  const tempBoxes = [
    { name: 'fahrenheit', check: 'F', label: '°F' },
    { name: 'celcius', check: 'C', label: '°C' },
  ];

  const pressBoxes = [
    { name: 'psi', check: 'PSI', label: 'PSI' },
    { name: 'hectopascal', check: 'hPa', label: 'hPa' },
  ];

  const lightBoxes = [
    { name: 'simpleLux', check: false, label: 'Simple' },
    { name: 'advancedLux', check: true, label: 'Advanced (Lumens)' },
  ];

  return (
    <>
      <SettingsCheckBoxes
        currentValue={tempType}
        setCurrentValue={setTempType}
        checkBoxData={tempBoxes}
        label='Temperature'
      />
      <SettingsCheckBoxes
        currentValue={pressType}
        setCurrentValue={setPressType}
        checkBoxData={pressBoxes}
        label='Pressure'
      />
      <SettingsCheckBoxes
        currentValue={advancedLux}
        setCurrentValue={setAdvancedLux}
        checkBoxData={lightBoxes}
        label='Light'
      />
    </>
  );
}
