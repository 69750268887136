import React, { useContext } from 'react';
import { Box, Card } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import { makeStyles } from '@material-ui/core/styles';

import { AlertContext } from '../AlertContext';
import { EditAction } from '../ManageAlertModalComp/EditAction';

/**
 * returns the typography color based in the
 * theme dark mode and the tone given for the colors
 * @param {Object, int, int} theme, lightTone=700, darkTone=300
 */
const textColor = ({ theme, lightTone = 700, darkTone = 300 }) => {
  if (theme.palette.type === 'light') {
    return grey[lightTone];
  } else {
    return grey[darkTone];
  }
};

/**
 * InformationTile component for
 * dashboard top area
 *
 * @props
 * numberOfEntities: integer
 * entityName: String
 * icon: React Component
 * underlineColor: String (Color)
 */
export default function InformationTile(props) {
  const {
    card,
    card: {
      item: {
        icon, // icon to render
        title, // string , title of the InformationTile
        subtitle,
        underlineColor, // underline Color
      },
    },
    children, // children to render
  } = props;

  const { setModalState } = useContext(AlertContext);

  const useStyles = makeStyles(theme => ({
    card: {
      padding: theme.spacing(3, 4.5, 2, 4),
      borderRadius: '5px',
      height: 232,
    },
    titleContainer: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      margin: theme.spacing(0, 0, 6, 0),
    },
    headerInfo: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    cardTitle: {
      fontSize: 18,
      fontWeight: 'fontWeightMedium',
      textTransform: 'uppercase',
      color: textColor({ theme, lightTone: 500, darkTone: '#FFF' }),
    },
    cardSubtitle: {
      marginTop: 10,
      fontSize: 16,
      fontWeight: 'fontWeightMedium',
      textTransform: 'uppercase',
      color: textColor({ theme, lightTone: 500, darkTone: '#FFF' }),
    },
    underLine: {
      backgroundColor: underlineColor,
      height: '2px',
      width: '3rem',
    },
    typeIcon: {
      margin: '0 8px 0 0',
    },
  }));

  const classes = useStyles();

  return (
    <Card elevation={3} className={classes.card}>
      <div className={classes.titleContainer}>
        <Box className={classes.headerInfo}>
          <div className={classes.typeIcon}>{icon}</div>
          <Box>
            <Box className={classes.cardTitle}>{title}</Box>
            <Box className={classes.underLine} />
            <Box className={classes.cardSubtitle}>{subtitle}</Box>
          </Box>
        </Box>
        {card.item.name !== 'panic_button' &&
          card.item.name !== 'motion_detection' && (
            <EditAction
              click={() =>
                setModalState({
                  isOpen: true,
                  editType: 'alert',
                  alertObj: { ...card },
                })
              }
            />
          )}
      </div>
      <Box>{children}</Box>
    </Card>
  );
}
