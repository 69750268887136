import React from 'react';
import { Badge, withStyles } from '@material-ui/core';

import SideBatterySVG from '../../../icons/SideBatterySVG';

/**
 * Device Card
 * @param {Object} props
 * @props
 * batteryVolt: number
 * lastMessageTime: timestamp,
 */
export function DeviceBatteryIcon(props) {
  const { batteryVolt } = props;

  const StyledBadge = withStyles(theme => ({
    anchorOriginTopLeftRectangle: '', // To remove error from console
    badge: {
      width: 16,
      height: 16,
      borderRadius: '10%',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      left: '0%',
      top: '49%',
    },
  }))(Badge);

  return batteryVolt <= 1.9 ? (
    <StyledBadge
      overlap='circle'
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      variant='standard'
      badgeContent={<SideBatterySVG />}
    />
  ) : null;
}
