import React from "react";
import { Box, IconButton } from "@material-ui/core/";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";

import SnoozeIcon from "@material-ui/icons/Snooze";
import BuildIcon from "@material-ui/icons/Build";

/**
 *
 *
 * returns the bacjground color based in the
 * theme dark mode and the tone given for the colors
 * @param {Object, int} theme, lightTone=700, darkTone=300
 */
const rowColor = ({ theme, index }) => {
  if (theme.palette.type === "light") {
    return index % 2 === 0 ? grey[100] : "white";
  } else {
    return index % 2 === 0 ? grey[900] : grey[800];
  }
};
/**
 * Device Container with alertStatus
 *
 * @ deprecated replaced with the components inside ActiveAlertsList
 *
 * @props
 * index: integer
 * alertName: String
 * locationName: String
 * deviceName: String
 * trailingComponent: Component
 */
function AlertContainer(props) {
  const theme = useTheme();
  const {
    index, // Index use for the color
    alertName, // Name of the alert
    locationName, // Name of the device
    deviceName, // Name of the device
    time, // Time of the alert
    trailingComponent, // Leading component for the right of the container
    leadingIcon, // Component leadingIcon
    canSnooze, // Boolean show snooze button,
    noActiveAlerts, // If no list
    mobileLayout = false, // boolean to shot the tiles with the mobile layout
  } = props;

  const useStyles = makeStyles((theme) => ({
    container: {
      backgroundColor: rowColor({ theme, index }),
      paddingLeft: mobileLayout ? 18 : 35,
      paddingRight: 5,
      paddingTop: 10,
      paddingBottom: 10,
    },
    titleText: {
      fontSize: mobileLayout ? 12 : 14,
      fontWeight: 600,
      color: theme.palette.type === "light" ? "#747474" : "#FFF",
    },
    descriptionText: {
      fontSize: mobileLayout ? 11 : 12,
      color: theme.palette.type === "light" ? "#747474" : "#FFF",
    },
    icon: {
      margin: theme.spacing(0, 0, 1.5, 0),
    },
    leadingIcon: {
      marginRight: theme.spacing(1),
    },
    trailingValue: {
      marginRight: theme.spacing(3),
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
        display: 'flex'
      }
    },
    snoozeIcon: {
      color: theme.palette.warning.main,
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      }
    },
    buildIcon: {
      color: theme.palette.primary.main,
    },
    tr: {
      width:'100%'
    }
  }));

  const calculateTime = (time) => {
    const alertDate = new Date(time)
    const currentDate = new Date()
    let difference = (currentDate.getTime() - alertDate.getTime()) / 1000
    if (difference < 3600) {
      const minutes = Math.trunc(difference / 60)
      return `${minutes} Mins ago`
    } else if (difference < 86400) {
      const hours = Math.trunc(difference / 3600)
      return `${hours} Hours ago`
    } else {
      const days = Math.trunc(difference / 86400)
      return `${days} Days ago`
    }
    return difference
  }
  const classes = useStyles();
  
  return noActiveAlerts ? (
    <Box
      style={{
        backgroundColor: rowColor({ theme, index }),
        paddingTop: 10,
        paddingBottom: 10,
      }}
    >
      <Box display="flex" justifyContent="center">
        <Box>
          <Box className={classes.titleText}>{alertName}</Box>
        </Box>
      </Box>
    </Box>
  ) : (
    <Box className={classes.container}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" justifyContent="space-around">
          <Box className={classes.leadingIcon}>{leadingIcon}</Box>
          <Box>
            <Box className={classes.titleText}>{alertName}</Box>
            <Box className={classes.descriptionText}>
              {locationName} - {deviceName} {time ? `- ${calculateTime(time)}` : null}
            </Box>
          </Box>
        </Box>
        <Box className={classes.trailingValue}>
          {!trailingComponent ? (
            <>
            <Box display="flex" alignItems="center">
              {canSnooze ? (
                <IconButton
                  className={classes.snoozeIcon}
                  aria-label="snooze_alarm"
                >
                  <SnoozeIcon />
                </IconButton>
              ) : null}
            </Box>
            <Box display="flex" alignItems="center">
                <IconButton
                  className={classes.buildIcon}
                  aria-label="snooze_alarm"
                >
                  <BuildIcon />
                </IconButton>
            </Box>
            </>
          ) : (
            { trailingComponent }
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default AlertContainer;
