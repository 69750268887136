import React, { useContext } from 'react';

import {
  Grid,
  Avatar,
  Typography,
  makeStyles,
  IconButton,
  Box
} from '@material-ui/core';

import { AppContext } from '../../AppContext';

import grey from '@material-ui/core/colors/grey';

import { Edit, DeleteForever } from "@material-ui/icons";

import StatusBadge from '../Generic/StatusBadge';

export default function HeaderMobile(props) {

  const { appState } = useContext(AppContext);


  const {
    title, // Name of the sensor (Title of the Card)
    avatar, // Avatar component
    handleEdit, // method to edit the element
    handleDelete, // method to delete the element
    handleEditAvatarImage, // method to edit the avatar
    lastMessageTime, // last message time of the gateway
    showBadge = true, // boolean to show the badge
    subtitles = [], // array of Strings of all the extra information
    showEditActions = true, // boolean to show the delete and edit action buttons
    showDelete = true, // boolean to show the delete button
    isAvatarIcon = false, // boolean to add padding to reduce icon size
  } = props;

  const useStyles = makeStyles(theme => ({
    mobileTopSection: {
      padding: "15px 15px 25px 15px",
      background: theme.palette.primary.main,
      borderRadius: "0px 0px 15px 15px"
    },
    avatarContainerGrid: {
    },
    avatarContainer: {
      transform: "translate(0px,-5px)",
      marginTop: 0,

    },
    avatarLarge: {
      background: 'white',
      margin: 'auto',
      height: '98px',
      width: '98px',
      border: '5px solid #FFF',
      // border: '5px solid red',
      // boxShadow: '0px 0px 0px 5px #FFF',
      padding: isAvatarIcon ? '18px' : '0px'
    },
    
    avatarImage: {
      objectFit: !avatar && 'contain',
    },
    title: {
      fontSize: 14,
      fontWeight: 'bold',
      color: 'white'
    },
    subTitle: {
      fontSize: 12,
      color: 'white'
    },
    icon: {
      fontSize: 25,
      color: "#FFF",
    },
    statusCircle: {
      right: "0%",
      bottom: "49%",
    },
    editImageButtonContainer: {
      marginTop: '-20px',
      textAlign: "center",
    },
    editImageButton: {
      color: grey[500],
      border: `2px solid ${grey[500]}`,
      backgroundColor: '#ffffff',
      '& svg': {
        fontSize: 16,
      },
    },
  }))

  const classes = useStyles();

  return (
    <Grid
      className={classes.mobileTopSection}
      container
      justify='center'
      alignItems='center'
      xs={12}
    >
      <Grid
        className={classes.avatarContainerGrid}
        item
        xs={3}
      >
        <Box
          className={classes.avatarContainer}
          display="flex"
          justifyContent="center"
        >
          {
            showBadge ?
            <StatusBadge
              conditional={lastMessageTime > 0 }
              classes={{
                anchorOriginBottomRightCircle: classes.statusCircle,
              }}
              overlap="circle"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              variant="dot"
            >
              <Avatar
                classes={{ img: classes.avatarImage }}
                alt="avatar"
                src={avatar}
                className={classes.avatarLarge}
              />
            </StatusBadge>
            :
            <Avatar
              classes={{ img: classes.avatarImage }}
              alt="avatar"
              src={avatar}
              className={classes.avatarLarge}
            />
          }
        </Box>
        <Grid
          item
          xs={12}
          display="flex"
          className={classes.editImageButtonContainer}
        >
          <IconButton
            size="small"
            className={classes.editImageButton}
            onClick={handleEditAvatarImage}
          >
            <Edit
              style={{
                fontSize: 14
              }}
            />
          </IconButton>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          textAlign: 'center',
        }}
      >
        <Typography className={classes.title} gutterBottom>
          {title}
        </Typography>
      </Grid>
      { subtitles.map(subtitle => (
        <Grid
          item
          xs={12}
          style={{
            textAlign: 'center',
          }}
        >
          <Typography className={classes.subTitle} gutterBottom>
            {subtitle}
          </Typography>
        </Grid>
      ))}
      {showEditActions && (
        <Grid
          container
          item
          xs={12}
          justify="space-between"
          style={{
            transform: 'translate(0px,-50px)',
            height: 0,
            overflow: 'visible'
          }}
        >
          <Grid
            item
          >
            <IconButton
              onClick={handleEdit}
              style={{
                marginTop: 8
              }}
              size="medium"
            >
              <Edit className={classes.icon} />
            </IconButton>
          </Grid>

          <Grid
            item
          >
            { showDelete && (
              <IconButton
                onClick={handleDelete}
                style={{
                  marginTop: 8
                }}
              >
                <DeleteForever className={classes.icon} />
              </IconButton>
            )}
          </Grid>
        </Grid>
        )}
    </Grid>
  )
};
