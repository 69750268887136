import React from 'react';
import { Grid, makeStyles, TextField } from '@material-ui/core';

import ModalFormHeader from '../../common/ModalComponents/ModalFormHeader';

const useStyles = makeStyles(theme => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
  },
}));

/**
 * 1st step in create account modal
 * @param {Object} props
 */
export function ActivationCode(props) {
  const classes = useStyles();
  const { handleChange, activationCode, activationCodeErr } = props;

  return (
    <Grid container item xs={12} spacing={2}>
      <ModalFormHeader
        header='Enter your activation code'
        desc='Locate your activation code on the insert card of your MiSensors Package.'
      />
      <Grid item xs={12}>
        <TextField
          name='activationCode'
          value={activationCode}
          className={classes.textField}
          onChange={handleChange}
          fullWidth
          variant='outlined'
          placeholder='Activation Code *'
          error={activationCodeErr}
        />
      </Grid>
    </Grid>
  );
}
