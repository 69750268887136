import React, { useState } from 'react';
import { TextField, Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function LocFormCity(props) {
  const log = window.log('LocationForm');

  const { city, classes, setCity, cityError } = props;

  const [cities, setCities] = useState([]);

  const handleSetCityChange = async (e, v) => {
    let arr = [];
    if (e) {
      try {
        setCity(e.currentTarget.value);
        const response = await fetch(
          `https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json?apiKey=${process.env.REACT_APP_HERE_API_KEY}&query=${e.currentTarget.value}&resultType=city`,
        );
        const data = await response.json();
        if (data.suggestions) {
          data.suggestions.forEach(item => arr.push(item.address.city));
        }
      } catch (error) {
        log(error);
      }
    }
    setCities(arr);
  };

  const handleCitySelect = (e, v) => {
    if (v) {
      setCity(v);
    }
  };

  return (
    <Grid item xs={12}>
      <Autocomplete
        id='city'
        options={cities}
        getOptionLabel={option => `${option}`}
        inputValue={city}
        onChange={(e, v) => handleCitySelect(e, v)}
        onInputChange={(e, v) => handleSetCityChange(e, v)}
        renderInput={params => (
          <TextField
            {...params}
            className={classes.textField}
            autoComplete='city'
            label='City'
            name='city'
            variant='outlined'
            required
            style={{
              width: '105%',
            }}
            error={cityError}
          />
        )}
      />
    </Grid>
  );
}
