import React, { useState, useContext, useEffect } from 'react';
import {
  Box,
  Grid,
  InputAdornment,
  LinearProgress,
  makeStyles,
  TextField,
} from '@material-ui/core';
import DirectResellerCheck from './DirectResellerCheckBox';
import { navigate } from 'hookrouter';
import MuiPhoneNumber from 'material-ui-phone-number';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';

//Components
import ResponsiveDialog from '../Generic/GenericDialog';
import ImageButton from '../Generic/ImageButton';
import LocationForm from '../common/LocationForm/LocationForm';
import { AppContext } from '../../AppContext';

const useStyles = makeStyles(theme => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
  },
  fieldPadding: {
    paddingBottom: theme.spacing(3),
    color:
      theme.palette.type === 'light'
        ? theme.palette.grey[600]
        : theme.palette.text.primary,
  },
  fontColor: {
    color:
      theme.palette.type === 'light'
        ? theme.palette.grey[600]
        : theme.palette.text.primary,
  },
  imageButtonSize: {
    width: 175,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 150,
      marginBottom: 10,
    },
  },
  tickSize: {
    transform: 'scale(1.5)',
  },
}));

/**
 * Location Form Modal
 * @param {Object} props
 * @props
 * openState: Boolean
 * handleClose: Functions
 */
export default function AddResellerFormModal(props) {
  const log = window.log('AddResellerFormModal');
  const classes = useStyles();
  const {
    appState,
    setLogoutState,
    openResellerModal,
    setOpenResellerModal,
    snack,
  } = useContext(AppContext);

  const { getResellers } = props;

  const [resellerInfo, setResellerInfo] = useState({
    companyName: '',
    street: '',
    city: '',
    state: '',
    zip: '',
    fname: '',
    lname: '',
    email: '',
    phone: '',
    upgradeCommissionRate: '',
    renewalCommissionRate: '',
  });

  const [resellerInfoError, setResellerInfoError] = useState({
    companyName: false,
    street: false,
    city: false,
    state: false,
    zip: false,
    fname: false,
    lname: false,
    email: false,
    phone: false,
    upgradeCommissionRate: false,
    renewalCommissionRate: false,
  });

  const resetResellerInfo = () => {
    setResellerInfo({
      companyName: '',
      street: '',
      city: '',
      state: '',
      zip: '',
      fname: '',
      lname: '',
      email: '',
      phone: '',
    });
    setResellerInfoError({
      companyName: false,
      street: false,
      city: false,
      state: false,
      zip: false,
      fname: false,
      lname: false,
      email: false,
      phone: false,
    });
  };

  const [newResellerId, setNewResellerId] = useState(null);

  //Submit USER state hook
  const [submitState, setSubmitState] = useState(false);

  const [isDirect, setIsDirect] = useState(false);

  const handleDirect = () => {
    setIsDirect(!isDirect);
    setResellerInfo({
      ...resellerInfo,
      upgradeCommissionRate: '0.00',
      renewalCommissionRate: '0.00',
    });
  };

  const handleCommission = (e, type) => {
    if (!isNaN(e.currentTarget.value)) {
      if (e.currentTarget.value.includes('.')) {
        const split = e.currentTarget.value.split('.');
        if (split[1].length > 2) return;
      }
      setIsDirect(false);
      setResellerInfo({
        ...resellerInfo,
        [type]: e.currentTarget.value,
      });
    }
  };

  // Submit function to api
  const handleSubmitUser = async () => {
    if (
      !resellerInfo.companyName ||
      !resellerInfo.street ||
      !resellerInfo.city ||
      !resellerInfo.state ||
      !resellerInfo.zip ||
      !resellerInfo.fname ||
      !resellerInfo.lname ||
      !resellerInfo.email ||
      !resellerInfo.phone ||
      (!resellerInfo.upgradeCommissionRate &&
        resellerInfo.upgradeCommissionRate !== 0) ||
      (!resellerInfo.renewalCommissionRate &&
        resellerInfo.renewalCommissionRate !== 0)
    ) {
      setResellerInfoError({
        companyName: !resellerInfo.companyName,
        street: !resellerInfo.street,
        city: !resellerInfo.city,
        state: !resellerInfo.state,
        zip: !resellerInfo.zip,
        fname: !resellerInfo.fname,
        lname: !resellerInfo.lname,
        email: !resellerInfo.email,
        phone: !resellerInfo.phone,
        upgradeCommissionRate: !resellerInfo.upgradeCommissionRate,
        renewalCommissionRate: !resellerInfo.renewalCommissionRate,
      });

      snack('Something went wrong. Please double check all fields.', 'error');
      return false;
    }
    // if (resellerInfo.state.length !== 2) {
    //   snack('Please Use Two Letter State Code', 'error');
    //   return false;
    // }

    let formattedPhoneNum = resellerInfo.phone
      .split('(')
      .join('')
      .split(')')
      .join('')
      .split(' ')
      .join('')
      .split('-')
      .join('');

    if (formattedPhoneNum.length <= 5) {
      formattedPhoneNum = '';
    }

    try {
      resellerInfo.phone = formattedPhoneNum;

      const data = {
        companyName: resellerInfo.companyName,
        street: resellerInfo.street,
        city: resellerInfo.city,
        state: resellerInfo.state,
        zip: resellerInfo.zip,
        employeeName: resellerInfo.fname + ' ' + resellerInfo.lname,
        email: resellerInfo.email,
        phone: resellerInfo.phone,
        upgradeCommissionRate: resellerInfo.upgradeCommissionRate,
        renewalCommissionRate: resellerInfo.renewalCommissionRate,
        fname: resellerInfo.fname,
        lname: resellerInfo.lname,
        isDirect: isDirect,
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/add-reseller`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
          body: JSON.stringify(data),
        },
      );

      const json = await response.json();

      //Add a delay in order to show loading screen on stepper form
      const delay = ms => new Promise(res => setTimeout(res, ms));
      await delay(1500);

      if (json.success) {
        if (json.data && json.data.id >= 0) {
          setNewResellerId(json.data.id);
        }
        if (json.errors.length) {
          snack(json.errors[0].msg, 'error');
        }
        return true;
      } else {
        if (json.errors.length) {
          json.errors.forEach(err => {
            snack(err.msg, 'error');
            if (err.type === 'token') setLogoutState(true);
          });
          setResellerInfoError({
            companyName: !resellerInfo.companyName,
            street: !resellerInfo.street,
            city: !resellerInfo.city,
            state: !resellerInfo.state,
            zip: !resellerInfo.zip,
            fname: !resellerInfo.fname,
            lname: !resellerInfo.lname,
            email: !resellerInfo.email,
            phone: !resellerInfo.phone,
          });
        }
      }
    } catch (err) {
      log(err);
      snack(
        'Something went wrong. Please contact support if error persists.',
        'error',
      );
      return false;
    }
  };

  const handleClose = () => {
    setOpenResellerModal(false);
    resetResellerInfo();
  };

  const handleRestart = async () => {
    setOpenResellerModal(false);
    resetResellerInfo();
    setTimeout(() => {
      setOpenResellerModal(true);
    }, 500);
  };

  return (
    <div>
      <ResponsiveDialog
        openState={openResellerModal}
        handleClose={handleClose}
        handleCancel={handleClose}
        handleFinish={() => {
          if (newResellerId) {
            navigate(`/admin/reseller/${newResellerId}`);
          } else {
            if (getResellers) {
              getResellers();
            }
          }
          handleClose();
        }}
        title='ADD A NEW RESELLER'
        stepsArray={[
          {
            label: 'RESELLER INFO',
            // nextFunction: handleSubmitUser,
            // validator: !submitState,
            showProgress: true,
            child: (
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Box
                    className={classes.fontColor}
                    fontWeight='fontWeightBold'
                    fontSize={20}
                  >
                    Enter reseller information
                  </Box>
                </Grid>
                <Grid item xs={12} fontWeight='fontWeightLight' fontSize={16}>
                  <Box className={classes.fontColor}>
                    Enter the Name, Street, City, State and Zip Code information
                    for your reseller below.
                  </Box>
                </Grid>
                <LocationForm
                  addCompanyField={true}
                  removeUseCurrentLocation={true}
                  companyName={resellerInfo.companyName}
                  setCompanyName={value => {
                    setResellerInfo(prevState => {
                      return { ...prevState, companyName: value };
                    });
                  }}
                  companyNameError={resellerInfoError.companyName}
                  street={resellerInfo.street}
                  setStreet={value => {
                    setResellerInfo(prevState => {
                      return { ...prevState, street: value };
                    });
                  }}
                  streetError={resellerInfoError.street}
                  city={resellerInfo.city}
                  setCity={value => {
                    setResellerInfo(prevState => {
                      return { ...prevState, city: value };
                    });
                  }}
                  cityError={resellerInfoError.city}
                  state={resellerInfo.state}
                  setState={value => {
                    setResellerInfo(prevState => {
                      return { ...prevState, state: value };
                    });
                  }}
                  stateError={resellerInfoError.state}
                  zipcode={resellerInfo.zip}
                  setZipcode={value => {
                    setResellerInfo(prevState => {
                      return { ...prevState, zip: value };
                    });
                  }}
                  zipcodeError={resellerInfoError.zip}
                  classes={classes}
                />
              </Grid>
            ),
          },
          {
            label: 'CONTACT INFO',
            showProgress: true,
            child: (
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Box
                    className={classes.fontColor}
                    fontWeight='fontWeightBold'
                    fontSize={20}
                  >
                    Enter contact information
                  </Box>
                </Grid>
                <Grid item xs={12} fontWeight='fontWeightLight' fontSize={16}>
                  <Box className={classes.fontColor}>
                    Enter full name, email and phone number
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name='First Name'
                    className={classes.textField}
                    fullWidth
                    variant='outlined'
                    placeholder='First Name*'
                    label='First Name*'
                    error={resellerInfoError.fname}
                    value={resellerInfo.fname}
                    onChange={e =>
                      setResellerInfo({
                        ...resellerInfo,
                        fname: e.currentTarget.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name='Last Name'
                    className={classes.textField}
                    fullWidth
                    variant='outlined'
                    placeholder='Last Name*'
                    label='Last Name*'
                    error={resellerInfoError.lname}
                    value={resellerInfo.lname}
                    onChange={e =>
                      setResellerInfo({
                        ...resellerInfo,
                        lname: e.currentTarget.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name='email'
                    className={classes.textField}
                    fullWidth
                    variant='outlined'
                    placeholder='Email*'
                    label='Email*'
                    error={resellerInfoError.email}
                    value={resellerInfo.email}
                    onChange={e =>
                      setResellerInfo({
                        ...resellerInfo,
                        email: e.currentTarget.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <MuiPhoneNumber
                    fullWidth
                    className={classes.textField}
                    variant='outlined'
                    placeholder='Phone Number*'
                    label='Phone Number*'
                    defaultCountry={'us'}
                    preferredCountries={['us']}
                    disableAreaCodes={true}
                    autoFormat={true}
                    error={resellerInfoError.phone}
                    value={resellerInfo.phone}
                    onChange={e =>
                      setResellerInfo({ ...resellerInfo, phone: e })
                    }
                    countryCodeEditable={false}
                    inputProps={{
                      autoComplete: 'new-password',
                    }}
                  />
                </Grid>
              </Grid>
            ),
          },
          {
            label: 'COMMISSION',
            nextFunction: handleSubmitUser,
            validator: !submitState,
            showProgress: true,
            saveButtonStep: true,
            child: (
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Box
                    className={classes.fontColor}
                    fontWeight='fontWeightBold'
                    fontSize={20}
                  >
                    Enter commission rate
                  </Box>
                </Grid>
                <Grid item xs={12} fontWeight='fontWeightLight' fontSize={16}>
                  <Box className={classes.fontColor}>
                    Enter the commission percentages, for upgrade and renewals
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name='upgradeComissionRate'
                    className={classes.textField}
                    fullWidth
                    variant='outlined'
                    placeholder='Upgrade Commission Rate (ex. 30%)'
                    label='Upgrade Commission Rate (ex. 30%)'
                    error={resellerInfoError.upgradeCommissionRate}
                    value={resellerInfo.upgradeCommissionRate}
                    inputProps={{
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>%</InputAdornment>
                      ),
                    }}
                    onChange={e => handleCommission(e, 'upgradeCommissionRate')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name='renewalComissionRate'
                    className={classes.textField}
                    fullWidth
                    variant='outlined'
                    placeholder='Renewal Commission Rate (ex. 30%)'
                    label='Renewal Commission Rate (ex. 30%)'
                    error={resellerInfoError.renewalCommissionRate}
                    value={resellerInfo.renewalCommissionRate}
                    inputProps={{
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>%</InputAdornment>
                      ),
                    }}
                    onChange={e => handleCommission(e, 'renewalCommissionRate')}
                  />
                </Grid>
                <DirectResellerCheck
                  check={isDirect}
                  change={handleDirect}
                ></DirectResellerCheck>
              </Grid>
            ),
          },
          {
            label: 'LOADING',
            child: (
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Box
                    className={classes.fontColor}
                    fontWeight='fontWeightBold'
                    fontSize={20}
                  >
                    Adding Reseller into Platform
                  </Box>
                </Grid>
                <Grid item xs={12} fontWeight='fontWeightLight' fontSize={16}>
                  <Box className={classes.fontColor}>
                    MiSensors is currently configuring the reseller into the
                    platform. This should take less than a minute.
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <LinearProgress />
                </Grid>
              </Grid>
            ),
          },
          {
            label: 'COMPLETE', // Because is the finish
            child: (
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Box
                    className={classes.fontColor}
                    fontWeight='fontWeightBold'
                    fontSize={20}
                  >
                    Success! The new reseller has been added.
                  </Box>
                </Grid>
                <Grid item xs={12} fontWeight='fontWeightLight' fontSize={16}>
                  <Box className={classes.fontColor}>
                    You may now add additional resellers by selecting the option
                    below.
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justify='space-around' display='flex'>
                    <Grid item className={classes.imageButtonSize}>
                      <ImageButton
                        onClick={() => {
                          handleRestart();
                        }}
                        isComponent={true}
                        image={
                          <MonetizationOnOutlinedIcon
                            style={{ fontSize: 55 }}
                          />
                        }
                        action='ADD'
                        text='ANOTHER RESELLER'
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ),
          },
        ]}
      />
    </div>
  );
}
