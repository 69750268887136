import React from 'react';
import { Box, Typography } from '@material-ui/core/';

import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

const rowColor = ({ theme, index }) => {
  if (theme.palette.type === 'light') {
    return index % 2 === 0 ? grey[100] : 'white';
  } else {
    return index % 2 === 0 ? grey[900] : grey[800];
  }
};

export default function ListElement(props) {
  const {
    title, // Title
    index, // Index
    subtitle, // Subtitle
    onClick = () => {},
    leftSideItems = null,
    titleIsBold, // Change the style of the tittle to bold
  } = props;

  const useStyles = makeStyles(theme => ({
    container: {
      backgroundColor: rowColor({ theme, index }),
      padding: '10px',
      borderBottom: `1px solid ${
        theme.palette.type === 'light' ? grey[200] : grey[700]
      }`,
      display: 'flex',
      justifyContent: 'space-between',
    },
    fontColor: {
      color: theme.palette.type === 'light' ? grey[500] : '#FFF',
    },
  }));

  const classes = useStyles();

  return (
    <Box key={index} className={classes.container} onClick={onClick}>
      <div>
        <Typography
          className={classes.fontColor}
          variant='button'
          display='block'
          style={{
            fontWeight: titleIsBold ? 'bold' : 'normal'
          }}
        >
          {title}
        </Typography>
        <Typography className={classes.fontColor} variant='body2'>
          {subtitle}
        </Typography>
      </div>
      {leftSideItems}
    </Box>
  );
}
