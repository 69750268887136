import _ from 'lodash';
import React, { useState, useEffect, Fragment } from 'react';

import {
  Grid,
  Box,
  makeStyles,
  withStyles,
  Badge,
  IconButton,
  MobileStepper,
} from '@material-ui/core';
import CardInfoIconsMobile from '../Generic/CardInfoIconsMobile';
import CardInfoIcons from '../Generic/CardInfoIcons';

import grey from '@material-ui/core/colors/grey';

import GenericListCard from '../Generic/GenericListCard';

import { Edit } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import { DeviceAvatar } from './DeviceCardComponents/DeviceAvatar';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
/**
 * Device Card
 * @param {Object} props
 * @props
 * id: Integer
 * trailingItems: Object Array [{ icon: React Component , value: String }]
 * name: String,
 * sensorId: String,
 * locationName: String
 */
export default function GatewayCard(props) {
  const {
    name, // Name of the sensor (Title of the Card)
    wrap,
    avatar,
    resize,
    buttons,
    subtitle,
    subtitle2,
    subtitle3,
    enableZero,
    sensorCard, // if for sensors
    singleGatewayCard,
    lastMessageTime,
    singleAlertCard,
    singleLocationCard,
    showStatus,
    defaultAvatar,
    handleEditImage,
    batteryVolt,
    reloadSocket,
    isTileLayout = false, // Boolean to show the element as a Tile and not a card
    backgroundColor = 'white', // Card background color
    gatewayCard, // if for gateways
    trailingItems, // Trailing Items for the CardInfoIcons
    displayDivider,
    showAvatar = true,
    onClick = () => {},
    showOnlyItems = [],
    isItemsDict = false,
    passValidator = false,
  } = props;

  const [mobileStepperStep, setMobileStepperStep] = useState(0);

  const mobileStepperChange = step => {
    setMobileStepperStep(step);
  };

  let currentDate = new Date().getTime() / 1000;
  const [connectedStatus, setConnectedStatus] = useState(
    currentDate - lastMessageTime <= 300,
  );

  useEffect(() => {
    currentDate = new Date().getTime() / 1000;
    if (
      currentDate - lastMessageTime > 300 &&
      batteryVolt &&
      batteryVolt <= 2.76
    ) {
      setConnectedStatus(true);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      currentDate = new Date().getTime() / 1000;
      if (
        currentDate - lastMessageTime > 300 &&
        batteryVolt &&
        batteryVolt <= 2.76
      ) {
        setConnectedStatus(false);
      } else if (currentDate - lastMessageTime > 300 && connectedStatus) {
        setConnectedStatus(false);
      } else if (currentDate - lastMessageTime <= 300 && !connectedStatus) {
        setConnectedStatus(true);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [reloadSocket, lastMessageTime]);

  const theme = useTheme();
  const isMobileSize = isWidthDown('xs', props.width);

  /**
   * Event to send the browser to the user page
   */

  const useStyles = makeStyles(theme => ({
    cardInfoIcons: {
      maxWidth: sensorCard && '72%',
      transform: gatewayCard && 'translateY(-4px)',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        position: (gatewayCard || singleLocationCard) && 'absolute',
        right: gatewayCard ? 15 : singleLocationCard && 43,
        transform: singleAlertCard ? 'translate(85%, -86%)' : '',
        maxWidth: sensorCard && '100%',
      },
      [theme.breakpoints.down('815')]: {
        transform: singleAlertCard && 'translate(80%, -86%)',
      },
      [theme.breakpoints.down('705')]: {
        transform: singleAlertCard && 'translate(74%, -86%)',
      },
      [theme.breakpoints.down('xs')]: {
        position: (gatewayCard || singleLocationCard) && 'absolute',
        transform: singleAlertCard ? 'translate(16%, -86%)' : '',
      },
    },
    trailingItems: {
      width: '40%',
    },
    leadingContainer: {
      width: '45%',
    },
    title: {
      fontSize: 14,
      fontWeight: 700,
      color: theme.palette.type === 'light' ? '#747474' : '#FFF',
      whiteSpace: false ? 'wrap' : 'nowrap',
    },
    subTitle: {
      fontSize: 12,
      color: theme.palette.type === 'light' ? '#747474' : '#FFF',
      whiteSpace: false ? 'wrap' : 'nowrap',
    },
    message: {
      color: grey[500],
      margin: 0,
    },
    avatarContainer: {
      marginTop: sensorCard && 5,
      [theme.breakpoints.down('md')]: {
        transform: sensorCard && 'translateX(-40px)',
      },
      [theme.breakpoints.down('sm')]: {
        transform: sensorCard ? 'translate(149px,-5px)' : '',

        marginTop: sensorCard && 0,
      },
      [theme.breakpoints.down('865')]: {
        transform: sensorCard && 'translate(93px,-5px)',

        marginTop: sensorCard && 0,
      },
      [theme.breakpoints.down('720')]: {
        transform: sensorCard && 'translate(36px,-5px)',

        marginTop: sensorCard && 0,
      },
      [theme.breakpoints.down('xs')]: {
        transform: sensorCard && 'translate(0px,-5px)',

        marginTop: sensorCard && 0,
      },
    },
    avatarLarge: {
      width: 74,
      height: 'auto',
      border: '1px solid #CCC',
      [theme.breakpoints.down('xs')]: {
        width: 58,
      },
    },
    cardContentTextContainer: {
      marginLeft: gatewayCard
        ? 37
        : singleGatewayCard
        ? 14
        : sensorCard && !isMobileSize && 15,
      marginTop: gatewayCard && 5,
      height: (gatewayCard || singleLocationCard) && 'fit-content',

      [theme.breakpoints.down('md')]: {
        transform: sensorCard ? 'translateX(-64px)' : '',
      },

      [theme.breakpoints.down('sm')]: {
        display: sensorCard && 'grid',
        marginLeft: gatewayCard && 0,
      },
      [theme.breakpoints.down('xs')]: {
        transform: sensorCard && 'translateX(-40px)',
        display: sensorCard && 'grid',
        width: 180,
        whiteSpace: 'nowrap',
      },
      [theme.breakpoints.down('505')]: {
        transform: 'translateX(-5px)',
        display: 'grid',
        width: 145,
        whiteSpace: 'nowrap',
      },
    },
    statusCircle: {
      right: '0%',
      bottom: '49%',
      willChange: 'transform, opacity',
    },
    avatarImage: {
      objectFit: defaultAvatar && 'none',
      transform: defaultAvatar && gatewayCard && 'translateY(-5px)',
    },
    editImageButton: {
      '& svg': {
        fontSize: 14,
      },
    },
    tileStyles: {
      backgroundColor: backgroundColor,
      padding: theme.spacing(2),
      borderBottom: `1px solid ${grey[300]}`,
    },
    mobileStepper: {
      justifyContent: 'center',
      backgroundColor: backgroundColor,
      position: 'absolute',
      bottom: '-10px',
      right: '14%',
      transform: 'scale(0.9)',
    },
  }));
  const classes = useStyles();
  const camelizeAndFixText = text => {
    if (!text) {
      return text;
    }
    switch (text) {
      case 'light_detection':
        text = 'light';
        break;
      case 'door_open_close':
        text = 'door_open';
        break;
      default:
        text = text;
    }
    text = text.replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''));
    let realName = text.substr(0, 1).toLowerCase() + text.substr(1);
    return realName;
  };
  const divideTrailingItems = (items, isDict) => {
    const response = [];
    if (isDict) {
      let tempArray = [];
      let counter = 0;
      for (const key in items) {
        if (!showOnlyItems.includes(key)) {
          continue;
        }
        tempArray.push(items[key]);
        if (++counter % 2 === 0) {
          response.push([...tempArray]);
          tempArray = [];
        }
      }
      if (tempArray.length > 0) {
        response.push([...tempArray]);
      }
    }
    return response;
  };

  const dividedItems = divideTrailingItems(trailingItems, isItemsDict);

  const StyledBadge = withStyles(theme => ({
    anchorOriginBottomRightCircle: '', // To remove error from console
    badge: {
      width: 16,
      height: 16,
      borderRadius: '50%',
      willChange: 'transform, opacity',
      backgroundColor: connectedStatus ? '#59C754' : '#FF6A6A',
      color: connectedStatus ? '#59C754' : '#FF6A6A',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': batteryVolt &&
        batteryVolt > 2.76 && {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          borderRadius: '50%',
          animation: connectedStatus ? '$ripple 1.2s infinite ease-in-out' : '',
          border: '1px solid currentColor',
          content: '""',
        },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }))(Badge);

  const content = (
    <Grid
      container
      style={{
        position: 'relative',
      }}
    >
      <Grid
        container
        item
        xs={12}
        onClick={onClick}
        spacing={3}
        style={{ cursor: 'pointer', marginLeft: isMobileSize ? 0 : 8 }}
      >
        {showAvatar && showStatus ? (
          <Grid item xs={3} sm={gatewayCard ? 5 : 5} md={2} lg={1}>
            <DeviceAvatar
              avatar={avatar}
              sensorCard={sensorCard}
              showStatus={showStatus}
              batteryVolt={batteryVolt}
              gatewayCard={gatewayCard}
              defaultAvatar={defaultAvatar}
              lastMessageTime={lastMessageTime}
            />
            {!singleGatewayCard && !sensorCard && (
              <Grid
                item
                xs={12}
                display='flex'
                style={{
                  marginTop: -10,
                  backgroundColor: grey,
                  textAlign: 'center',
                }}
              >
                <IconButton
                  style={{ color: '#ffffff', backgroundColor: grey[400] }}
                  size='small'
                  className={classes.editImageButton}
                  onClick={handleEditImage}
                >
                  <Edit></Edit>
                </IconButton>
              </Grid>
            )}
          </Grid>
        ) : showAvatar ? (
          <Grid item xs={4} sm={gatewayCard ? 12 : 5} md={2} lg={1}>
            <DeviceAvatar
              avatar={avatar}
              sensorCard={sensorCard}
              showStatus={showStatus}
              batteryVolt={batteryVolt}
              gatewayCard={gatewayCard}
              defaultAvatar={defaultAvatar}
              lastMessageTime={lastMessageTime}
            />
          </Grid>
        ) : null}
        {/* name and sensors container */}
        <Grid
          item
          container
          xs={7}
          sm={gatewayCard || singleLocationCard ? 12 : 7}
          md={showAvatar ? 10 : 12}
          lg={showAvatar ? 11 : 12}
          style={{
            flexWrap: (gatewayCard || singleLocationCard) && 'nowrap',
            paddingLeft: '15px',
          }}
        >
          <Grid
            item
            container
            direction='column'
            justify='center'
            xs={gatewayCard || singleLocationCard ? 12 : 12}
            sm={gatewayCard || singleLocationCard ? 12 : 12}
            md={resize ? 9 : 3}
            className={classes.cardContentTextContainer}
          >
            <Box component='div' textOverflow='ellipsis' overflow='hidden'>
              <Box
                component='div'
                textOverflow='ellipsis'
                overflow='hidden'
                className={classes.title}
              >
                {name}
              </Box>
              <Box
                component='div'
                textOverflow='ellipsis'
                overflow='hidden'
                className={classes.subTitle}
              >
                {subtitle}
              </Box>
              <Box
                component='div'
                textOverflow='ellipsis'
                overflow='hidden'
                className={classes.subTitle}
              >
                {subtitle2}
              </Box>
              <Box
                component='div'
                textOverflow='ellipsis'
                overflow='hidden'
                className={classes.subTitle}
              >
                {subtitle3}
              </Box>
            </Box>
          </Grid>
          {isMobileSize ? null : (
            <Grid
              item
              container
              xs={gatewayCard || singleLocationCard ? 5 : 12}
              sm={gatewayCard || singleLocationCard ? 5 : 12}
              md={resize ? 3 : 9}
              direction='row'
              className={classes.cardInfoIcons}
            >
              <CardInfoIcons
                singleLocationCard={singleLocationCard}
                singleAlertCard={singleAlertCard}
                singleGatewayCard={singleGatewayCard}
                sensorCard={sensorCard}
                gatewayCard={gatewayCard}
                wrapItems={wrap}
                displayDivider={displayDivider}
                isDict={isItemsDict}
                items={trailingItems}
                showOnlyItems={showOnlyItems}
                buttons={buttons}
                enableZero={enableZero}
              />
            </Grid>
          )}
        </Grid>
        {isMobileSize && dividedItems ? (
          <Grid
            item
            xs={5}
            style={{
              height: '110px',
              paddingRight: 0,
              paddingLeft: 0,
            }}
          >
            <AutoPlaySwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={mobileStepperStep}
              onChangeIndex={mobileStepperChange}
              enableMouseEvents
            >
              {dividedItems.map((item, index) => (
                <div key={index}>
                  <CardInfoIconsMobile
                    singleLocationCard={singleLocationCard}
                    singleAlertCard={false}
                    singleGatewayCard={false}
                    sensorCard={sensorCard}
                    textFontSize='12px'
                    gatewayCard={gatewayCard}
                    wrapItems={wrap}
                    displayDivider={'block'}
                    isDict={false}
                    items={item}
                    showOnlyItems={showOnlyItems}
                    buttons={buttons}
                    enableZero={enableZero}
                    deviceCard={true}
                  />
                </div>
              ))}
            </AutoPlaySwipeableViews>

            {dividedItems.length > 1 ? (
              <MobileStepper
                variant='dots'
                steps={dividedItems.length}
                position='static'
                activeStep={mobileStepperStep}
                className={classes.mobileStepper}
              />
            ) : (
              <></>
            )}
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );

  return (
    <Fragment>
      {isTileLayout ? (
        <Box className={classes.tileStyles}>{content}</Box>
      ) : (
        <GenericListCard sensorCard={sensorCard}>{content}</GenericListCard>
      )}
    </Fragment>
  );
}

const useStyles = makeStyles(
  (
    theme,
    sensorCard,
    gatewayCard,
    singleAlertCard,
    singleGatewayCard,
    singleLocationCard,
  ) => ({
    cardInfoIcons: {
      maxWidth: sensorCard && '72%',
      transform: gatewayCard && 'translateY(-4px)',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        position: (gatewayCard || singleLocationCard) && 'absolute',
        right: gatewayCard ? 15 : singleLocationCard && 43,
        transform: singleAlertCard ? 'translate(85%, -86%)' : '',
        maxWidth: sensorCard && '100%',
      },
      [theme.breakpoints.down('815')]: {
        transform: singleAlertCard && 'translate(80%, -86%)',
      },
      [theme.breakpoints.down('705')]: {
        transform: singleAlertCard && 'translate(74%, -86%)',
      },
      [theme.breakpoints.down('xs')]: {
        position: (gatewayCard || singleLocationCard) && 'absolute',
        transform: singleAlertCard ? 'translate(16%, -86%)' : '',
      },
    },
    title: {
      fontSize: 14,
      fontWeight: 700,
      color: theme.palette.type === 'light' ? '#747474' : '#FFF',
      whiteSpace: 'wrap',
    },
    subTitle: {
      fontSize: 12,
      color: theme.palette.type === 'light' ? '#747474' : '#FFF',
      whiteSpace: 'wrap',
    },
    cardContentTextContainer: {
      marginLeft: gatewayCard ? 37 : singleGatewayCard ? 14 : sensorCard && 15,
      marginTop: gatewayCard && 5,
      height: (gatewayCard || singleLocationCard) && 'fit-content',

      [theme.breakpoints.down('md')]: {
        transform: sensorCard ? 'translateX(-64px)' : '',
      },

      [theme.breakpoints.down('sm')]: {
        display: sensorCard && 'grid',
        marginLeft: gatewayCard && 0,
      },
      [theme.breakpoints.down('xs')]: {
        transform: sensorCard && 'translateX(-40px)',
        display: sensorCard && 'grid',
        width: 180,
        whiteSpace: 'nowrap',
      },
      [theme.breakpoints.down('380')]: {
        transform: 'translateX(-5px)',
        display: 'grid',
        width: 145,
        whiteSpace: 'nowrap',
      },
    },
    editImageButton: {
      '& svg': {
        fontSize: 14,
      },
    },
  }),
);
