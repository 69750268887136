import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../AppContext';
import { Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { A } from 'hookrouter';
import GatewayIcon from '@material-ui/icons/Router';
import SensorIcon from '../../img/icons/SensorIcon';
import CustomersIcon from '../../img/icons/CustomersIcon';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import HeaderBar from '../Generic/HeaderBar';
import SingleResellerCard from './SingleResellerCard';
import LoadingSpinner from '../Generic/LoadingSpinner';
import AdminDashboardIcon from '../../img/icons/AdminDashboardIcon';
import CodeIcon from '../../img/icons/CodeIcon.js';
import LabelIcon from '../../img/icons/LabelIcon.js';
import CardActions from '../Generic/CardActions';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LabelGeneratorForm from './LabelGeneratorForm';
import IconButton from '../Generic/IconButton';
import EditResellerFormModal from './EditResellerFormModal';

const useStyles = makeStyles(theme => ({
  root: {},
  tableTitle: {
    marginLeft: '1rem',
    fontWeight: 500,
    fontSize: '1.5rem',
  },
  breadcrumbs: {
    color: theme.palette.text.primary,
  },
}));

const SingleReseller = props => {
  const log = window.log('SingleReseller');
  const classes = useStyles();
  const theme = useTheme();
  const smallDevice = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    appState,
    snack,
    setLogoutState,
    setOpenEditResellerModal,
  } = useContext(AppContext);

  const { id } = props;

  const [reseller, setReseller] = useState(null);

  const [labelGeneratorOpenState, setLabelGeneratorOpenState] = useState(false);
  const [initialPageLoad, setInitialPageLoad] = useState(true);

  let breadcrumbs = (
    <div style={{ display: 'inline-flex' }}>
      <A className={classes.breadcrumbs} href='/admin/reseller/all'>
        Reseller
      </A>
      <div>{'\xa0-\xa0'}</div>
    </div>
  );

  const getResellers = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/get-reseller?id=${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
        },
      );

      const json = await response.json();

      if (json.success) {
        if (json.data && json.data.resellers.length) {
          const reseller = json.data.resellers[0];
          setReseller(reseller);
        } else {
          snack('Unable to find the account. Please contact support.');
          json.errors.forEach(err => {
            if (err.type === 'token') {
              snack(err.msg, 'error');
              setLogoutState(true);
            }
          });
        }
      } else {
        log(json.errors);
        if (json.errors.length) {
          snack(json.errors[0].msg, 'error');
        }
      }
    } catch (err) {
      log('---', err);
      snack('Something went wrong. Please contact support.', 'error');
    }
    setInitialPageLoad(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    getResellers();
  }, []);

  const openLabelGeneratorOpenState = () => {
    setLabelGeneratorOpenState(true);
  };

  const closeLabelGeneratorOpenState = () => {
    setLabelGeneratorOpenState(false);
  };

  const handleOpenEditResellerModal = () => {
    setOpenEditResellerModal(true);
  };

  const formatActivationCode = code => {
    if (code.length === 9) {
      return `${code.slice(0, 3)}-${code.slice(3, 6)}-${code.slice(6, 9)}`;
    }
    return code;
  };

  const resendSignupLink = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/resend?id=${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: appState.auth.token,
        },
      });

      const json = await response.json();
      if ( json.success ) {
        snack('Successfully sent a reseller invitation email', 'success')
      } else {
        snack('Something went wrong sending reseller invitaiton email.', 'error')
      }
    } catch (err) {
      snack('Network Error')
    }
  }

  return initialPageLoad ? (
    <LoadingSpinner />
  ) : (
    <div className={classes.root}>
      <LabelGeneratorForm
        openState={labelGeneratorOpenState}
        handleClose={closeLabelGeneratorOpenState}
      />
      <EditResellerFormModal info={reseller} refreshInfo={getResellers} />
      {/* Generic Search  Bar */}
      <HeaderBar
        leadingIcon={
          <AdminDashboardIcon style={{ fontSize: 32 }} color='primary' />
        }
        breadcrumbs={breadcrumbs}
        title={reseller && reseller.company_name ? reseller.company_name : ''}
        addButton={false}
        searchInput={false}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SingleResellerCard
            singleLocationCard={true}
            enableZero={true}
            showAvatar={false}
            name={
              reseller && reseller.company_name
                ? reseller.company_name
                : 'No Reseller Name'
            }
            subtitle='asd'
            subtitle2={reseller && reseller.address1 ? reseller.address1 : ''}
            subtitle3={
              reseller && (reseller.city || reseller.state || reseller.zip)
                ? `${reseller.city}, ${reseller.state} ${reseller.zip}`
                : ''
            }
            contactName={
              reseller && reseller.first_name && reseller.last_name
                ? `${reseller.first_name} ${reseller.last_name}`
                : 'No Contact Name'
            }
            contactEmail={reseller && reseller.email ? reseller.email : ''}
            contactPhone={reseller && reseller.phone ? reseller.phone : ''}
            buttons={
              appState.auth.userInfo.perm === 99 && (
                <CardActions
                  smallDevice={smallDevice}
                  editEvent={handleOpenEditResellerModal}
                  disableDelete={true}
                />
              )
            }
            trailingItems={[
              {
                value:
                  reseller && reseller.totalCompanyCount
                    ? reseller.totalCompanyCount
                    : 0,
                title: 'Customer',
                icon: (
                  <CustomersIcon style={{ fontSize: 24, color: '#FF6A6A' }} />
                ),
              },
              {
                value:
                  reseller && reseller.totalGatewayCount
                    ? reseller.totalGatewayCount
                    : 0,
                title: 'Gateway',
                icon: (
                  <GatewayIcon style={{ fontSize: 24, color: '#59C754' }} />
                ),
              },

              {
                value:
                  reseller && reseller.totalSensorCount
                    ? reseller.totalSensorCount
                    : 0,
                title: 'Sensor',
                icon: (
                  <SensorIcon
                    style={{
                      fontSize: 24,
                      color: theme.palette.primary.main,
                    }}
                  />
                ),
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <IconButton
            icon={
              <CodeIcon
                style={{ fontSize: 40 }}
                filledcolor='#FFFFFF'
                outlinecolor='#9D9D9D'
              />
            }
            title={reseller.activation_code || ''}
            subtitle='This is your unique reseller activation code'
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <IconButton
            icon={<LabelIcon style={{ fontSize: 40 }} color='primary' />}
            title='Label Generator'
            subtitle='Print Labels'
            onClick={openLabelGeneratorOpenState}
          />
        </Grid>
        {reseller?.account_created === 0 ?
          <Grid item xs={12} sm={6}>
            <IconButton
              disabled={true}
              icon={<AccountCircleIcon style={{ fontSize: 40 }} color='primary' />}
              title='Resend Signup'
              subtitle='This will resend sign up link for the reseller portal'
              onClick={resendSignupLink}
            />
          </Grid>
          : null
        }
      </Grid>
    </div>
  );
};

export default SingleReseller;
