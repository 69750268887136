import _ from 'lodash';
import React, { useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function LocFormStates(props) {
  const log = window.log('LocationForm');
  const { setState, state, stateError, classes } = props;

  const [states, setStates] = useState([]);

  const handleStateSelect = (e, v) => {
    // determineOptions(v);
    if (v) setState(_.toUpper(v.name));
  };

  const handleSetStateChange = e => {
    if (e) setState(_.toUpper(e.currentTarget.value));
    determineOptions(e);
  };

  const determineOptions = async e => {
    const stateArr = [];

    if (e && e !== null) {
      try {
        // setCity(e.currentTarget.value);
        const response = await fetch(
          `https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json?apiKey=${process.env.REACT_APP_HERE_API_KEY}&query=${e.currentTarget.value}`,
        );
        const data = await response.json();

        if (data.suggestions) {
          data.suggestions.forEach(item => {
            if (item.matchLevel === 'state') {
              stateArr.push({
                name: item.address.state,
              });
            }
          });
        }
      } catch (error) {
        log(error);
      }
    }

    setStates(stateArr);
  };

  return (
    <Grid item xs={12}>
      <Autocomplete
        id='state'
        options={states}
        groupBy={option => option.firstLetter}
        getOptionLabel={option => `${option.name}`}
        getOptionSelected={option => option.abbr === state}
        inputValue={state}
        onChange={(e, v) => handleStateSelect(e, v)}
        onInputChange={(e, v) => handleSetStateChange(e, v)}
        renderInput={params => (
          <TextField
            {...params}
            className={classes.textField}
            autoComplete='state'
            label='State'
            name='state'
            variant='outlined'
            required
            style={{
              width: '105%',
            }}
            error={stateError}
            inputProps={{ ...params.inputProps }}
          />
        )}
      />
    </Grid>
  );
}
