import React from "react";
import { SvgIcon } from "@material-ui/core";

const LabelIcon = (props) => {
  const { filledcolor } = props;
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/SVGIcon"
      width="44"
      height="36"
      viewBox="0 0 44 36"
      {...props}
    >
      <path
        fill={filledcolor}
        d="M382.657,539.493h-36a4.012,4.012,0,0,0-4,4v28a4.013,4.013,0,0,0,4,4h36a4.012,4.012,0,0,0,4-4v-28A4.011,4.011,0,0,0,382.657,539.493Zm0,26h-36v-22h36Z"
        transform="translate(-342.657 -539.493)"
      />
      <g transform="translate(-342.657 -539.493)">
        <path
          fill={filledcolor}
          d="M355.06,550.5h-2.446v-1.659c1.978,0,2.68-.426,3.212-1.872H357.4v14.889h-2.34Z"
        />
        <path
          fill={filledcolor}
          d="M360.251,560.24c0-2.128.6-3.425,2.34-5.254,1.786-1.893,2.3-3.02,2.3-4.4,0-1.34-.468-1.7-1.212-1.7s-1.213.4-1.213,1.468v1.595h-2.212V550.5c0-2.383,1.191-3.744,3.488-3.744s3.489,1.361,3.489,3.744c0,1.766-.724,3.339-2.893,5.637-1.4,1.489-1.766,2.233-1.766,3.275v.319h4.446v2.127h-6.764Z"
        />
        <path
          fill={filledcolor}
          d="M368.632,558.368v-1.213h2.212v1.32c0,1.063.468,1.467,1.213,1.467s1.212-.362,1.212-1.68v-1.213c0-1.382-.468-1.893-1.531-1.893h-.787v-2.127h.85c.872,0,1.4-.383,1.4-1.574V550.37c0-1.063-.489-1.489-1.169-1.489s-1.128.4-1.128,1.383v.978H368.7v-.787c0-2.382,1.191-3.7,3.425-3.7s3.424,1.3,3.424,3.68v.532c0,1.6-.51,2.574-1.659,3v.043c1.255.468,1.723,1.553,1.723,3.062v1.32c0,2.381-1.191,3.679-3.488,3.679S368.632,560.75,368.632,558.368Z"
        />
      </g>
    </SvgIcon>)
};

export default LabelIcon;
