import _ from 'lodash';
import React from 'react';

import { SettingsCheckBoxes } from './SettingsCheckBoxes';

export function ThemeTab(props) {
  const {
    darkMode,
    primaryColor,
    handleDarkModeChange,
    handlePrimaryColorChange,
  } = props;

  const darkModeBoxes = [
    { name: 'light', check: false, label: 'Light' },
    { name: 'dark', check: true, label: 'Dark' },
  ];

  return (
    <>
      <SettingsCheckBoxes
        currentValue={darkMode}
        setCurrentValue={handleDarkModeChange}
        checkBoxData={darkModeBoxes}
        label='Color Mode'
      />
      <SettingsCheckBoxes
        currentValue={primaryColor}
        setCurrentValue={handlePrimaryColorChange}
        label='Accent Color'
        colorPicker={true}
      />
    </>
  );
}
