import grey from '@material-ui/core/colors/grey';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useState, useContext, useEffect } from 'react';
import { Card, Grid, Dialog, Typography, CardContent } from '@material-ui/core';
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';

import { AppContext } from '../../AppContext';
import { SelectPlan } from './ChangeSubscriptionComponents/SelectPlan';
import { OrderSummary } from './ChangeSubscriptionComponents/OrderSummary';
import { ActionButtons } from './ChangeSubscriptionComponents/ActionButtons';
import { CenteredHeading } from './ChangeSubscriptionComponents/CenteredHeading';
import { ChangeSensorPlans } from './ChangeSubscriptionComponents/ChangeSensorPlans';

/**
 * returns the typography color based in the
 * theme dark mode and the tone given for the colors
 * @param {Object, int, int} theme, lightTone=700, darkTone=300
 */
const textColor = ({ theme, lightTone = 700, darkTone = 300 }) => {
  if (theme.palette.type === 'light') {
    return grey[lightTone];
  } else {
    return grey[darkTone];
  }
};

const useStyles = makeStyles(theme => ({
  formContainer: {
    height: '100%',
    overflow: 'scroll',
    backgroundColor:
      theme.palette.type === 'light'
        ? '#F4F5F5'
        : theme.palette.background.default,
  },
  titleContainer: {
    backgroundColor: theme.palette.background.paper,
  },
  titleText: {
    fontWeight: 'bold',
    color: theme.palette.type === 'light' ? '#747474' : '#FFF',
  },
  cardContainer: {
    backgroundColor:
      theme.palette.type === 'light'
        ? '#F4F5F5'
        : theme.palette.background.default,
    overflowX: 'hidden',
    overflowY: 'auto',
    height: '100%',
    width: '770px',
  },
}));

/**
 * Account Settings Form Modal
 * @param {Object} props
 * @props
 * openState: Boolean
 * handleClose: Function
 * handleSave: Function
 */
const ChangeSubscription = props => {
  const log = window.log('ChangeSubscription');

  const { setLogoutState, snack } = useContext(AppContext);
  const { token, reload, openState, handleClose, currentSubscription } = props;

  const theme = useTheme();
  const classes = useStyles();

  const [selectedPlan, setSelectedPlan] = useState({});
  const [submitState, setSubmitState] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [listOfPlans, setListOfPlans] = useState([]);
  const [selSubLevel, setSelSubLevel] = useState('BASIC');
  const [subLevels, setSubLevels] = useState({
    BASIC: { name: 'basic' },
    PRO: { name: 'pro' },
    PREMIUM: { name: 'premium' },
  });
  const [isMobileSize, setIsMobileSize] = useState(false)

    useEffect(()=>{
      if(isWidthDown('xs', props.width)){
        setIsMobileSize(true)
        return;
      } else {
        setIsMobileSize(false)
      }

    },[props.width])

  useEffect(() => {
    getSubLevels();
    if (currentSubscription?.planInfo?.id) {
      const planIdArr = currentSubscription.planInfo.id.split('-');
      if (planIdArr[1]) setSelSubLevel(planIdArr[1]);
      else setSelSubLevel('BASIC');
      setSelectedPlan(currentSubscription.planInfo);
    }
  }, []);

  async function getSubLevels() {
    try {
      const subscriptionLevels = await fetch(
        process.env.REACT_APP_API_URL + '/payment/allSubPlans',
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
        },
      );

      const json = await subscriptionLevels.json();
      if (json.success) {
        setListOfPlans(json.data.listOfPlans);
        setSubLevels(json.data.subTiers);
      }
    } catch (err) {
      log(err);
      snack('Network Error', 'error');
    }
  }

  const handleSubmit = async () => {
    if (currentSubscription.numOfSensors > selectedPlan.sensors) {
      snack(
        'Please delete your sensors in order to downgrade from your current plan',
        'error',
      );
      return;
    }

    try {
      setSubmitState(true);
      //Submit subscription update request
      const newPlanId = `${selectedPlan.id.split('-')[0]}${
        selSubLevel !== 'BASIC' ? `-${selSubLevel}` : ''
      }`;
      const updateSubscriptionResponse = await fetch(
        process.env.REACT_APP_API_URL + '/payment/upgrade-plan',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
          body: JSON.stringify({
            newPlanId,
          }),
        },
      );

      const json = await updateSubscriptionResponse.json();
      if (json.success) {
        snack('Successfully updated your subscription', 'success');
        reload();
        handleClose();
      } else {
        snack(json.errors[0], 'error');
        json.errors.forEach(err => {
          if (err.type === 'token') {
            snack(err.msg, 'error');
            setLogoutState(true);
          }
        });
      }
    } catch (error) {
      log(error);
      snack('Network Error', 'error');
    }
    setSubmitState(false);
  };

  return (
    <Dialog
      maxWidth={'md'}
      fullScreen={fullScreen}
      open={openState}
      aria-labelledby='responsive-dialog-title'
      onClose={handleClose}
    >
      <Card className={isMobileSize ? {width: '100vw', height: '100vh'} : classes.cardContainer}>
        <CardContent className={classes.formContainer}>
          <Grid container spacing={4}>
            <Grid item xs={12} className={classes.titleContainer}>
              <Typography className={classes.titleText} align='center'>
                SUBSCRIPTION OVERVIEW
              </Typography>
            </Grid>

            <Grid
              item
              container
              xs={12}
              style={
                fullScreen
                  ? {}
                  : {
                      paddingLeft: theme.spacing(11),
                      paddingRight: theme.spacing(11),
                    }
              }
              className={classes.formContainer}
            >
              <CenteredHeading
                text='SELECT YOUR PLAN'
                textStyle={classes.titleText}
                marginTop='10px'
              />
              <SelectPlan
                isMobile={isMobileSize}
                subLevels={subLevels}
                selSubLevel={selSubLevel}
                selectedPlan={selectedPlan}
                setSelSubLevel={setSelSubLevel}
                currentSubscription={currentSubscription}
              />
              <CenteredHeading
                text='NUMBER OF SENSORS'
                textStyle={classes.titleText}
              />
              <ChangeSensorPlans
                subLevels={subLevels}
                listOfPlans={listOfPlans}
                selSubLevel={selSubLevel}
                selectedPlan={selectedPlan}
                setSelectedPlan={setSelectedPlan}
                currentSubscription={currentSubscription}
                isMobile={isMobileSize}
              />
              <CenteredHeading
                text='ORDER SUMMARY'
                textStyle={classes.titleText}
              />
              <OrderSummary
                textColor={textColor}
                selSubLevel={selSubLevel}
                selectedPlan={selectedPlan}
                currentSubscription={currentSubscription}
              />
            </Grid>
          </Grid>
          <ActionButtons
            handleClose={handleClose}
            submitState={submitState}
            selSubLevel={selSubLevel}
            handleSubmit={handleSubmit}
            selectedPlan={selectedPlan}
            currentSubscription={currentSubscription}
          />
        </CardContent>
      </Card>
    </Dialog>
  );
};

export default withWidth()(ChangeSubscription);
