import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import SelectSensor from '../AlertModalComponents/SelectSensor';

export function SensorAlertSettings(props) {
  const { alertInfo, allSensors, setValueChanged } = props;

  const [selectedSensorIndexArr, setSelectedSensorIndexArr] = useState([]); // Used by the data table to preselect sensors already set for alert

  useEffect(() => {
    if (!_.isEmpty(allSensors)) {
      const indexArr = [];
      const idArr = [];
      _.forEach(alertInfo.sensorsAssignedToAlert, sensor => {
        indexArr.push(_.findIndex(allSensors, ['sensorId', sensor.sensorId]));
        idArr.push(sensor.sensorId);
      });
      setSelectedSensorIndexArr(indexArr);
      setValueChanged(idArr);
    }
  }, [allSensors]);

  const mapRowsSelected = allRowsSelected => {
    const idArr = [];
    const selectedArr = allRowsSelected.map(row => {
      idArr.push(allSensors[row.dataIndex].sensorId);
      return row.dataIndex;
    });
    setSelectedSensorIndexArr(selectedArr);
    setValueChanged(idArr);
  };

  const selectSensorTableOptions = {
    selectableRows: 'multiple',
    rowsSelected: selectedSensorIndexArr,
    textLabels: {
      selectedRows: {
        text: 'Sensor(s) Selected',
      },
      body: {
        noMatch: 'No matching sensors',
      },
    },
    onRowSelectionChange: (curr, all) => mapRowsSelected(all),
  };

  return (
    <SelectSensor
      header='Select Sensors to apply alert profile'
      desc='Select one or more of your sensors to apply this alert profile to.'
      tableTitle='SELECT SENSORS'
      data={allSensors}
      options={{
        ...selectSensorTableOptions,
      }}
    />
  );
}
