import _ from 'lodash';
import { navigate } from 'hookrouter';
import { Grid, Typography } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import ImageIcon from '@material-ui/icons/Image';
import { makeStyles } from '@material-ui/core/styles';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import React, { useState, useEffect, useContext, Fragment } from 'react';
import AccountBoxIcon from '@material-ui/icons/AccountBox';

// Generic Imports
import { EventBus } from '../common/EventBus';
import BreadCrumb from '../Generic/BreadCrumb';
import IconButton from '../Generic/IconButton';
import { AppContext } from './../../AppContext';
import { permissions } from '../../permissions';
import uploadImage from '../common/api/uploadImage';

// Users import
import fetchOne from '../common/api/fetchOne';
import SingleUserCard from './SingleUserCard';
import EditPaymentDialog from './EditPaymentDialog';
import ChangeCompanyLogos from './ChangeCompanyLogos';
import EditPasswordDialog from './EditPasswordDialog';
import EditUserImageDialog from './EditUserImageDialog';
import EditCurrentUserDialog from './EditCurrentUserDialog';
import EditUserProfileDialog from './EditUserProfileDialog';

import { MobileTabsContainer } from '../Generic/Mobile/MobileTabs';
import MobileBottomButton, {
  MobileBottomButtonSpacer,
} from '../Generic/Mobile/MobileBottomButton';

import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import HeaderMobile from '../Gateways/HeaderMobile';

// Icon import
import SettingsIcon from '@material-ui/icons/Settings';

const useStyles = makeStyles(theme => ({
  bar: {
    margin: theme.spacing(1, 1, 3, 1),
  },
  root: {
    // width: "100%",
  },
  tableTitle: {
    marginLeft: '1rem',
    fontWeight: 500,
    fontSize: '1.5rem',
  },
  mobileSubtitle: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    fontSize: 13,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  settingsCards: {
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1),
    },
  },
}));

const AccountSettings = props => {
  const log = window.log('AccountSettings');

  /**
   * Mobile view variables
   */
  const isMobileSize = isWidthDown('xs', props.width);

  /**
   * End of mobile view variables
   */

  const {
    platformImageLocation,
    setPlatformImageLocation,
    faviconImageLocation,
    setFaviconImageLocation,
  } = props;
  const classes = useStyles();
  const {
    snack,
    appState,
    setAppState,
    setLogoutState,
    setOpenUserModal,
    updateUserProfile,
    setOpenAddingModal,
  } = useContext(AppContext);
  const [userInfo, setUserInfo] = useState({});
  const [companyInfo, setCompanyInfo] = useState({});
  const [networkErr, setNetworkErr] = useState(false);

  useEffect(() => {
    setUserInfo(appState.auth.userInfo);
    if (
      appState.auth.userInfo.profile &&
      appState.auth.userInfo.profile.profileImageLocation
    ) {
      setProfileImageLocation(
        appState.auth.userInfo.profile.profileImageLocation,
      );
    }
    if (appState.auth.userInfo.themePreferences?.platformImageLocation) {
      setPlatformImageLocation(
        appState.auth.userInfo.themePreferences.platformImageLocation,
      );
    }
    if (appState.auth.userInfo.themePreferences?.faviconImageLocation) {
      setFaviconImageLocation(
        appState.auth.userInfo.themePreferences.faviconImageLocation,
      );
    }
  }, [appState.auth.userInfo]);

  const [profileModalState, setProfileModalState] = useState(false);
  const [profileImageLocation, setProfileImageLocation] = useState('');
  // const [platformImageLocation, setPlatformImageLocation] = useState("");
  // const [faviconImageLocation, setFaviconImageLocation] = useState("");
  const [imgErr, setImgErr] = useState(false);
  const [file, setFile] = useState();
  const [imgFile, setImgFile] = useState();

  const handleRemoveProfileImageLocation = () => {
    setFile('');
    setImgFile('');
    setProfileImageLocation('');
  };

  const profileOpenModal = () => {
    setProfileModalState(true);
  };

  const profileCloseModal = () => {
    setProfileModalState(false);
  };

  const profileSaveModal = () => {
    setProfileModalState(false);
  };

  const [passwordModalState, setpasswordModalState] = useState(false);

  const passwordOpenModal = () => {
    setpasswordModalState(true);
  };

  const passwordCloseModal = () => {
    setpasswordModalState(false);
  };

  const [userImageModalState, setUserImageModalState] = useState(false);

  const userImageOpenModal = () => {
    setUserImageModalState(true);
  };

  const userImageCloseModal = () => {
    setUserImageModalState(false);
  };

  const userImageSaveModal = () => {
    setUserImageModalState(false);
  };

  const [userLogosModalState, setUserLogosModalState] = useState(false);

  const userLogosOpenModal = () => {
    setUserLogosModalState(true);
  };

  const userLogosCloseModal = () => {
    setUserLogosModalState(false);
  };

  const userLogosSaveModal = () => {
    setUserLogosModalState(false);
  };

  const [userPaymentModalState, setUserPaymentModalState] = useState(false);

  const userPaymentOpenModal = () => {
    setUserPaymentModalState(true);
  };

  const userPaymentCloseModal = () => {
    setUserPaymentModalState(false);
  };

  const userPaymentSaveModal = () => {
    setUserPaymentModalState(false);
  };

  const [state, setState] = useState([]);

  const navigateToBillingPlan = () => {
    navigate('/current/user/billing');
  };

  const openAddModal = () => {
    setOpenAddingModal(true);
  };
  const renderCompanyButton = () => {
    const userPerm = userInfo.perm;
    if (permissions[userPerm]?.profile.addCompanyLogo) {
      return (
        <Grid item xs={12} md={6} className={classes.settingsCards}>
          <IconButton
            icon={<ImageIcon style={{ fontSize: 40 }} color='primary' />}
            title='Company Logo'
            subtitle='Custom Main Logo and Logo Icon'
            onClick={userLogosOpenModal}
          />
        </Grid>
      );
    }

    return null;
  };

  const renderPaymentButton = () => {
    const userPerm = userInfo.perm;

    if (
      permissions[userPerm]?.profile?.addPaymentButton &&
      !appState.auth.isDirect
    ) {
      return (
        <Grid
          item
          xs={12}
          md={6}
          className={classes.settingsCards}
          style={{
            paddingBottom: '40px',
          }}
        >
          <IconButton
            icon={<CreditCardIcon style={{ fontSize: 40 }} color='primary' />}
            title='Payment Methods'
            subtitle='Credit card information'
            onClick={userPaymentOpenModal}
          />
        </Grid>
      );
    }

    return null;
  };

  const renderBillingButton = () => {
    const userPerm = userInfo.perm;
    if (
      permissions[userPerm]?.profile?.addBillingButton &&
      !appState.auth.isDirect
    ) {
      return (
        <Grid item xs={12} md={6} className={classes.settingsCards}>
          <IconButton
            icon={<LocalAtmIcon style={{ fontSize: 40 }} color='primary' />}
            title='Billing'
            subtitle='Subscriptions, billing dates, and recurring payments'
            onClick={navigateToBillingPlan}
          />
        </Grid>
      );
    }

    return null;
  };

  const getUser = async () => {
    try {
      const json = await fetchOne(
        'user',
        appState.auth.userInfo.id,
        appState.auth.userInfo.companyId,
        appState.auth.token,
      );
      log('JSON', json);
      if (json.success) {
        setState(json.data.users[0]);
        if (json.data.users[0].profile?.profileImageLocation) {
          setProfileImageLocation(
            json.data.users[0].profile.profileImageLocation,
          );
        }
      } else {
        setState([]);
        json.errors.forEach(err => {
          snack(err.msg, 'error');
          if (err.type === 'token') setLogoutState(true);
        });
      }
    } catch (error) {
      snack('Network Error', 'error');
      setNetworkErr(true);
    }
  };

  const getCompany = async () => {
    try {
      const request = await fetch(
        `${process.env.REACT_APP_API_URL}/company/get`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
          // body: JSON.stringify({ company, entityId }),
        },
      );

      const json = await request.json();

      if (json.success) {
        setCompanyInfo(json.data.company[0]);
      } else {
        json.errors.forEach(err => {
          snack(err.msg, 'error');
          if (err.type === 'token') setLogoutState(true);
        });
      }
    } catch (err) {
      snack('Network Error', 'error');
    }
  };

  useEffect(() => {
    getUser();
    getCompany();
    EventBus.on('AccountSettingsSubUserModalCallBacks', data => {
      getUser();
      const appStateClone = _.cloneDeep(appState);
      appStateClone.auth.userInfo = _.merge(
        appStateClone.auth.userInfo,
        data.userInfo,
      );
      setAppState(appStateClone);
    });

    return () => {
      EventBus.remove('AccountSettingsSubUserModalCallBacks');
    };
  }, [appState]);

  //After DCS Admin switches account, fetch that account's data
  useEffect(() => {
    if (!appState.isSwitched) return;
    getUser();
    getCompany();
    setAppState({ ...appState, isSwitched: false });
  }, [appState.isSwitched]);

  const handleOpenEditUserModal = async () => {
    EventBus.dispatch('editSubUserModalProps', {
      id: state.id,
      email: state.email,
      fname: state.fname,
      lname: state.lname,
      phoneNo: state.phoneNumber,
      perm: state.perm,
      permChanged: state.permChanged,
      updatePage: 'AccountSettings',
    });
    setOpenUserModal(true, true);
  };

  const singleFileUploadHandler = async e => {
    try {
      const renameText = `userImage-${appState.auth.userInfo.id}-${Date.now()}`;
      const data = new FormData();

      const folderDestination = 'Profile Image';
      if (file) {
        const response = await uploadImage(
          file,
          data,
          renameText,
          appState.auth.token,
          folderDestination,
        );
        if (response.error) {
          if ('LIMIT_FILE_SIZE' === response.error.code) {
            snack('Max size: 2MB', 'error');
          } else {
            // If not the given file type
            snack(response.error, 'error');
          }
        } else {
          // Success
          setProfileImageLocation(response.location);
          snack('Image successfully uploaded', 'success');
          handleSaveProfileImage(response.location);
        }
      } else if (imgFile) {
        const response = await uploadImage(
          imgFile,
          data,
          renameText,
          appState.auth.token,
          folderDestination,
        );
        if (response.error) {
          if ('LIMIT_FILE_SIZE' === response.error.code) {
            snack('Max size: 2MB', 'error');
          } else {
            // If not the given file type
            snack(response.error, 'error');
          }
        } else {
          // Success
          setProfileImageLocation(response.location);
          handleSaveProfileImage(response.location);
        }
      } else if (profileImageLocation) {
        //PASS
      } else {
        handleSaveProfileImage('');

        snack('Image Removed', 'success');
      }
    } catch (error) {
      log(error);
    }
  };

  // Save Profile Image
  const handleSaveProfileImage = location => {
    updateUserProfile('profileImageLocation', location);
  };

  // test
  const platformImageUploadHandler = async e => {
    try {
      const renameText = `platformImage-${
        appState.auth.userInfo.companyId
      }-${Date.now()}`;
      const data = new FormData();
      const file = await e.target.files[0];
      const destinationFolder = 'Platform Image';
      if (file) {
        const response = await uploadImage(
          file,
          data,
          renameText,
          appState.auth.token,
          destinationFolder,
        );
        if (response.error) {
          if ('LIMIT_FILE_SIZE' === response.error.code) {
            snack('Max size: 2MB', 'error');
          } else {
            // If not the given file type
            log(response);
            snack(response.error, 'error');
          }
        } else {
          // Success
          setPlatformImageLocation(response.location);
          snack('Image successfully uploaded', 'success');
        }
      } else {
        snack('Please upload file', 'error');
      }
    } catch (error) {
      log(error);
    }
  };

  const faviconImageUploadHandler = async e => {
    try {
      const renameText = `favicon-${
        appState.auth.userInfo.companyId
      }-${Date.now()}`;
      const data = new FormData();
      const file = await e.target.files[0];
      const destinationFolder = 'Favicon Image';
      if (file) {
        const response = await uploadImage(
          file,
          data,
          renameText,
          appState.auth.token,
          destinationFolder,
        );
        if (response.error) {
          if ('LIMIT_FILE_SIZE' === response.error.code) {
            snack('Max size: 2MB', 'error');
          } else {
            // If not the given file type
            snack(response.error, 'error');
          }
        } else {
          // Success
          setFaviconImageLocation(response.location);
          snack('Image successfully uploaded', 'success');
        }
      } else {
        snack('Please upload file', 'error');
      }
    } catch (error) {
      log(error);
    }
  };
  // Save company logos
  const handleSaveCompanyLogos = async () => {
    let changes = {};

    if (appState.auth.userInfo.themePreferences) {
      changes = {
        ...appState.auth.userInfo.themePreferences,
        platformImageLocation,
        faviconImageLocation,
      };
    } else {
      changes = {
        platformImageLocation,
        faviconImageLocation,
      };
    }

    try {
      const data = {
        themePreferences: JSON.stringify(changes),
        companyId: appState.auth.userInfo.companyId,
      };

      const response = await fetch(
        process.env.REACT_APP_API_URL + `/company/edit`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
          body: JSON.stringify(data),
        },
      );
      const json = await response.json();

      if (json.success) {
        snack('Settings successfully updated', 'success');
        let cp = { ...appState };
        cp.auth.userInfo.themePreferences = JSON.parse(
          json.data.themePreferences,
        );
        setAppState(cp);
        userLogosCloseModal();
      } else {
        snack(json.errors[0], 'error');
        setImgErr(true);
      }
    } catch (err) {
      log(err);
    }
  };

  /**
   *
   * @param int permissionLevel // permission Level of the user
   * @param int id // User Id
   */
  const determineEdit = (permissionLevel, id) => {
    // debugger;
    let canEdit = true;
    const perm = permissionLevel;

    if ((perm || perm === 0) && appState.auth.userInfo.id !== id) {
      const userPerm = appState.auth.userInfo.perm;

      if (userPerm === 1 || userPerm === 53) canEdit = false;
      if (userPerm === 0 && perm === 99) canEdit = false;
      if (userPerm === 2 && (perm === 99 || perm === 0)) canEdit = false;
    }
    return canEdit;
  };

  return networkErr ? null : (
    <React.Fragment>
      {isMobileSize ? (
        <Fragment>
          <div style={{ overflow: 'hidden' }}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <HeaderMobile
                  showBadge={false}
                  avatar={
                    userInfo.profile && userInfo.profile.profileImageLocation
                      ? userInfo.profile.profileImageLocation
                      : ''
                  }
                  title={`${state.fname || ''} ${state.lname || ''}`}
                  subtitles={[
                    state.email || '',
                    userInfo.companyName || '',
                    state.phoneNumber || '',
                    permissions[userInfo.perm]?.title ||
                      'error with permissions function',
                  ]}
                  handleEditAvatarImage={userImageOpenModal}
                  showEditActions={determineEdit(userInfo.perm, state.id)}
                  showDelete={false}
                  handleEdit={handleOpenEditUserModal}
                />
              </Grid>
              <Grid item xs={12}>
                <MobileTabsContainer>
                  <Typography
                    variant='button'
                    component='p'
                    className={classes.mobileSubtitle}
                  >
                    Profile Settings
                  </Typography>
                </MobileTabsContainer>
              </Grid>

              <Grid
                container
                item
                spacing={0}
                xs={12}
                style={{
                  height: 'calc(100vh - 369px)',
                  overflow: 'scroll',
                  padding: '15px 0px 0px 0px',
                  margin: '0',
                }}
              >
                {renderCompanyButton()}
                <Grid
                  item
                  xs={12}
                  md={
                    userInfo.perm === 0 ||
                    userInfo.perm === 2 ||
                    userInfo.perm === 99
                      ? 6
                      : 12
                  }
                  className={classes.settingsCards}
                >
                  <IconButton
                    icon={<LockIcon style={{ fontSize: 40 }} color='primary' />}
                    title='Password'
                    subtitle='Update Password'
                    onClick={passwordOpenModal}
                  />
                </Grid>
                {renderBillingButton()}
                {renderPaymentButton()}
              </Grid>
            </Grid>

            {userInfo.perm === 0 ||
            userInfo.perm === 2 ||
            userInfo.perm === 99 ? (
              <EditCurrentUserDialog
                openState={profileModalState}
                handleClose={profileCloseModal}
                handleSave={profileSaveModal}
                userInfo={userInfo}
                setUserInfo={setUserInfo}
                companyInfo={companyInfo}
                setCompanyInfo={setCompanyInfo}
                appState={appState}
              />
            ) : (
              <EditUserProfileDialog
                openState={profileModalState}
                handleClose={profileCloseModal}
                handleSave={profileSaveModal}
                userInfo={userInfo}
                setUserInfo={setUserInfo}
                appState={appState}
              />
            )}

            <EditPasswordDialog
              openState={passwordModalState}
              handleClose={passwordCloseModal}
              appState={appState}
            />
            <EditUserImageDialog
              openState={userImageModalState}
              handleClose={userImageCloseModal}
              handleSave={singleFileUploadHandler}
              setImgLocation={setProfileImageLocation}
              handleRemoveImg={handleRemoveProfileImageLocation}
              imgLocation={profileImageLocation}
              setImgErr={setImgErr}
              setFile={setFile}
              setImgFile={setImgFile}
            />
            <ChangeCompanyLogos
              openState={userLogosModalState}
              handleClose={userLogosCloseModal}
              handleSave={handleSaveCompanyLogos}
              platformImageUploadHandler={platformImageUploadHandler}
              faviconImageUploadHandler={faviconImageUploadHandler}
              platformImageLocation={platformImageLocation}
              setPlatformImageLocation={setPlatformImageLocation}
              faviconImageLocation={faviconImageLocation}
              setFaviconImageLocation={setFaviconImageLocation}
            />
            <EditPaymentDialog
              openState={userPaymentModalState}
              handleClose={userPaymentCloseModal}
              handleSave={userPaymentSaveModal}
            />
          </div>
          <MobileBottomButton onClick={openAddModal} />
        </Fragment>
      ) : (
        <Fragment>
          <div className={classes.root}>
            <BreadCrumb
              className={classes.bar}
              title='Account Settings'
              leadingIcon={
                <SettingsIcon style={{ fontSize: 32 }} color='primary' />
              }
            />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SingleUserCard
                  id={state.id}
                  accountNumber={state.accountNumber}
                  name={`${state.fname || ''} ${state.lname || ''}`}
                  email={state.email || ''}
                  companyName={userInfo.companyName || ''}
                  phoneNumber={state.phoneNumber || ''}
                  userSettingsPage={true}
                  permissionLevel={userInfo.perm}
                  avatar={
                    userInfo.profile && userInfo.profile.profileImageLocation
                      ? userInfo.profile.profileImageLocation
                      : ''
                  }
                  handleOpenEditUserModal={handleOpenEditUserModal}
                  disableDelete={true}
                  handleEditImage={userImageOpenModal}
                />
              </Grid>
              {renderCompanyButton()}
              <Grid
                item
                xs={12}
                md={
                  permissions[userInfo?.perm]?.profile?.addCompanyLogo ? 6 : 12
                }
              >
                <IconButton
                  icon={<LockIcon style={{ fontSize: 40 }} color='primary' />}
                  title='Password'
                  subtitle='Update Password'
                  onClick={passwordOpenModal}
                />
              </Grid>
              {renderBillingButton()}
              {renderPaymentButton()}
            </Grid>

            {permissions[userInfo?.perm]?.profile?.editCurrentUser ? (
              <EditCurrentUserDialog
                openState={profileModalState}
                handleClose={profileCloseModal}
                handleSave={profileSaveModal}
                userInfo={userInfo}
                setUserInfo={setUserInfo}
                companyInfo={companyInfo}
                setCompanyInfo={setCompanyInfo}
                appState={appState}
              />
            ) : (
              <EditUserProfileDialog
                openState={profileModalState}
                handleClose={profileCloseModal}
                handleSave={profileSaveModal}
                userInfo={userInfo}
                setUserInfo={setUserInfo}
                appState={appState}
              />
            )}

            <EditPasswordDialog
              openState={passwordModalState}
              handleClose={passwordCloseModal}
              appState={appState}
            />
            <EditUserImageDialog
              openState={userImageModalState}
              handleClose={userImageCloseModal}
              handleSave={singleFileUploadHandler}
              setImgLocation={setProfileImageLocation}
              handleRemoveImg={handleRemoveProfileImageLocation}
              imgLocation={profileImageLocation}
              setImgErr={setImgErr}
              setFile={setFile}
              setImgFile={setImgFile}
            />
            <ChangeCompanyLogos
              openState={userLogosModalState}
              handleClose={userLogosCloseModal}
              handleSave={handleSaveCompanyLogos}
              platformImageUploadHandler={platformImageUploadHandler}
              faviconImageUploadHandler={faviconImageUploadHandler}
              platformImageLocation={platformImageLocation}
              setPlatformImageLocation={setPlatformImageLocation}
              faviconImageLocation={faviconImageLocation}
              setFaviconImageLocation={setFaviconImageLocation}
            />
            <EditPaymentDialog
              openState={userPaymentModalState}
              handleClose={userPaymentCloseModal}
              handleSave={userPaymentSaveModal}
            />
          </div>
        </Fragment>
      )}
    </React.Fragment>
  );
};

export default withWidth()(AccountSettings);
