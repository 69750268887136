import React, { useState } from 'react';
import { TextField, Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function LocFormStreets(props) {
  const log = window.log('LocationForm');

  const {
    street,
    classes,
    setCity,
    setState,
    setStreet,
    setZipcode,
    streetError,
  } = props;

  //lists
  const [streets, setStreets] = useState([]);
  // Validate is the results are undefined and discard its is necessay
  const validateUndefined = object => {
    let validator = false;
    const keys = [
      'houseNumber',
      'street',
      'displayText',
      'city',
      'state',
      'postalCode',
    ];

    log(object);
    for (var key in keys) {
      if (object[keys[key]] !== undefined) {
        validator = true;
      } else {
        object[keys[key]] = '';
      }
    }
    log(validator);
    return validator;
  };

  const getOptionLabel = option => {
    let str = '';
    let hasComma = false;
    if (option.displayText !== '') {
      str += option.displayText;
      hasComma = true;
    }

    if (option.city !== '') {
      str += ` ${option.city}`;
      hasComma = true;
    }

    if (hasComma) {
      str += ',';
    }

    if (option.state !== '') {
      str += ` ${option.state}`;
    }

    if (option.postalCode !== '') {
      str += ` ${option.postalCode}`;
    }

    return str;
  };
  // Autocomplete functions
  const handleSetStreetChange = async (e, v) => {
    let arr = [];
    if (e) {
      try {
        setStreet(e.currentTarget.value);
        const response = await fetch(
          `https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json?apiKey=${process.env.REACT_APP_HERE_API_KEY}&query=${e.currentTarget.value}`,
        );
        const data = await response.json();
        if (data.suggestions.length > 0) {
          data.suggestions.forEach(item => {
            let locationObject = Object.assign(
              {},
              {
                ...(item.address.houseNumber
                  ? { houseNumber: item.address.houseNumber }
                  : {}),
                ...(item.address.street ? { street: item.address.street } : {}),
                ...(item.address.city ? { city: item.address.city } : {}),
                ...(item.address.state ? { state: item.address.state } : {}),
                ...(item.address.postalCode
                  ? { postalCode: item.address.postalCode }
                  : {}),
                ...(item.address.country
                  ? { country: item.address.country }
                  : {}),
                ...(item.address.county ? { county: item.address.county } : {}),
              },
            );
            if (validateUndefined(locationObject)) {
              arr.push({
                ...(item.address.houseNumber
                  ? { houseNumber: item.address.houseNumber }
                  : { houseNumber: '' }),
                ...(item.address.street
                  ? { street: item.address.street }
                  : { street: '' }),
                ...(item.address.city
                  ? { city: item.address.city }
                  : { city: '' }),
                ...(item.address.state
                  ? { state: item.address.state }
                  : { state: '' }),
                ...(item.address.postalCode
                  ? { postalCode: item.address.postalCode }
                  : { postalCode: '' }),
                ...(item.address.country
                  ? { country: item.address.country }
                  : { country: '' }),
                ...(item.address.county ? { county: item.address.county } : {}),
              });
            }
          });
        } else {
          log('handle err');
        }
      } catch (error) {
        console.log(error);
      }
    }
    log(arr);
    setStreets(arr);
  };

  const handleStreetSelect = (e, v) => {
    if (v) {
      setStreet(v.displayText);
      setCity(v.city);
      setState(v.state);
      setZipcode(v.postalCode);
    }
  };

  const streetOptions = streets.map(option => {
    let text = `${option.houseNumber ? option.houseNumber + ' ' : ''}${
      option.street ? option.street + ' ' : ''
    }`;

    return {
      displayText: text,
      ...option,
    };
  });

  return (
    <Grid item xs={12}>
      <Autocomplete
        id='street'
        options={streetOptions}
        getOptionLabel={getOptionLabel}
        inputValue={street}
        onChange={(e, v) => handleStreetSelect(e, v)}
        onInputChange={(e, v) => handleSetStreetChange(e, v)}
        renderInput={params => (
          <TextField
            {...params}
            className={classes.textField}
            autoComplete='street'
            label='Street'
            name='street'
            variant='outlined'
            required
            style={{
              width: '105%',
            }}
            error={streetError}
          />
        )}
      />
    </Grid>
  );
}
