import React, { useState } from 'react';
import { TextField, Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function LocFormZips(props) {
  const log = window.log('LocationForm');

  const { classes, zipcode, setZipcode, zipcodeError } = props;

  const [zipcodes, setZipcodes] = useState([]);

  const handleSetZipcodeChange = async e => {
    let arr = [];
    if (e) {
      try {
        setZipcode(e.currentTarget.value);
        const response = await fetch(
          `https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json?apiKey=${process.env.REACT_APP_HERE_API_KEY}&query=${e.currentTarget.value}&resultType=postalCode`,
        );
        const data = await response.json();
        if (data.suggestions) {
          data.suggestions.forEach(item =>
            arr.push(item.label.match(/(\d+)/)[0]),
          );
        }
      } catch (error) {
        log(error);
      }
    }
    setZipcodes(arr);
  };

  const handleZipcodeSelect = (e, v) => {
    if (v) {
      setZipcode(v);
    }
  };

  return (
    <Grid item xs={12}>
      <Autocomplete
        id='zipcode'
        options={zipcodes}
        getOptionLabel={option => `${option}`}
        inputValue={zipcode}
        onChange={(e, v) => handleZipcodeSelect(e, v)}
        onInputChange={(e, v) => handleSetZipcodeChange(e, v)}
        renderInput={params => (
          <TextField
            {...params}
            className={classes.textField}
            autoComplete='zipcode'
            label='ZIP Code'
            name='zipcode'
            variant='outlined'
            required
            style={{
              width: '105%',
            }}
            error={zipcodeError}
          />
        )}
      />
    </Grid>
  );
}
