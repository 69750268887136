import React from 'react';
import { Grid } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { SettingsCheckBoxes } from './UserSettingsComponents/SettingsCheckBoxes'

export const UserSettingsMobile = ({data, singleSetHandler, color}) => {
    const styles = {
        container: {
            overflow: 'scroll',
            width: '100vw'
        },
        headerCell: {
            color: color,
            backgroundColor: '#F4F5F5'
        },
        thRow:{
            height: '5vh'
        }
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TableContainer style={styles.container}>
                    {data.map((section)=>{
                        return(
                            <Table>
                                <TableHead>
                                    <TableRow key={section.group} style={styles.thRow}>
                                        <TableCell align='center' style={styles.headerCell}>
                                            {section.group}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {section.items.map((item)=>{
                                        return(
                                        <TableRow key={item.name}>
                                            <TableCell>
                                                <SettingsCheckBoxes
                                                    currentValue={item.value}
                                                    setCurrentValue={section.group === 'Reports'? singleSetHandler :item.handler}
                                                    checkBoxData={item.options}
                                                    label={item.name}
                                                    colorPicker={(item.name === 'Accent Color')}
                                                    single={(section.group === 'Reports')}
                                                    isMobile
                                                />
                                            </TableCell>
                                        </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        )
                    })}
                </TableContainer>
            </Grid>
        </Grid>
    )
}