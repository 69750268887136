import _ from 'lodash';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useEffect, useState, useContext, memo } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, Grid, Dialog, Button, Typography } from '@material-ui/core';

import { AlertContext } from './AlertContext';
import { AppContext } from '../../AppContext';
import fetchAll from '../common/api/fetchAll';
import { UserAlertSettings } from './ManageAlertModalComp/UserAlertSettings';
import AlertCustomRecipients from './AlertModalComponents/AlertCustomRecipients';
import { ManageAlertSettings } from './ManageAlertModalComp/ManageAlertSettings';
import { ManageAlertBaseline } from './ManageAlertModalComp/ManageAlertBaseline';
import { SensorAlertSettings } from './ManageAlertModalComp/SensorAlertSettings';
import RangeSettingsLightStep from './AlertModalComponents/RangeSettingsLightStep';
import {
  determineCheckValues,
  determineLightRangeType,
  determineSimpleLuxNum,
} from '../common/helpers/calculateLuxDisplay';

/**
 * This is the shell that holds all the single edits for an alert
 * @param {*} props
 */
function ManageAlertContent() {
  const { snack, appState, setLogoutState } = useContext(AppContext);
  const {
    alertInfo,
    modalState,
    setModalState,
    setValueChanged,
    saveValueChange,
  } = useContext(AlertContext);

  const theme = useTheme();
  const classes = useStyles(theme);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // baseline state
  const [baseLineModal, setBaseLineModal] = useState(false);
  const [baselineSensors, setBaselineSensors] = useState([]); // Used for baseline and assigned sensor
  const [baselineSensorSel, setBaselineSensorSel] = useState({});

  // user state
  const [customRecip, setCustomRecip] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  // light state
  const [advLightErr, setAdvLightErr] = useState(false);
  const [isLight, setIsLight] = useState(false);
  const [showMarkers, setShowMarkers] = useState(true);
  const defaultLightState = {
    firstLightThresholdLow: 0,
    firstLightThresholdHigh: 0,
    secondLightThresholdLow: 0,
    secondLightThresholdHigh: 0,
    rangeType: 'none',
    rangeValues: [0, 1000],
    isAdvancedSetting: false,
    checkValues: [false, false, false, false],
    enableLightAlert: true,
  };
  const [lightState, setLightState] = useState();

  useEffect(() => {
    if (modalState?.alertObj?.item?.name === 'light_detection') {
      setIsLight(true);
      setDefaultLightObject();
    } else setIsLight(false);

    setBaseLineModal(false);
    setBaselineSensorSel({});
    if (modalState.isOpen) {
      if (modalState.editType === 'sensor' || modalState.editType === 'alert') {
        getDevices();
      }
      if (modalState.editType === 'user') separateUserandRecips();
    }
  }, [modalState.isOpen]);

  const toUnderScoreSlider = str =>
    str.toLowerCase().replace('of ', '').split(' ').join('_');

  function setDefaultLightObject() {
    const { value, subtitle } = modalState.alertObj.item.slider;
    const {
      firstLightThresholdLow,
      firstLightThresholdHigh,
      secondLightThresholdLow,
      secondLightThresholdHigh,
    } = JSON.parse(alertInfo.alert).light;
    defaultLightState.rangeType = toUnderScoreSlider(subtitle);

    defaultLightState.checkValues = determineCheckValues(
      value,
      defaultLightState.rangeType,
      appState.userSettings.advancedLux,
    );

    defaultLightState.firstLightThresholdLow = firstLightThresholdLow;
    defaultLightState.firstLightThresholdHigh = firstLightThresholdHigh;
    defaultLightState.secondLightThresholdLow = secondLightThresholdLow;
    defaultLightState.secondLightThresholdHigh = secondLightThresholdHigh;

    if (defaultLightState.rangeType === 'split_value') {
      defaultLightState.rangeValues = [
        [firstLightThresholdLow, firstLightThresholdHigh],
        [secondLightThresholdLow, secondLightThresholdHigh],
      ];
    } else {
      defaultLightState.rangeValues = [
        firstLightThresholdLow,
        firstLightThresholdHigh,
      ];
    }

    setLightState(defaultLightState);
  }

  const manageButtonClick = () => {
    setBaseLineModal(true);
  };

  // Sets the customRecip state with the changes
  // set valueChanged state with selectedUsers
  const customRCallback = recipArr => {
    setCustomRecip(recipArr);
    setValueChanged([...recipArr, ...selectedUsers]);
  };

  // sets the selectedUser state
  // sets valuechanged with customRecip
  const selUserCallback = userArr => {
    setSelectedUsers(userArr);
    setValueChanged([...userArr, ...customRecip]);
  };

  // split user and custom recipients from alert obj
  const separateUserandRecips = () => {
    const alertObj = JSON.parse(alertInfo.alert);
    const customRArr = [];
    const selUserArr = [];

    _.forEach(alertObj.users.selectedUsers, user => {
      if (user.id) selUserArr.push(user);
      else customRArr.push(user);
    });
    if (customRArr.length < 5) {
      customRArr.push({
        name: '',
        phone: '',
        email: '',
      });
    }
    setCustomRecip(customRArr);
    setSelectedUsers(selUserArr);
  };

  const getDevices = async () => {
    try {
      const json = await fetchAll(
        'sensor',
        appState.auth.userInfo.companyId,
        appState.auth.token,
      );

      if (json.success) {
        setBaselineSensors(json.data.sensors);
      } else {
        json.errors.forEach(err => {
          snack(err.msg, 'error');
          if (err.type === 'token') setLogoutState(true);
        });
      }
    } catch (err) {
      snack('Network Error', 'error');
    }
  };

  const determineContent = () => {
    let content = (
      <ManageAlertSettings
        setValueChanged={setValueChanged}
        baselineSensorSel={baselineSensorSel}
        manageButtonClick={manageButtonClick}
      />
    );

    if (baseLineModal) {
      content = (
        <ManageAlertBaseline
          baseLineModal={baseLineModal}
          baselineSensors={baselineSensors}
          setBaseLineModal={setBaseLineModal}
          baselineSensorSel={baselineSensorSel}
          setBaselineSensorSel={setBaselineSensorSel}
        />
      );
    }

    const changeLightState = newVal => {
      if (!newVal.isAdvancedSetting) {
        newVal.rangeType = determineLightRangeType(newVal);
      }
      setLightState(newVal);
      setValueChanged(newVal);
    };

    if (isLight) {
      content = (
        <RangeSettingsLightStep
          advLightErr={advLightErr}
          rangeSelError={{
            rangeSelDropdown: false,
            min: false,
            max: false,
          }}
          showMarkers={showMarkers}
          setShowMarkers={setShowMarkers}
          objectDefinition={modalState.alertObj.item}
          baselineSensorSel={baselineSensorSel}
          invalidRangeTypeError={false}
          isAdvancedSetting={lightState.isAdvancedSetting}
          object={lightState}
          setObject={changeLightState}
          setAdvLightErr={setAdvLightErr}
        />
      );
    }

    if (modalState.editType === 'sensor') {
      content = (
        <SensorAlertSettings
          alertInfo={alertInfo}
          allSensors={baselineSensors}
          setValueChanged={setValueChanged}
        />
      );
    }

    if (modalState.editType === 'user') {
      content = (
        <UserAlertSettings
          selectedUsers={selectedUsers}
          setValueChanged={selUserCallback}
        />
      );
    }

    if (modalState.editType === 'customRecipients') {
      content = (
        <AlertCustomRecipients
          customRecipients={customRecip}
          customRecipientsCallback={customRCallback}
        />
      );
    }

    return content;
  };

  const customRecipientsButton = () => {
    let customRButton = null;
    if (
      modalState.editType === 'user' ||
      modalState.editType === 'customRecipients'
    ) {
      customRButton = (
        <div>
          <Button
            className={classes.customRButton}
            variant='outlined'
            color='primary'
            onClick={() =>
              setModalState({
                ...modalState,
                editType:
                  modalState.editType === 'user' ? 'customRecipients' : 'user',
              })
            }
          >
            {modalState.editType === 'user'
              ? 'Add Custom Recipients'
              : 'Select Users'}
          </Button>
        </div>
      );
    }
    return customRButton;
  };

  // Used for every right side button in each modal rendered
  const handleSave = async editType => {
    if (baseLineModal) {
      setBaseLineModal(false);
      return;
    }
    if (editType === 'alert' && advLightErr) {
      return;
    }

    saveValueChange(editType);
  };

  return (
    modalState.isOpen && (
      <Dialog
        open={modalState.isOpen}
        onClose={() => setModalState({ ...modalState, isOpen: false })}
        aria-labelledby='resolve-alert-modal-title'
        aria-describedby='resolve-alert-modal-description'
        fullScreen={fullScreen} // commented temporarily because it breaks the layout
        fullWidth={true}
        maxWidth={'md'}
      >
        <Grid
          item
          container
          xs={12}
          justify='center'
          alignItems='center'
          className={classes.titleContainer}
        >
          <Box>
            <Typography className={classes.title}>
              Edit Alert Settings
            </Typography>
          </Box>
        </Grid>

        <Grid className={classes.contentContainer}>
          {determineContent()}
          {customRecipientsButton()}
          <div
            className={classes.buttonContainer}
            style={{
              marginTop:
                modalState.editType === 'user' ||
                modalState.editType === 'customRecipients'
                  ? 24
                  : 64,
            }}
          >
            <Button
              variant='outlined'
              className={`${classes.button} ${classes.cancelButton}`}
              onClick={() => {
                setModalState({ ...modalState, isOpen: false });
              }}
            >
              Cancel
            </Button>

            <Button
              variant='contained'
              color='primary'
              className={`${classes.button} ${classes.rightButton}`}
              id='closeModalButton'
              onClick={() => handleSave(modalState.editType)}
            >
              {baseLineModal ? 'Select' : 'Save'}
            </Button>
          </div>
        </Grid>
      </Dialog>
    )
  );
}

const useStyles = makeStyles(theme => ({
  titleContainer: {
    padding: theme.spacing(1),
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.background.paper
        : theme.palette.background.paper,
    height: '60px',
    flexBasis: 'auto',
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor:
      theme.palette.type === 'light'
        ? '#F4F5F5'
        : theme.palette.background.default,
    padding: '72px 50px',
    [theme.breakpoints.down('sm')]: {
      padding: '25px 10px',
      height: '100%',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  customRButton: {
    marginTop: 24,
  },
  button: {
    width: 114,
    padding: 8,
    fontSize: 13,
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
  cancelButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
  rightButton: { boxShadow: 'none' },
}));

export const ManageAlertModal = memo(ManageAlertContent);
