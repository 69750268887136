import _ from 'lodash';
import React, { useState, useContext, useEffect } from 'react';

import { EventBus } from '../common/EventBus';
import marker from '../../img/place-24px.svg';
import Address from './ModalComponents/Address';
import { AppContext } from './../../AppContext';
import gateway from '../../img/router-24px.svg';
import ImageButton from '../Generic/ImageButton';
import Loading from '../common/ModalComponents/Loading';
import ResponsiveDialog from '../Generic/GenericDialog';
import Complete from '../common/ModalComponents/Complete';
import LocationName from './ModalComponents/LocationName';

/**
 * Location Form Modal
 * @param {Object} props
 * @props
 * openState: Boolean
 * handleClose: Functions
 */
export default function LocationFormModal(props) {
  const log = window.log('LocationFormModal');

  const {
    appState,
    setAppState,
    setlogoutState,
    openLocationModal,
    setOpenLocationModal,
    setOpenGatewayModal,
    setFeatureCount,
    snack,
    getLocations,
    limitReachedSnackbar,
  } = useContext(AppContext);

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);

  const [street, setStreet] = useState('');
  const [streetError, setStreetError] = useState(false);

  const [city, setCity] = useState('');
  const [cityError, setCityError] = useState(false);

  const [state, setState] = useState('');
  const [stateError, setStateError] = useState(false);

  const [zipcode, setZipcode] = useState('');
  const [zipcodeError, setZipcodeError] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [locationId, setLocationId] = useState('');
  const [editLocationName, setEditLocationName] = useState('');

  const setDefaultObject = (editObj = null) => {
    if (editObj) {
      setIsEdit(true);
      setCity(editObj.editLocationCity);
      setName(editObj.editLocationName);
      setLocationId(editObj.locationId);
      setState(editObj.editLocationState);
      setZipcode(editObj.editLocationZip);
      setStreet(editObj.editLocationStreet);
      setEditLocationName(editObj.editLocationName);
    } else {
      setCity('');
      setName('');
      setState('');
      setStreet('');
      setZipcode('');
      setIsEdit(false);
      setLocationId('');
      setEditLocationName('');
    }
    setNameError(false);
    setCityError(false);
    setStateError(false);
    setStreetError(false);
    setZipcodeError(false);
  };

  useEffect(() => {
    setDefaultObject();
    EventBus.on('editLocationModalProps', data => {
      setDefaultObject(data);
    });

    return () => {
      EventBus.remove('editLocationModalProps');
    };
  }, []);

  const handleEditCallbacks = () => {
    EventBus.dispatch('locationModalCallBacks');
  };

  // Marks Add location as complete for onboard
  const handleLocationFeatureComplete = async () => {
    let changes = {
      ...appState.auth.userInfo.features,
      addLocation: true,
    };

    try {
      const data = {
        themePreferences: appState.auth.userInfo.themePreferences
          ? JSON.stringify(appState.auth.userInfo.themePreferences)
          : null,
        features: JSON.stringify(changes),
        companyId: appState.auth.userInfo.companyId,
      };
      const response = await fetch(
        process.env.REACT_APP_API_URL + `/company/edit`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
          body: JSON.stringify(data),
        },
      );
      const json = await response.json();

      if (json.success) {
        let cp = { ...appState };
        cp.auth.userInfo.features = JSON.parse(json.data.features);
        setAppState(cp);
        let features = JSON.parse(json.data.features);
        let count = Object.values(features).filter(item => item === false)
          .length;
        if (!features.finishLater) {
          count = count - 1;
        }
        setFeatureCount(count);
      } else {
        snack(json.errors[0], 'error');
        json.errors.forEach(err => {
          snack(err.msg, 'error');
          if (err.type === 'token') setlogoutState(true);
        });
      }
    } catch (err) {
      log(err);
    }
  };
  // Submit function to api
  const handleSubmitLocation = async () => {
    let foundError = false;
    if (street.length === 0) {
      setStreetError(true);
      foundError = true;
    }
    if (city.length === 0) {
      setCityError(true);
      foundError = true;
    }
    if (state.length === 0) {
      setStateError(true);
      foundError = true;
    }
    if (zipcode.length === 0) {
      setZipcodeError(true);
      foundError = true;
    }
    if (foundError) {
      snack('Please Fill Out All Fields', 'error');
      return false;
    }
    // if (state.length !== 2) {
    //   snack('Please Use Two Letter State Code', 'error');
    //   return false;
    // }

    try {
      let correctedStreet = street;
      if (street.includes('#')) {
        correctedStreet = correctedStreet.split('#').join('%23');
      }

      const response = await fetch(
        `https://geocoder.ls.hereapi.com/6.2/geocode.json?apiKey=${process.env.REACT_APP_HERE_API_KEY}&searchtext=${correctedStreet}+${city}+${state}+${zipcode}`,
      );
      const json = await response.json();

      if (json.Response.View.length === 0) {
        snack('Not a valid address', 'error');
        return false;
      } else {
        log('added location', json);
        let lat =
          json.Response.View[0].Result[0].Location.DisplayPosition.Latitude;
        let lng =
          json.Response.View[0].Result[0].Location.DisplayPosition.Longitude;
        try {
          const data = {
            street,
            city,
            state,
            zipcode,
            primary: false,
            lat,
            lng,
          };

          if (isEdit) {
            _.assignIn(data, { locationId, editLocationName: name });
          } else _.assignIn(data, { name });

          // const delay = (ms) => new Promise((res) => setTimeout(res, ms));

          // await delay(1500);

          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/location/${
              isEdit ? 'edit' : 'add'
            }`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: appState.auth.token,
              },
              body: JSON.stringify(data),
            },
          );
          const json = await response.json();

          if (json.success) {
            if (isEdit) {
              handleEditCallbacks();
            } else {
              setDefaultObject();
              handleLocationFeatureComplete();
            }
            getLocations();
            return true;
          } else {
            let errorObj = {};
            json.errors.forEach(error => {
              if (error.type === 'street') {
                setStreetError(true);
              }
              if (error.type === 'city') {
                setCityError(true);
              }
              if (error.type === 'state') {
                setStateError(true);
              }
              if (error.type === 'zipcode') {
                setZipcodeError(true);
              }
              //add error to err object
              errorObj = { ...errorObj, [error.type]: true };
              //err alert
              if ( error.type === 'limit' ) {
                limitReachedSnackbar('locations');
              } else if ( error.type === 'system' ) {
                snack('System error. If the problem persists, please contact support.', 'error');
              } else if (error.type === 'token') {
                setlogoutState(true);
              } else {
                snack(error.msg, 'error');
              }
            });
            return false;
          }
        } catch (err) {
          log(err);
          snack('Network Error', 'error');
          return false;
        }
      }
    } catch (error) {
      log(error);
      snack('Network Error', 'error');
      return false;
    }
  };

  const handleCheckName = async () => {
    log('Looking at name ' + name);
    log('Looking at token ' + appState.auth.token);
    //Check that name doesn't already exist in the database
    if (isEdit) {
      if (editLocationName === undefined || editLocationName === '') {
        setNameError(true);
        snack('A Location name is required', 'error');
        return false;
      }
      return true;
    }
    if (!isEdit || (isEdit && name !== editLocationName)) {
      try {
        const request = await fetch(
          `${process.env.REACT_APP_API_URL}/location/checkLocationName?locationName=${name}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: appState.auth.token,
            },
          },
        );
        const json = await request.json();
        if (!json.success) {
          setNameError(true);
          let errorObj = {};
          json.errors.forEach(error => {
            //Add error to err object
            errorObj = { ...errorObj, [error.type]: true };
            //err alert
            snack(error.msg, 'error');
            if (error.type === 'token') setlogoutState(true);
          });
        } else {
          setNameError(false);
          return json.success;
        }
      } catch (err) {
        snack('Network Error', 'error');
      }
    }
  };

  const handleRestart = async () => {
    handleClose();
    setTimeout(() => {
      setOpenLocationModal(true);
    }, 1000);
  };

  const handleClose = () => {
    setOpenLocationModal(false);
    setDefaultObject();
  };

  //Open the Gateway Form Modal
  const openGatewayModal = () => {
    setOpenLocationModal(false);
    setDefaultObject();
    setTimeout(() => {
      setOpenGatewayModal(true);
    }, 500);
  };

  return (
    <ResponsiveDialog
      openState={openLocationModal}
      handleClose={handleClose}
      handleCancel={handleClose}
      title={`${isEdit ? 'EDIT' : 'ADD A NEW'} LOCATION`}
      stepsArray={[
        {
          label: 'LOCATION NAME',
          nextFunction: handleCheckName,
          showProgress: false,
          child: (
            <LocationName
              name={name}
              isEdit={isEdit}
              setName={setName}
              nameErr={nameError}
            />
          ),
        },
        {
          label: 'ADDRESS',
          nextFunction: handleSubmitLocation,
          validator: true,
          showProgress: true,
          saveButtonStep: true,
          child: (
            <Address
              info={{ street, city, state, zipcode }}
              setters={{ setStreet, setCity, setState, setZipcode }}
              errors={{ streetError, cityError, stateError, zipcodeError }}
            />
          ),
        },
        {
          label: 'LOADING',
          child: (
            <Loading
              header={`${
                isEdit ? 'Updating' : 'Adding'
              } Location into Platform`}
              desc='MiSensors is currently configuring your location into the platform. This should take less than a minute.'
            />
          ),
        },
        {
          label: 'COMPLETE', // Because is the finish
          child: (
            <Complete
              header={`Success! Your location has been ${
                isEdit ? 'updated' : 'added'
              }.`}
              desc={
                isEdit
                  ? 'Select Finish to return to the location page.'
                  : 'You may now add additional locations or start adding gateways by selecting the options below.'
              }
              completeButtons={
                !isEdit && [
                  <ImageButton
                    onClick={() => openGatewayModal()}
                    image={gateway}
                    action='Continue'
                    text='ADD GATEWAY'
                    key='1'
                  />,
                  <ImageButton
                    onClick={() => handleRestart()}
                    image={marker}
                    action='ADD'
                    text='ANOTHER LOCATION'
                    key='2'
                  />,
                ]
              }
            />
          ),
        },
      ]}
    />
  );
}
