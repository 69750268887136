import { navigate } from 'hookrouter';
import React, { useContext } from 'react';
import grey from '@material-ui/core/colors/grey';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Avatar, Typography, Box } from '@material-ui/core';

import { AppContext } from '../../AppContext';
import CardActions from '../Generic/CardActions';
import UserImage from '../../img/icons/user.svg';
import { PendingTag } from '../Generic/PendingTag';
import GenericListCard from '../Generic/GenericListCard';

/**
 * UserCard
 * @param {Object} props
 * @props
 * id: Integer
 * name: String
 * avatar: String
 * email: String
 * companyName: String
 */
function UserCard(props) {
  const { appState } = useContext(AppContext);
  const theme = useTheme();
  const smallDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    id, // Id of the user,
    avatar, // Link to the image
    name, // Name of the user
    email, // Email of the user
    companyName, //Company of the user
    perm, // Permissions Level
    handleOpenEditUserModal,
    handleOpenDeleteUserModal,
    isUser,
    pendingUser, // User hasn't accepted the invitation yet
  } = props;

  const useStyles = makeStyles(theme => ({
    rootCard: {
      paddingLeft: 20,
    },
    avatarLarge: {
      width: '74px',
      height: '74px',
      border: '1px solid #CCC',
      backgroundColor: !avatar && '#F4F5F5',
    },
    avatarImage: {
      objectFit: !avatar && 'none',
    },
    title: {
      fontWeight: 'bold',
      fontSize: 14,
      color: theme.palette.type === 'light' ? grey[600] : '#fff',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },
    subTitle: {
      color: theme.palette.type === 'light' ? grey[500] : '#FFF',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },
    userInfoText: {
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-end',
      },
      [theme.breakpoints.up('2090')]: {
        transform: 'translateX(-60px)',
      },
    },
  }));

  const classes = useStyles();

  /**
   * Event to send the browser to the user page
   */
  const showUser = () => {
    if (isUser) {
      navigate(`/current/user/configuration`);
    } else {
      navigate(`/user/${id}`);
    }
  };

  const determineEdit = () => {
    let canEdit = true;
    if (perm || perm === 0) {
      const userPerm = appState.auth.userInfo.perm;
      if (userPerm === 1 || userPerm === 53) canEdit = false;
      if (userPerm === 0 && perm === 99) canEdit = false;
      if (userPerm === 2 && (perm === 99 || perm === 0)) canEdit = false;
    }
    return canEdit;
  };

  return (
    <GenericListCard userCard={true}>
      <Grid
        item
        container
        xs={12}
        spacing={0}
        height='142px'
        style={{ position: 'relative' }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          onClick={showUser}
          style={{ cursor: 'pointer' }}
        >
          <Grid item xs={10} sm={12} md={9}>
            <Box display='flex' justifyContent='center'>
              <Avatar
                classes={{ img: classes.avatarImage }}
                alt='avatar'
                src={avatar ? avatar : UserImage}
                className={classes.avatarLarge}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={12} md={8}>
          <Grid
            data-testclass='user_card'
            item
            container
            direction='column'
            className={classes.userInfoText}
            xs={12}
            md={8}
            onClick={showUser}
            style={{ cursor: 'pointer' }}
          >
            <Typography className={classes.title} gutterBottom>
              {name}
            </Typography>
            <Typography className={classes.subTitle} gutterBottom>
              Email: {email}
            </Typography>
            <Typography className={classes.subTitle} gutterBottom>
              {companyName}
            </Typography>
          </Grid>
        </Grid>
        {pendingUser && <PendingTag pendingUser={pendingUser} id={id} />}
        {determineEdit() && (
          <Grid
            container
            item
            xs={12}
            md={2}
            justify='center'
            alignItems='center'
          >
            <CardActions
              editEvent={handleOpenEditUserModal}
              deleteEvent={handleOpenDeleteUserModal}
              smallDevice={smallDevice}
              disableDelete={isUser}
            />
          </Grid>
        )}
      </Grid>
    </GenericListCard>
  );
}

export default UserCard;
