import React, { useContext, useEffect, useState } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { linearGradientDef } from '@nivo/core';
import { useTheme } from '@material-ui/core/styles';
import moment from 'moment';
import _ from 'lodash';
import convertValuesForUserPrefWithoutUnit from '../../common/helpers/settingsConvert';
import { ENTITY_KEY_MAP } from '../../../constants'

import { AppContext } from '../../../AppContext';

export const DeviceLineGraph = ({
  entity, // sensor device entity, holds the values of the sensor
  markers = [], // chart markers for line chart
  chartTimeSelection,
  reportData,
  messageKey,
  handleHighLow,
  fullScreen = false // Value its the dialog its going to be open fullScreen adjusting some values for the margin on the chart
}) => {
  const { appState } = useContext(AppContext);
  const theme = useTheme();

  const chartDataTest = [
    {
      id: 'main',
      data: [],
    },
    {
      id: 'dataGap',
      data: []
    }
  ];

  const getChartFormat = () => {
    switch (chartTimeSelection) {
      case 'hour':
        return appState.userSettings?.timeFormat === '12hr'
          ? '%-I:%M%p'
          : '%-H:%M';
      case 'day':
        return appState.userSettings?.timeFormat === '12hr' ? '%-I%p' : '%-H';
      default:
        return '%a';
    }
  };

  const getChartTickValues = () => {
    switch (chartTimeSelection) {
      case 'hour':
        return 'every 10 minutes';
      case 'day':
        return 'every hour';
      default:
        return 'every day';
    }
  };

  const getDate = date => {
    return moment(date).format('M-D-YYYY H:mm');
  };

  const isDate = (date, comparedDate) => {
    return (
      date.getDate() === comparedDate.getDate() &&
      date.getMonth() === comparedDate.getMonth() &&
      date.getFullYear() === comparedDate.getFullYear()
    );
  };

  if (messageKey === 'battery') {
    messageKey = 'batteryVoltage';
  }

  const prepareWeekData = () => {
    const endDate = new Date();
    const value = 86400; // Seconds on a day
    const chartData = [];

    let initialDate = new Date(endDate - 6 * value * 1000);
    initialDate.setHours(0);
    initialDate.setMinutes(0);
    initialDate.setSeconds(0);
    for (let i = 0; i < 7; i++) {
      const dataDay = [];
      if (reportData.reportData) {
        for (let j = 0; j < reportData.reportData?.length; j++) {
          if (
            isDate(new Date(reportData.reportData[j].dateReceived), initialDate)
          ) {
            dataDay.push(reportData.reportData[j][messageKey]);
          }
        }
      } else {
        for (let j = 0; j < reportData.length; j++) {
          if (isDate(new Date(reportData[j].dateReceived), initialDate)) {
            dataDay.push(reportData[j][messageKey]);
          }
        }
      }

      let sum = dataDay.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
      let avg = (sum / dataDay.length || 0).toFixed(2);
      chartData.push({
        x: getDate(initialDate.getTime()),
        y: dataDay.length === 0 ? null : parseFloat(convertValuesForUserPrefWithoutUnit(avg, messageKey, appState)),
      });
      initialDate = new Date(initialDate.getTime() + value * 1000);
    }
    handleHighLow(chartData)

    return chartData;
  };

  const preparedDayData = () => {
    const endDate = new Date();
    const value = 3600; // Seconds on a hour
    const chartData = [];
    let initialDate = new Date();
    initialDate.setHours(0);
    initialDate.setMinutes(0);
    initialDate.setSeconds(0);

    while (initialDate.getTime() < endDate.getTime()) {
      const dataDay = [];
      for (let j = 0; j < reportData.length; j++) {
        const comparedDate = new Date(reportData[j].dateReceived);
        if (
          initialDate.getTime() <= comparedDate.getTime() &&
          comparedDate.getTime() < initialDate.getTime() + value * 1000
        ) {
          dataDay.push(reportData[j][messageKey]);
        }
      }

      let sum = dataDay.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
      let avg = (sum / dataDay.length || 0).toFixed(2);
      chartData.push({
        x: getDate(initialDate.getTime()),
        y: dataDay.length === 0 ? null : parseFloat(convertValuesForUserPrefWithoutUnit(avg, messageKey, appState)),
      });
      initialDate = new Date(initialDate.getTime() + value * 1000);
    }
    handleHighLow(chartData)

    return chartData;
  };

  const preparedHourData = () => {
    const endDate = new Date();
    const value = 600; // Seconds on 10 minuts
    let chartData = [];
    let initialDate = new Date(endDate - 6000 * 1000);

    while (initialDate.getTime() < endDate.getTime()) {
      const dataDay = [];
      for (let j = 0; j < reportData.length; j++) {
        const comparedDate = new Date(reportData[j].dateReceived);
        if (
          initialDate.getTime() <= comparedDate.getTime() &&
          comparedDate.getTime() < initialDate.getTime() + value * 1000
        ) {
          dataDay.push(reportData[j][messageKey]);
        }
      }

      let sum = dataDay.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
      let avg = (sum / dataDay.length || 0).toFixed(2);

      chartData.push({
        x: getDate(initialDate.getTime()),
        y: dataDay.length === 0 ? null : parseFloat(convertValuesForUserPrefWithoutUnit(avg, messageKey, appState)),
      });
      initialDate = new Date(initialDate.getTime() + value * 1000);
    }
    if (chartData.length >= 7) {
      chartData = chartData.splice(-6);
    }
    handleHighLow(chartData)

    return chartData;
  };

  if (chartTimeSelection === 'hour') {
    chartDataTest[0].data = preparedHourData();
  } else if (chartTimeSelection == 'day') {
    chartDataTest[0].data = preparedDayData();
  } else {
    chartDataTest[0].data = prepareWeekData();
  }

  const formatToolTipValue = (type, value, appState) => {
    switch (type) {
      case 'Temperature':
        return `${value} °${appState.userSettings.tempType}`;
      case 'Humidity':
        return value + '%';
      case 'Pressure':
        return `${value} ${appState.userSettings.pressType}`;
      case 'Impact Detection':
        return value + 'G';
      case 'Light Detection':
        return value + ' lux';
      case 'Proximity':
        return value + ' dBm';
      case 'Battery':
        return value + 'V';
      default:
        return value;
    }
  };

  const getToolTipMsg = (p) => {
    let date = 'Date: ' + p.data.xFormatted.split(' ')[0]
    let type = ENTITY_KEY_MAP[messageKey].charAt(0).toUpperCase() + ENTITY_KEY_MAP[messageKey].slice(1) + ': '
    let status =  p.serieId === 'main' ? formatToolTipValue(entity.title, p.data.yFormatted, appState): 'Sensor Offline'
    return (
      <>
      <div>{date}</div>
        <div>
        {p.serieId === 'main' ? type + status: status}
        </div>
      </>
    )
  }

  const standardToolTips = slice => {
    return (
      <>
        {slice.points
          .filter(p => p.value !== null)
          .map(p => [
            <div key={p.serieId}>
              <span
                style={{
                  display: 'block',
                  width: '12px',
                  height: '12px',
                  background: p.serieColor,
                }}
              />
              {getToolTipMsg(p)}
            </div>,
          ])}
      </>
    );
  };

  // Builds the second series of data for missing sensor information
  chartDataTest[0].data.forEach((item, i) => {
    if(item.y === null){
      if(i !== 0 && i !== chartDataTest[0].data.length-1){
        chartDataTest[1].data.push(chartDataTest[0].data[i-1])
        chartDataTest[1].data.push(chartDataTest[0].data[i+1])
      }
    } else {
      chartDataTest[1].data.push({x: chartDataTest[0].data[i].x, y: null})
    }
  })

  const baseLineValues = () =>{
    let a = _.filter(chartDataTest[0].data, (currObj)=>{
      return currObj.y !== null
    })
      if(a.length >= 1){
        return parseFloat(_.minBy(a, 'y').y)
      } else {
        return 0
      }
  }

  return (
    <ResponsiveLine
      data={chartDataTest}
      markers={markers}
      areaBaselineValue={chartDataTest[0].data ? baseLineValues() : 0}
      margin={{ top: 50, right: fullScreen ? 20 : 85, bottom: 30, left: 60 }}
      xScale={{
        type: 'time',
        format: '%-m-%d-%Y %-I:%M',
        useUTC: false,
        precision: 'minute',
      }}
      xFormat={
        appState.userSettings?.timeFormat === '12hr'
          ? 'time:%m-%d-%Y %-I:%M %p'
          : 'time:%m-%d-%Y %-H:%M'
      }
      yScale={{
        type: 'linear',
        min: chartDataTest[0].data ? baseLineValues() : 0,
        max: 'auto',
        stacked: false,
        reverse: false,
      }}
      curve='monotoneX'
      axisTop={null}
      axisRight={null}
      itemTextColor={'#fffff'}
      axisBottom={{
        format: getChartFormat(),
        tickRotation: 0,
        tickValues: getChartTickValues(),
        tickCount: 10,
        legendOffset: 50,
        legendPosition: 'middle',
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendOffset: -45,
        legendPosition: 'middle',
      }}
      colors={[theme.palette.primary.main, '#F44336']}
      lineWidth={2}
      enablePoints={true}
      pointSize={10}
      pointColor={'#fff'}
      pointBorderWidth={3}
      pointBorderColor={{ from: 'serieColor', modifiers: [] }}
      pointLabel={function (e) {
        return e.x + ': ' + e.y;
      }}
      pointLabelYOffset={-12}
      enableArea={true}
      useMesh={true}
      enableSlices='x'
      sliceTooltip={val => {
        const { slice } = val;
        return (
          <div
            style={{
              color: 'black',
              backgroundColor: 'white',
              padding: 10,
              boxShadow: '2px 2px #e1e1e1',
            }}
          >
            {standardToolTips(slice)}
          </div>
        );
      }}
      keys={['main', 'dataGap']} //defines keys to use for data
      defs={[
        linearGradientDef('gradientA', [
          { offset: 0, color: 'inherit' },
          { offset: 100, color: 'inherit', opacity: 0 },
        ]),
        linearGradientDef('gradientB', [
          { offset: 0, color: '#F44336' },
          { offset: 100, color: '#F44336', opacity: 0 },
        ]),
      ]}
      fill={[{ match: {id: 'main'}, id: 'gradientA' },{ match: {id: 'dataGap'}, id: 'gradientB' }]} //maps gradients to keys
      legends={[]}
      labelTextColor='black'
      theme={
        appState?.userSettings?.darkMode && {
          axis: {
            ticks: {
              text: {
                fill: 'white',
              },
            },
            legend: {
              text: {
                fill: 'white',
              },
            },
          },
        }
      }
    />
  );
};
