import React from 'react';
import './range_alert_styles/alert_range.css';

import { makeStyles, Tooltip } from "@material-ui/core";

// Helper Functions
import { determineSimpleLuxDisplay } from '../common/helpers/calculateLuxDisplay';

export default function RangeAlertDisplay(props) {
  const log = window.log('RangeAlertDisplay');

  // width of the marker with the range value
  const markerWidth = 30;

  // size of the diameter of the circle in the bar
  const alertPointDiameter = 20;

  const {
    openState,
    minValue, // minimum value that the range will show
    maxValue, // maximum value that the range will show
    minRange, // minimum value of the acceptable range
    maxRange, // maximum value of the acceptable range
    alertValue, // value of the alert
    barWidth = 350, // float , barWidth in Pixels
    measurementUnit, // unit of measurement
    alertType,
    alertSetting,
    rangeType, // message of the range if it is within the acceptable value or outside
    // twoRanges, // boolean to mark there are two ranges
    // rangeOne, // dict with minValue and maxValue keys { minValue: , maxValue: }
    // rangeTwo, // dict with minValue and maxValue keys { minValue: , maxValue: }
  } = props;
  log(props);
  const barMaxOffset = barWidth - alertPointDiameter;

  let rangeAreaWidth = 0;
  let maxRangePosition;
  let minRangePosition;

  let hasRange = false

  let alertValuePosition;

  if (minRange === maxRange) {
    rangeAreaWidth = 0
    hasRange = false
  } else {
    hasRange = true
  }

  if (alertValue < minRange) {
    // the value is below the acceptable threshold
    if (hasRange) {
      rangeAreaWidth = barWidth * 0.39
      maxRangePosition = barWidth * 0.66;
      minRangePosition = barWidth * 0.33;
      // position of alertValue
      alertValuePosition = barWidth * 0.20
    } else {
      maxRangePosition = barWidth * 0.66;
      minRangePosition = barWidth * 0.66;
      // position of alertValue
      alertValuePosition = barWidth * 0.33
    }
  } else if (alertValue > maxRange) {
    // the value is above the acceptable threshold
    if (hasRange) {
      rangeAreaWidth = barWidth * 0.39
      maxRangePosition = barWidth * 0.66;
      minRangePosition = barWidth * 0.33;
      // position of alertValue
      alertValuePosition = barWidth * 0.80
    } else {
      maxRangePosition = barWidth * 0.33;
      minRangePosition = barWidth * 0.33;
      // position of alertValue
      alertValuePosition = barWidth * 0.66
    }
  } else if ((alertValue > minRange && alertValue < maxRange) || (alertValue === minRange || alertValue === maxRange)) {
    /// Value in range
    if (hasRange) {
      rangeAreaWidth = barWidth * 0.45
      maxRangePosition = barWidth * 0.7;
      minRangePosition = barWidth * 0.3;
    } else {
      maxRangePosition = barWidth * 0.5;
      minRangePosition = barWidth * 0.5;
    }
    // position of alertValue
    alertValuePosition = barWidth * 0.5
  }

  const useStylesTooltip = makeStyles((theme) => ({
    popper: {
      top: '10px !important',
      zIndex: '5500 !important'
    },
    arrow: {
      color: '#FF6A6A',
    },
    tooltip: {
      backgroundColor: '#FF6A6A',
      fontSize: 13,
      fontWeight: 600,
      color: '#FFFFFF'
    },
  }));

  function CustomTooltip(props) {
    const classes = useStylesTooltip();

    return <Tooltip arrow classes={classes} {...props} TransitionComponent={({ children }) => children}/>;
  }

  const useStyles = makeStyles((theme) => ({
    rangeMinValue: {
      transform: `translateX(${minRangePosition}px)`,
    },
    rangeMaxValue: {
      transform: `translateX(${maxRangePosition}px)`,
    },
    rangeArea:
      rangeAreaWidth === 0
        ? {
            width: `5px`,
            transform: `translateX(${minRangePosition + markerWidth / 2}px)`,
          }
        : {
            width: `${rangeAreaWidth}px`,
            transform: `translateX(${minRangePosition}px)`,
          },
    alertValue: {
      transform: `translateX(${alertValuePosition + alertPointDiameter / 4}px)`,
    },
    rangeComponent: {
      width: `${barWidth}px`,
      minWidth: `${barWidth}px`,
      backgroundColor: theme.palette.background.paper,
    },
    barMaxValue: {
      transform: `translateX(${barMaxOffset}px)`,
    },
    alertPointValue: {
      top: `${-(alertPointDiameter / 2)}px`,
      height: `${alertPointDiameter}px`,
      width: `${alertPointDiameter}px`,
    },
    markerWidth: {
      width: `${markerWidth}px`,
    },
    // Temporary Fix for alignment of Temperature ToolTip on Safari
    // tooltipWidth: {
    //   width: `max-content !important`,
    //   paddingLeft: 3,
    //   paddingRight: 3
    // }
  }));

  const classes = useStyles();

  return (
    <div className={`out_of_range_alert_component ${classes.rangeComponent}`}>
      <div className='range_container'>
        <span className='bar'></span>
        <span className={`range_values bar--min_value ${classes.markerWidth}`}>
          {/* {minValue}
          {measurementUnit} */}
        </span>
        <span
          className={`range_values bar--max_value ${classes.barMaxValue} ${classes.markerWidth}`}
        >
          {/* {maxValue}
          {measurementUnit} */}
        </span>
        <span className={`range_area ${classes.rangeArea}`}></span>
        <span
          className={`range_values area_marker ${classes.rangeMinValue} ${classes.markerWidth}`}
        >
          {alertType === 'light' && !alertSetting
            ? determineSimpleLuxDisplay(minRange)
            : minRange}
          {measurementUnit}
        </span>
        <span
          className={`range_values area_marker ${classes.rangeMaxValue} ${classes.markerWidth}`}
        >
          {alertType === 'light' && !alertSetting
            ? determineSimpleLuxDisplay(maxRange)
            : maxRange}
          {measurementUnit}
        </span>
        <div className={`value--container ${classes.alertValue}`} style={{ display: 'flex', justifyContent: 'center' }}>
          <CustomTooltip
            title={`${alertType === 'light' && !alertSetting ? determineSimpleLuxDisplay(alertValue) : alertValue} ${measurementUnit}`}
            open={openState}
            placement="top"
          >
            <span className={`alert_point ${classes.alertPointValue}`}></span>
          </CustomTooltip>
          {/* <div className={`tooltip_value ${classes.tooltipWidth}`}>
            {alertType === 'light' && !alertSetting ? determineSimpleLuxDisplay(alertValue) : alertValue}
            {measurementUnit}
          </div> */}
        </div>
      </div>
      <div className='alert_message'>{rangeType}</div>
    </div>
  );
}
