import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TablePagination,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { TableHead, TableCell, TableRow } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';

const buildList = (data, itemBuilder, emptyBuilder) => {
  if (data.length === 0) {
    // if list empty return the element to show when empty
    return emptyBuilder();
  }
  const result = data.map(itemBuilder);
  return result;
};

/**
 * Generic List for
 * the list in the dashboard
 *
 * @props
 * title: String
 * actionTitle: String
 * onClick: Function
 * children: Components (Color)
 * action: Components
 * showActions: Boolean
 */
function GenericList(props) {
  const {
    title, // Title of the list
    actionTitle, // Title for the action in the bottom the list
    onClick, // Function for the action in the bottom of the list
    children, // Children components
    action, // Action for the CardHeader
    showActions = true,
    dashboardTable,
    noHeight=false,
    useItemBuilder = true, // if true the table will render using item builder and will not use the children
    data = [], // list of elements to render
    itemBuilder = (item, index) => {}, // function that returns the component of an item in the list given the item and the index
    emptyBuilder = () => {}, //function that returns the component to render if the list is empty
    listHeight, // component height
    manageAlertsPage = false, // style is slightly different for the manageAlertsPage
    paddingTop, // Padding top for the mobile headerAlert
    paginate = false, //boolean: whether to paginate or not
    columnHead,
    ...other
  } = props;

  const [page, setPage] = useState(0);

  const useStyles = makeStyles(theme => ({
    cardContainer: {
      borderRadius: 10,
      ...(listHeight
        ? {
          height: listHeight,
        }
        : {
          height: dashboardTable ? 670 : 393,
        }),
      },
      cardContents: {
      overflow: 'auto',
      ...(listHeight
        ? {
            height: actionTitle ? `calc(${listHeight} - 75px)` : listHeight,
            maxHeight: listHeight,
          }
        : {
            height: dashboardTable ? 550 : '70%',
            maxHeight: dashboardTable ? 550 : '70%',
          }),
    },
    cardHeader: {
      boxShadow: '0 1px 2px 0px rgba(0, 0, 0, .1)',
      paddingLeft: manageAlertsPage ? 0 : 20,
      borderBottom: '.5px solid #CCC',
    },
    headerTitle: {
      paddingLeft: manageAlertsPage ? 10 : 16,
      fontSize: 14,
      fontWeight: 600,
      color:
        theme.palette.type === 'light'
          ? theme.palette.grey[600]
          : theme.palette.text.primary,
    },
    margin: {
      margin: theme.spacing(1),
      transform: 'translateY(-13px)',
    },
    tableBody: {
      '& tr:nth-of-type(odd)': {
        backgroundColor: theme.palette.type === 'light' ? grey[100] : grey[900],
      },
      '& tr:nth-of-type(even)': {
        backgroundColor: theme.palette.type === 'light' ? 'white' : grey[800],
      },
    },
  }));

  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (paginate) {
      setRowsPerPage(10);
    } else {
      setRowsPerPage(0);
    }
  }, [paginate]);

  return (
    <Card elevation={2} className={classes.cardContainer} {...other}>
      {title !== undefined ? (
        <Box
          fontWeight='fontWeightMedium'
          fontSize={16}
          className={classes.cardHeader}
          >
          <CardHeader
            className={classes.headerTitle}
            title={title.toUpperCase()}
            disableTypography={true}
            action={action}
          />
        </Box>
      ) : (
        <div></div>
      )}
      <CardContent
        className={noHeight? '' : classes.cardContents}
        style={{ padding: 0, paddingTop: 0 }}
      >
        <Table style={{ height: '100%' }}>
          {columnHead ? (
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>ID</TableCell>
              </TableRow>
            </TableHead>
          ) : (
            <></>
          )}

          <TableBody
            style={{ height: '100%' }}
            className={columnHead ? classes.tableBody : ''}
          >
            {useItemBuilder
              ? buildList(
                  rowsPerPage > 0
                    ? data.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                    : data,
                  itemBuilder,
                  emptyBuilder,
                )
              : children}
          </TableBody>
        </Table>
        {paginate ? (
          <TablePagination
            rowsPerPageOptions={[10]}
            component='div'
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : (
          <></>
        )}
      </CardContent>
      {showActions ? (
        <CardActions
          style={{
            justifyContent: 'center',
            borderTop: '.5px solid #CCC',
            marginTop: 15,
          }}
        >
          <Button
            onClick={onClick}
            size='large'
            className={classes.margin}
            color='primary'
          >
            {actionTitle}
          </Button>
        </CardActions>
      ) : null}
    </Card>
  );
}

export default GenericList;
