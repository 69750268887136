import _ from 'lodash';
import React from 'react';

import SliderCard from '../../Generic/SliderCard';

export function ManageAlertSlider(props) {
  const { rangeValues, setRangeValues, rangeType, slider } = props;

  const lightMarks = [
    { value: 0, label: 'Off' },
    { value: 1, label: 'Low' },
    { value: 2, label: 'Med' },
    { value: 3, label: 'High' },
  ];

  // for the onChange Prop
  const rangeValueChange = newValue => {
    const clonedRV = _.clone(rangeValues);
    if (clonedRV) {
      switch (rangeType) {
        case 'above_value':
          clonedRV[0] = newValue;
          setRangeValues(clonedRV);
          break;
        case 'below_value':
          clonedRV[1] = newValue;
          setRangeValues(clonedRV);
          break;
        default:
          setRangeValues(newValue);
          break;
      }
    }
  };

  // For the slider value prop
  const determineValues = () => {
    if (rangeType) {
      switch (rangeType) {
        case 'above_value':
          return rangeValues[0];
        case 'below_value':
          return rangeValues[1];
        default:
          return rangeValues;
      }
    }
  };

  // for the range type props
  const determineIsRangeType = () => {
    if (rangeType && rangeType.includes('range')) return true;
    else return false;
  };

  return determineValues() || determineValues() === 0 ? (
    <SliderCard
      showMarkers={true}
      rangeType={rangeType}
      values={determineValues()}
      isRangeType={determineIsRangeType()}
      unitMeasure={slider.unitMeasure}
      maximumValue={slider.maximumValue}
      minimumValue={slider.minimumValue}
      light_markers={slider.unitMeasure === 'LuxSimple' && lightMarks}
      onChange={newValue => rangeValueChange(newValue)}
      error={{
        rangeSelDropdown: false,
        min: false,
        max: false,
      }}
    />
  ) : null;
}
