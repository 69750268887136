import _ from 'lodash';
import { navigate } from 'hookrouter';
import MUIDataTable from 'mui-datatables';
import grey from '@material-ui/core/colors/grey';

import {
  makeStyles,
  useTheme
} from '@material-ui/core/styles';

import { Box, Grid, Typography } from '@material-ui/core';

import React, { useContext, useEffect, useState } from 'react';

import { AppContext } from '../../../AppContext';
import ManageAlertSensorCell from '../ManageAlertSensorCell';

import GenericList from "../../Dashboard/GenericList";

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(1),
    cursor: 'pointer'
  },
  alertNameText: {
    // marginLeft: 25,
    fontSize: 14,
    paddingBottom: '10px',
    fontWeight: 600,
    color: theme.palette.type === 'light' ? '#747474' : '#FFF',
  },
  titleText: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.type === 'light' ? '#747474' : '#FFF',
  },
  table: {
    margin: theme.spacing(4, 0),
    '& tr:nth-of-type(odd)': {
      backgroundColor: theme.palette.type === 'light' ? grey[50] : grey[900],
    },
    '& tr:nth-of-type(even)': {
      backgroundColor: theme.palette.type === 'light' ? 'white' : grey[800],
    },
  },
}));

/**
 * returns the background color based in the
 * theme dark mode and the tone given for the colors
 * @param {Object, int} theme, lightTone=700, darkTone=300
 */
const rowColor = ({ theme, index }) => {
  if (theme.palette.type === "light") {
    return index % 2 === 0 ? grey[100] : "white";
  } else {
    return index % 2 === 0 ? grey[900] : grey[800];
  }
};

export default function ManageAlerts(props) {
  const log = window.log('AllAlerts');
  const classes = useStyles();

  const { appState } = useContext(AppContext);
  const {
    alertData,
    listHeight,
    ...other
    // smallDevice
  } = props;

  const theme = useTheme();

  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    setUserInfo(appState.auth.userInfo);
  }, [appState.auth.userInfo]);

  const onRowClick = (element) => {
    navigate(`/alert/${element['alert']['alert_id']}`);
  };


  const checkUserInfo = () => {
    if (
      userInfo.perm === 0 ||
      userInfo.perm === 2 ||
      userInfo.perm === 50 ||
      userInfo.perm === 51 ||
      userInfo.perm === 52 ||
      userInfo.perm === 99
    ) {
      return true;
    } else {
      return false;
    }
  };
  return checkUserInfo() ? (
    <GenericList
      {...other}
      listHeight={listHeight}
      data={alertData}
      paginate
      useItemBuilder={true}
      emptyBuilder={() => (
        <Box
          style={{
            backgroundColor: rowColor({ theme, index: 0}),
          }}
          className={classes.container}
        >
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              xs={12}
            >
              <Typography
                className={classes.alertNameText}
              >
                {"No Alerts"}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
      itemBuilder={(item, index) => (
        <Box
          key={index}
          style={{
            backgroundColor: rowColor({ theme, index }),
          }}
          className={classes.container}
          onClick={() => {
            onRowClick(item);
          }}
        >
          <Grid
            container
            spacing={0}
          >
            <Grid item xs={10}>
              <Grid item container spacing={0}>
                <Grid
                  item
                  xs={12}
                >
                  <Typography
                    className={classes.alertNameText}
                  >
                    {item.alert_name}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                >
                  <Box>
                    <ManageAlertSensorCell
                      sensorIcons={item.alert.sensorProfile}
                      justifyContent="flex-start"
                      isSmall={true}
                      showSensorCounter={false}
                      size={20}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={2}
              container
              alignItems="center"
            >
                <ManageAlertSensorCell
                  numberOfAlerts={item.alert.numberOfAlerts}
                  justifyContent="center"
                />
            </Grid>

          </Grid>
        </Box>
      )}
    ></GenericList>
  ) : null;
}
