import React, { useState, useEffect, useContext } from 'react';
import MUIDataTable from 'mui-datatables';
import { Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { navigate, A } from 'hookrouter';
import Pagination from '@material-ui/lab/Pagination';

//MATERIAL UI ICONS
import GatewayIcon from '@material-ui/icons/Router';

//DCS ICONS
import SensorIcon from '../../img/icons/SensorIcon';
import CustomersIcon from '../../img/icons/CustomersIcon';
import AdminDashboardIcon from '../../img/icons/AdminDashboardIcon';

//COMPONENTS
import HeaderBar from '../Generic/HeaderBar';
import ResellerCard from './ResellerCard';
import LoadingSpinner from '../Generic/LoadingSpinner';
import AddResellerFormModal from './AddResellerFormModal';
import { AppContext } from './../../AppContext';

const useStyles = makeStyles(theme => ({
  root: {},
  tableTitle: {
    marginLeft: '1rem',
    fontWeight: 500,
    fontSize: '1.5rem',
  },
  breadcrumbs: {
    color: theme.palette.text.primary,
  },
}));

const AllResellers = () => {
  const log = window.log('AllResellers');
  const theme = useTheme();
  const classes = useStyles();

  const { appState, setLogoutState, setOpenResellerModal, snack } = useContext(
    AppContext,
  );

  const [state, setState] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [listView, setlistView] = useState(false);
  const [initialPageLoad, setInitialPageLoad] = useState(true);

  let breadcrumbs = (
    <div style={{ display: 'inline-flex' }}>
      <A className={classes.breadcrumbs} href='/admin'>
        Dashboard
      </A>
      <div>{'\xa0-\xa0'}</div>
    </div>
  );

  const options = {
    onRowClick: selectedRow => {
      navigate(`/admin/reseller/${selectedRow[0]}`);
    },
    responsive: 'standard',
    selectableRows: 'none',
    search: false,
    fixedHeader: false,
  };

  const filterColumns = ['company_name', 'address1', 'city', 'state', 'zip'];

  const columns = [
    {
      name: 'id',
      options: {
        viewColumns: false,
        display: false,
        filter: false,
      },
    },
    {
      name: 'company_name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'totalCompanyCount',
      label: 'Companies',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'totalGatewayCount',
      label: 'Gateways',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'totalSensorCount',
      label: 'Sensors',
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  const handleSortChange = () => {
    setlistView(!listView);
  };

  const getResellers = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/admin/get-reseller',
        {
          headers: {
            Authorization: appState.auth.token,
          },
        },
      );

      const json = await response.json();
      if (json.success) {
        setState(json.data.resellers);
        setOriginalData(json.data.resellers);
      } else {
        json.errors.forEach(err => {
          snack(err.msg, 'error');
          if (err.type === 'token') setLogoutState(true);
        });
      }
    } catch (err) {
      snack('Network Error', 'error');
    }
    setInitialPageLoad(false);
  };

  useEffect(() => {
    getResellers();
  }, []);

  //  Pagination
  const itemsPerPage = 10;
  const [page, setPage] = React.useState(1);

  const handleChange = (event, value) => {
    setPage(value);
  };

  return initialPageLoad ? (
    <LoadingSpinner />
  ) : (
    <div className={classes.root}>
      <AddResellerFormModal getResellers={getResellers} />
      {/* Generic Search  Bar */}
      <HeaderBar
        leadingIcon={
          <AdminDashboardIcon style={{ fontSize: 32 }} color='primary' />
        }
        breadcrumbs={breadcrumbs}
        title='Resellers'
        buttonAddTitle='ADD RESELLER'
        buttonAddEvent={() => setOpenResellerModal(true)}
        searchCallback={value => {
          log('Inside of search callback ', state);
          let filteredArray = [];
          //Iteratate over every location object
          for (var i = 0; i < originalData.length; i++) {
            let match = false;
            log(originalData[i]);
            //Iterate over every filter condtion
            for (var j = 0; j < filterColumns.length; j++) {
              log('Look ' + originalData[i][filterColumns[j]]);
              //Check for match
              if (
                originalData[i][filterColumns[j]]
                  .toLowerCase()
                  .includes(value.toLowerCase())
              ) {
                match = true;
              }
            }
            //If match add to filtered array
            if (match) {
              filteredArray.push(originalData[i]);
            }
          }
          log('Filter ', filteredArray);
          setState(filteredArray);
        }}
        sortActive={listView}
        sortButton={true}
        sortButtonEvent={handleSortChange}
      />
      <Grid container spacing={2}>
        {state ? (
          listView ? (
            <Grid xs={12}>
              <MUIDataTable
                title={'Resellers'}
                data={state}
                columns={columns}
                options={options}
              />
            </Grid>
          ) : (
            state
              .slice((page - 1) * itemsPerPage, page * itemsPerPage)
              .map(item => (
                <Grid item xs={12} sm={6} key={item.id}>
                  <ResellerCard
                    id={item.id}
                    title={`${item.company_name}`}
                    street={`${item.address1}`}
                    city={`${item.city}`}
                    state={`${item.state}`}
                    zip={`${item.zip}`}
                    phone={item.phone}
                    trailingItems={[
                      {
                        value: item.totalCompanyCount,
                        title: 'Customer',
                        icon: (
                          <CustomersIcon
                            style={{ fontSize: 28, color: '#FF6A6A' }}
                          />
                        ),
                      },
                      {
                        value: item.totalGatewayCount,
                        title: 'Gateway',
                        icon: (
                          <GatewayIcon
                            style={{ fontSize: 28, color: '#59C754' }}
                          />
                        ),
                      },
                      {
                        value: item.totalSensorCount,
                        title: 'Sensor',
                        icon: (
                          <SensorIcon
                            style={{
                              fontSize: 28,
                              color: theme.palette.primary.main,
                            }}
                          />
                        ),
                      },
                    ]}
                  />
                </Grid>
              ))
          )
        ) : null}
        {!listView && (
          <Grid item xs={12} container justify='center'>
            <Pagination
              variant='outlined'
              count={Math.ceil(state.length / itemsPerPage)}
              page={page}
              onChange={handleChange}
              defaultPage={1}
              showFirstButton
              showLastButton
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default AllResellers;
