import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Grid, Divider } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

// import { ProratedPlan } from './ProratedPlan';

const useStyles = makeStyles((theme, textColor) => ({
  cardValueText: {
    color: textColor && textColor({ theme, lightTone: 500, darkTone: '#FFF' }),
  },
}));

export function OrderSummary(props) {
  const { textColor, selSubLevel, selectedPlan, currentSubscription } = props;

  const theme = useTheme();
  const classes = useStyles(theme, textColor);
  const [state, setState] = useState({
    subLevel: '0.00',
    sensorPrice: '0.00',
    subTotal: '0.00',
    tax: '0.00',
    total: '0.00',
    proratedPrice: 0,
    from: new Date(),
    to: new Date(),
  });

  useEffect(() => {
    determinePrice();
  }, [selectedPlan, selSubLevel]);

  const salesTax = 0;

  const findProratePrice = () => {
    let proratedPlan = null;
    if (!currentSubscription.proratedPricePlans) return;
    for (const plan of currentSubscription.proratedPricePlans) {
      if (plan.id.split('-')[0] === selectedPlan.id.split('-')[0]) {
        proratedPlan = _.clone(plan.proratedPriceInfo);
        proratedPlan.proratedPrice = proratedPlan.proratedPrice[selSubLevel];
        break;
      }
    }
    if (!proratedPlan) return;
    return proratedPlan;
  };

  const determinePrice = () => {
    const prices = {
      subLevel: 0,
      sensorPrice: 0,
      subTotal: 0,
      tax: 0,
      total: 0,
      proratedPrice: 0,
    };

    let proratedPlan;
    if (selectedPlan) {
      // configure subscription price based on current level of sensors
      prices.subLevel = _.find(currentSubscription.proratedPricePlans, {
        id: currentSubscription.planInfo.id.split('-')[0],
      }).price[selSubLevel];

      // Configre sensor price based on selected subscription
      prices.subTotal = _.find(currentSubscription.proratedPricePlans, {
        id: selectedPlan.id.split('-')[0],
      }).price[selSubLevel];

      prices.sensorPrice = prices.subTotal - prices.subLevel;

      prices.tax = (salesTax / 100) * prices.subTotal;

      proratedPlan = findProratePrice();
      prices.proratedPrice = proratedPlan.proratedPrice;
      prices.total = proratedPlan.proratedPrice;
    }

    // Set all to dollar format
    _.forEach(prices, (price, key) => {
      prices[key] = `$${price.toFixed(2)}`;
    });

    setState({ ...prices, from: proratedPlan.from, to: proratedPlan.to });
  };

  return (
    <Grid item xs={12} container style={{ margin: '0 47px' }}>
      <Grid item xs={12} container className={classes.cardValueText}>
        <Grid item xs={6}>
          <p>{`${_.upperFirst(_.toLower(selSubLevel))} Plan Subscription`}</p>
        </Grid>
        <Grid item xs={6} container justify='flex-end'>
          <p style={{ fontWeight: 'bold' }}>{state.subLevel}</p>
        </Grid>
        <Grid item xs={6}>
          <p>{selectedPlan.description || ''}</p>
        </Grid>
        <Grid item xs={6} container justify='flex-end'>
          <p style={{ fontWeight: 'bold' }}>{state.sensorPrice}</p>
        </Grid>
        <Grid item xs={12} container>
          <Divider
            variant='middle'
            style={{ width: '100%', margin: '0 auto' }}
          />
        </Grid>
        <Grid item xs={6}>
          <p style={{ fontWeight: 'bold' }}>Subtotal</p>
        </Grid>
        <Grid item xs={6} container justify='flex-end'>
          <p style={{ fontWeight: 'bold' }}>{state.subTotal}</p>
        </Grid>
        <Grid item xs={12} container>
          <Divider
            variant='middle'
            style={{
              width: '100%',
              margin: '0 auto',
              marginBottom: '1em',
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <p>
            {`Total prorated to ${new Date(state.to).toLocaleDateString()}`}
          </p>
        </Grid>
        <Grid item xs={6} container justify='flex-end'>
          <p style={{ fontWeight: 'bold' }}>{state.proratedPrice}</p>
        </Grid>
      </Grid>
    </Grid>
  );
}
