export const listOfPlans = [
  {
    tier: 1,
    id: 'MSLIC0612',
    price: 49,
    sensors: 6,
    name: 'MS-LIC-06-12',
    description: 'MiSensors Annual License (Up to 6 Sensors)',
    text: '(Up to 6 Sensors)',
  },
  {
    tier: 2,
    id: 'MSLIC1212',
    price: 69,
    sensors: 12,
    name: 'MS-LIC-12-12',
    description: 'MiSensors Annual License (Up to 12 Sensors)',
    text: '(Up to 12 Sensors)',
  },
  {
    tier: 3,
    id: 'MSLIC2512',
    price: 99,
    sensors: 25,
    name: 'MS-LIC-25-12',
    description: 'MiSensors Annual License (Up to 25 Sensors)',
    text: '(Up to 25 Sensors)',
  },
  {
    tier: 4,
    id: 'MSLIC5012',
    price: 149,
    sensors: 50,
    name: 'MS-LIC-50-12',
    description: 'MiSensors Annual License (Up to 50 Sensors)',
    text: '(Up to 50 Sensors)',
  },
  {
    tier: 5,
    id: 'MSLIC10012',
    price: 249,
    sensors: 100,
    name: 'MS-LIC-100-12',
    description: 'MiSensors Annual License (Up to 100 Sensors)',
    text: '(Up to 100 Sensors)',
  },
];
