import { navigate } from 'hookrouter';
import React, { useState, useContext } from 'react';
import {
  Box,
  Grid,
  Checkbox,
  makeStyles,
  LinearProgress,
  FormControlLabel,
} from '@material-ui/core';

import { AppContext } from './../../AppContext';
import GenericDialog from '../Generic/GenericDialog';

const useStyles = makeStyles(theme => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
  },
  locationSelect: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
  },
  fieldPadding: {
    paddingBottom: theme.spacing(4),
  },
}));

/**
 * Gateway Form Modal
 * @param {Object} props
 * @props
 * openState: Boolean
 * handleClose: Functions
 */
export default function EditGatewayFormModal(props) {
  const log = window.log('DeleteUserFormModal');

  const {
    snack,
    appState,
    setLogoutState,
    openDeleteUserModal,
    setOpenDeleteUserModal,
  } = useContext(AppContext);
  const { userInfo, getUsers } = props;
  const classes = useStyles();
  //Gateway name state hooks

  //checkbox state
  const [confirmed, setConfirmed] = useState(false);

  const handleDeleteUser = async () => {
    log('Inside of handle delete user\t' + userInfo.id);
    //A user cannont delete themselves

    log('User Delete: Inside of handle delete user');

    try {
      const data = {
        userId: userInfo.id,
        input: confirmed,
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/user/delete`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
          body: JSON.stringify(data),
        },
      );
      const json = await response.json();

      log('User delete: Looking at the json response ', json);

      if (json.success) {
        if (json.errors.length) {
          snack(json.errors[0].msg, 'error');
        }
        getUsers && getUsers();
        return true;
      } else {
        json.errors.forEach(error => {
          //err alert
          snack(error.msg, 'error');
          if (error.type === 'token') setLogoutState(true);
        });
        return false;
      }
    } catch (err) {
      log(err);
      return false;
    }
  };

  const handleClose = () => {
    setOpenDeleteUserModal(false);
  };

  const handleFinish = () => {
    setOpenDeleteUserModal(false);
    navigate(`/user/all`);
  };

  return (
    <div>
      <GenericDialog
        openState={openDeleteUserModal}
        handleClose={handleClose}
        handleCancel={handleClose}
        handleFinish={handleFinish}
        title='DELETE USER'
        stepsArray={[
          {
            label: 'DELETE USER',
            nextFunction: handleDeleteUser,
            validator: true,
            showProgress: true,
            saveButtonStep: true,
            child: (
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Box fontWeight='fontWeightBold' fontSize={20}>
                    Are you sure you want to delete this user ?
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  fontWeight='fontWeightLight'
                  fontSize={16}
                  style={{ marginTop: 10 }}
                ></Grid>
                <FormControlLabel
                  control={
                    <Checkbox
                      data-testid='delete_input_checkbox'
                      checked={confirmed}
                      onChange={e => setConfirmed(e.target.checked)}
                      name='delete'
                      color='primary'
                    />
                  }
                  label='Yes'
                />
              </Grid>
            ),
          },
          {
            label: 'LOADING',
            child: (
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Box
                    fontWeight='fontWeightBold'
                    fontSize={20}
                    className={classes.fieldPadding}
                  >
                    Deleting gateway from platform
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  fontWeight='fontWeightLight'
                  fontSize={16}
                  style={{ marginTop: 10 }}
                  className={classes.fieldPadding}
                >
                  <Box>MiSensors is currently deleting your user.</Box>

                  <Grid
                    item
                    xs={12}
                    style={{ marginTop: 10 }}
                    className={classes.fieldPadding}
                  >
                    <LinearProgress />
                  </Grid>
                </Grid>
              </Grid>
            ),
          },
          {
            label: 'COMPLETE', // Because is the finish
            child: (
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Box fontWeight='fontWeightBold' fontSize={20}>
                    Success! Your User has been deleted.
                  </Box>
                  <Box style={{ marginTop: '1rem' }}>
                    Select finish to return to the Users page.
                  </Box>
                </Grid>
              </Grid>
            ),
          },
        ]}
      />
    </div>
  );
}
