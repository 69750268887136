import _ from 'lodash';
import React, { useEffect, useState, useContext } from 'react';

import { AlertContext } from '../AlertContext';
import { AppContext } from '../../../AppContext';
import { ManageAlertSlider } from './ManageAlertSlider';
import { ManageAlertDropDown } from './ManageAlertDropDown';
import SensorValueCard from '../../Generic/SensorValueCard';
import ModalFormHeader from '../../common/ModalComponents/ModalFormHeader';
import { calculateLuxDisplay } from '../../common/helpers/calculateLuxDisplay';

export function ManageAlertSettings(props) {
  const { setValueChanged, baselineSensorSel, manageButtonClick } = props;
  const { appState } = useContext(AppContext);
  const { modalState } = useContext(AlertContext);

  const [rangeType, setRangeType] = useState();
  const [rangeValues, setRangeValues] = useState();

  useEffect(() => {
    if (modalState?.alertObj?.item?.slider) {
      const { slider } = modalState.alertObj.item;
      determineRangeType(slider);
      setRangeValues(slider.value.map(x => +x)); // Sets both values in the array to number type
    }

    if (modalState?.alertObj?.item?.doorAlertValue) {
      setRangeType(modalState.alertObj.item.doorAlertValue);
    }
  }, [modalState]);

  useEffect(() => {
    setValueChanged({ rangeType, rangeValues });
  }, [rangeType, rangeValues]);

  /**
   * determines correct value of rangeType
   * @param {*} slider
   */
  const determineRangeType = slider => {
    let rangeSubtitle = _.snakeCase(slider.subtitle);
    if (rangeSubtitle.includes('of')) {
      rangeSubtitle = rangeSubtitle.split('_of').join('');
    }
    setRangeType(rangeSubtitle);
  };

  // Adds baseline to component if the alert uses a range
  const setBaseLine = () => {
    if (modalState.alertObj.type === 'range') {
      const { icon, title, name } = modalState.alertObj.item;
      const clonedIcon = {
        ...icon,
        props: { ...icon.props, style: { height: 40, width: 40 } },
      };

      let baselineValue;

      if (
        !_.isEmpty(baselineSensorSel) &&
        baselineSensorSel.lastSensorMessage['light']
      ) {
        if (name === 'light_detection' && !appState.userSettings.advancedLux) {
          baselineValue = calculateLuxDisplay(
            baselineSensorSel.lastSensorMessage['light'],
          );
        } else {
          baselineValue = baselineSensorSel.lastSensorMessage[name];
        }
      }

      return (
        <SensorValueCard
          hasButton={!_.isEmpty(baselineSensorSel) ? false : true}
          icon={clonedIcon}
          readingName={
            !_.isEmpty(baselineSensorSel) ? 'Baseline Reading' : null
          }
          sensorName={
            !_.isEmpty(baselineSensorSel) ? baselineSensorSel.sensorName : title
          }
          sensorValue={baselineValue}
          manageButtonClick={manageButtonClick}
        />
      );
    }
  };

  // Adds dropdown to component
  const setDropDown = () => {
    if (modalState.alertObj.type && rangeType) {
      return (
        <ManageAlertDropDown
          rangeType={rangeType}
          setRangeType={setRangeType}
          name={modalState.alertObj.item.name}
        />
      );
    }
  };

  // Adds slider card to component if alert uses range
  const setSliderCard = () => {
    if (modalState.alertObj.type === 'range') {
      const { slider } = modalState.alertObj.item;
      return (
        <ManageAlertSlider
          slider={slider}
          rangeType={rangeType}
          rangeValues={rangeValues}
          setRangeValues={setRangeValues}
        />
      );
    }
  };

  return (
    <>
      <ModalFormHeader
        header={`Alert Range Settings - ${modalState.alertObj.item.title}`}
        desc='Define when an alert notification will be sent to the recipient'
      />
      {setBaseLine()}
      {setDropDown()}
      {setSliderCard()}
    </>
  );
}
