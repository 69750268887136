import React, { useContext } from 'react';
import { Button, Grid } from '@material-ui/core';

import { AppContext } from '../../../AppContext';
import LocationSelect from '../../common/LocationSelect';
import ModalFormHeader from '../../common/ModalComponents/ModalFormHeader';

/**
 * Gateway Form Modal
 * @param {Object} props
 * @props
 * locationId: string
 * setLocationId: () => void
 */
export default function SelectLocation(props) {
  const { setOpenLocationModal } = useContext(AppContext);
  const { locationId, setLocationId } = props;
  return (
    <Grid container item xs={12} spacing={2}>
      <ModalFormHeader
        header='Select the Location of your gateway'
        desc='Select the location where you have installed your gateway.'
      />
      <Grid item xs={12}>
        <Button
          style={{
            width: '105%',
          }}
          onClick={() => setOpenLocationModal(true)}
          variant='outlined'
          color='primary'
        >
          NO LOCATION CREATED? ADD A NEW LOCATION HERE
        </Button>
      </Grid>
      <Grid item xs={12}>
        <LocationSelect
          returnValue={locationId}
          setReturnValue={setLocationId}
          width={'100%'}
        />
      </Grid>
    </Grid>
  );
}
