import React, { createContext, useContext, useState } from 'react';
import { useEffect } from 'react';

import { AppContext } from '../../AppContext';
import fetchAll from '../common/api/fetchAll';
import { saveAlertChange } from './ManageAlertModalComp/saveAlertChange';

export const AlertContext = createContext({});

export const AlertProvider = props => {
  const { snack, appState, setLogoutState } = useContext(AppContext);

  const [allUsers, setAllUsers] = useState([]); // used for the assigned users list, and the edit users modal
  const [alertInfo, setAlertInfo] = useState({});
  const [valueChanged, setValueChanged] = useState(); // universal value changed saved from right side button

  // triggers useEffect in ManageAlert.js
  const [triggerFromContext, setTriggerFromContext] = useState(false);

  const [modalState, setModalState] = useState({
    alertObj: {},
    isOpen: false,
    editType: null,
  });

  useEffect(() => {
    if (modalState.isOpen) setValueChanged(null);
  }, [modalState.isOpen]);

  const getUsers = async () => {
    const json = await fetchAll(
      'user',
      appState.auth.userInfo.companyId,
      appState.auth.token,
    );

    if (json.success) setAllUsers(json.data.users);
    else {
      json.errors.forEach(err => {
        snack(err.msg, 'error');
        if (err.type === 'token') setLogoutState(true);
      });
    }
  };

  /**
   * Saves values into db
   * props are in the case of no modalstate
   * @param {} newEditType
   * @param {*} newValueChanged
   * @returns
   */
  const saveValueChange = async (editType, newValueChanged = null) => {
    if (!editType) return;

    if (newValueChanged || valueChanged) {
      const response = await saveAlertChange(
        editType,
        alertInfo,
        appState,
        newValueChanged ? newValueChanged : valueChanged,
        editType === 'alert' ? modalState.alertObj.item.name : null,
      );
      if (response === true) {
        snack('Update successful', 'success');
      } else {
        response.forEach(error => {
          snack(error.msg, 'error');
        });
      }
    }
    setModalState({ alertObj: {}, isOpen: false });
    setTriggerFromContext(!triggerFromContext);
  };

  return (
    <AlertContext.Provider
      value={{
        alertInfo,
        setAlertInfo,
        allUsers,
        getUsers,
        modalState,
        setModalState,
        setValueChanged,
        saveValueChange,
        triggerFromContext,
      }}
    >
      {props.children}
    </AlertContext.Provider>
  );
};
