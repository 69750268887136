import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

export const FullViewContainer = props => {
  const useStyles = makeStyles(theme => ({
    fullViewContainer: {
      overflow: 'hidden',
      height: 'calc(100vh - 55px)',
      margin: 0,
      padding: 0,
    },
  }));
  const classes = useStyles();

  return <div className={classes.fullViewContainer}>{props.children}</div>;
};

export const ListViewContainer = props => {
  const { marginTop = 0 } = props;

  const useStyles = makeStyles(theme => ({
    listViewContainer: {
      overflowY: 'scroll',
      overflowX: 'hidden',
      height: `calc(100vh - ${106 + marginTop}px)`,
      margin: 0,
      padding: 0,
      marginTop: marginTop,
    },
  }));
  const classes = useStyles();

  return <div className={classes.listViewContainer}>{props.children}</div>;
};
