import React, { useState, useEffect, useContext } from 'react';
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AppContext } from './../../AppContext';

import fetchAll from '../common/api/fetchAll';
import GatewaySelectSkeleton from '../common/GatewaySelectSkeleton';

const useStyles = makeStyles(theme => ({
  // formControl: {
  //   marginTop: 8,
  // },
  outlined: {
    transform: 'translate(14px, 20px) scale(.85)',
  },
  select: {
    padding: theme.spacing(3, 2),
  },
  selectMenu: {
    maxHeight: 58,
    backgroundColor: theme.palette.background.paper,
  },
}));

const LocationSelect = props => {
  const log = window.log('LocationSelect');

  const {
    appState,
    setLogoutState,
    openEditLocationModal,
    openLocationModal,
    snack,
  } = useContext(AppContext);
  const {
    setOriginalData,
    filteredList = [],
    selectedGateway,
    selectedSensor,
    resetState,
    returnValue,
    setReturnValue,
    width,
    optional,
  } = props;

  const inputLabel = React.useRef(null);

  const classes = useStyles();

  const [locationNames, setLocationNames] = useState([]);
  //   const [gatewayValue, setGatewayValue] = useState();

  const getLocations = async () => {
    log('Location_select: Inside of get locations');

    try {
      const json = await fetchAll(
        'location',
        appState.auth.userInfo.companyId,
        appState.auth.token,
      );

      log('Location_select: Setting location names ', json);

      if (json.success) {
        if (setOriginalData) {
          setOriginalData(json.data.locations);
        }
        setLocationNames(json.data.locations);
        return true;
      } else {
        setLocationNames([]);
        json.errors.forEach(err => {
          snack(err.msg, 'error');
          if (err.type === 'token') setLogoutState(true);
        });
        return false;
      }
    } catch (err) {
      log('------: getLocations -> err', err);
      snack('Network Error', 'error');
    }
  };

  useEffect(() => {
    getLocations();
  }, [openLocationModal, openEditLocationModal]);

  return (
    <>
      {locationNames ? (
        <FormControl
          style={{ width }}
          variant='outlined'
          className={classes.formControl}
        >
          <InputLabel
            classes={{ outlined: classes.outlined }}
            ref={inputLabel}
            id='demo-simple-select-outlined-label'
          >
            Select Location {optional ? '(Optional)' : ''}
          </InputLabel>
          <Select
            className={classes.selectMenu}
            labelid='demo-simple-select-outlined-label'
            id='demo-simple-select-outlined'
            value={returnValue}
            name='location'
            onChange={setReturnValue}
            labelWidth={optional ? 200 : 150}
          >
            <MenuItem value={resetState ? 'reset' : ''}>
              <Grid>
                <Typography>
                  {resetState ? 'Reset' : 'Select Location'}
                </Typography>
              </Grid>
            </MenuItem>
            {filteredList.length || selectedGateway || selectedSensor
              ? filteredList.map((item, index) => (
                  <MenuItem value={item.id} key={index}>
                    <Grid>
                      <Typography noWrap>
                        {item.name ? item.name : item.street}
                      </Typography>
                    </Grid>
                  </MenuItem>
                ))
              : locationNames.map((item, index) => (
                  <MenuItem value={item.id} key={index}>
                    <Grid>
                      <Typography>
                        {item.name ? item.name : item.street}
                      </Typography>
                    </Grid>
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
      ) : (
        <GatewaySelectSkeleton />
      )}
    </>
  );
};

export default LocationSelect;
