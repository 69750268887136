import React from 'react';
import { Grid, TextField, makeStyles } from '@material-ui/core';

import ModalFormHeader from '../../common/ModalComponents/ModalFormHeader';

const useStyles = makeStyles(theme => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    height: 55,
    color:
      theme.palette.type === 'light'
        ? theme.palette.grey[600]
        : theme.palette.text.primary,
  },
  helperText: {
    margin: 0,
    marginRight: 14,
    marginLeft: 14,
    marginTop: 3,
    color: 'rgba(0, 0, 0, 0.54)',
    textAlign: 'left',
    lineHeight: 1.66,
    fontWeight: 400,
  },
}));

/**
 * Set Alert Name
 * @param {name: string, nameCallback: ()=>void, nameError: bool} props
 */
export default function AlertName(props) {
  const classes = useStyles();
  const { name, nameCallback, nameError, isEdit } = props;

  return (
    <Grid item xs={12} style={{ height: '100%' }}>
      <ModalFormHeader
        header={isEdit ? 'Edit your alert name' : 'Alert Name'}
        desc='Provide a unique name for your Alert. You will likely need a descriptive name to help you manage your Alert definitions.'
      />
      <Grid item xs={12} style={{ marginTop: 25 }}>
        <TextField
          className={classes.textField}
          fullWidth
          variant='outlined'
          placeholder='Alert Name'
          label='Alert Name'
          value={name}
          error={nameError}
          onChange={event => {
            nameCallback('name', event.target.value);
          }}
          inputProps={{
            maxLength: 60,
          }}
        />
        <p className={classes.helperText}>{`${name.length}/60`}</p>
      </Grid>
    </Grid>
  );
}
