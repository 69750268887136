import React from 'react';

import {
  Badge,
  withStyles,
} from '@material-ui/core';

const StatusBadge = (props) => {
  const { conditional, children, ...other } = props

  const CustomBadge = withStyles((theme) => ({
    anchorOriginBottomRightCircle: "", // To remove error from console
    badge: {
      width: 16,
      height: 16,
      borderRadius: "50%",
      backgroundColor: conditional ? "#59C754" : "#FF6A6A",
      color: conditional ? "#59C754" : "#FF6A6A",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "$ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": conditional && {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }))(Badge);

  return (<CustomBadge {...other}>
    { children}
  </CustomBadge>)
}

export default StatusBadge