import React from 'react';
import { Button } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  mobileBottomButton: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    height: 45,
    borderRadius: '30px 30px 0px 0px',
    zIndex: 3,
  },
  plusButton: {
    width: '100%',
    position: 'fixed',
    bottom: 85,
    height: 85,
    alignText: 'center',
    display: 'inline',
  },
  mobileSpacer: {
    content: '',
    height: '45px',
    width: '100%',
  },
  icon: {
    margin: 'auto',
    color: 'grey',
    marginLeft: 'calc(50vw - 24px)',
  },
}));

export default function MobileBottomButton(props) {
  const classes = useStyles();

  const { onClick } = props;

  return (
    <Button
      variant='contained'
      color='primary'
      onClick={onClick}
      className={classes.mobileBottomButton}
    >
      <AddCircleOutlineIcon onClick={onClick} />
    </Button>
  );
}
export function MobilePlusButton(props) {
  const classes = useStyles();
  const { onClick } = props;

  return (
    <div className={classes.plusButton}>
      <AddCircleOutlineIcon
        onClick={() => {
          onClick();
        }}
        className={classes.icon}
      />
    </div>
  );
}

export function MobileBottomButtonSpacer(props) {
  const classes = useStyles();

  return <div className={classes.mobileSpacer} />;
}
