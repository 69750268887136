import React, { useState, useEffect, useContext } from 'react';
import 'braintree-web';
import DropIn from 'braintree-web-drop-in-react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { navigate } from 'hookrouter';
import insertOne from '../common/api/insertOne';
import LocationForm from '../common/LocationForm/LocationForm';
import {
  Button,
  Avatar,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  Card,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { AppContext } from '../../AppContext';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '3rem 0 6rem 0',
  },
  button: {
    backgroundColor: theme.palette.secondary.main,
    width: '6rem',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    backgroundColor: theme.palette.secondary.main,
    margin: theme.spacing(3, 0, 2),
  },
}));

function Copyright() {
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {'Copyright © '}
      <Link color='inherit' href='https://www.dcsbusiness.com/'>
        DCS Business
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const PaymentPage = () => {
  const log = window.log('PaymentPage');

  const { setLogoutState, snack } = useContext(AppContext);
  const classes = useStyles();

  const [instance, setInstance] = useState();
  const [clientToken, setClientToken] = useState();
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [pw1, setPW1] = useState('');
  const [pw2, setPW2] = useState('');

  const [streetError, setStreetError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [zipcodeError, setZipcodeError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const url = new URL(window.location);
  const token = url.searchParams.get('token');

  const getClientToken = async () => {
    const data = {
      companyId: '',
    };
    try {
      // Get a client token for authorization from server
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/payment/get-token`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ data }),
        },
      );
      const json = await response.json();

      if (json.success) {
        setClientToken({ clientToken: json.clientToken });
      } else {
        json.errors.forEach(err => {
          snack(err.msg, 'error');
          if (err.type === 'token') setLogoutState(true);
        });
      }
    } catch (error) {
      log(error);
    }
  };

  const submitLocation = async companyId => {
    try {
      const data = {
        companyId,
        street,
        city,
        state,
        zipcode,
        primary: true,
      };
      log(data);

      const json = await insertOne('location', data, token);
      if (json.success) {
        snack('User information successfully updated', 'success');
        setStreetError(false);
        setCityError(false);
        setStateError(false);
        setZipcodeError(false);
        setPasswordError(false);
        navigate('/');
      } else {
        let errorObj = {};
        json.errors.forEach(error => {
          if (error.type === 'street') {
            setStreetError(true);
          }
          if (error.type === 'city') {
            setCityError(true);
          }
          if (error.type === 'state') {
            setStateError(true);
          }
          if (error.type === 'zipcode') {
            setZipcodeError(true);
          }
          if (error.type === 'password') {
            setPasswordError(true);
          }
          //add error to err object
          errorObj = { ...errorObj, [error.type]: true };
          //err alert
          snack(error.msg, 'error');
        });
      }
    } catch (err) {
      log(err);
    }
  };

  const submitPurchase = async () => {
    try {
      // Send the nonce to your server
      const { nonce } = await instance.requestPaymentMethod();

      const data = {
        pw1,
        pw2,
        userToken: token,
        nonce,
        street,
        city,
        state,
        zipcode,
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/payment/sandbox`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ data }),
        },
      );
      const json = await response.json();
      log(json);
      if (json.success) {
        snack('Payment successfully processed', 'success');

        handleOnSubmit(json.customer);
      } else {
        log(json);
        snack('Please check all information is correct', 'error');
        json.errors.forEach(err => {
          if (err.type === 'token') {
            snack(err.msg, 'error');
            setLogoutState(true);
          }
        });
      }
    } catch (err) {
      console.error(err.message);
      snack(err.message, 'error');
    }
  };

  const handleOnSubmit = async user => {
    const data = {
      company: user.company,
      fname: user.firstName,
      lname: user.lastName,
      email: user.email,
      pw: pw1,
      companyId: user.id,
    };
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/auth/signup-payment',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        },
      );
      const json = await response.json();

      if (json.success) {
        // snack("Payment successfully processed", "success");
        submitLocation(user.id);
      } else {
        snack('Please check all information is correct', 'error');
        json.errors.forEach(err => {
          if (err.type === 'token') {
            snack(err.msg, 'error');
            setLogoutState(true);
          }
        });
      }
    } catch (err) {
      console.error(err.message);
      snack(err.message, 'error');
    }
  };

  useEffect(() => {
    getClientToken();
  }, []);

  return (
    <Card className={classes.root}>
      {!token ? (
        <Container component='main' maxWidth='xs'>
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component='h1' variant='h5'>
              Sign up
            </Typography>
            <form className={classes.form} noValidate>
              <Grid container spacing={2} justify='center'>
                <LocationForm
                  setStreet={setStreet}
                  setCity={setCity}
                  setState={setState}
                  setZipcode={setZipcode}
                  street={street}
                  city={city}
                  state={state}
                  zipcode={zipcode}
                  streetError={streetError}
                  cityError={cityError}
                  stateError={stateError}
                  zipcodeError={zipcodeError}
                />
                <Grid item xs={6}>
                  <TextField
                    variant='outlined'
                    type='password'
                    error={passwordError}
                    label='Create Password'
                    value={pw1}
                    className={classes.textField}
                    onChange={e => setPW1(e.currentTarget.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant='outlined'
                    type='password'
                    error={passwordError}
                    disabled={pw1.length < 8}
                    label='Re-enter Password'
                    value={pw2}
                    className={classes.textField}
                    onChange={e => setPW2(e.currentTarget.value)}
                  />
                </Grid>

                {clientToken ? (
                  <Grid item sx={12}>
                    <DropIn
                      options={{
                        authorization: clientToken.clientToken,
                        preselectVaultedPaymentMethod: true,
                      }}
                      onInstance={instance => setInstance(instance)}
                    />
                  </Grid>
                ) : (
                  <h1>loading...</h1>
                )}
                {/* <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={primary}
                        onChange={handlePrimaryCheck}
                        color="primary"
                      />
                    }
                    label="Set as primary location."
                  />
                </Grid> */}
              </Grid>
              <Button
                fullWidth
                variant='contained'
                className={classes.submit}
                onClick={submitPurchase}
              >
                Sign Up
              </Button>
              <Grid container justify='flex-end'>
                <Grid item>
                  <Link href='#' variant='body2'>
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
          <Box mt={5}>
            <Copyright />
          </Box>
        </Container>
      ) : (
        navigate('/')
      )}
    </Card>
  );
};

export default PaymentPage;
