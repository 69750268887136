import _ from 'lodash';

export const stringValueHelper = value => {
  if (parseFloat(value).toFixed(2) === 'NaN') {
    return value;
  } else {
    return parseFloat(value).toFixed(2);
  }
};

/**
 *
 * @param {string} key is an object key in a device record
 * @param {obj} data and array of devices message objects
 * @returns and object with keys of max and min
 */
export const getMinMaxValuesPerDiem = (key, data) => {
  let maxValue = _.maxBy(data, item => {
    return parseFloat(item[key]);
  });
  let minValue = _.minBy(data, item => {
    return parseFloat(item[key]);
  });
  return { max: maxValue?.[key], min: minValue?.[key] };
};
