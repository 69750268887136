import _ from 'lodash';
import React from 'react';
import { Box, Button, Grid, makeStyles } from '@material-ui/core';

/**
 * Image Button
 * @param {Object} props
 * @props
 * id: Integer
 * image: Image
 * action: String,
 * onClick: String,
 * text: String
 */
export default function ImageButton(props) {
  const {
    image, // Image in the button
    action, // Action of the button
    onClick, // onClick Handle
    text, // Title in the button
    color, // Color of the background of the button
    uploadHandler, // Function - Add file type input field
    component,
    isComponent, // For component images
    uploadedImg = true,
    disabled, // Disable button
  } = props;

  const useStyles = makeStyles(theme => ({
    margin: {
      width: '100%',
      // paddingTop: theme.spacing(5),
      // paddingBottom: theme.spacing(5),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      // marginRight: theme.spacing(2),
      backgroundColor: color,
      textAlign: 'center',
      height: 200,
      [theme.breakpoints.down('xs')]: {
        height: 140,
      },
    },
    container: {
      width: '75%',
    },
    img: {
      width: '100%',
      height: 45,
      margin: 0,
      [theme.breakpoints.down('xs')]: {
        height: 30,
      },
    },
    uploadedImg: {
      height: 45,
      margin: 0,
      [theme.breakpoints.down('xs')]: {
        height: 30,
      },
    },
    text: {
      fontSize: 16,
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
      },
    },
  }));
  const classes = useStyles();

  return (
    <Button
      disabled={disabled}
      variant='outlined'
      color='primary'
      size='small'
      component={component || 'button'}
      className={classes.margin}
      onClick={onClick}
      id={`add_modal_${_.snakeCase(text)}`}
    >
      {uploadHandler ? (
        <input
          type='file'
          style={{ display: 'none' }}
          onChange={uploadHandler}
        />
      ) : null}
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          {isComponent ? (
            image
          ) : (
            <img
              data-testid={`${action}_${text}`}
              src={image}
              alt='button'
              className={uploadedImg ? classes.img : classes.uploadedImg}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Box fontSize={16}>{action}</Box>
        </Grid>
        <Grid item xs={12}>
          <Box fontSize={16}>{text}</Box>
        </Grid>
      </Grid>
    </Button>
  );
}
