import React, { useContext } from 'react';

import { Button, Grid, TextField, makeStyles } from '@material-ui/core';

import { AppContext } from '../../../AppContext';
import ModalFormHeader from '../../common/ModalComponents/ModalFormHeader';

const useStyles = makeStyles(theme => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
  },
  helperText: {
    margin: 0,
    marginRight: 14,
    marginLeft: 14,
    marginTop: 3,
    color: 'rgba(0, 0, 0, 0.54)',
    textAlign: 'left',
    lineHeight: 1.66,
    fontWeight: 400,
  },
}));

/**
 * NameAndID
 * @param {Object} props
 * @props
 * isEdit: boolean
 * name: string
 * setName: () => void
 * nameError: Boolean
 * gatewayMac: string
 * setNameError: () => void
 * setGatewayMac: () => void
 * gatewayMacError: Boolean
 */
export default function NameAndID(props) {
  const classes = useStyles();
  const { snack } = useContext(AppContext);
  const {
    name,
    isEdit,
    setName,
    nameError,
    gatewayMac,
    setNameError,
    setGatewayMac,
    gatewayMacError,
  } = props;

  const handleNameChange = name => {
    if (name) {
      //validate sensor name
      //allows a-z, A-Z, 0-9, spaces,Underscore,parentheses,brackets,dash, and .
      let re = /^([-.a-zA-Z0-9_ ()[\]]+)$/;
      const tested = re.test(name);

      if (!tested) {
        setNameError(true);
        snack('No non alpha numeric characters allowed', 'error');
      }

      if (tested && nameError) setNameError(false);
    }

    setName(name);
  };

  const handleQRCode = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('enableQR|gatewayMiSensors');
    }
  };

  return (
    <Grid item xs={12}>
      <ModalFormHeader
        header={
          isEdit
            ? 'Edit your gateway name'
            : 'Enter Gateway ID and give your gateway a name'
        }
        desc={`${
          isEdit ? '' : 'Enter your gateway ID. '
        }Giving your gateway a name will help you locate
          and manage all of your gateways and devices.`}
      />
      {!isEdit && (
        <Grid item xs={12} style={{ marginTop: 25 }}>
          <TextField
            className={classes.textField}
            value={gatewayMac}
            error={gatewayMacError}
            fullWidth
            variant='outlined'
            placeholder='Gateway ID'
            label='Gateway ID'
            onChange={e => setGatewayMac(e.target.value.toUpperCase())}
          />
        </Grid>
      )}
      <Grid item xs={12} style={{ marginTop: 25 }}>
        <TextField
          className={classes.textField}
          style={{ height: 55 }}
          error={nameError}
          fullWidth
          name='name'
          value={name}
          variant='outlined'
          placeholder='Gateway Name'
          label='Gateway Name'
          onChange={e => handleNameChange(e.target.value)}
          inputProps={{
            maxLength: 60,
          }}
        />
        <p className={classes.helperText}>{`${name.length}/60`}</p>
      </Grid>
      {window.ReactNativeWebView && !isEdit && (
        <Grid item xs={12} style={{ marginTop: 25 }}>
          <Button variant='contained' color='primary' onClick={handleQRCode}>
            Scan QR
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
