import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import CssBaseline from '@material-ui/core/CssBaseline';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

import { AppProvider } from './AppContext';
import Content from './components/AppContent/Content';

const App = () => {
  const breakpoints = createBreakpoints({});
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  // Buttons
  const secondaryColor = '#03a9f4';
  // Background
  const backgroundColor = '#F4F5F5';

  // Nav
  const [primaryColor, setPrimaryColor] = useState('#08b3ff');
  const [darkMode, setDarkMode] = useState(prefersDarkMode);

  const handlePrimaryColorChange = color => {
    setPrimaryColor(color.hex);
  };

  const handleDarkModeChange = setting => {
    setDarkMode(typeof setting === 'boolean' ? setting : !darkMode);
  };

  const theme = createMuiTheme({
    overrides: {
      MuiCssBaseline: {
        '@global': {
          // "*": {
          //   "scrollbar-width": "thin",
          // },
          '*::-webkit-scrollbar': {
            width: '7px',
            // height: "4px",
          },
          '*::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: '#CCC',
            // outline: "1px solid slategrey",
          },
        },
      },
      MuiStepIcon: {
        root: {
          '&$completed': {
            color: '#81c784',
          },
          '&$active': {
            color: primaryColor,
          },
        },
        active: {},
        completed: {},
        text: { fill: '#fff' },
      },
      MuiButton: {
        containedPrimary: {
          color: '#fff',
        },
      },
      MuiIconButton: {
        edgeStart: {
          color: '#fff !important',
        },
      },

      MuiStepLabel: {
        label: {
          '&$active': {
            color: primaryColor,
          },
          '&$completed': {
            color: '#81c784',
          },
        },
      },
      MuiCard: {
        root: {
          boxShadow: '0px 0px 6px #00000040 !important',
        },
      },
      MuiPaper: {
        elevation4: {
          boxShadow: '0px 0px 6px #00000040 !important',
        },
        elevation1: {
          boxShadow: '0px 0px 6px #00000040 !important',
        },
        elevation2: {
          boxShadow: '0px 0px 6px #00000040 !important',
        },
        elevation3: {
          boxShadow: '0px 0px 6px #00000040 !important',
        },
        rounded: {
          borderRadius: '5px !important',
        },
      },
      MUIDataTableBodyCell: {
        // root: {
        //   overflow: "hidden",
        //   whiteSpace: "nowrap",
        //   textOverflow: "ellipsis",
        //   maxWidth: 200,
        // },
        // stackedCommon: {
        //   lineHeight: "0",
        // },
      },
      // MUIDataTableBody: {
      //   emptyTitle: {
      //     [breakpoints.down("sm")]: {
      //       transform: "translate(-52%,0%)",
      //     },
      //   },
      // },
      MUIDataTableHeadCell: {
        root: {
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          color: darkMode ? '#FFF' : '#747474',
          fontWeight: 600,
        },
      },
      MUIDataTable: {
        responsiveBase: {
          borderTop: '.5px solid #CCC',
        },
        responsiveScrollMaxHeight: {
          borderTop: '.5px solid #CCC',
        },
      },
      MUIDataTableBodyRow: {
        root: {
          '&:nth-child(odd)': {
            backgroundColor: darkMode ? '#303030' : '#F5F5F5',
          },
        },
      },
      MUIDataTablePagination: {
        navContainer: {
          flexWrap: 'wrap',
        },
      },
      MUIDataTableJumpToPage: {
        caption: {
          [breakpoints.down('sm')]: {
            fontSize: 10,
          },
        },
        select: {
          [breakpoints.down('sm')]: {
            fontSize: 10,
          },
        },
      },
      MuiTablePagination: {
        caption: {
          [breakpoints.down('sm')]: {
            fontSize: 10,
          },
        },
        select: {
          [breakpoints.down('sm')]: {
            fontSize: 10,
          },
        },
      },
      MUIDataTableToolbar: {
        left: {
          fontSize: 14,
          color: darkMode ? '#FFF' : '#747474',
          fontWeight: 500,
          marginLeft: 12,
        },
      },
      MuiTypography: {
        body1: {
          fontSize: 14,
          color: darkMode ? '#FFF' : '#747474',
          fontWeight: 500,
        },
      },
    },
    typography: {
      fontFamily: 'Open Sans',
    },
    palette: {
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: secondaryColor,
      },
      background: {
        default: darkMode ? '#303030' : '#F4F5F5',
      },
      text: {
        primary: darkMode ? '#FFF' : '#051F34',
      },
      error: {
        light: '#e57373',
        main: '#f44336',
        dark: '#d32f2f',
      },
      warning: {
        light: '#ffb74d',
        main: '#ff9800',
        dark: '#f57c00',
      },
      success: {
        light: '#81c784',
        main: '#4caf50',
        dark: '#388e3c',
      },
      iconColor: {
        outline: '#051f34',
        filled: '#08b3ff',
      },
      iconColorAlert: {
        outline: '#051f34',
        filled: '#ff6a6a',
      },
      iconColorDarkMode: {
        outline: '#CCCCCC',
        filled: '#08b3ff',
      },
      iconColorDarkModeAlert: {
        outline: '#CCCCCC',
        filled: '#ff6a6a',
      },
      type: darkMode ? 'dark' : 'light',
    },
  });

  // add action to all snackbars
  const notistackRef = React.createRef();
  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  };

  //for iOS/Safari
  let userAgent = window.navigator.userAgent;
  const isMobileSafari = () => {
    return (
      userAgent.match(/(iPod|iPhone|iPad)/) && userAgent.match(/AppleWebKit/)
    );
  };

  if (isMobileSafari()) {
    document.body.style.position = 'fixed';
    document.body.style.height = '100%';
    document.body.style.overflow = 'hidden';
  }

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={1500}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        ref={notistackRef}
        action={key => (
          <Button
            variant='outlined'
            style={{ color: '#FFF' }}
            onClick={onClickDismiss(key)}
          >
            Dismiss
          </Button>
        )}
      >
        <AppProvider>
          <CssBaseline />
          <Content
            setDarkMode={setDarkMode}
            primaryColor={primaryColor}
            handlePrimaryColorChange={handlePrimaryColorChange}
            secondaryColor={secondaryColor}
            backgroundColor={backgroundColor}
            darkMode={darkMode}
            handleDarkModeChange={handleDarkModeChange}
          />
        </AppProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
