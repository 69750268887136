import _ from 'lodash';
import React from 'react';

import { SettingsCheckBoxes } from './SettingsCheckBoxes';

// NOTE Unsure of where meaurement will end up according to designs
export function SystemTab(props) {
  const { timeFormat, setTimeFormat } = props;

  const timeBoxes = [
    { name: '12hr', check: '12hr', label: '12 Hour' },
    { name: '24hr', check: '24hr', label: '24 Hour' },
  ];

  // const measurementBoxes = [
  //   { name: 'imperial', check: 'imperial', label: 'Imperial' },
  //   { name: 'metric', check: 'metric', label: 'Metric' },
  // ];

  return (
    <SettingsCheckBoxes
      currentValue={timeFormat}
      setCurrentValue={setTimeFormat}
      checkBoxData={timeBoxes}
      label='Time Format'
    />
  );
}
