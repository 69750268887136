import _ from 'lodash';
import React, { useContext, useState, useEffect, Fragment } from 'react';
import { AppContext } from '../../AppContext';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Grid,
  TextField,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Chip,
  Input
} from '@material-ui/core';
import { A, navigate } from 'hookrouter';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import LoginLayout from './LoginLayout';

import withWidth, { isWidthDown } from '@material-ui/core/withWidth';


const useStyles = makeStyles(theme => ({
  textField: {
    width: '100%',
  },
  formContainer: {
    width: '80%',
  },
  linkForgotPassword: {
    color: theme.palette.primary.light,
    padding: theme.spacing(1),
    textDecoration: 'none',
    fontSize: 16,
  },
  linkForgotPasswordMobile: {
    color: theme.palette.text.secondary,
    textDecoration: 'none',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 14,
  },
  rememberMeLabelMobile: {
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    fontSize: 14,
    '& span': {
      fontWeight: 'bold',
    }
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  button: {
    width: 200,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: '.5rem',
      borderRadius: '50px',
      fontWeight: 'bold'
    },
  },
  mobileChips: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    width: '25vw',
  },
  mobileViewContainer: {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}));

const Login = (props) => {
  
  const {
    initState,
    setAppState,
    setLogoutState,
    snack,
    setFeatureCount,
  } = useContext(AppContext);

  /**
   * Mobile specific components and states
   */
  const showMobile = window.location.pathname.includes('login')

  const theme = useTheme();

  const [showLoginView, setShowLoginView] = useState(showMobile);

  const isMobileSize = isWidthDown('xs', props.width)

  /**
   * End of Mobile specific components and states
   */

  const [state, setState] = useState({
    email: '',
    pw: '',
  });

  const [err, setErr] = useState({
    email: false,
    pw: false,
  });

  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const classes = useStyles();

  const attemptLogin = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/auth/login',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: state.email,
            pw: state.pw,
          }),
        },
      );

      const json = await response.json();

      if (json.success) {
        const { token, userInfo } = json.data;
        const mergedInfo = _.merge(
          _.cloneDeep(initState.auth.userInfo),
          userInfo,
        );
        if (!mergedInfo.profile) {
          mergedInfo.profile = initState.auth.userInfo.profile;
        }

        if (json.data.userInfo.is_enabled === 0) {
          snack('Account Is Disabled', 'error');
        } else {
          if (rememberMe) {
            localStorage.setItem('email', state.email);
          } else {
            if (localStorage.getItem('email')) {
              localStorage.removeItem('email');
            }
          }

          //  ensure the logout state is false
          //  if user was logged in previously and was kicked out due to token error
          setLogoutState(false);
          setAppState({
            ...initState,
            auth: {
              loggedIn: true,
              token,
              isDirect: mergedInfo.isDirect,
              userInfo: mergedInfo,
            },
          });
          if (userInfo && userInfo.features) {
            let count = Object.values(userInfo.features).filter(
              item => item === false,
            ).length;

            if (!userInfo.features.finishLater) {
              count = count - 1;
            }
            setFeatureCount(count);
          }
        }
      } else {
        setErr({ [json.errors[0].errType]: true });
        snack(json.errors[0].msg, 'error');
        json.errors.forEach(err => {
          if (err.type === 'token') {
            snack(err.msg, 'error');
            setLogoutState(true);
          }
        });
      }
    } catch (err) {
      snack(
        'Unable to make your request at this time. Please try again later.',
        'error',
      );
    }
  };

  useEffect(() => {
    const email = localStorage.getItem('email');
    if (email) {
      setRememberMe(true);
      setState({ ...state, email });
    }
  }, []);

  return (
    <Fragment>
      {
        isMobileSize
        ?
          <Fragment>
            { showLoginView
              ?
              <Box className={classes.mobileViewContainer}>
                <Box className={classes.formContainer}>

                  <Grid container spacing={3}>
                    <Grid
                      item
                      container
                      xs={12}
                      justify="space-around"
                      alignItems="center"
                    >
                      <Chip
                        style={{
                          color: '#FFF',
                        }}
                        color="primary"
                        label="Login"
                        className={classes.mobileChips}
                      />
                      <Chip
                        onClick={() => {
                          navigate('/signup')
                        }}
                        label="Sign up"
                        style={{
                          color: theme.palette.text.secondary,
                        }}
                        color="white"
                        variant="outlined"
                        className={classes.mobileChips}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id='email'
                        className={classes.textField}
                        label='Email Address'
                        onChange={e =>
                          setState({ ...state, email: e.currentTarget.value })
                        }
                        value={state.email}
                        error={err.email}
                        onKeyPress={e => {
                          if (e.key === 'Enter') {
                            document.getElementById('submit').focus();
                            attemptLogin();
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <FormControl className={classes.textField} >
                        <InputLabel htmlFor='outlined-adornment-password'>
                          Password
                        </InputLabel>
                        <Input
                          id='outlined-adornment-password'
                          className={classes.textField}
                          fullWidth
                          label='Password'
                          placeholder='Password'
                          value={state.pw}
                          name='password'
                          onChange={e =>
                            setState({ ...state, pw: e.currentTarget.value })
                          }
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              document.getElementById('submit').focus();
                              attemptLogin();
                            }
                          }}
                          error={err.pw}
                          type={showPassword ? 'text' : 'password'}
                          endAdornment={
                            <InputAdornment position='end'>
                              <IconButton
                                aria-label='toggle password visibility'
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge='end'
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <FormControlLabel
                        className={classes.rememberMeLabelMobile}
                        control={
                          <Checkbox
                            color='primary'
                            checked={rememberMe}
                            onChange={() => {
                              setRememberMe(!rememberMe);
                            }}
                          />
                        }
                        label='Remember me'
                        labelPlacement='end'
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <A href='/reset' className={classes.linkForgotPasswordMobile}>
                        Forgot your password?
                      </A>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      <Button
                        id='submit'
                        variant='contained'
                        className={classes.button}
                        size='large'
                        color='primary'
                        onClick={() => attemptLogin()}
                      >
                        Login
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              :
              <LoginLayout
                subtitle='Monitoring made easy'
                subtitleStyles={{
                  fontSize: '18px',
                }}
              >
                <Box className={classes.formContainer}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      height: '35vh'
                    }}
                  />

                  <Grid
                    container
                    item
                    xs={12}
                  >
                    <Button
                      id='submit'
                      variant='contained'
                      className={classes.button}
                      size='large'
                      color='primary'
                      onClick={() => setShowLoginView(true)}
                    >
                      Login
                    </Button>
                    <A
                      className={classes.button}
                      href='/signup'
                      style={{ textDecoration: 'none' }}
                    >
                      <Button
                        id='submit'
                        variant='outlined'
                        className={classes.button}
                        size='large'
                        color='primary'
                      >
                        Sign Up
                  </Button>
                    </A>
                  </Grid>
                </Box>
              </LoginLayout>
              }
          </Fragment>
        :
          <LoginLayout subtitle='Welcome back! Please login into your account.'>
            <Box className={classes.formContainer}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    id='email'
                    className={classes.textField}
                    label='Email Address'
                    onChange={e =>
                      setState({ ...state, email: e.currentTarget.value })
                    }
                    value={state.email}
                    error={err.email}
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        document.getElementById('submit').focus();
                        attemptLogin();
                      }
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <FormControl className={classes.textField} variant='outlined'>
                    <InputLabel htmlFor='outlined-adornment-password'>
                      Password
                    </InputLabel>
                    <OutlinedInput
                      id='outlined-adornment-password'
                      className={classes.textField}
                      fullWidth
                      variant='outlined'
                      label='Password'
                      placeholder='Password'
                      value={state.pw}
                      name='password'
                      onChange={e =>
                        setState({ ...state, pw: e.currentTarget.value })
                      }
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          document.getElementById('submit').focus();
                          attemptLogin();
                        }
                      }}
                      error={err.pw}
                      type={showPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge='end'
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        color='primary'
                        checked={rememberMe}
                        onChange={event => {
                          setRememberMe(!rememberMe);
                        }}
                      />
                    }
                    label='Remember me'
                    labelPlacement='end'
                  />
                  <A href='/reset' className={classes.linkForgotPassword}>
                    Forgot your password?
                  </A>
                </Grid>
                <Grid container item xs={12} className={classes.buttons}>
                  <Button
                    id='submit'
                    variant='contained'
                    className={classes.button}
                    size='large'
                    color='primary'
                    onClick={() => attemptLogin()}
                  >
                    Login
                  </Button>
                  <A
                    className={classes.button}
                    href='/signup'
                    style={{ textDecoration: 'none' }}
                  >
                    <Button
                      id='submit'
                      variant='outlined'
                      className={classes.button}
                      size='large'
                      color='primary'
                    >
                      Sign Up
                    </Button>
                  </A>
                </Grid>
              </Grid>
            </Box>
          </LoginLayout>
      }
    </Fragment>
  );
};

export default withWidth()(Login);
