import { A, navigate } from 'hookrouter';
import 'react-image-crop/dist/ReactCrop.css';
import GroupIcon from '@material-ui/icons/Group';
import { Backdrop, Grid, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React, { useState, useEffect, useContext } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import fetchOne from '../common/api/fetchOne';
import SingleUserCard from './SingleUserCard';
import Breadcrumb from '../Generic/BreadCrumb';
import { AppContext } from './../../AppContext';
import uploadImage from '../common/api/uploadImage';
import LoadingSpinner from '../Generic/LoadingSpinner';
import DeleteUserFormModal from './DeleteUserFormModal';
import EditUserImageDialog from './EditUserImageDialog';
import NotFoundPageLoggedIn from '../Auth/NotFoundPageLoggedIn';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import DevicesStatusList from "../Dashboard/DevicesStatusList.js";
import MobileBottomButton, { MobileBottomButtonSpacer } from '../Generic/Mobile/MobileBottomButton'

import SingleUserHeaderMobile from './HeaderMobile'

import {
  FullViewContainer
} from '../Generic/Mobile/MobileContainers'

import {
  MobileTabsContainer,
} from "../Generic/Mobile/MobileTabs"
import { EventBus } from '../common/EventBus';

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
  breadcrumbs: {
    color: theme.palette.text.primary,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  mobileSubtitle: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    fontSize: 13,
    fontWeight: 'bold'
  }
}));

const SingleSubUser = props => {
  const log = window.log('SingleSubUser');

  const classes = useStyles();
  const {
    snack,
    appState,
    setAppState,
    setLogoutState,
    setOpenUserModal,
    setOpenDeleteUserModal,
    setOpenAddingModal,
    contextResolvedAlerts
  } = useContext(AppContext);

  /**
  * Mobile view variables
  */
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('sm'));

  /**
   * End of mobile view variables
   */
  const { id } = props;
  const [state, setState] = useState({});

  const [file, setFile] = useState();
  const [imgFile, setImgFile] = useState();
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [initialPageLoad, setInitialPageLoad] = useState(true);
  const [profileImageLocation, setProfileImageLocation] = useState('');
  const [userImageModalState, setUserImageModalState] = useState(false);

  const userImageOpenModal = () => {
    setUserImageModalState(true);
  };

  const userImageCloseModal = () => {
    setUserImageModalState(false);
  };

  const getUser = async () => {
    try {
      const json = await fetchOne(
        'user',
        id,
        appState.auth.userInfo.companyId,
        appState.auth.token,
      );

      log('JSON', json);
      if (json.success) {
        setState(json.data.users[0]);
        if (
          json.data.users[0].profile &&
          json.data.users[0].profile.profileImageLocation
        ) {
          setProfileImageLocation(
            json.data.users[0].profile.profileImageLocation,
          );
        }
      } else {
        setState(null);
        json.errors.forEach(err => {
          snack(err.msg, 'error');
          if (err.type === 'token') setLogoutState(true);
        });
      }
    } catch (error) {
      log(error);
    }
    setInitialPageLoad(false);
  };

  useEffect(() => {
    getUser();
    EventBus.on('SingleSubUserSubUserModalCallBacks', () => {
      getUser();
    });

    return () => {
      EventBus.remove('SingleSubUserSubUserModalCallBacks');
    };
  }, []);

  //After DCS Admin switches account, fetch that account's data
  useEffect(() => {
    if (!appState.isSwitched) return;
    setAppState({ ...appState, isSwitched: false });
    navigate('/user/all');
  }, [appState.isSwitched]);

  const singleFileUploadHandler = async e => {
    setOpenBackdrop(true);

    try {
      const renameText = `userImage-${appState.auth.userInfo.id}-${Date.now()}`;
      const data = new FormData();

      const folderDestination = 'Profile Image';
      if (file) {
        const response = await uploadImage(
          file,
          data,
          renameText,
          appState.auth.token,
          folderDestination,
        );
        if (response.error) {
          if ('LIMIT_FILE_SIZE' === response.error.code) {
            snack('Max size: 2MB', 'error');
          } else {
            // If not the given file type
            snack(response.error, 'error');
          }
          setOpenBackdrop(false);
        } else {
          // Success
          setProfileImageLocation(response.location);
          snack('Image successfully uploaded', 'success');
          handleSaveProfileImage(response.location);
        }
      } else if (imgFile) {
        const response = await uploadImage(
          imgFile,
          data,
          renameText,
          appState.auth.token,
          folderDestination,
        );
        if (response.error) {
          if ('LIMIT_FILE_SIZE' === response.error.code) {
            snack('Max size: 2MB', 'error');
          } else {
            // If not the given file type
            snack(response.error, 'error');
          }
          setOpenBackdrop(false);
        } else {
          // Success
          setProfileImageLocation(response.location);
          handleSaveProfileImage(response.location);
        }
      } else if (profileImageLocation) {
        //PASS
        setOpenBackdrop(false);
      } else {
        handleSaveProfileImage('');

        snack('Image Removed', 'success');
      }
    } catch (error) {
      log(error);
      setOpenBackdrop(false);
    }
  };

  const handleRemoveProfileImageLocation = () => {
    setFile(false);
    setImgFile(null);
    setProfileImageLocation('');
  };

  // Save Profile Image
  const handleSaveProfileImage = async location => {
    const changes = {
      profileImageLocation: location,
    };

    try {
      const data = {
        ...state,
        profile: JSON.stringify(changes),
      };
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/user/editUserImage',
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
          body: JSON.stringify(data),
        },
      );

      const json = await response.json();

      if (json.success) {
        // snack("Image successfully updated", "success");
        if (appState.auth.userInfo.id === state.id) {
          let cp = { ...appState };
          cp.auth.userInfo.profile = JSON.parse(json.data.profile);
          setAppState(cp);
        }
        getUser();
      }
    } catch (err) {
      log(err);
    }
    setOpenBackdrop(false);
  };

  const handleOpenEditUserModal = () => {
    EventBus.dispatch('editSubUserModalProps', {
      id: state.id,
      email: state.email,
      fname: state.fname,
      lname: state.lname,
      phoneNo: state.phoneNumber,
      perm: state.perm,
      permChanged: state.permChanged,
      updatePage: 'SingleSubUser',
    });
    setOpenUserModal(true, true);
  };

  const handleOpenDeleteUserModal = () => {
    if (state.id === appState.auth.userInfo.id) {
      snack('A user cannot delete themselves', 'error');
    } else {
      setOpenDeleteUserModal(true);
    }
  };

  let breadcrumbs = (
    <div style={{ display: 'inline-flex' }}>
      <A className={classes.breadcrumbs} href='/user/all'>
        Users
      </A>
      <div>{'\xa0-\xa0'}</div>
    </div>
  );


  const openAddModal = () => {
    setOpenAddingModal(true);
  };
  const tableHeight = 'calc(100vh - 378px)'
  return state ? (
    initialPageLoad ? (
      <LoadingSpinner />
    ) : (
      isMobileSize ? (
        <FullViewContainer>
          <DeleteUserFormModal userInfo={state} />
          <EditUserImageDialog
            setFile={setFile}
            setImgFile={setImgFile}
            openState={userImageModalState}
            handleClose={userImageCloseModal}
            imgLocation={profileImageLocation}
            handleSave={singleFileUploadHandler}
            setImgLocation={setProfileImageLocation}
            handleRemoveImg={handleRemoveProfileImageLocation}
          />
          {state ? (
            <Grid className={classes.root} container spacing={0}>
              <Grid item xs={12}>
                <SingleUserHeaderMobile
                  isAvatarIcon={!state.gatewayImageLocation}
                  avatar={
                    profileImageLocation
                  }
                  title={`${state.fname || ''} ${state.lname || ''}`
                  }
                  handleEditAvatarImage={userImageOpenModal}
                  handleEdit={handleOpenEditUserModal}
                  handleDelete={handleOpenDeleteUserModal}
                  subtitles={[
                    state.email,
                    state.phoneNumber
                  ]}
                  showEditActions={appState.auth.userInfo.perm !== 1 &&
                    appState.auth.userInfo.perm !== 53}
                />
              </Grid>
              <Grid item xs={12}>
                <MobileTabsContainer>
                  <Typography
                    variant="button"
                    component="p"
                    className={classes.mobileSubtitle}
                    style={{
                      color: theme.palette.primary.main
                    }}
                  >
                    LATEST ACTIVITY
                  </Typography>
                </MobileTabsContainer>
                
              </Grid>
              <Grid item xs={12}>
                <DevicesStatusList
                  listHeight={tableHeight}
                  className={classes.mobileTableList}
                  devices={contextResolvedAlerts}
                  entity={"alerts"}
                  actionTitle={false}
                  mobileLayout={isMobileSize}
                />
              </Grid>
              <MobileBottomButtonSpacer />
              <MobileBottomButton
                onClick={openAddModal}
              />
            </Grid>
          ) :
            <NotFoundPageLoggedIn />}
        </FullViewContainer >
      ) :
        <>
          <DeleteUserFormModal userInfo={state} />
          <EditUserImageDialog
            setFile={setFile}
            setImgFile={setImgFile}
            openState={userImageModalState}
            handleClose={userImageCloseModal}
            imgLocation={profileImageLocation}
            handleSave={singleFileUploadHandler}
            setImgLocation={setProfileImageLocation}
            handleRemoveImg={handleRemoveProfileImageLocation}
          />
          <Backdrop className={classes.backdrop} open={openBackdrop}>
            <CircularProgress color='inherit' />
          </Backdrop>
          {state ? (
            <div>
              <Grid item className={classes.title}>
                <Breadcrumb
                  breadcrumbs={breadcrumbs}
                  leadingIcon={
                    <GroupIcon style={{ fontSize: 32 }} color='primary' />
                  }
                  title={`${state.fname} ${state.lname}`}
                />
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <SingleUserCard
                    id={state.id}
                    name={`${state.fname || ''} ${state.lname || ''}`}
                    email={state.email || ''}
                    companyName={state.companyName || ''}
                    phoneNumber={state.phoneNumber || ''}
                    permissionLevel={state.perm}
                    userSettingsPage={false}
                    avatar={profileImageLocation}
                    handleEditImage={userImageOpenModal}
                    handleOpenEditUserModal={handleOpenEditUserModal}
                    handleOpenDeleteUserModal={handleOpenDeleteUserModal}
                  />
                </Grid>
              </Grid>
            </div>
          ) : (
            <NotFoundPageLoggedIn />
          )}
        </>
    )
  ) : (
    <NotFoundPageLoggedIn />
  );
};

export default SingleSubUser;
