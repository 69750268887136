import React, { Fragment } from "react";

import { Box, Typography, makeStyles } from "@material-ui/core";
import CardInfoIcons from "../Generic/CardInfoIcons";
import grey from "@material-ui/core/colors/grey";
import { navigate } from "hookrouter";
import GenericListCard from "./GenericListCard";

/**
 * LocationCard
 * @param {Object} props
 * @props
 * id: Integer
 * trailingItems: Object Array [{ icon: React Component , value: String }]
 * title: String,
 * message: String,
 */
export default function LocationCard(props) {

  const {
    id, // Id of the location
    trailingItems, // Trailing Items for the CardInfoIcons
    title, // Title of the Card
    street, // Address variable of the Card
    city, // Address variable of the Card
    state, // Address variable of the Card
    zip, // Address variable of the Card
    phone, // Phone number of the Card,
    isTileLayout = false, // Boolean to show the element as a Tile and not a card
    backgroundColor = 'white' // Card background color
  } = props;

  const useStyles = makeStyles((theme) => ({
    cardContainer: {
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        display: "block",
        justifyContent: "flex-start",
      },
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        justifyContent: "space-between",
      },
    },
    trailingItems: {
      width: "40%",
      [theme.breakpoints.down("sm")]: {
        margin: "auto",
        width: "100%",
      },
      [theme.breakpoints.down("xs")]: {
        margin: "0px",
        width: "45%",
      },
    },
    leadingContainer: {
      width: "50%",
      marginTop: 7,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
      [theme.breakpoints.down("xs")]: {
        width: "50%",
        display: "block",
        flexDirection: "row",
        alignItems: "start",
      },
    },
    title: {
      fontSize: 14,
      fontWeight: "bold",
      color: theme.palette.type === "light" ? grey[600] : "#fff",
      [theme.breakpoints.down("sm")]: {
        whiteSpace: "nowrap",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 12
      }
    },
    messageStreet: {
      color: theme.palette.type === "light" ? grey[500] : "#fff",
      margin: 0,
      whiteSpace: "nowrap",
      fontSize: 12,
      [theme.breakpoints.down("xs")]: {
        fontSize: 10
      }
    },
    messageCityStateZip: {
      color: theme.palette.type === "light" ? grey[500] : "#fff",
      margin: 0,
      whiteSpace: "nowrap",
      fontSize: 12,
      [theme.breakpoints.down("xs")]: {
        fontSize: 10
      }
    },
    tileStyles: {
      backgroundColor: backgroundColor,
      padding: theme.spacing(2),
      borderBottom: `1px solid ${grey[300]}`,
      overflow: 'hidden'
    }
  }));

  /**
   * Event to send the browser to the user page
   */
  const showLocation = () => {
    navigate(`/location/${id}`);
  };
  const classes = useStyles();

  const content = (
    <Box
      className={classes.cardContainer}
      onClick={showLocation}
      style={{ cursor: "pointer" }}
    >
      <Box className={classes.leadingContainer}>
        <Typography className={classes.title}>{title}</Typography>
        <Box>
          <Typography className={classes.messageStreet}>{street}</Typography>
        </Box>
        <Box>
          <Typography className={classes.messageCityStateZip}>
            {city}, {state} {zip}
          </Typography>
        </Box>
        <Box component="div" whiteSpace="normal">
          <Typography className={classes.messageCityStateZip}>
            {phone}
          </Typography>
        </Box>
      </Box>
      <div className={classes.trailingItems}>
        <CardInfoIcons
          locationCard={true}
          wrapItems="nowrap"
          items={trailingItems}
          isBold={true}
          enableZero={true}
        />
      </div>
    </Box>
  )

  return (
    <Fragment>
      { isTileLayout ?
        <Box
          className={classes.tileStyles}
        >
          {content}
        </Box>
        :
        <GenericListCard
          backgroundColor={backgroundColor}
        >
          {content}
        </GenericListCard>
      }
    </Fragment>
  );
}
