import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';

import LocationForm from '../../common/LocationForm/LocationForm';
import ModalFormHeader from '../../common/ModalComponents/ModalFormHeader';

const useStyles = makeStyles(theme => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
  },
}));
/**
 * Add name and address for company
 * @param {Object} props
 */
export function CompanyInfo(props) {
  const { onboardObject, setOnboardObject, errorState } = props;

  const classes = useStyles();

  return (
    <Grid container item xs={12} spacing={2}>
      <ModalFormHeader
        header='Enter company information'
        desc='Enter the Name, Street, City, State and Zip Code information for your company below. This will be used as your Primary MiSensors Location after signup.'
      />
      <LocationForm
        addCompanyField={true}
        companyName={onboardObject.companyName}
        setCompanyName={value => {
          setOnboardObject(prevState => {
            return { ...prevState, companyName: value };
          });
        }}
        companyNameError={errorState.companyName}
        street={onboardObject.street}
        setStreet={value => {
          setOnboardObject(prevState => {
            return { ...prevState, street: value };
          });
        }}
        streetError={errorState.street}
        city={onboardObject.city}
        setCity={value => {
          setOnboardObject(prevState => {
            return { ...prevState, city: value };
          });
        }}
        cityError={errorState.city}
        state={onboardObject.state}
        setState={value => {
          setOnboardObject(prevState => {
            return { ...prevState, state: value };
          });
        }}
        stateError={errorState.state}
        zipcode={onboardObject.zip}
        setZipcode={value => {
          setOnboardObject(prevState => {
            return { ...prevState, zip: value };
          });
        }}
        zipcodeError={errorState.zip}
        classes={classes}
      />
    </Grid>
  );
}
