import React, { useState, useContext } from 'react';
import { AppContext, AppProvider } from './../../AppContext';
import {
  Box,
  Grid,
  TextField,
  makeStyles,
  LinearProgress,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import GenericDialog from '../Generic/GenericDialog';
import { navigate } from 'hookrouter';

import LocationSelect from '../common/LocationSelect';
import { SES } from 'aws-sdk';

const useStyles = makeStyles(theme => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
  },
  locationSelect: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
  },
  fieldPadding: {
    paddingBottom: theme.spacing(4),
  },
}));

/**
 * Gateway Form Modal
 * @param {Object} props
 * @props
 * openState: Boolean
 * handleClose: Functions
 */
export default function EditGatewayFormModal(props) {
  const log = window.log('DeleteGatewayFormModal');

  const {
    appState,
    setLogoutState,
    openDeleteGatewayModal,
    setOpenDeleteGatewayModal,
    snack,
  } = useContext(AppContext);
  const { getGateway, gatewayEntryId } = props;
  const classes = useStyles();
  //Gateway name state hooks

  const [nameError, setNameError] = useState(false);
  //Gateway MAC state hooks
  const [gatewayMac, setGatewayMac] = useState('');
  const [gatewayMacError, setGatewayMacError] = useState(false);
  //Location ID state hook
  const [locationId, setLocationId] = useState();
  //Submit gateway state hook
  const [submitState, setSubmitState] = useState(false);

  //checkbox state
  const [confirmed, setConfirmed] = useState(false);
  const handleDeleteGateaway = async () => {
    log('Inside of handle delete gateway');
    log('Gateway_Delete: Inside of handle delete gateway');
    log('Gateway_Submit: Looking at gatewayEntryID ' + gatewayEntryId);

    try {
      const data = {
        userId: appState.auth.userInfo.id,
        input: confirmed,
        gatewayEntryId: gatewayEntryId,
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/gateway/delete`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
          body: JSON.stringify(data),
        },
      );
      const json = await response.json();

      log('Gateway_Delete: Looking at the json response ', json);

      if (json.success) {
        setTimeout(() => setOpenDeleteGatewayModal(false), 1999);
        setTimeout(() => navigate('/gateway/all', true), 2000);
        return true;
      } else {
        setNameError(true);
        let errorObj = {};
        json.errors.forEach(error => {
          if (error.type === 'gatewayMac') {
            setGatewayMacError(true);
          }
          //add error to err object
          errorObj = { ...errorObj, [error.type]: true };
          //err alert
          snack(error.msg, 'error');
          if (error.type === 'token') setLogoutState(true);
        });
        return false;
      }
    } catch (err) {
      log(err);
      return false;
    }

  };

  const handleClose = () => {
    // setOpenEditGatewayModal(false);
    setOpenDeleteGatewayModal(false);
  };

  const testFunc = () => {
    setOpenDeleteGatewayModal(false);
    navigate(`/gateway/all`);
  };

  return (
    <div>
      <GenericDialog
        openState={openDeleteGatewayModal}
        handleClose={handleClose}
        handleCancel={handleClose}
        handleFinish={testFunc}
        title='DELETE GATEWAY'
        stepsArray={[
          {
            label: 'DELETE GATEWAY',
            nextFunction: handleDeleteGateaway,
            validator: !submitState,
            showProgress: true,
            saveButtonStep: true,
            child: (
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Box fontWeight='fontWeightBold' fontSize={20}>
                    Are you sure you want to delete this gateway ?
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  fontWeight='fontWeightLight'
                  fontSize={16}
                  style={{ marginTop: 10 }}
                ></Grid>
                <Grid item xs={12} style={{ marginTop: 25 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={confirmed}
                        onChange={e => setConfirmed(e.target.checked)}
                        name='delete'
                        color='primary'
                      />
                    }
                    label='Yes'
                  />
                </Grid>
              </Grid>
            ),
          },
          {
            label: 'LOADING',
            child: (
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Box
                    fontWeight='fontWeightBold'
                    fontSize={20}
                    className={classes.fieldPadding}
                  >
                    Deleting gateway from platform
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  fontWeight='fontWeightLight'
                  fontSize={16}
                  style={{ marginTop: 10 }}
                  className={classes.fieldPadding}
                >
                  <Box>MiSensors is currently deleting your gateway.</Box>

                  <Grid
                    item
                    xs={12}
                    style={{ marginTop: 10 }}
                    className={classes.fieldPadding}
                  >
                    <LinearProgress />
                  </Grid>
                </Grid>
              </Grid>
            ),
          },
          {
            label: 'COMPLETE', // Because is the finish
            child: (
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Box fontWeight='fontWeightBold' fontSize={20}>
                    Success! Your gateway has been deleted.
                  </Box>
                  <Box style={{ marginTop: '1rem' }}>
                    Select finish to return to the gateway page.
                  </Box>
                </Grid>
              </Grid>
            ),
          },
        ]}
      />
    </div>
  );
}
