import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";

import { AppContext } from "../../../AppContext";

export default function CurrLocButton(props) {
  const log = window.log("LocationForm");

  const { setCity, setState, setStreet, setZipcode } = props;

  const { setLogoutState, snack } = useContext(AppContext);

  const useStyles = makeStyles((theme) => ({
    locationListItem: {
      color: theme.palette.primary.main,
      "&:hover": {
        fontWeight: "bold",
        cursor: "pointer",
      },
    },
    titleText: {
      fontWeight: "bold",
      color: "#747474",
    },
  }));

  //current location hooks

  const [guessList, setGuessList] = useState([]);

  // current location functions

  const getCurrentLocation = (e) => {
    e.preventDefault();

    const locationCallback = async (position) => {
      const { latitude, longitude, accuracy } = position.coords;
      try {
        if (accuracy > 0 && accuracy < 10000) {
          let json = await fetch(
            `${process.env.REACT_APP_API_URL}/location/current?lat=${latitude}&long=${longitude}&acc=${accuracy}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          json = await json.json();
          if (json.success) {
            log(json);
            if (json.data.result.length === 0) {
              setGuessList(false);
            } else {
              setGuessList(json.data);
            }
          } else {
            json.errors.forEach((err) => {
              snack(err.msg, "error");
              if (err.type === "token") setLogoutState(true);
            });
          }
        } else {
          setGuessList(false);
        }
      } catch (err) {
        log("------: locationCallback -> err", err);
        snack("Network Error", "error");
      }
    };

    const error = () => {
      setGuessList(false);
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(locationCallback, error);
    } else {
      log("Geolocation is not supported by this browser.");
    }
  };

  const CurrentLocationGuessList = (props) => {
    const classes = useStyles();
    const { items } = props;
    return (
      <>
        {items.result && (
          <ul>
            <Typography
              className={classes.titleText}
              variant="h6"
              component="h6"
            >
              Is one of these your current address?
            </Typography>
            {items.result.map((item, index) => (
              <li
                className={classes.locationListItem}
                key={index}
                onClick={() => {
                  // setState({ ...state, address: item.label });
                  setStreet(item.HouseNumber + " " + item.Street);
                  setCity(item.City);
                  setState(item.State);
                  setZipcode(item.PostalCode);
                  setGuessList([]);
                }}
              >
                {item.Label}
              </li>
            ))}
          </ul>
        )}
      </>
    );
  };

  return [
    <Grid item xs={12} key="1">
      <Button
        id="currentLocation"
        variant="outlined"
        color="secondary"
        onClick={getCurrentLocation}
      >
        Use Current Location
      </Button>
    </Grid>,
    <Grid item xs={12} key="2">
      {guessList ? (
        <CurrentLocationGuessList items={guessList} />
      ) : (
        <p>Unable to retrieve your location.</p>
      )}
    </Grid>,
  ];
}
