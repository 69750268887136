import React, { useState, useContext } from "react";
import {
  Typography,
  Dialog,
  Grid,
  Card,
  CardContent,
  CardActions,
  TextField,
  Button,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import LocationForm from "../common/LocationForm/LocationForm";
import { AppContext } from "../../AppContext";

const useStyles = makeStyles((theme) => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
  },
  gatewaySelect: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
  },
  fieldPadding: {
    paddingBottom: theme.spacing(4),
  },
  textFieldPadding: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  leftButton: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  rightButton: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: "1rem",
    },
  },
  formContainer: {
    backgroundColor:
      theme.palette.type === "light"
        ? "#EBEBEB"
        : theme.palette.background.default,
  },
  titleText: {
    fontWeight: "bold",
    color: "#747474",
  },
  // textField: {
  //   backgroundColor: theme.palette.background.paper,
  //   borderRadius: "5px",
  // },
}));

/**
 * Account Settings Form Modal
 * @param {Object} props
 * @props
 * openState: Boolean
 * handleClose: Function
 * handleSave: Function
 */
const EditCurrentUserDialog = (props) => {
  const log = window.log("EditCurrentUserDialog");

  const { snack } = useContext(AppContext);
  const {
    openState,
    handleClose,
    handleSave,
    userInfo,
    setUserInfo,
    companyInfo,
    setCompanyInfo,
    appState,
    setLogoutState,
  } = props;

  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [fullWidth, setFullWidth] = useState(false);
  const [maxWidth, setMaxWidth] = useState("sm");
  const classes = useStyles();

  const [fname, setFname] = useState(userInfo.fname ? userInfo.fname : "");
  const [fnameError, setFnameError] = useState(false);

  const [lname, setLname] = useState(userInfo.lname ? userInfo.lname : "");
  const [lnameError, setLnameError] = useState(false);

  const [email, setEmail] = useState(userInfo.email ? userInfo.email : "");
  const [emailError, setEmailError] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState(
    userInfo.phoneNumber ? userInfo.phoneNumber : ""
  );
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const [companyName, setCompanyName] = useState(
    userInfo.companyName ? userInfo.companyName : ""
  );
  const [companyNameError, setCompanyNameError] = useState(false);

  const [street, setStreet] = useState(
    companyInfo.street ? companyInfo.street : ""
  );
  const [streetError, setStreetError] = useState(false);

  const [city, setCity] = useState(companyInfo.city ? companyInfo.city : "");
  const [cityError, setCityError] = useState(false);

  const [state, setState] = useState(
    companyInfo.state ? companyInfo.state : ""
  );
  const [stateError, setStateError] = useState(false);

  const [zip, setZip] = useState(companyInfo.zip ? companyInfo.zip : "");
  const [zipError, setZipError] = useState(false);

  const handleSubmit = async () => {
    let foundError = false;
    if (fname.length === 0) {
      setFnameError(true);
      foundError = true;
    }
    if (lname.length === 0) {
      setLnameError(true);
      foundError = true;
    }
    if (email.length === 0) {
      setEmailError(true);
      foundError = true;
    }
    if (phoneNumber.length === 0) {
      setPhoneNumberError(true);
      foundError = true;
    }
    if (companyName.length === 0) {
      setCompanyNameError(true);
      foundError = true;
    }
    if (street.length === 0) {
      setStreetError(true);
      foundError = true;
    }
    if (city.length === 0) {
      setCityError(true);
      foundError = true;
    }
    if (state.length === 0) {
      setStateError(true);
      foundError = true;
    }
    if (zip.length === 0) {
      setZip(true);
      foundError = true;
    }
    if (foundError) {
      snack("Please Fill Out All Fields", "error");
      return false;
    }

    try {
      const response = await fetch(
        `https://geocoder.ls.hereapi.com/6.2/geocode.json?apiKey=${process.env.REACT_APP_HERE_API_KEY}&searchtext=${street}+${city}+${state}+${zip}`
      );
      const json = await response.json();

      if (json.Response.View.length === 0) {
        snack("Not a valid address", "error");

        return false;
      } else {
        let lat =
          json.Response.View[0].Result[0].Location.DisplayPosition.Latitude;
        let lng =
          json.Response.View[0].Result[0].Location.DisplayPosition.Longitude;
        try {
          const data = {
            fname,
            lname,
            email,
            phoneNumber,
            street,
            city,
            state,
            zipcode: zip,
            lat,
            lng,
            userId: appState.auth.userInfo.id,
          };

          const delay = (ms) => new Promise((res) => setTimeout(res, ms));

          await delay(1500);

          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/company/editAccountInfo`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: appState.auth.token,
              },
              body: JSON.stringify(data),
            }
          );
          const json = await response.json();

          if (json.success) {
            setFnameError(false);
            setLnameError(false);
            setEmailError(false);
            setPhoneNumberError(false);
            setCompanyNameError(false);
            setStreetError(false);
            setCityError(false);
            setStateError(false);
            setZipError(false);
            snack("Account Information Updated", "success");
            handleSave();
          } else {
            let errorObj = {};
            json.errors.forEach((error) => {
              if (error.type === "fname") {
                setFnameError(true);
              }
              if (error.type === "lname") {
                setLnameError(true);
              }
              if (error.type === "email") {
                setEmailError(true);
              }
              if (error.type === "phoneNumber") {
                setPhoneNumberError(true);
              }
              if (error.type === "companyName") {
                setCompanyNameError(true);
              }
              if (error.type === "street") {
                setStreetError(true);
              }
              if (error.type === "city") {
                setCityError(true);
              }
              if (error.type === "state") {
                setStateError(true);
              }
              if (error.type === "zipcode") {
                setZipError(true);
              }
              //add error to err object
              errorObj = { ...errorObj, [error.type]: true };
              //err alert
              snack(error.msg, "error");
              if (error.type === "token") setLogoutState(true);
            });
          }
        } catch (err) {
          log(err);
          snack("Network Error", "error");
        }
      }
    } catch (error) {
      log(error);
      snack("Network Error", "error");
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen} // commented temporarily because it breaks the layout
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={openState}
      aria-labelledby="responsive-dialog-title"
      onClose={handleClose}
    >
        <Card style={{ overflow: "auto" }}>
          <CardContent>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography className={classes.titleText} align="center">
                  EDIT ACCOUNT INFORMATION
                </Typography>
              </Grid>

              <Grid
                item
                container
                xs={12}
                style={
                  fullScreen
                    ? {}
                    : {
                        paddingLeft: theme.spacing(6),
                        paddingRight: theme.spacing(6),
                      }
                }
                className={classes.formContainer}
              >
                <Grid
                  item
                  xs={12}
                  className={classes.textFieldPadding}
                  md={6}
                  style={fullScreen ? {} : { paddingRight: theme.spacing(1) }}
                >
                  <TextField
                    className={classes.textField}
                    fullWidth
                    variant="outlined"
                    label="First Name"
                    placeholder="First Name*"
                    value={fname}
                    onChange={(e) => setFname(e.target.value)}
                    error={fnameError}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={classes.textFieldPadding}
                  md={6}
                  style={fullScreen ? {} : { paddingLeft: theme.spacing(1) }}
                >
                  <TextField
                    className={classes.textField}
                    fullWidth
                    variant="outlined"
                    label="Last Name"
                    placeholder="Last Name*"
                    value={lname}
                    onChange={(e) => setLname(e.target.value)}
                    error={lnameError}
                  />
                </Grid>
                <Grid item xs={12} className={classes.textFieldPadding}>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    variant="outlined"
                    label="Email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={emailError}
                  />
                </Grid>
                <Grid item xs={12} className={classes.textFieldPadding}>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    variant="outlined"
                    label="Phone Number"
                    placeholder="Phone Number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    error={phoneNumberError}
                  />
                </Grid>
                <Grid
                  style={{
                    paddingLeft: theme.spacing(3),
                    paddingRight: theme.spacing(3),
                  }}
                  container
                  spacing={2}
                >
                  <LocationForm
                    addCompanyField={true}
                    companyName={companyName}
                    setCompanyName={setCompanyName}
                    companyNameError={companyNameError}
                    street={street}
                    setStreet={setStreet}
                    streetError={streetError}
                    city={city}
                    setCity={setCity}
                    cityError={cityError}
                    state={state}
                    setState={setState}
                    stateError={stateError}
                    zipcode={zip}
                    setZipcode={setZip}
                    zipcodeError={zipError}
                    classes={classes}
                  />
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions className={classes.formContainer}>
            <Grid
              className={classes.formContainer}
              container
              style={{
                paddingLeft: theme.spacing(8),
                paddingRight: theme.spacing(8),
                marginBottom: theme.spacing(4),
              }}
            >
              <Grid item xs={12} sm={6}>
                <Button
                  className={classes.leftButton}
                  onClick={handleClose}
                  size="large"
                  variant="outlined"
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} container justify="flex-end">
                <Button
                  className={classes.rightButton}
                  onClick={handleSubmit}
                  size="large"
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
    </Dialog>
  );
};

export default EditCurrentUserDialog;
