import { NAME_MAP } from '../../constants';

export const sensorProfileMap = profile => {
  let profileMap = [];
  for (let i = profile.length - 1; i >= 0; i--) {
    if (profile[i].value === true) {
      profileMap.push(NAME_MAP[profile[i].type]);
    }
  }
  return profileMap;
};

export const convertTempForUserPref = (appState, value) => {
  let unitType = ' °C';
  //Convert the Fahrenheit
  if (appState.userSettings.measurement === 'imperial') {
    value = value * (9 / 5) + 32;
    unitType = ' °F';
  }
  value = value.toFixed(2);
  value = value + unitType;
  return value;
};
