import moment from 'moment-timezone';
import { ResponsiveBar } from '@nivo/bar';
import { linearGradientDef } from '@nivo/core';
import React, { useContext } from 'react';
import { useTheme } from '@material-ui/core/styles';
// import * as d3 from "d3";
import { AppContext } from '../../../AppContext';

export function DeviceBarChart(props) {
  const {
    chartData = [], // chart data for both line and bar
    barGraphKeys = [], // char keys for bar graph
    chartTimeSelection,
    fullScreen = false, // Value its the dialog its going to be open fullScreen adjusting some values for the margin on the chart
  } = props;

  const { appState } = useContext(AppContext);

  chartData.forEach((item, i)=>{
    chartData[i].date = moment(chartData[i].date).format('M-D-YYYY H:mm')
  })

  const theme = useTheme();

  const getChartFormat = () => {
    switch (chartTimeSelection) {
      case 'hour':
        return appState.userSettings?.timeFormat === '12hr'
          ? 'hh:mm A'
          : 'HH:mm';
      case 'day':
        return appState.userSettings?.timeFormat === '12hr' ? 'hhA ' : 'h:00 ';
      default:
        return 'ddd';
    }
  };

  const formatDate = date => {
    return moment(date).format(getChartFormat());
  };

  const getChartTickValues = () => {
    switch (chartTimeSelection) {
      case 'hour':
        return 'every 10 minutes';
      case 'day':
        return 'every hour';
      default:
        return 'every day';
    }
  };

  const colors = [theme.palette.primary.main, theme.palette.success.main];

  const getColor = bar => {
    return colors[barGraphKeys.indexOf(bar.id)];
  };

  return (
    <ResponsiveBar
      data={chartData}
      keys={barGraphKeys}
      groupMode='grouped'
      indexBy='date'
      margin={{ top: 50, right: fullScreen ? 20 : 60, bottom: 30, left: 60 }}
      padding={0.3}
      colors={getColor}
      borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      axisTop={null}
      axisRight={null}
      labelFormat={v => ''}
      tooltipFormat={value => value}
      xScale={{
        type: 'time',
        format: '%m-%d-%Y %I:%M',
        useUTC: false,
        precision: 'minute',
      }}
      xFormat={
        appState.userSettings?.timeFormat === '12hr'
          ? 'time:%m-%d-%Y %I:%M %p'
          : 'time:%m-%d-%Y %H:%M'
      }
      axisBottom={{
        format: value => formatDate(value),
        tickRotation: 0,
        tickPadding: 5,
        tickValues: getChartTickValues(),
        tickCount: 10,
        legendOffset: 50,
        legendPosition: 'middle',
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: '',
        legendPosition: 'middle',
        tickValues: 4,
        legendOffset: -40,
        format: e => Math.floor(e) === e && e
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: 'color',
        modifiers: [['darker', 1.6]],
      }}
      enableArea={false}
      useMesh={false}
      defs={[
        linearGradientDef('gradientA', [
          { offset: 0, color: 'inherit' },
          { offset: 100, color: 'inherit', opacity: 0.3 },
        ]),
      ]}
      fill={[{ match: '*', id: 'gradientA' }]}
      legends={[]}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      theme={
        appState?.userSettings?.darkMode && {
          axis: {
            ticks: {
              text: {
                fill: 'white',
              },
            },
            legend: {
              text: {
                fill: 'white',
              },
            },
          },
          tooltip: {
            container: {
              color: 'black',
            },
          },
        }
      }
    />
  );
}
