import React from "react";
import { Card, makeStyles, Box } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import grey from "@material-ui/core/colors/grey";

export default function CheckboxCard(props) {
  const {
    icon, // icon
    title, // tile of the checkbox
    value = false, // initial boolean value,
    toggleEvent, // function toggleEvent()
    alertDisabled = false, //to easily enable/disable a feature in adding alerts
    sensorDisabled = false, //to easily enable/disable a feature in adding sensors
    header //to help decide whether or not to disable a feature
  } = props;

  const featureDisabled = ()=>{
    //just in case a feature needs to be enabled in one modal, disabled in another or disabled in both
    if(sensorDisabled && header.includes("Sensor")){
      return true
    }else if(alertDisabled && header.includes("Alert")){
      return true
    } else if(alertDisabled && sensorDisabled){
      return true
    }
    return false
  }

  const rgbColorToOpacity = (color, opacity = 0.3) => {
    return color.replace("rgb", "rgba").replace(")", `, ${opacity})`);
  };

  const cardColor = (_theme, _checked) => {
    if (value) {
      if (_theme.palette.type === "light") {
        return rgbColorToOpacity(_theme.palette.primary.light);
      }
    } else {
      return _theme.palette.background.paper;
    }
  };

  //function only needs to get called once per checkbox
  const isDisabled = featureDisabled();
  const useStyles = makeStyles((theme) => {
    return {
      card: {
        borderColor: isDisabled ? "lightgrey" : theme.palette.primary.light,
        backgroundColor: isDisabled ? "lightgrey" : cardColor(theme, value),
        margin: theme.spacing(0.5),
        cursor: isDisabled ? "default" : "pointer",
        boxShadow: '0px 0px 0px !important'
      },
      iconCheck: {
        width: "30px",
      },
      icon: {
        width: 38,
        padding: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
      },
      text: {
        color: theme.palette.type === "light" ? grey[700] : grey[300],
        fontSize: 14,
        transform: 'translateX(10px)',
        [theme.breakpoints.down("xs")]: {
          fontSize: 13,
        },
        fontWeight: 600,
      },
    };
  });

  const classes = useStyles();

  return (
    <Card className={classes.card} variant={"outlined"} onClick={isDisabled ? undefined : toggleEvent}>
      <Box
        style={{ paddingLeft: 10, paddingRight: 10 }}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" alignItems="center">
          <div className={classes.icon}>{icon}</div>
          <p className={classes.text}>{title}</p>
        </Box>
        <Box className={classes.iconCheck}>
          {value ? (
            <CheckIcon color="primary" style={{ fontSize: 28 }} />
          ) : null}
        </Box>
      </Box>
    </Card>
  );
}
