import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Select,
  Divider,
  MenuItem,
  TextField,
  makeStyles,
  FormControl,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  fontColor: {
    color:
      theme.palette.type === 'light'
        ? theme.palette.grey[600]
        : theme.palette.text.primary,
  },
  select: {
    backgroundColor: theme.palette.background.paper,
  },
  formSelect: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  textField: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginBottom: 8,
    borderRadius: '5px',
    height: 55,
    color:
      theme.palette.type === 'light'
        ? theme.palette.grey[600]
        : theme.palette.text.primary,
  },
}));

/**
 * Update the treshold for the objects in advanced lighting
 */
const updateThresholdObjectAdv = (temp, type) => {
  let firstLightThresholdLow;
  let firstLightThresholdHigh;
  let secondLightThresholdLow;
  let secondLightThresholdHigh;

  if (!_.isArray(temp[0])) {
    if (type === 'below_value') {
      firstLightThresholdHigh = temp[1];
    } else if (type === 'above_value') {
      firstLightThresholdHigh = temp[0];
    } else {
      firstLightThresholdLow = temp[0];
      firstLightThresholdHigh = temp[1];
    }
  } else {
    firstLightThresholdLow = temp[0][0];
    firstLightThresholdHigh = temp[0][1];

    secondLightThresholdLow = temp[1][0];
    secondLightThresholdHigh = temp[1][1];
  }

  return {
    firstLightThresholdLow,
    firstLightThresholdHigh,
    secondLightThresholdLow,
    secondLightThresholdHigh,
  };
};

/**
 * Range Settings Step
 * @param {*} props
 */

export function RSLightStepAdvanced(props) {
  const {
    object,
    setObject,
    advLightErr,
    rangeSelError,
    setAdvLightErr,
    setShowMarkers,
    objectDefinition,
    invalidRangeTypeError,
  } = props;

  const defaultSplitValueErrorObj = {
    lowLux1: '',
    highLux1: '',
    lowLux2: '',
    highLux2: '',
    isError: false,
  };

  const [splitValueErrors, setSplitValueErrors] = useState(
    defaultSplitValueErrorObj,
  );

  useEffect(() => {
    if (validateIsSplit() && _.isArray(object.rangeValues[1])) {
      checkForErrors();
    }
  }, [object]);

  useEffect(() => {
    if (validateIsSplit() && splitValueErrors.isError !== advLightErr) {
      setAdvLightErr(splitValueErrors.isError);
    }
  }, [splitValueErrors]);

  const classes = useStyles();
  /**
   * Possible types of alerts in the range
   */
  const rangeTypesOptions = [
    {
      name: 'Select a Range Type',
      isDefault: true,
      value: 'none',
    },
    {
      name: 'Below Value',
      value: 'below_value',
    },
    {
      name: 'Above Value',
      value: 'above_value',
    },
    {
      name: 'Inside of Range',
      value: 'inside_range',
    },
    {
      name: 'Outside of Range',
      value: 'outside_range',
    },
    {
      name: 'Split Value',
      value: 'split_value',
    },
  ];

  // Validate is the option selected is a range to show the two values
  const validateIsRange = () => {
    return object.rangeType.includes('range');
  };

  // Validate is the option selected is a range to show the four values
  const validateIsSplit = () => {
    return object.rangeType.includes('split');
  };

  /**
   *  changeRangeCallback update the rangeType for the lightState
   * @param event
   */
  const changeRangeCallback = event => {
    const temp = Object.assign({}, object);
    if (
      temp.rangeValues[0] > temp.rangeValues[1] &&
      event.target.value.includes('range')
    ) {
      temp.rangeValues.reverse();
      setObject(temp);
    }

    // moving from single array to array of arrays
    if (
      event.target.value.includes('split') &&
      !_.isArray(temp.rangeValues[0])
    ) {
      temp.rangeValues = [temp.rangeValues, [null, null]];
    }

    // moving back from array of arrays to single array
    if (
      !event.target.value.includes('split') &&
      _.isArray(temp.rangeValues[0])
    ) {
      temp.rangeValues = temp.rangeValues[0];
    }

    setObject({
      ...temp,
      rangeType: event.target.value,
    });
  };

  /**
   * changeluxValueCallback update the value of the ranges of the lightState
   * @param event, int index
   */
  const changeluxValueCallback = (event, index) => {
    const value = parseInt(event.target.value);
    const clone = _.clone(object);
    const { rangeType, rangeValues } = clone;

    if (!validateIsRange() && !validateIsSplit()) {
      switch (rangeType) {
        case 'below_value':
          rangeValues[1] = value;
          rangeValues[1] = value;
          break;
        case 'above_value':
          rangeValues[0] = value;
          break;
        default:
          rangeValues[index] = value;
          break;
      }

      rangeValues[0] = value;
      rangeValues[1] = value;
    } else if (validateIsRange() && !validateIsSplit()) {
      rangeValues[index] = value;

      if (rangeValues[0] > rangeValues[1]) {
        rangeValues.reverse();
      }
    }

    if (validateIsSplit()) {
      switch (index) {
        case 0:
          rangeValues[0][0] = value;
          break;
        case 1:
          rangeValues[0][1] = value;
          break;
        case 2:
          rangeValues[1][0] = value;
          break;
        default:
          // case 3
          rangeValues[1][1] = value;
          break;
      }
    }

    const thresholds = updateThresholdObjectAdv(rangeValues, object.rangeType);

    setObject({ ...clone, ...thresholds });
  };

  /**
   * Checks on split values for correctness
   * @param {number} value
   * @param {number} index
   */
  function checkForErrors() {
    const errorObj = _.clone(defaultSplitValueErrorObj);
    const { rangeValues } = object;
    if (!_.isArray(rangeValues[0])) {
      return;
    }

    // All Fields have input
    const noEmpties =
      (rangeValues[0][0] || rangeValues[0][0] === 0) &&
      (rangeValues[0][1] || rangeValues[0][1] === 0) &&
      (rangeValues[1][0] || rangeValues[1][0] === 0) &&
      (rangeValues[1][1] || rangeValues[1][1] === 0);

    if (noEmpties) {
      if (rangeValues[0][0] >= rangeValues[0][1]) {
        errorObj.lowLux1 = 'high';
        errorObj.isError = true;
      }

      if (rangeValues[0][1] <= rangeValues[0][0]) {
        errorObj.highLux1 = 'low';
        errorObj.isError = true;
      }

      if (rangeValues[0][1] >= rangeValues[1][0]) {
        errorObj.highLux1 = 'high';
        errorObj.isError = true;
      }

      if (rangeValues[1][0] <= rangeValues[0][1]) {
        errorObj.lowLux2 = 'low';
        errorObj.isError = true;
      }

      if (rangeValues[1][0] >= rangeValues[1][1]) {
        errorObj.lowLux2 = 'high';
        errorObj.isError = true;
      }

      if (rangeValues[1][1] <= rangeValues[1][0]) {
        errorObj.highLux2 = 'low';
        errorObj.isError = true;
      }
    } else {
      errorObj.isError = true;
    }
    setSplitValueErrors(errorObj);
  }

  /**
   * determines what string to show for an error for split value fields
   * valuelocation is which textfield error is showing
   * 0 = first low lux
   * 1 = first high lux
   * 2 = second low lux
   * 3 = second high lux
   * @param {number} valueLocation
   * @returns
   */
  function determineHelperTextValue(valueLocation) {
    const helperObj = { text: '', error: false };
    if (!validateIsSplit()) return helperObj;

    switch (valueLocation) {
      case 0:
        if (splitValueErrors.lowLux1) {
          helperObj.text = 'Must Be < First High Lux Value';
          helperObj.error = true;
        }
        break;
      case 1:
        if (splitValueErrors.highLux1 === 'low') {
          helperObj.text = 'Must Be > First Low Lux Value';
          helperObj.error = true;
        }
        if (splitValueErrors.highLux1 === 'high') {
          helperObj.text = 'Must Be < Second Low Lux Value';
          helperObj.error = true;
        }
        break;
      case 2:
        if (splitValueErrors.lowLux2 === 'low') {
          helperObj.text = 'Must Be > First High Lux Value';
          helperObj.error = true;
        }
        if (splitValueErrors.lowLux2 === 'high') {
          helperObj.text = 'Must Be < Second High Lux Value';
          helperObj.error = true;
        }
        break;
      default:
        // case 3
        if (splitValueErrors.highLux2) {
          helperObj.text = 'Must Be > Second High Lux Value';
          helperObj.error = true;
        }
        break;
    }
    return helperObj;
  }

  return (
    <>
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12}>
          <FormControl
            variant='outlined'
            className={classes.formSelect}
            error={invalidRangeTypeError}
          >
            <Select
              labelId='alert-profile-range-type'
              id='alert-profile-range-select'
              className={classes.select}
              inputProps={{
                name: objectDefinition.name,
                id: 'range-type-selector',
              }}
              defaultValue={0}
              onChange={changeRangeCallback}
              onOpen={() => setShowMarkers(false)}
              onClose={() => setShowMarkers(true)}
              value={object.rangeType}
              error={rangeSelError.rangeSelDropdown}
            >
              {rangeTypesOptions.map(({ name, value, isDefault }, index) => {
                const label = isDefault ? <em>{name}</em> : name;
                return (
                  <MenuItem
                    key={`alert__range__setting__option__${index}`}
                    value={value}
                  >
                    {label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        {validateIsSplit() && (
          <Grid item xs={12}>
            <Box className={classes.fontColor}>
              Input the values you wish to trigger your alert.
            </Box>
          </Grid>
        )}
        {validateIsSplit() && (
          <Grid item xs={12}>
            <Box className={classes.fontColor}>Lower Split Value</Box>
          </Grid>
        )}
        <Grid item xs={6}>
          <TextField
            variant='outlined'
            type='number'
            placeholder={
              validateIsRange()
                ? validateIsSplit()
                  ? 'Enter first low lux value'
                  : 'Enter low lux value'
                : 'Enter lux value'
            }
            onChange={event => changeluxValueCallback(event, 0)}
            value={
              validateIsSplit()
                ? object.rangeValues[0][0]
                : object.rangeValues[0]
            }
            className={classes.textField}
            InputProps={{ inputProps: { min: 0 } }}
            helperText={determineHelperTextValue(0).text}
            error={determineHelperTextValue(0).error}
          />
        </Grid>

        {(validateIsRange() || validateIsSplit()) && (
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              type='number'
              placeholder={
                validateIsSplit()
                  ? 'Enter first high lux value'
                  : 'Enter high lux value'
              }
              onChange={event => changeluxValueCallback(event, 1)}
              value={
                validateIsSplit()
                  ? object.rangeValues[0][1]
                  : object.rangeValues[1]
              }
              InputProps={{ inputProps: { min: 0 } }}
              className={classes.textField}
              helperText={determineHelperTextValue(1).text}
              error={determineHelperTextValue(1).error}
            />
          </Grid>
        )}

        {validateIsSplit() && (
          <>
            <Grid item xs={12}>
              <Box className={classes.fontColor}>Upper Split Value</Box>
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant='outlined'
                type='number'
                placeholder='Second low lux value'
                onChange={event => changeluxValueCallback(event, 2)}
                value={object.rangeValues[1][0]}
                InputProps={{ inputProps: { min: 0 } }}
                className={classes.textField}
                helperText={determineHelperTextValue(2).text}
                error={determineHelperTextValue(2).error}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant='outlined'
                type='number'
                placeholder='Second high lux value'
                onChange={event => changeluxValueCallback(event, 3)}
                value={object.rangeValues[1][1]}
                InputProps={{ inputProps: { min: 0 } }}
                className={classes.textField}
                helperText={determineHelperTextValue(3).text}
                error={determineHelperTextValue(3).error}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Divider variant='fullWidth' />
        </Grid>
      </Grid>
    </>
  );
}
