import React from 'react';

import {
  Grid,
  Avatar,
  Box,
  Typography,
  makeStyles,
  withStyles,
  Badge,
  IconButton,
} from '@material-ui/core';
import CardInfoIcons from '../Generic/CardInfoIcons';
import CardInfoIconsMobile from '../Generic/CardInfoIconsMobile';

import grey from '@material-ui/core/colors/grey';
import { navigate } from 'hookrouter';
import GenericListCard from '../Generic/GenericListCard';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Edit } from '@material-ui/icons';
import UserImage from '../../img/icons/user.svg';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

/**
 * Device Card
 * @param {Object} props
 * @props
 * id: Integer
 * trailingItems: Object Array [{ icon: React Component , value: String }]
 * name: String,
 * sensorId: String,
 * locationName: String
 */
export default function SingleLocationCard(props) {
  const {
    // id, // Id of the device
    trailingItems, // Trailing Items for the CardInfoIcons
    isItemsDict = false,
    name, // Name of the sensor (Title of the Card)
    // sensorId, // Message of the Card (Address)
    subtitle,
    subtitle2, //
    subtitle3,
    showOnlyItems = [],
    avatar,
    showAvatar = true,
    onClick = () => {},
    wrap,
    displayDivider,
    buttons,
    enableZero,
    resize,
    gatewayCard, // if for gateways
    sensorCard, // if for sensors
    singleGatewayCard,
    singleAlertCard,
    singleLocationCard,
    showStatus,
    onlineStatus,
    defaultAvatar,
    handleEditImage,
  } = props;
  /**
   * Event to send the browser to the user page
   */
  // const showDevice = () => {
  //   navigate(`/device/${id}`);
  // };
  const useStyles = makeStyles(theme => ({
    // cardContainer: {
    //   margin: theme.spacing(2, 0),
    //   paddingLeft: 20,
    // },
    cardInfoIcons: {
      maxWidth: sensorCard && '72%',
      transform: gatewayCard && 'translateY(-4px)',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        position: (gatewayCard || singleLocationCard) && 'absolute',
        right: gatewayCard ? 15 : singleLocationCard && 43,
        transform: singleAlertCard
          ? 'translate(85%, -86%)'
          : singleGatewayCard && 'translateY(28px)',
        maxWidth: sensorCard && '100%',
      },
      [theme.breakpoints.down('815')]: {
        transform: singleAlertCard && 'translate(80%, -86%)',
      },
      [theme.breakpoints.down('705')]: {
        transform: singleAlertCard && 'translate(74%, -86%)',
      },
      [theme.breakpoints.down('xs')]: {
        position: (gatewayCard || singleLocationCard) && 'absolute',
        // right: gatewayCard ? -70 : singleGatewayCard && -120,
        transform: singleAlertCard
          ? 'translate(16%, -86%)'
          : singleGatewayCard && 'translateY(37px)',
      },
    },
    trailingItems: {
      width: '40%',
    },
    leadingContainer: {
      width: '45%',
    },
    title: {
      fontSize: 14,
      fontWeight: 700,
      color: theme.palette.type === 'light' ? '#747474' : '#FFF',
      whiteSpace:  'nowrap',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
      },
    },
    subTitle: {
      fontSize: 12,
      color: theme.palette.type === 'light' ? '#747474' : '#FFF',
      whiteSpace:  'nowrap',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
      },
    },
    message: {
      color: grey[500],
      margin: 0,
    },
    avatarContainer: {
      marginTop: sensorCard && 5,
      [theme.breakpoints.down('md')]: {
        transform: (sensorCard || singleGatewayCard) && 'translateX(-40px)',
      },
      [theme.breakpoints.down('sm')]: {
        transform: sensorCard
          ? 'translate(149px,-5px)'
          : singleGatewayCard && 'translateX(0px)',

        marginTop: sensorCard && 0,
      },
      [theme.breakpoints.down('865')]: {
        transform: sensorCard && 'translate(93px,-5px)',

        marginTop: sensorCard && 0,
      },
      [theme.breakpoints.down('720')]: {
        transform: sensorCard && 'translate(36px,-5px)',

        marginTop: sensorCard && 0,
      },
      [theme.breakpoints.down('xs')]: {
        transform: sensorCard && 'translate(0px,-5px)',

        marginTop: sensorCard && 0,
      },
    },
    avatarLarge: {
      width: 74,
      height: 'auto',
      border: '1px solid #CCC',
    },
    cardContentTextContainer: {
      marginLeft:
        gatewayCard && !singleGatewayCard
          ? 37
          : singleGatewayCard
          ? 14
          : sensorCard && 15,
      marginTop: gatewayCard && 5,
      height: (gatewayCard || singleLocationCard) && 'fit-content',
      transform: singleGatewayCard && 'translateY(-12px)',
      [theme.breakpoints.down('md')]: {
        transform: sensorCard
          ? 'translateX(-64px)'
          : singleGatewayCard && 'translate(-64px, -12px)',
      },

      [theme.breakpoints.down('sm')]: {
        transform: singleGatewayCard && 'translate(12px,20px)',
        display: sensorCard && 'grid',
        marginLeft: (gatewayCard || singleLocationCard) && 0,
      },
      [theme.breakpoints.down('xs')]: {
        transform: sensorCard && 'translateX(0px)',

        display: sensorCard && 'grid',
      },
    },
    statusCircle: {
      right: '0%',
      bottom: '49%',
    },
    avatarImage: {
      objectFit: defaultAvatar && 'none',
      transform: defaultAvatar && gatewayCard && 'translateY(-5px)',
    },
    editImageButton: {
      '& svg': {
        fontSize: 14,
      },
    },
    editImageButtonContainer: {
      [theme.breakpoints.down('md')]: {
        transform: singleGatewayCard && 'translateX(-40px)',
      },
      [theme.breakpoints.down('sm')]: {
        transform: singleGatewayCard && 'translateX(0px)',
      },
    },
  }));
  const classes = useStyles();
  const theme = useTheme();
  const smallDevice = useMediaQuery(theme.breakpoints.down('xs'));
  const StyledBadge = withStyles(theme => ({
    anchorOriginBottomRightCircle: '', // To remove error from console
    badge: {
      width: 16,
      height: 16,
      borderRadius: '50%',
      backgroundColor: onlineStatus ? '#59C754' : '#FF6A6A',
      color: onlineStatus ? '#59C754' : '#FF6A6A',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: '$ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': onlineStatus && {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }))(Badge);

  return (
    <GenericListCard
      sensorCard={sensorCard}
      singleGatewayCard={singleGatewayCard}
      smallDevice={smallDevice}
    >
      <Grid container id='single_location_card'>
        <Grid
          container
          item
          xs={12}
          onClick={onClick}
          style={{ cursor: 'auto' }}
        >
          {showAvatar && showStatus ? (
            <Grid item xs={12} sm={gatewayCard ? 12 : 5} md={2} lg={1}>
              <Box
                className={classes.avatarContainer}
                display='flex'
                justifyContent='center'
              >
                <Avatar
                    classes={{ img: classes.avatarImage }}
                    alt="avatar"
                    src={avatar ? avatar : UserImage}
                    className={classes.avatarLarge}
                  />
                <StyledBadge
                  classes={{
                    anchorOriginBottomRightCircle: classes.statusCircle,
                  }}
                  overlap='circle'
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  variant='dot'
                >
                  
                </StyledBadge>
              </Box>
              {singleGatewayCard && (
                <Grid
                  item
                  xs={12}
                  display='flex'
                  className={classes.editImageButtonContainer}
                  style={{
                    marginTop: -10,
                    backgroundColor: grey,
                    textAlign: 'center',
                  }}
                >
                  <IconButton
                    style={{ color: '#ffffff', backgroundColor: grey[400] }}
                    size='small'
                    className={classes.editImageButton}
                    onClick={handleEditImage}
                  >
                    <Edit></Edit>
                  </IconButton>
                </Grid>
              )}
            </Grid>
          ) : showAvatar ? (
            <Grid item xs={12} sm={gatewayCard ? 12 : 5} md={2} lg={1}>
              <Box
                className={classes.avatarContainer}
                display='flex'
                justifyContent='center'
              >
                <Avatar
                  classes={{ img: classes.avatarImage }}
                  alt='avatar'
                  src={avatar ? avatar : UserImage}
                  className={classes.avatarLarge}
                />
              </Box>
            </Grid>
          ) : null}
          {/* name and sensors container */}
          <Grid
            item
            container
            xs={12}
            sm={gatewayCard || singleLocationCard ? 12 : 7}
            md={showAvatar ? 10 : 12}
            lg={showAvatar ? 11 : 12}
            style={{
              flexWrap:
                (gatewayCard || singleLocationCard) && !smallDevice && 'nowrap',
            }}
          >
            <Grid
              item
              container
              direction='column'
              justify='center'
              xs={gatewayCard || singleLocationCard ? 12 : 12}
              sm={gatewayCard || singleLocationCard ? 12 : 12}
              md={resize ? 9 : 3}
              className={classes.cardContentTextContainer}
              // style={{ height: "fit-content" }}
            >
              <Typography className={classes.title} gutterBottom>
                {name}
              </Typography>
              <Typography className={classes.subTitle} gutterBottom>
                {subtitle}
              </Typography>
              <Typography className={classes.subTitle} gutterBottom>
                {subtitle2}
              </Typography>
              <Typography className={classes.subTitle} gutterBottom>
                {subtitle3}
              </Typography>
            </Grid>

            {smallDevice ? (
              <Grid item xs={12}>
                <CardInfoIconsMobile
                  singleLocationCard={singleLocationCard}
                  singleAlertCard={singleAlertCard}
                  singleGatewayCard={singleGatewayCard}
                  sensorCard={sensorCard}
                  gatewayCard={gatewayCard}
                  wrapItems={wrap}
                  displayDivider={displayDivider}
                  isDict={isItemsDict}
                  items={trailingItems}
                  showOnlyItems={showOnlyItems}
                  buttons={buttons}
                  enableZero={enableZero}
                />
              </Grid>
            ) : (
              <Grid
                item
                container
                xs={gatewayCard || singleLocationCard ? 12 : 12}
                sm={gatewayCard || singleLocationCard ? 12 : 12}
                md={resize ? 3 : 9}
                direction='row'
                className={classes.cardInfoIcons}
              >
                <CardInfoIcons
                  singleLocationCard={singleLocationCard}
                  singleAlertCard={singleAlertCard}
                  singleGatewayCard={singleGatewayCard}
                  sensorCard={sensorCard}
                  gatewayCard={gatewayCard}
                  wrapItems={wrap}
                  displayDivider={displayDivider}
                  isDict={isItemsDict}
                  items={trailingItems}
                  showOnlyItems={showOnlyItems}
                  buttons={buttons}
                  enableZero={enableZero}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </GenericListCard>
  );
}