import React from 'react';
import clsx from 'clsx';
import {
  Box,
  Typography,
  Dialog,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  IconButton,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ImageButton from '../Generic/ImageButton';
import logo from '../../img/MiSensorsLogo.svg';
import logoIcon from '../../img/MiSensorLogo_Icon.svg';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import grey from '@material-ui/core/colors/grey';

const useStyles = makeStyles(theme => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
  },
  gatewaySelect: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
  },
  fieldPadding: {
    paddingBottom: theme.spacing(4),
  },
  textFieldPadding: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  descriptionText: {
    fontSize: 16,
    color: theme.palette.type === 'light' ? '#747474' : '#FFF',
  },
  inputNormal: {
    height: '50px',
    marginTop: '50px',
    background: 'white',
    border: '1px solid grey',
    padding: '4px',
    borderRadius: '5px',
    paddingLeft: '15px',
  },
  inputFocus: {
    height: '50px',
    marginTop: '50px',
    background: 'white',
    border: `2px solid ${theme.palette.primary.main}`,
    padding: '4px',
    borderRadius: '5px',
    paddingLeft: '15px',
    width: '100%',
    color: theme.palette.type === 'light' ? grey[600] : '#FFF',
  },
  error: {
    borderColor: '#f44336',
  },
  leftButton: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  rightButton: {
    width: 100,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: '1rem',
    },
  },
  formContainer: {
    height: `100%`,
    backgroundColor:
      theme.palette.type === 'light'
        ? '#F4F5F5'
        : theme.palette.background.default,
  },
  actionContainer: {
    paddingTop: theme.spacing(4),
    backgroundColor:
      theme.palette.type === 'light'
        ? '#F4F5F5'
        : theme.palette.background.default,
  },
  titleContainer: {
    backgroundColor: theme.palette.background.paper,
  },
  titleText: {
    fontWeight: 'bold',
    color: theme.palette.type === 'light' ? '#747474' : '#FFF',
  },
  cardContainer: {
    overflowX: 'hidden',
    overflowY: 'auto',
    height: `100%`,
  },
}));

/**
 * Account Settings Form Modal
 * @param {Object} props
 * @props
 * openState: Boolean
 * handleClose: Function
 * handleSave: Function
 */
const ChangeCompanyLogos = props => {
  const {
    openState,
    handleClose,
    handleSave,
    platformImageUploadHandler,
    faviconImageUploadHandler,
    platformImageLocation,
    setPlatformImageLocation,
    faviconImageLocation,
    setFaviconImageLocation,
  } = props;

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [fullWidth, setFullWidth] = React.useState(false);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const classes = useStyles();
  const [values, setValues] = React.useState({
    password: '',
    confirmPassword: '',
  });
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  return (
    <Dialog
      fullScreen={fullScreen} // commented temporarily because it breaks the layout
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={openState}
      onClose={handleClose}
      aria-labelledby='responsive-dialog-title'
    >
      <Card className={classes.cardContainer}>
        <CardContent
          style={{
            padding: 0,
            margin: '0 auto',
            marginTop: '16px',
          }}
          className={classes.formContainer}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} className={classes.titleContainer}>
              <Typography className={classes.titleText} align='center'>
                UPLOAD COMPANY LOGOS
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs={12}
              style={
                fullScreen
                  ? {}
                  : {
                      paddingLeft: theme.spacing(6),
                      paddingRight: theme.spacing(6),
                      paddingBottom: 0,
                    }
              }
              className={classes.formContainer}
            >
              <Grid item xs={12} className={classes.textFieldPadding}>
                <p className={classes.descriptionText}>
                  Change your company logo and favicon images.
                </p>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              style={
                fullScreen
                  ? {
                      paddingLeft: 22,
                      paddingRight: theme.spacing(2),
                      paddingBottom: theme.spacing(2),
                    }
                  : {
                      paddingLeft: theme.spacing(8),
                      paddingRight: theme.spacing(8),
                      paddingBottom: theme.spacing(2),
                      paddingTop: 0,
                    }
              }
              className={classes.formContainer}
              spacing={1}
            >
              <Grid item xs={12} sm={6}>
                <Box
                  display='flex'
                  style={{ position: 'relative', padding: 46 }}
                >
                  {!platformImageLocation ? (
                    <ImageButton
                      image={
                        theme.palette.type === 'light'
                          ? 'https://misensors-images.s3.amazonaws.com/App+Image+Resources/MiSensorsLogo.svg'
                          : 'https://misensors-images.s3.amazonaws.com/App+Image+Resources/MiSensorsLogo+-+DarkMode.svg'
                      }
                      action='UPLOAD'
                      text='MAIN LOGO'
                      component='label'
                      uploadHandler={platformImageUploadHandler}
                    />
                  ) : (
                    <>
                      <ImageButton
                        image={platformImageLocation}
                        action='UPLOAD'
                        text='MAIN LOGO'
                        component='label'
                        uploadedImg={false}
                        uploadHandler={platformImageUploadHandler}
                      />
                      <IconButton
                        data-testid='delete_main_logo'
                        aria-label='delete'
                        style={{ position: 'absolute', right: 47 }}
                        size='small'
                        onClick={() => setPlatformImageLocation('')}
                      >
                        <HighlightOffIcon />
                      </IconButton>
                    </>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box
                  display='flex'
                  style={{ position: 'relative', padding: 46 }}
                >
                  {!faviconImageLocation ? (
                    <ImageButton
                      image={
                        theme.palette.type === 'light'
                          ? 'https://misensors-images.s3.amazonaws.com/App+Image+Resources/MiSensorLogo_Icon.svg'
                          : 'https://misensors-images.s3.amazonaws.com/App+Image+Resources/MiSensorLogo_Icon+-+DarkMode.svg'
                      }
                      action='UPLOAD'
                      text='FAVICON'
                      component='label'
                      uploadHandler={faviconImageUploadHandler}
                    />
                  ) : (
                    <>
                      <ImageButton
                        image={faviconImageLocation}
                        action='UPLOAD'
                        text='FAVICON'
                        component='label'
                        uploadedImg={false}
                        uploadHandler={faviconImageUploadHandler}
                      />
                      <IconButton
                        data-testid='delete_favicon'
                        aria-label='delete'
                        style={{ position: 'absolute', right: 47 }}
                        size='small'
                        onClick={() => setFaviconImageLocation('')}
                      >
                        <HighlightOffIcon />
                      </IconButton>
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <CardActions className={classes.actionContainer}>
            <Grid
              container
              style={{
                paddingLeft: theme.spacing(8),
                paddingRight: theme.spacing(8),
                marginBottom: theme.spacing(4),
              }}
            >
              <Grid item xs={12} sm={6}>
                <Button
                  className={classes.leftButton}
                  onClick={handleClose}
                  size='large'
                  variant='outlined'
                  color='primary'
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} container justify='flex-end'>
                <Button
                  className={classes.rightButton}
                  size='large'
                  variant='contained'
                  color='primary'
                  onClick={handleSave}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </CardContent>
      </Card>
    </Dialog>
  );
};

export default ChangeCompanyLogos;
