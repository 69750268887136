import React from "react";

import {
  Box,
  Grid,
  Typography
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import grey from "@material-ui/core/colors/grey";

/**
 *
 * @param {*} props
 */
export default function TiledCard(props) {
  const {
    children, // Children : React.component
    trailingItem, // Trailing item : React.component
    bottomItem, // Trailing item : React.component
    title = "", // Title : string
    subtitles = [], // Subtitles [string]
    backgroundColor = '#FFFFFF', // Background color
    gutterBottom = false, // negative margin between subtitles and title
    ...other
  } = props;

  const useStyles = makeStyles((theme) => ({
    tileStyles: {
      backgroundColor: backgroundColor,
      padding: theme.spacing(2),
      borderBottom: `1px solid ${grey[300]}`,
    },
    title: {
      fontWeight: "bold",
      lineHeight: 1
    },
    subtitle: {
      lineHeight: 1
    }
  }));

  const classes = useStyles();

  return (
    <Box
      className={classes.tileStyles}
      {...other}
    >
      <Grid
        container
      >
        <Grid
          item
          container
          xs={trailingItem !== undefined ? 8 : 12}
        >
          <Grid item xs={12} style={{ marginBottom: gutterBottom ? -15 : 0}}>
            <Typography
              color="text.primary"
              className={classes.title}
            >
              { title }
            </Typography>
          </Grid>
          {subtitles.map((item, index) => (
            <Grid item xs={12} style={{ marginBottom: gutterBottom && index === 0 ? -15 : 0}}
            >
              <Typography
                color="text.primary"
                className={classes.subtitle}
              >
                { item }
              </Typography>
            </Grid>
          ))}
        </Grid>
        { trailingItem && (
          <Grid item xs={4}>
            { trailingItem }
          </Grid> )
        }
        <Grid item xs={12}>
          {children}
        </Grid>
        

      </Grid>
    </Box>
  )
};
