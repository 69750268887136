import React from "react";
import HeaderBar from "../Generic/HeaderBar";
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';



const UpdateDevices = () => {
  return  (
    <div>
      <HeaderBar
        dashboardHeader={true}
        leadingIcon={
          <SystemUpdateAltIcon
            style={{
              fontSize: "1.75rem",
              marginLeft: 7,
            }}
            color="primary"
          />
        }
        title="Update Devices"
        searchInput={false}
        addButton={false}
      ></HeaderBar>
    </div>
  );
};

export default UpdateDevices;
