import React from 'react';
import grey from '@material-ui/core/colors/grey';
import { Box, Button, Divider, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  card: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    width: '100%',
    padding: theme.spacing(1),
    border: '1px solid #cccccc',
    margin: theme.spacing(1.5, 0),
  },
  content: {
    color: theme.palette.type === 'light' ? grey[600] : grey[400],
    fontSize: '15px',
    margin: theme.spacing(0, 1),
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px'
    }
  },
  sensorValue: {
    color: theme.palette.type === 'light' ? grey[600] : grey[400],
    fontSize: '18px',
    margin: theme.spacing(0, 1),
  },
  button: {
    marginRight: 6,
    [theme.breakpoints.down('sm')]: {
      fontSize: '8px'
    }
  },
}));

/**
 * Small input like card to show sensor value in add alert form
 */
export default function SensorValueCard(props) {
  const {
    icon,
    sensorName,
    readingName,
    sensorValue,
    manageButtonClick, // This is for the managealertmodal
    hasButton = false,
  } = props;

  const classes = useStyles();

  const determineButton = () => {
    if (hasButton) {
      return (
        <Button
          color='primary'
          variant='outlined'
          className={classes.button}
          onClick={manageButtonClick}
        >
          SELECT BASELINE SENSOR
        </Button>
      );
    } else {
      return (
        <Box display='flex' alignItems='center'>
          <Box className={classes.content}>{readingName}</Box>
          <Divider orientation='vertical' flexItem />
          <Box className={classes.sensorValue}>{sensorValue}</Box>
        </Box>
      );
    }
  };

  return (
    <Box
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      className={classes.card}
    >
      {/* icon ,sensorName section */}
      <Box display='flex' alignItems='center'>
        <Box>{icon}</Box>
        <Box className={classes.content}>{sensorName}</Box>
      </Box>

      {/* readingName ,sensorValue  section*/}
      {determineButton()}
    </Box>
  );
}
