import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from './../../AppContext';
import { Grid, Button } from '@material-ui/core';
import { navigate, A } from 'hookrouter';
import MUIDataTable from 'mui-datatables';
import GroupIcon from '@material-ui/icons/Group';
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';

import UserCard from './UserCard';
import UserCardMobile from './UserCardMobile';
import HeaderBar from '../Generic/HeaderBar';
import { EventBus } from '../common/EventBus';
import fetchAll from '../common/api/fetchAll';
import { permissions } from '../../permissions';
import LoadingSpinner from '../Generic/LoadingSpinner';
import DeleteUserFormModal from './DeleteUserFormModal';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

// mobile components
import {
  MobileTabsContainer,
} from "../Generic/Mobile/MobileTabs"

import MobileSearchBar from '../Generic/Mobile/MobileSearchBar'
import MobileBottomButton, { MobileBottomButtonSpacer } from '../Generic/Mobile/MobileBottomButton'
import {
  FullViewContainer,
  ListViewContainer
} from '../Generic/Mobile/MobileContainers'

// permissions

import userPermissions from './user_permission';

const useStyles = makeStyles(theme => ({
  tableTitle: {
    marginLeft: '1rem',
    fontWeight: 500,
    fontSize: '1.5rem',
  },
  breadcrumbs: {
    color: theme.palette.text.primary,
  },
  mobileListContainer: {
    marginTop: 10,
    overflowX: "hidden",
  },
  // mobileBottomButton: {
  //   width: '100%',
  //   position: 'fixed',
  //   bottom: 0,
  //   height: 45,
  //   borderRadius: "15px 15px 0px 0px"
  // },
  // mobileSpacer: {
  //   content: "",
  //   height: '45px',
  //   width: '100%',
  // },
  mobilePagination: {
    marginTop: '15px'
  }
}));

const AllSubUsers = (props) => {
  const log = window.log('AllSubUsers');
  const classes = useStyles();

  const {
    snack,
    appState,
    setAppState,
    openUserModal,
    openAlertModal,
    setLogoutState,
    openSensorModal,
    openGatewayModal,
    setOpenUserModal,
    getProfileSetting,
    openLocationModal,
    setOpenAddingModal,
    setOpenDeleteUserModal,
    darkModeContainerColor
  } = useContext(AppContext);

  /**
   * Mobile view variables
   */
  const isMobileSize = isWidthDown('xs', props.width)

  /**
   * End of mobile view variables
   */

  const [state, setState] = useState();

  const { reloadPage } = props;

  const [userInfo, setUserInfo] = useState({});
  const [originalData, setOriginalData] = useState([]);
  const [initialPageLoad, setInitialPageLoad] = useState(true);
  const [listView, setListView] = useState(
    getProfileSetting('pageView.subUsers') || false,
  );

  const filterColumns = ['fname', 'email'];

  useEffect(() => {
    getUsers();
  }, [
    reloadPage,
    openUserModal,
    openAlertModal,
    openSensorModal,
    openGatewayModal,
    openLocationModal,
  ]);

  //After DCS Admin switches account, fetch that account's data
  useEffect(() => {
    if (!appState.isSwitched) return;
    getUsers(true);
    setAppState({ ...appState, isSwitched: false });
  }, [appState.isSwitched]);

  const getUsers = async switchedAccount => {
    try {
      const json = await fetchAll(
        'user',
        appState.auth.userInfo.companyId,
        appState.auth.token,
      );

      log('Looking at the users json ', json);
      if (json.success) {
        const { users } = json.data;
        setOriginalData({ users });
        setState(users);
        log('json.data.users:', json.data);
      } else {
        if (switchedAccount) setState();
        json.errors.forEach(err => {
          snack(err.msg, 'error');
          if (err.type === 'token') setLogoutState(true);
        });
      }
    } catch (err) {
      snack('Network Error', 'error');
    }
    setInitialPageLoad(false);
  };

  const options = {
    onRowClick: selectedRow => {
      navigate(`/user/${selectedRow[0]}`);
    },
    responsive: 'standard',
    selectableRows: 'none',
    search: false,
    fixedHeader: false,
  };

  const columns = [
    {
      name: 'id',
      options: {
        viewColumns: false,
        display: false,
        filter: false,
      },
    },
    {
      name: 'fname',
      label: 'First Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'lname',
      label: 'Last Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'perm',
      label: 'Permission Level',
      options: {
        filter: true,
        sort: true,
        customBodyRender: value =>
          permissions[value]?.title || 'error with permissions function',
      },
    },
  ];

  const handleOpenEditUserModal = user => {
    log('Looking at user info ', user);
    EventBus.dispatch('editSubUserModalProps', {
      id: user.id,
      email: user.email,
      fname: user.fname,
      lname: user.lname,
      phoneNo: user.phoneNumber,
      perm: user.perm,
      permChanged: user.permChanged,
      updatePage: 'AllSubUsers',
    });
    setOpenUserModal(true, true);
  };

  const handleOpenDeleteUserModal = user => {
    log('open delete user modal clicked');
    log('Looking at the user data\n', user);
    log('Looking at token data\n', appState.auth.userInfo);
    if (user.id === appState.auth.userInfo.id) {
      snack('A user cannot delete themselves', 'error');
    } else {
      setUserInfo(user);
      setOpenDeleteUserModal(true);
    }
  };

  const searchCallback = (value) => {
    let filteredArray = [];
    //Iterate over every gateway object
    for (var i = 0; i < originalData['users'].length; i++) {
      let match = false;
      //Iterate over every filter condition
      for (var j = 0; j < filterColumns.length; j++) {
        //Check for match
        if (
          originalData['users'][i][filterColumns[j]]
            .toLowerCase()
            .includes(value.toLowerCase())
        ) {
          match = true;
        }
      }
      //If match add to filtered array
      if (match) {
        filteredArray.push(originalData['users'][i]);
      }
    }
    setState(filteredArray);
  }

  let breadcrumbs = (
    <div style={{ display: 'inline-flex' }}>
      <A className={classes.breadcrumbs} href='/'>
        Dashboard
      </A>
      <div>{'\xa0-\xa0'}</div>
    </div>
  );

  const openAddModal = () => {
    setOpenAddingModal(true);
  };

  const sortButtonEvent = () => {
    // NOTE: This may comeback in future ui designs
    // updateUserProfile('pageView.subUsers', !listView);
    setListView(!listView);
  };

  useEffect(() => {
    setListView(getProfileSetting('pageView.subUsers'));
  }, [appState?.pageView?.locations]);

  //  Pagination
  const itemsPerPage = 10;
  const [page, setPage] = React.useState(1);

  const handleChange = (event, value) => {
    setPage(value);
  };

  return initialPageLoad ? (
    <LoadingSpinner />
  ) : state ? (
    <div>
      {
        isMobileSize ?
        <FullViewContainer>
          <DeleteUserFormModal userInfo={userInfo} getUsers={getUsers} />
          {/* Generic Search  Bar */}
          <MobileTabsContainer>
            <MobileSearchBar
              searchCallback={searchCallback}
            />
          </MobileTabsContainer>
          <ListViewContainer>
            <Grid
              container
              spacing={0}
              className={classes.mobileListContainer}
            >
              {state ? (
                state.map((user, index) => {
                  
                  
                    return (
                      <Grid item xs={12} sm={6} md={6} key={user.id}>
                        <UserCardMobile
                          index={index}
                          showAvatar={false}
                          showActions={false}
                          isTileLayout={true}
                          backgroundColor={darkModeContainerColor() ?  index % 2 === 0 ? '#212121' :'#424242' : index % 2 === 0 ? '#FFF' : '#F4F5F5'}
                          key={user.id}
                          id={user.id}
                          name={`${user.fname} ${user.lname}`}
                          email={user.email}
                          companyName={user.company.name}
                          perm={user.perm}
                          avatar={
                            user.profile && user.profile.profileImageLocation
                              ? user.profile.profileImageLocation
                              : ''
                          }
                          handleOpenEditUserModal={() =>
                            handleOpenEditUserModal(user)
                          }
                          handleOpenDeleteUserModal={() =>
                            handleOpenDeleteUserModal(user)
                          }
                          isUser={
                            appState.auth.userInfo.id === user.id ? true : false
                          }
                          pendingUser={user.pending} 
                        />
                      </Grid>
                    );
                  
                  
                })
              ) : null}
            </Grid>
            <MobileBottomButtonSpacer />
          </ListViewContainer>
          <MobileBottomButton
            onClick={openAddModal}
          />
        </FullViewContainer>
        :
        <div>
          {/* <SubUserFormModal getUsers={getUsers} /> */}
          <DeleteUserFormModal userInfo={userInfo} getUsers={getUsers} />
          <HeaderBar
            leadingIcon={<GroupIcon style={{ fontSize: 32 }} color='primary' />}
            // breadcrumbs={breadcrumbs}
            title='Users'
            buttonAddTitle='ADD USER'
            buttonAddEvent={openAddModal}
            searchCallback={searchCallback}
            sortActive={listView}
            sortButton={true}
            sortButtonEvent={sortButtonEvent}
          />
          <Grid container spacing={2}>
            {state ? (
              listView ? (
                <Grid xs={12} item>
                  <MUIDataTable
                    title={'Users'}
                    data={state}
                    columns={columns}
                    options={options}
                  />
                </Grid>
              ) : (
                state
                  .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                  .map(user => {
                      return (
                        <Grid item xs={12} sm={6} md={6} key={user.id}>
                          <UserCard
                            key={user.id}
                            id={user.id}
                            name={`${user.fname} ${user.lname}`}
                            email={user.email}
                            companyName={user.company.name}
                            perm={user.perm}
                            avatar={
                              user.profile && user.profile.profileImageLocation
                                ? user.profile.profileImageLocation
                                : ''
                            }
                            handleOpenEditUserModal={() =>
                              handleOpenEditUserModal(user)
                            }
                            handleOpenDeleteUserModal={() =>
                              handleOpenDeleteUserModal(user)
                            }
                            isUser={
                              appState.auth.userInfo.id === user.id ? true : false
                            }
                             pendingUser={user.pending} // once the user clicks join, no longer pending
                          />
                        </Grid>
                      );
                  })
              )
            ) : null}
            {!listView && (
              <Grid item xs={12} container justify='center'>
                <Pagination
                  variant='outlined'
                  count={Math.ceil(state.length / itemsPerPage)}
                  page={page}
                  onChange={handleChange}
                  defaultPage={1}
                  showFirstButton
                  showLastButton
                />
              </Grid>
            )}
          </Grid>
        </div>
      }
    </div>
  ) : null;
};

export default withWidth()(AllSubUsers);
