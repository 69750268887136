import React from 'react';
import { Grid, makeStyles, TextField } from '@material-ui/core';

import ModalFormHeader from '../../common/ModalComponents/ModalFormHeader';

const useStyles = makeStyles(theme => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    height: 55,
  },
  helperText: {
    margin: 0,
    marginRight: 14,
    marginLeft: 14,
    marginTop: 3,
    color: 'rgba(0, 0, 0, 0.54)',
    textAlign: 'left',
    lineHeight: 1.66,
    fontWeight: 400,
  },
}));

/**
 * Location Form Modal
 * @param {Object} props
 */
export default function LocationName(props) {
  const classes = useStyles();

  const { name, isEdit, setName, nameError } = props;

  return (
    <Grid container item xs={12} spacing={2}>
      <ModalFormHeader
        header={
          isEdit ? 'Edit your location name' : 'Give your location a name'
        }
        desc='Giving your location a name will you help you locate and manage all of your gateways and devices.'
      />
      <Grid item xs={12}>
        <TextField
          name='name'
          error={nameError}
          value={name}
          className={classes.textField}
          onChange={e => setName(e.target.value)}
          fullWidth
          variant='outlined'
          placeholder='Location Name'
          label='Location Name'
          inputProps={{
            maxLength: 60,
          }}
          style={{
            width: '105%',
          }}
        />
        <p className={classes.helperText}>{`${name.length}/60`}</p>
      </Grid>
    </Grid>
  );
}
