import React from 'react';
import { TextField, Grid } from '@material-ui/core';

import LocFormZip from './LocFormZip';
import LocFormCity from './LocFormCity';
import CurrLocButton from './CurrLocButton';
import LocFormStates from './LocFormStates';
import LocFormStreets from './LocFormStreets';

const LocationForm = props => {
  const {
    city,
    state,
    street,
    classes, // requires classes.textField
    setCity,
    zipcode,
    setState,
    cityError,
    setStreet,
    setZipcode,
    stateError,
    companyName,
    streetError,
    zipcodeError,
    setCompanyName,
    addCompanyField,
    companyNameError,
    removeUseCurrentLocation,
  } = props;

  return (
    <>
      {!removeUseCurrentLocation && (
        <CurrLocButton
          setCity={setCity}
          setState={setState}
          setStreet={setStreet}
          setZipcode={setZipcode}
        />
      )}
      {addCompanyField && (
        <Grid item xs={12}>
          <TextField
            name='companyName'
            value={companyName}
            className={classes.textField}
            onChange={e => setCompanyName(e.currentTarget.value)}
            fullWidth
            required
            variant='outlined'
            label='Company Name'
            placeholder='Company Name*'
            error={companyNameError}
            style={{
              width: '105%',
            }}
          />
        </Grid>
      )}
      <LocFormStreets
        street={street}
        classes={classes}
        setCity={setCity}
        setState={setState}
        setStreet={setStreet}
        setZipcode={setZipcode}
        streetError={streetError}
      />
      <LocFormCity
        city={city}
        classes={classes}
        setCity={setCity}
        cityError={cityError}
      />
      <LocFormStates
        state={state}
        classes={classes}
        setState={setState}
        stateError={stateError}
      />
      <LocFormZip
        classes={classes}
        zipcode={zipcode}
        setZipcode={setZipcode}
        zipcodeError={zipcodeError}
      />
    </>
  );
};

export default LocationForm;
