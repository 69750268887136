import _ from 'lodash';

/* Per the ticket:
Lux Value Mapping:

Off - 0 to 50

Medium Light - 51 to 1500

High Light - 1501 to 10000

Very High Light - > 10001
*/
export function calculateLuxDisplay(value) {
  if (value <= 50) {
    return 'Off';
  }

  if (value >= 51 && value <= 1500) {
    return 'Low';
  }

  if (value >= 1501 && value <= 10000) {
    return 'Medium';
  }

  if (value >= 10001) {
    return 'High';
  }

  return '-';
}

export function determineSimpleLuxNum(value) {
  if (value <= 50) {
    return 0;
  }

  if (value >= 51 && value <= 1500) {
    return 1;
  }

  if (value >= 1501 && value <= 10000) {
    return 2;
  }

  if (value >= 10001) {
    return 3;
  }

  return '-';
}

// split simple values to still show a range on the manage alert slider
export function determineSplitSimple(valueArr) {
  let value1 = determineSimpleLuxNum(valueArr[0]);
  let value2 = determineSimpleLuxNum(valueArr[1]);

  // lower value
  switch (value1) {
    case 1:
      value1 = 0.51;
      break;
    case 2:
      value1 = 1.51;
      break;
    case 3:
      value1 = 2.51;
      break;
    default:
      // 0
      break;
  }

  // higher value
  switch (value2) {
    case 0:
      value1 = 0.5;
      break;
    case 1:
      value1 = 1.5;
      break;
    case 2:
      value1 = 2.5;
      break;
    default:
      // 3
      break;
  }
  return [value1, value2];
}

export function determineSplitAdv(valueArr, type) {
  switch (type) {
    case 'Below Value':
      return [+valueArr[1][1], +valueArr[1][1]];
    case 'Above Value':
      return [+valueArr[0][0], +valueArr[0][0]];
    case 'Outside of Range':
      return [+valueArr[0][1], +valueArr[1][0]];
    case 'Inside of Range':
      return [+valueArr[0][0], +valueArr[1][1]];
    default:
      // "Split Value"
      return valueArr;
  }
}

// determines state of the checkboxes in basic view for editing light alert
export const determineCheckValues = (values, type, adv) => {
  if (adv) {
    if (_.isArray(values[0])) {
      values = [
        determineSplitSimple(values[0]),
        determineSplitSimple(values[1]),
      ];
    } else {
      values = determineSplitSimple(values);
    }
  }

  const checkValues = [false, false, false, false];
  if (type === 'below_value') {
    for (let i = 0; i <= values[0]; i++) {
      checkValues[i] = true;
    }
  }
  if (type === 'above_value') {
    for (let i = checkValues.length - 1; i >= values[0]; i--) {
      if (values[1] >= 1.51 && values[1] <= 2.5) checkValues[2] = true;
      if (values[0] >= 0.51 && values[1] <= 1.5) checkValues[1] = true;
      checkValues[i] = true;
    }
  }
  if (type === 'outside_range') {
    checkValues[0] = true;
    checkValues[3] = true;
    // if (values[0] === 1) checkValues[1] = true;
    // if (values[1] === 2) checkValues[2] = true;
    if (values[1] >= 1.51 && values[1] <= 2.5) checkValues[2] = true;
    if (values[0] >= 0.51) checkValues[1] = true;
  }

  if (type === 'inside_range') {
    for (let i = 0; i < values.length; i++) {
      if (values[1] >= 1.51 && values[1] <= 2.5) checkValues[2] = true;
      if (values[0] >= 0.51) checkValues[1] = true;
      checkValues[values[i]] = true;
    }
    // In case all 4 boxes are checked
    if (checkValues[0] && checkValues[3]) {
      checkValues[1] = true;
      checkValues[2] = true;
    }
  }

  if (type === 'split_value') {
    if (values[0][1] === 0) checkValues[0] = true;
    if (values[0][1] === 1) checkValues[1] = true;
    if (values[1][1] === 2) checkValues[2] = true;
    if (values[1][1] === 3) checkValues[3] = true;
  }

  return checkValues;
};

export function determineSimpleLuxDisplay(value) {
  if (value < 0.51) return 'Off';
  if (value > 0.5 && value < 1.51) return 'Low';
  if (value > 1.5 && value < 2.51) return 'Medium';
  if (value > 2.5 && value <= 3) return 'High';
}

// since light ranges are not selected by user in BASIC we must determine them by clicked checkboxes
export const determineLightRangeType = lightObj => {
  const { checkValues, rangeType, lightSettings } = lightObj;

  // If user has selected a range type in ADVANCED
  if (rangeType && lightSettings === 'advanced') return rangeType;

  // checkValues looks like this [true, false, true, false]
  // if true in pos 0 and 3 range type must be outside range
  // unless all 4 are checked
  if (checkValues[0] && checkValues[3]) {
    if (checkValues[1] && checkValues[2]) return 'inside_range';
    else return 'outside_range';
  }

  // if false in pos 0 and 3 range type must be inside range
  if (!checkValues[0] && !checkValues[3]) return 'inside_range';

  // if true in pos 0 and false in pos 3 may either be below or split
  if (checkValues[0] && !checkValues[3]) {
    if (!checkValues[1] && checkValues[2]) return 'split_value';
    else return 'below_value';
  }
  // if true in pos 3 and false in pos 0 may either be above or split
  if (!checkValues[0] && checkValues[3]) {
    if (checkValues[1] && !checkValues[2]) return 'split_value';
    else return 'above_value';
  }
};
