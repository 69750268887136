import React, { useState, useEffect, useContext } from 'react';

import { EventBus } from '../common/EventBus';
import { AppContext } from '../../AppContext';
import marker from '../../img/place-24px.svg';
import ImageButton from '../Generic/ImageButton';
import Loading from '../common/ModalComponents/Loading';
import ResponsiveDialog from '../Generic/GenericDialog';
import Complete from '../common/ModalComponents/Complete';
import EnterUserInfo from './SubUserFormModalComponents/EnterUserInfo';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

/**
 * Location Form Modal
 */
export default function SubUserFormModal() {
  const log = window.log('SubUserFormModal');
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    snack,
    appState,
    setAppState,
    openUserModal,
    setLogoutState,
    setFeatureCount,
    setOpenUserModal,
    limitReachedSnackbar,
  } = useContext(AppContext);

  const [userInfo, setUserInfo] = useState({
    fname: '',
    lname: '',
    email: '',
    permissions: '',
  });

  const [errors, setErrors] = useState({
    fnameError: false,
    lnameError: false,
    emailError: false,
    permissionsError: false,
  });

  const [isEdit, setIsEdit] = useState(false);
  const [updatePage, setUpdatePage] = useState('');
  const [editUserInfo, setEditUserInfo] = useState({});

  const setDefaultObject = (editObj = null) => {
    if (editObj) {
      setIsEdit(true);
      setUserInfo({
        id: editObj.id,
        email: editObj.email,
        fname: editObj.fname,
        lname: editObj.lname,
        phoneNo: editObj.phoneNo,
        permissions: editObj.perm,
        permChanged: editObj.permChanged,
      });
      setEditUserInfo({ ...editObj });
      setUpdatePage(editObj.updatePage);
    } else {
      setUserInfo({
        fname: '',
        lname: '',
        email: '',
        permissions: '',
      });
    }
    setErrors({
      fnameError: false,
      lnameError: false,
      emailError: false,
      permissionsError: false,
    });
  };

  useEffect(() => {
    setDefaultObject();
    EventBus.on('editSubUserModalProps', data => {
      setDefaultObject(data);
    });

    return () => {
      EventBus.remove('editSubUserModalProps');
    };
  }, []);

  const handleEditCallBack = () => {
    if (updatePage === 'AccountSettings') {
      EventBus.dispatch('AccountSettingsSubUserModalCallBacks', { userInfo });
    } else if (updatePage === 'SingleSubUser') {
      EventBus.dispatch('SingleSubUserSubUserModalCallBacks');
    }
  };

  // Marks Add User as complete for onboard
  const handleUserFeatureComplete = async () => {
    let changes = {
      ...appState.auth.userInfo.features,
      addUser: true,
    };

    try {
      const data = {
        themePreferences: appState.auth.userInfo.themePreferences
          ? JSON.stringify(appState.auth.userInfo.themePreferences)
          : null,
        features: JSON.stringify(changes),
        companyId: appState.auth.userInfo.companyId,
      };
      const response = await fetch(
        process.env.REACT_APP_API_URL + `/company/edit`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
          body: JSON.stringify(data),
        },
      );
      const json = await response.json();

      if (json.success) {
        let cp = { ...appState };
        cp.auth.userInfo.features = JSON.parse(json.data.features);
        setAppState(cp);
        let features = JSON.parse(json.data.features);
        let count = Object.values(features).filter(
          item => item === false,
        ).length;
        if (!features.finishLater) {
          count = count - 1;
        }
        setFeatureCount(count);
      } else {
        snack(json.errors[0], 'error');
        json.errors.forEach(err => {
          if (err.type === 'token') {
            snack(err.msg, 'error');
            setLogoutState(true);
          }
        });
      }
    } catch (err) {
      log(err);
    }
  };

  // Submit function to api
  const handleSubmitUser = async () => {
    const { fname, lname, email, permissions, phoneNo } = userInfo;

    if (
      editUserInfo.fname === fname &&
      editUserInfo.lname === lname &&
      editUserInfo.email === email &&
      editUserInfo.perm === permissions &&
      (phoneNo ? editUserInfo.phoneNumber === phoneNo : true)
    ) {
      return true;
    }

    let foundError = false;
    if (fname.length === 0) {
      handleErrorsChange('fnameError', true);
      foundError = true;
    }
    if (lname.length === 0) {
      handleErrorsChange('lnameError', true);
      foundError = true;
    }
    if (email.length === 0) {
      handleErrorsChange('emailError', true);
      foundError = true;
    }
    if (permissions === '') {
      handleErrorsChange('permissionsError', true);
      foundError = true;
    }

    if (foundError) {
      snack('Please Fill Out All Fields', 'error');
      return false;
    }

    let formattedPhoneNum = userInfo.phoneNo
      ? userInfo.phoneNo
          .split('(')
          .join('')
          .split(')')
          .join('')
          .split(' ')
          .join('')
          .split('-')
          .join('')
      : '';

    if (formattedPhoneNum.length <= 5) {
      formattedPhoneNum = '';
    }

    try {
      const data = {
        fname,
        lname,
        email,
        perm: permissions,
      };

      if (isEdit) {
        data['permChanged'] =
          editUserInfo.permChanged || userInfo.perm === editUserInfo.perm;
        data['phoneNumber'] = formattedPhoneNum;
        data['id'] = userInfo.id;
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/user/${
          isEdit ? 'editUser' : 'addUser'
        }`,
        {
          method: isEdit ? 'PUT' : 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
          body: JSON.stringify(data),
        },
      );
      const json = await response.json();

      if (json.success) {
        if (isEdit) {
          if (json.errors.length) {
            if (json.errors[0] && json.errors[0].msg) {
              snack(json.errors[0].msg, 'error');
            }
          }
          handleEditCallBack();

          handleErrorsChange('fnameError', false);
          handleErrorsChange('lnameError', false);
          handleErrorsChange('emailError', false);
        } else {
          handleUserFeatureComplete();
        }
        setDefaultObject();
        return true;
      } else {
        let errorObj = {};
        json.errors.forEach(error => {
          switch (error.type) {
            case 'fname':
              handleErrorsChange('fnameError', true);
              break;
            case 'lname':
              handleErrorsChange('lnameError', true);
              break;
            case 'email':
              handleErrorsChange('emailError', true);
              break;
            default:
              // permissions
              handleErrorsChange('permissionsError', true);
              break;
          }

          //add error to err object
          errorObj = { ...errorObj, [error.type]: true };
          if (error.type === 'limit') {
            limitReachedSnackbar('users');
          } else if (error.type === 'system') {
            snack(
              'System error. If the problem persists, please contact support.',
              'error',
            );
          } else if (error.type === 'token') {
            snack(error.msg, 'error');
            setLogoutState(true);
          } else {
            //err alert
            snack(error.msg, 'error');
          }
        });
        return false;
      }
    } catch (err) {
      log(err);
      return false;
    }
  };

  const handleUserInfoChange = (key, value) => {
    setUserInfo({ ...userInfo, [key]: value });
  };

  const handleErrorsChange = (key, value) => {
    setErrors({ ...errors, key, value });
  };

  const handleClose = () => {
    setOpenUserModal(false);
    setDefaultObject();
    setIsEdit(false);
  };

  const handleRestart = async () => {
    setOpenUserModal(false);
    setDefaultObject();
    setIsEdit(false);
    setTimeout(() => {
      setOpenUserModal(true);
    }, 500);
  };

  return (
    <ResponsiveDialog
      openState={openUserModal}
      handleClose={handleClose}
      handleCancel={handleClose}
      title={`${isEdit ? 'EDIT' : 'ADD A NEW'} USER`}
      stepsArray={[
        {
          label: !isMobileSize && 'Enter Name and Email of the new user.',
          nextFunction: handleSubmitUser,
          validator: true,
          showProgress: true,
          saveButtonStep: true,
          child: (
            <EnterUserInfo
              errors={errors}
              isEdit={isEdit}
              userInfo={userInfo}
              handleUserInfoChange={handleUserInfoChange}
            />
          ),
        },
        {
          label: 'LOADING',
          child: (
            <Loading
              header={isEdit ? 'Updating User' : 'Creating Invitation'}
              desc={`MiSensors is currently ${
                isEdit
                  ? 'updating the'
                  : 'creating email invitation for your new'
              } user.`}
            />
          ),
        },
        {
          label: 'COMPLETE', // Because is the finish
          child: (
            <Complete
              header={`Success! ${
                isEdit
                  ? 'User has been updated'
                  : 'Your new user invitation has been sent'
              }.`}
              desc={
                isEdit
                  ? 'Select Finish to return to the page.'
                  : 'You may now add additional users by selecting the option below.'
              }
              completeButtons={
                !isEdit && [
                  <ImageButton
                    onClick={() => {
                      handleRestart();
                    }}
                    image={marker}
                    action='ADD'
                    text='USER'
                    key='complete_restart'
                  />,
                ]
              }
            />
          ),
        },
      ]}
    />
  );
}
