import React from 'react';
import { Grid, Checkbox, Typography } from '@material-ui/core';
import DividerWithText from '../common/DividerWithText';

const DirectResellerCheck = ({ check, change }) => {
  return (
    <>
      <Grid item xs={12}>
        <DividerWithText>OR</DividerWithText>
      </Grid>
      <Grid container direction='row' justify='center' alignItems='center'>
        <Grid item xs={6}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography>Make them a direct reseller.</Typography>
            <Checkbox
              checked={check}
              onChange={change}
              color='primary'
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default DirectResellerCheck;
