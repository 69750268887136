import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Card,
  Grid,
  Slider,
  Tooltip,
  TextField,
  CardContent,
} from '@material-ui/core';

import { AppContext } from './../../AppContext';

const useStyles = makeStyles(theme => ({
  card: {
    margin: theme.spacing(1.5, 0),
    border: `1px solid #cccccc`,
    overflow: 'visible',
    padding: theme.spacing(1.5),
    boxShadow: 'none !important',
  },
  cardContent: {
    marginTop: theme.spacing(2),
  },
  slider: {
    width: '90%',
  },
  manualInputTextfield: {
    width: 120,
  },
}));

const useTooltipStyles = makeStyles(theme => ({
  arrow: {
    color: theme.palette.primary.main,
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    fontSize: '14px',
  },
}));

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  const classes = useTooltipStyles();

  return (
    <Tooltip
      arrow
      open={open}
      enterTouchDelay={0}
      placement='top'
      title={value}
      classes={classes}
    >
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

export default function SliderCard(props) {
  const { appState, modalOpenState } = useContext(AppContext);

  const classes = useStyles();
  const {
    isRangeType = false, // boolean, if it is a single value or a range
    values, // number, one element , or array of two elements
    unitMeasure = '%', // String, to specify the unit , example "°F"
    minimumValue = 0, // number, minimum value of the range
    maximumValue = 100, // number, maximun value of the range
    onChange, // Function (event, newValue)
    rangeType,
    showMarkers, // Show markers boolean
    error,
    light_markers = null, // array of marks specific to light_detection defined in rangeSettings
  } = props;

  const defaultValue = isRangeType
    ? [minimumValue, maximumValue / 2]
    : minimumValue;

  const valueTextFormat = value => {
    if (light_markers && !appState.userSettings.advancedLux) {
      switch (value) {
        case 1:
          return 'Low';
        case 2:
          return 'Med';
        case 3:
          return 'High';
        default:
          // 0
          return 'Off';
      }
    }
    return `${value}${unitMeasure}`;
  };

  let marks = [
    {
      value: minimumValue,
      label: valueTextFormat(minimumValue),
    },
    {
      value: maximumValue,
      label: valueTextFormat(maximumValue),
    },
  ];

  if (light_markers) marks = light_markers;

  const isInverted = () => {
    if (rangeType === 'outside_range' || rangeType === 'above_value') {
      return 'inverted';
    }
    return 'normal';
  };

  const inputChange = (value, index) => {
    // if value does equals nothing or "-"
    if (isNaN(parseInt(value))) {
      if (!isRangeType) onChange('');
      else {
        const changedVal = values;
        changedVal[index] = '';
        onChange(changedVal);
      }
      return;
    }

    if (isRangeType) {
      const changedVal = values;
      changedVal[index] = +value;
      onChange(changedVal);
    } else onChange(+value);
  };

  const inputProps = {
    min: minimumValue,
    max: maximumValue,
  };

  return (
    <Card className={classes.card} variant='outlined'>
      <CardContent className={classes.cardContent}>
        <Box display='flex' alignItems='center' justifyContent='center'>
          <Slider
            className={classes.slider}
            ValueLabelComponent={ValueLabelComponent}
            defaultValue={defaultValue}
            valueLabelFormat={valueTextFormat}
            value={values}
            onChange={(event, newValue) => {
              onChange(newValue);
            }}
            min={minimumValue}
            max={maximumValue}
            valueLabelDisplay={showMarkers && modalOpenState() ? 'on' : 'off'}
            aria-labelledby='range-slider'
            getAriaValueText={valueTextFormat}
            marks={marks}
            track={isInverted()}
          />
        </Box>
        {light_markers && !appState.userSettings.advancedLux ? null : (
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ marginTop: 25 }}>
              <TextField
                className={classes.manualInputTextfield}
                fullWidth
                variant='outlined'
                placeholder={isRangeType ? 'Low Value' : 'Value'}
                label={isRangeType ? 'Low Value' : 'Value'}
                type='number'
                inputProps={inputProps}
                value={isRangeType ? values[0] : values}
                onChange={event => inputChange(event.target.value, 0)}
                error={error.min}
              />
            </Grid>
            {isRangeType ? (
              <Grid item xs={12} style={{ marginTop: 25 }}>
                <TextField
                  className={classes.manualInputTextfield}
                  fullWidth
                  variant='outlined'
                  placeholder='High Value'
                  label='High Value'
                  type='number'
                  inputProps={inputProps}
                  value={values[1]}
                  onChange={event => inputChange(event.target.value, 1)}
                  error={error.max}
                />
              </Grid>
            ) : null}
          </Grid>
        )}
      </CardContent>
    </Card>
  );
}
