import _ from 'lodash';
import { navigate } from 'hookrouter';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import SmsFailedIcon from '@material-ui/icons/SmsFailed';
import React, { useRef, useState, useEffect, useContext } from 'react';
import {
  Grow,
  Link,
  Badge,
  Popper,
  IconButton,
  TableFooter,
  ClickAwayListener,
  Typography,
  Avatar,
  Paper,
  Menu,
  MenuList,
  MenuItem,
  TextField,
} from '@material-ui/core';

import { AppContext } from '../../../AppContext';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import HeaderAccountList from './HeaderAccountList';

const useStyles = makeStyles(theme => {
  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
    },
    accountIcon: {
      marginRight: 5,
      color: theme.palette.primary.contrastText,
    },
    popper: {
      marginRight: '50px',
      top: '-15px !important', //This overides the MUI-Popper absolute value
      zIndex: '999999',
    },
    footer: {
      width: '415px',
      display: 'flex',
      justifyContent: 'center',
      padding: '8px',
    },
    link: {
      color: '#0870FF',
      cursor: 'pointer',
    },
    triangle: {
      backgroundColor: theme.palette.type === 'light' ? '#FFF' : '#424242',
      transform: 'rotate(45deg)',
      alignSelf: 'flex-start',
      width: '15px',
      height: '15px',
      position: 'absolute',
      top: '25px',
      right: '52px',
    },
  };
});

/**
 * Returns the Alerts list from the header
 * @param {*} props
 */
export default function HeaderAccount(props) {
  const log = window.log('HeaderAccount');
  const theme = useTheme();
  const classes = useStyles();
  const { dividerStyle, isSmallDevice, parentClasses } = props;
  const { appState } = useContext(AppContext);

  const [openAccountsMenu, setOpenAccountsMenu] = useState(false);
  const [accounts, setAccounts] = useState([]);

  let windowSizeSmall = useMediaQuery(theme.breakpoints.down('sm'));
  let windowSizeXSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const anchorRef = useRef(null);

  useEffect(() => {
    if ( !isAdmin() ) return;
    fetchAllAccounts();
  }, []);

  const fetchAllAccounts = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/admin/all-accounts',
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
        },
      );

      const json = await response.json();
      if (json.success && json.data.accounts && json.data.accounts.length) {
        const updatedAccounts = new Array(1);
        //PUSH ADMIN ACCOUNT TO THE TOP OF THE LIST
        for (const account of json.data.accounts) {
          if (
            appState.parentAccount &&
            appState?.parentAccount?.auth?.userInfo?.id === account.label.id
          ) {
            updatedAccounts[0] = account;
            continue;
          } else if (
            !appState.parentAccount &&
            appState?.auth?.userInfo?.id === account?.label?.id &&
            !updatedAccounts[0]
          ) {
            updatedAccounts[0] = account;
            continue;
          }
          updatedAccounts.push(account);
        }
        setAccounts(updatedAccounts);
      }
    } catch (err) {
      log('Error -> fetchAllAccounts -> ', err);
    }
  };

  // When user clicks on Alert Icon in Header
  const handleToggleAccountsMenu = e => {
    setOpenAccountsMenu(!openAccountsMenu);
  };

  // When user clicks away from list component
  const handleCloseAccountsMenu = e => {
    setOpenAccountsMenu(false);
  };

  const isAdmin = () => {
    return appState?.auth?.userInfo?.perm === 99;
  };

  return (
    <div
      className={classes.container}
      style={{ cursor: isAdmin() ? 'pointer' : 'default' }}
    >
      <div
        className={classes.accountIcon}
        onClick={
          isAdmin() || appState.parentAccount ? handleToggleAccountsMenu : null
        }
      >
        <div style={{ padding: 0 }} data-testid='header_avatar'>
          {appState?.auth?.userInfo?.profile?.profileImageLocation ? (
            <Avatar
              className={parentClasses.avatarMenuImage}
              src={appState.auth.userInfo.profile.profileImageLocation}
            />
          ) : (
            <AccountCircleIcon style={{ fontSize: '2rem' }} />
          )}
        </div>
      </div>
      <div className={dividerStyle} key='account_divider' />
      <div
        key='account_icon'
        aria-controls={openAccountsMenu ? 'menu-list-grow' : undefined}
        aria-haspopup='true'
        ref={anchorRef}
      >
        <Typography
          style={{
            fontSize: 14,
            display: windowSizeXSmall ? 'none' : '',
            marginRight: 15,
          }}
          variant='subtitle1'
          className={parentClasses.text}
          onClick={
            isAdmin() || appState.parentAccount
              ? handleToggleAccountsMenu
              : null
          }
        >
          {appState.auth.userInfo.fname} {appState.auth.userInfo.lname}
        </Typography>
        {isAdmin() || appState.parentAccount ? (
          <Popper
            className={classes.popper}
            open={openAccountsMenu}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            placement='top'
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleCloseAccountsMenu}>
                    <div>
                      <HeaderAccountList
                        title='Switch to:'
                        accounts={accounts}
                        closeMenu={handleCloseAccountsMenu}
                      />
                    </div>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        ) : null}
      </div>
    </div>
  );
}
