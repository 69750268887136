import React, { useContext, useState, Fragment } from "react";
import { AppContext } from "../../AppContext";
import { navigate } from "hookrouter";

import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  TextField,
  Button,
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
  Input,
  Typography
} from "@material-ui/core";

import withWidth, { isWidthDown } from '@material-ui/core/withWidth';


import LoginLayout from "./LoginLayout";
import MuiPhoneNumber from "material-ui-phone-number";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import TermAndConditions from "../Generic/TermAndConditions";

const useStyles = makeStyles(theme => ({
  textField: {
    width: '100%',
  },
  formContainer: {
    width: '80%',
  },
  linkForgotPassword: {
    color: theme.palette.primary.light,
    padding: theme.spacing(1),
    textDecoration: 'none',
    fontSize: 16,
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  button: {
    width: 200,
    [theme.breakpoints.down("xs")]: {
      borderRadius: '50px',
      width: "45%",
      fontWeight: 'bold',
      marginTop: ".5rem"
    },
  },
  mobileViewContainer: {
    width: "100%",
    height: "100vh",
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  mobileTitle: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginBottom: '40px'
  }
}));

const ResellerInvitation = (props) => {
  const log = window.log('ResellerInvitation');

  const { appState, setAppState, setLogoutState, snack } = useContext(
    AppContext,
  );
  let url = window.location.href;
  let urlobj = new URL(url);
  let urlEmail = urlobj.searchParams.get('email');
  let userToken = urlobj.searchParams.get('token');

  const [state, setState] = useState({
    email: urlEmail,
    phoneNumber: '',
    pw: '',
    pwConfirmation: '',
  });

  const [currentStep, setCurrentStep] = useState(1);

  const [err, setErr] = useState({
    email: false,
    phoneNumber: false,
    pw: false,
    pwConfirmation: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  /**
   * Mobile specific components and states
   */

  const isMobileSize = isWidthDown('xs', props.width)

  /**
   * End of Mobile specific components and states
   */


  const classes = useStyles();

  const nextStep = () => {
    // log("Looking at user token: " + userToken);
    const formattedPhoneNum = state.phoneNumber
      .split('(')
      .join('')
      .split(')')
      .join('')
      .split(' ')
      .join('')
      .split('-')
      .join('');
    const pwRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (state.pw === '' || state.pwConfirmation === '') {
      setErr({
        pw: !state.pw1,
        pwConfirmation: !state.pw2,
      });
      setIsSubmitting(false);
      return snack(
        'Something went wrong. Please double check all fields.',
        'error',
      );
    } else if (state.pw !== state.pwConfirmation) {
      setErr({
        pw: true,
        pwConfirmation: true,
      });
      setIsSubmitting(false);
      return snack('Password does not match.', 'error');
    } else if (!pwRegex.exec(state.pw)) {
      setErr({
        pw: true,
        pwConfirmation: true,
      });
      setIsSubmitting(false);
      return snack('Password requirements were not met.', 'error');
    }

    setCurrentStep(2);
  };
  const attemptSignUp = async () => {
    // log("Looking at user token: " + userToken);
    const formattedPhoneNum = state.phoneNumber
      .split('(')
      .join('')
      .split(')')
      .join('')
      .split(' ')
      .join('')
      .split('-')
      .join('');
    const pwRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (state.pw === '' || state.pwConfirmation === '') {
      setErr({
        pw: !state.pw1,
        pwConfirmation: !state.pw2,
      });
      setIsSubmitting(false);
      return snack(
        'Something went wrong. Please double check all fields.',
        'error',
      );
    } else if (state.pw !== state.pwConfirmation) {
      setErr({
        pw: true,
        pwConfirmation: true,
      });
      setIsSubmitting(false);
      return snack('Password does not match.', 'error');
    } else if (!pwRegex.exec(state.pw)) {
      setErr({
        pw: true,
        pwConfirmation: true,
      });
      setIsSubmitting(false);
      return snack('Password requirements were not met.', 'error');
    }

    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/reseller/create-account',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: userToken,
          },
          body: JSON.stringify({
            email: state.email,
            password: state.pw,
            passwordConfirmation: state.pwConfirmation,
            phoneNum: formattedPhoneNum,
            checkCompany: true,
            profile: { eulaConfirmed: agreeButtonState },
          }),
        },
      );
      const json = await response.json();
      log('Looking at JSON ', json);
      if (json.success) {
        snack('User account created', 'success');
        const { token, userInfo } = json.data;

        setAppState({
          ...appState,
          auth: {
            loggedIn: true,
            token,
            userInfo,
          },
        });
        setErr({
          pw: false,
          pwConfirmation: false,
        });
        navigate('/');
      } else {
        setCurrentStep(1);
        setErr({ [json.errors[0].errType]: true });
        snack(json.errors[0].msg, 'error');
        if (err.type === 'token') {
          snack(err.msg, 'error');
          setLogoutState(true);
        }
      }
    } catch (err) {
      log(err);
      snack(
        'Something went wrong. Please refresh the page and try again.',
        'error',
      );
    }
    setIsSubmitting(false);
  };

  const goToHomePage = async () => {
    navigate('/');
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const [agreeButtonState, setAgreeButtonState] = useState(false);

  const reachBottomFunction = () => {
    setAgreeButtonState(true);
  };

  const checkFunction = () => {
    setPromotionsCheckState(!promotionsCheckState);
  };

  const [promotionsCheckState, setPromotionsCheckState] = useState(true);

  return (
    <Fragment>
      { isMobileSize ?
        <Box
          className={classes.mobileViewContainer}
          subtitle="You’ve been invited to join our organization.."
        >
          <Typography
            className={classes.mobileTitle}
          >
            You’ve been invited to join our organization..
          </Typography>
          {currentStep === 2 ? (
            <Box className={classes.formContainer}>
              <Grid container spacing={1}>
                <TermAndConditions
                  isUserInvitation={true}
                  reachBottomFunction={reachBottomFunction}
                  checked={promotionsCheckState}
                  checkFunction={checkFunction}
                />
                <Grid container item xs={12} className={classes.buttons}>
                  <Button
                    id="cancel"
                    variant="outlined"
                    className={classes.button}
                    size="large"
                    color="primary"
                    onClick={() => goToHomePage()}
                  >
                    cancel
                  </Button>
                  <Button
                    id="submit"
                    variant="contained"
                    className={classes.button}
                    size="large"
                    color="primary"
                    disabled={isSubmitting || !agreeButtonState}
                    onClick={() => attemptSignUp()}
                  >
                    I Agree
                    </Button>
                </Grid>
              </Grid>
            </Box>
          ) : (
              <Box className={classes.formContainer}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      // variant="outlined"
                      id="email"
                      className={classes.textField}
                      label="Email Address"
                      onChange={(e) =>
                        setState({ ...state, email: e.currentTarget.value })
                      }
                      value={state.email}
                      error={err.email}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          document.getElementById("password").focus();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MuiPhoneNumber
                      className={classes.textField}
                      id="phoneNumber"
                      fullWidth
                      // variant="outlined"
                      value={state.phoneNumber}
                      placeholder="Phone Number"
                      label="Phone Number"
                      defaultCountry={"us"}
                      preferredCountries={["us"]}
                      disableAreaCodes={true}
                      autoFormat={true}
                      onChange={(e) => setState({ ...state, phoneNumber: e })}
                      countryCodeEditable={false}
                      inputProps={{
                        autoComplete: "new-password",
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <FormControl className={classes.textField} >
                      <InputLabel htmlFor="outlined-adornment-password">
                        Password
                      </InputLabel>
                      <Input
                        id="outlined-adornment-password"
                        className={classes.textField}
                        fullWidth
                        label="Password"
                        placeholder="Password"
                        value={state.pw}
                        name="password"
                        onChange={(e) =>
                          setState({ ...state, pw: e.currentTarget.value })
                        }
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            document.getElementById("submit").focus();
                            nextStep();
                          }
                        }}
                        error={err.pw}
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <FormControl className={classes.textField} >
                      <InputLabel htmlFor="outlined-adornment-password-confirmation">
                        Password Confirmation
                      </InputLabel>
                      <Input
                        id="outlined-adornment-password-confirmation"
                        className={classes.textField}
                        fullWidth
                        label="Password Confirmation"
                        placeholder="Password Confirmation"
                        value={state.pwConfirmation}
                        name="password confirmation"
                        onChange={(e) =>
                          setState({
                            ...state,
                            pwConfirmation: e.currentTarget.value,
                          })
                        }
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            document.getElementById("submit").focus();
                            nextStep();
                          }
                        }}
                        error={err.pwConfirmation}
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      <FormHelperText id="component-error-text">
                        Requirements: at least 8 characters, at least 1 uppercase, at
                        least 1 lowercase, at least 1 number, at least 1 special
                        character (@$!%*?&)
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid container item xs={12} className={classes.buttons}>
                    <Button
                      id="cancel"
                      variant="outlined"
                      className={classes.button}
                      size="large"
                      color="primary"
                      onClick={() => goToHomePage()}
                    >
                      cancel
                    </Button>
                    <Button
                      id="submit"
                      variant="contained"
                      className={classes.button}
                      size="large"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={() => nextStep()}
                    >
                      Join
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            )}
        </Box>
      :
        <LoginLayout subtitle="You’ve been invited to join our organization..">
          {currentStep === 2 ? (
            <Box className={classes.formContainer}>
              <Grid container spacing={1}>
                <TermAndConditions
                  isUserInvitation={true}
                  reachBottomFunction={reachBottomFunction}
                  checked={promotionsCheckState}
                  checkFunction={checkFunction}
                />
                <Grid container item xs={12} className={classes.buttons}>
                  <Button
                    id="cancel"
                    variant="outlined"
                    className={classes.button}
                    size="large"
                    color="primary"
                    onClick={() => goToHomePage()}
                  >
                    cancel
                  </Button>
                  <Button
                    id="submit"
                    variant="contained"
                    className={classes.button}
                    size="large"
                    color="primary"
                    disabled={isSubmitting || !agreeButtonState}
                    onClick={() => attemptSignUp()}
                  >
                    I Agree
              </Button>
                </Grid>
              </Grid>
            </Box>
          ) : (
              <Box className={classes.formContainer}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      id="email"
                      className={classes.textField}
                      label="Email Address"
                      onChange={(e) =>
                        setState({ ...state, email: e.currentTarget.value })
                      }
                      value={state.email}
                      error={err.email}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          document.getElementById("password").focus();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MuiPhoneNumber
                      className={classes.textField}
                      id="phoneNumber"
                      fullWidth
                      variant="outlined"
                      value={state.phoneNumber}
                      placeholder="Phone Number"
                      label="Phone Number"
                      defaultCountry={"us"}
                      preferredCountries={["us"]}
                      disableAreaCodes={true}
                      autoFormat={true}
                      onChange={(e) => setState({ ...state, phoneNumber: e })}
                      countryCodeEditable={false}
                      inputProps={{
                        autoComplete: "new-password",
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <FormControl className={classes.textField} variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-password">
                        Password
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        className={classes.textField}
                        fullWidth
                        variant="outlined"
                        label="Password"
                        placeholder="Password"
                        value={state.pw}
                        name="password"
                        onChange={(e) =>
                          setState({ ...state, pw: e.currentTarget.value })
                        }
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            document.getElementById("submit").focus();
                            nextStep();
                          }
                        }}
                        error={err.pw}
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <FormControl className={classes.textField} variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-password-confirmation">
                        Password Confirmation
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password-confirmation"
                        className={classes.textField}
                        fullWidth
                        variant="outlined"
                        label="Password Confirmation"
                        placeholder="Password Confirmation"
                        value={state.pwConfirmation}
                        name="password confirmation"
                        onChange={(e) =>
                          setState({
                            ...state,
                            pwConfirmation: e.currentTarget.value,
                          })
                        }
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            document.getElementById("submit").focus();
                            nextStep();
                          }
                        }}
                        error={err.pwConfirmation}
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      <FormHelperText id="component-error-text">
                        Requirements: at least 8 characters, at least 1 uppercase, at
                        least 1 lowercase, at least 1 number, at least 1 special
                        character (@$!%*?&)
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid container item xs={12} className={classes.buttons}>
                    <Button
                      id="cancel"
                      variant="outlined"
                      className={classes.button}
                      size="large"
                      color="primary"
                      onClick={() => goToHomePage()}
                    >
                      cancel
                    </Button>
                    <Button
                      id="submit"
                      variant="contained"
                      className={classes.button}
                      size="large"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={() => nextStep()}
                    >
                      Join
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            )}
        </LoginLayout>
      }
    </Fragment>
  );
};

export default withWidth()(ResellerInvitation);
