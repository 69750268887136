import LoginLayout from './LoginLayout';
import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect, useContext } from 'react';

import { navigate } from 'hookrouter';
import { AppContext } from '../../AppContext';
import OnboardModal from '../Auth/CreateAccountModal';

const useStyles = makeStyles(theme => ({
  button: {
    width: 200,
    [theme.breakpoints.down("xs")]: {
      marginTop: '35vh',
      width: '100%',
      borderRadius: '50px',
      fontWeight: 'bold'
    },
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  formContainer: {
    width: '80%',
  },
}));

export default function SuccessSignup(props) {
  const { snack } = useContext(AppContext);
  const { setOpenOnboardModal } = props;
  const classes = useStyles();

  const [setupModal, setSetupModal] = useState(false);

  const url = new URL(window.location);
  const token = url.searchParams.get('token');

  useEffect(() => {
    if (!token) {
      snack('Oops! Your Signup link has expired. Please start over.', 'error');
      return navigate('/signup', true);
    }

    const verifyToken = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/auth/verify-token?token=${token}`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );

        const json = await response.json();
        if (!json.success) {
          snack(
            'Oops! Your Signup link has expired. Please start over.',
            'error',
          );
          navigate('/signup', true);
        }
      } catch (err) {
        snack('Network Error', 'error');
      }
    };

    verifyToken();
  }, []);

  const handleClose = () => {
    setSetupModal(false);
  };

  return (
    <LoginLayout
      subtitle={
        'Congratulations your user account has been setup.\nClick "Begin Setup" to begin setting up the MiSensors application for your business.'
      }
    >
      <Grid container className={classes.formContainer}>
        <Grid item xs={12} className={classes.buttons}>
          <Button
            id='submit'
            variant='contained'
            className={classes.button}
            size='large'
            color='primary'
            onClick={() => {
              setSetupModal(true);
            }}
          >
            Begin Setup
          </Button>
        </Grid>
      </Grid>
      <OnboardModal
        setOpenOnboardModal={setOpenOnboardModal}
        openState={setupModal}
        handleClose={handleClose}
      ></OnboardModal>
    </LoginLayout>
  );
}
