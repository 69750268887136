import React from "react";
import { SvgIcon } from "@material-ui/core";

const CardViewIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="23 "
        viewBox="0 0 29 27"
      >
        <rect className="a" width="13" height="7" rx="2" />
        <rect
          className="a"
          width="13"
          height="7"
          rx="2"
          transform="translate(0 10)"
        />
        <rect
          className="a"
          width="13"
          height="7"
          rx="2"
          transform="translate(0 20)"
        />
        <rect
          className="a"
          width="13"
          height="7"
          rx="2"
          transform="translate(16)"
        />
        <rect
          className="a"
          width="13"
          height="7"
          rx="2"
          transform="translate(16 10)"
        />
        <rect
          className="a"
          width="13"
          height="7"
          rx="2"
          transform="translate(16 20)"
        />
      </svg>
    </SvgIcon>
  );
};

export default CardViewIcon;
