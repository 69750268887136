import React, { useContext } from 'react';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Box, Grid, useTheme, IconButton } from '@material-ui/core';

import { AppContext } from '../../../AppContext';
import ImageButton from '../../Generic/ImageButton';
import uploadImage from '../../common/api/uploadImage';
import ModalFormHeader from '../../common/ModalComponents/ModalFormHeader';

/**
 * Upload images for company
 * @param {Object} props
 */
export function CompanyLogo(props) {
  const log = window.log('CompanyLogo');
  const { token, onboardObject, setOnboardObject } = props;
  const { snack } = useContext(AppContext);

  const theme = useTheme();

  const platformImageUploadHandler = async e => {
    try {
      const renameText = `platformImage-${onboardObject.companyName}`;
      const data = new FormData();
      const file = await e.target.files[0];
      const destinationFolder = 'Platform Image';
      if (file) {
        const response = await uploadImage(
          file,
          data,
          renameText,
          token,
          destinationFolder,
        );
        if (response.error) {
          if ('LIMIT_FILE_SIZE' === response.error.code) {
            snack('Max size: 2MB', 'error');
          } else {
            // If not the given file type
            log(response);
            snack('Error Loading Image', 'error');
          }
        } else {
          // Success
          setOnboardObject({
            ...onboardObject,
            platformImageLocation: response.location,
          });
          snack('Image successfully uploaded', 'success');
        }
      } else {
        snack('Please upload file', 'error');
      }
    } catch (error) {
      log(error);
    }
  };

  const faviconImageUploadHandler = async e => {
    try {
      const renameText = `favicon-${onboardObject.companyName}`;
      const data = new FormData();
      const file = await e.target.files[0];
      const destinationFolder = 'Favicon Image';
      if (file) {
        const response = await uploadImage(
          file,
          data,
          renameText,
          token,
          destinationFolder,
        );
        if (response.error) {
          if ('LIMIT_FILE_SIZE' === response.error.code) {
            snack('Max size: 2MB', 'error');
          } else {
            // If not the given file type
            snack('Error Loading Image. Must be a SVG file.', 'error');
          }
        } else {
          // Success
          setOnboardObject({
            ...onboardObject,
            faviconImageLocation: response.location,
          });
          snack('Image successfully uploaded', 'success');
        }
      } else {
        snack('Please upload SVG file', 'error');
      }
    } catch (error) {
      log(error);
    }
  };

  return (
    <Grid container item xs={12} spacing={2}>
      <ModalFormHeader
        header='Upload Company Logos'
        desc='Upload your main logo and logo icon to customize the MiSensors software to your brand.'
      />
      <ModalFormHeader desc='* This step is Not required' />

      <Grid item xs={12} sm={6}>
        <Box display='flex' style={{ position: 'relative' }}>
          {!onboardObject.platformImageLocation ? (
            <ImageButton
              image={
                theme.palette.type === 'light'
                  ? 'https://misensors-images.s3.amazonaws.com/App+Image+Resources/MiSensorsLogo.svg'
                  : 'https://misensors-images.s3.amazonaws.com/App+Image+Resources/MiSensorsLogo+-+DarkMode.svg'
              }
              action='UPLOAD'
              text='MAIN LOGO'
              component='label'
              uploadHandler={platformImageUploadHandler}
            />
          ) : (
            <>
              <ImageButton
                image={onboardObject.platformImageLocation}
                action='UPLOAD'
                text='MAIN LOGO'
                component='label'
                uploadedImg={false}
                uploadHandler={platformImageUploadHandler}
              />
              <IconButton
                aria-label='delete'
                style={{ position: 'absolute', right: 0 }}
                size='small'
                onClick={() =>
                  setOnboardObject({
                    ...onboardObject,
                    platformImageLocation: '',
                  })
                }
              >
                <HighlightOffIcon />
              </IconButton>
            </>
          )}
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box display='flex' style={{ position: 'relative' }}>
          {!onboardObject.faviconImageLocation ? (
            <ImageButton
              image={
                theme.palette.type === 'light'
                  ? 'https://misensors-images.s3.amazonaws.com/App+Image+Resources/MiSensorLogo_Icon.svg'
                  : 'https://misensors-images.s3.amazonaws.com/App+Image+Resources/MiSensorLogo_Icon+-+DarkMode.svg'
              }
              action='UPLOAD'
              text='FAVICON'
              component='label'
              uploadHandler={faviconImageUploadHandler}
            />
          ) : (
            <>
              <ImageButton
                image={onboardObject.faviconImageLocation}
                action='UPLOAD'
                text='FAVICON'
                component='label'
                uploadedImg={false}
                uploadHandler={faviconImageUploadHandler}
              />
              <IconButton
                aria-label='delete'
                style={{ position: 'absolute', right: 0 }}
                size='small'
                onClick={() =>
                  setOnboardObject({
                    ...onboardObject,
                    faviconImageLocation: '',
                  })
                }
              >
                <HighlightOffIcon />
              </IconButton>
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
