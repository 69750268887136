import React from 'react';
import { Grid, Tooltip, Button } from '@material-ui/core';

export const SnapShotGraphButtons = ({
  loading,
  selection,
  setSelection,
  state,
  description,
  className,
  currState,
  sevenDays,
}) => (
  <Grid item>
    <Tooltip
      disableFocusListener={state || loading}
      disableTouchListener={state || loading}
      disableHoverListener={state || loading}
      title={
        !sevenDays
          ? 'Sensor has been offline for greater than 7 Days'
          : 'Sensor offline during this time'
      }
    >
      <span>
        <Button
          variant={currState === selection ? 'contained' : 'outlined'}
          className={className}
          size='small'
          color='primary'
          disabled={!state}
          onClick={() => {
            setSelection(selection);
          }}
        >
          {description}
        </Button>
      </span>
    </Tooltip>
  </Grid>
);
