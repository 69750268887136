import { navigate } from 'hookrouter';
import React, { useState, useEffect, useContext } from 'react';

import { AppContext } from '../../AppContext';
import ResponsiveDialog from '../Generic/GenericDialog';

import Loading from '../common/ModalComponents/Loading';
import Complete from '../common/ModalComponents/Complete';
import { CompanyInfo } from './CreateAccountModalComps/CompanyInfo';
import { CompanyLogo } from './CreateAccountModalComps/CompanyLogo';
import { PaymentInfo } from './CreateAccountModalComps/PaymentInfo';
import { PersonalInfo } from './CreateAccountModalComps/PersonalInfo';
import { ActivationCode } from './CreateAccountModalComps/ActivationCode';

/**
 * Onboard Form Modal
 * @param {Object} props
 * @props
 * openState: Boolean
 * handleClose: Functions
 */
export default function OnboardFormModal(props) {

  
  const log = window.log('OnboardFormModal');

  const { appState, setAppState, setLogoutState, snack } = useContext(
    AppContext,
  );
  const { openState, handleClose, setOpenOnboardModal } = props;

  const url = new URL(window.location);
  const [token, setToken] = useState(url.searchParams.get('token'));
  const [userInfo, setUserInfo] = useState(url.searchParams.get({}));
  const [tokenize, setTokenizeFunc] = useState();

  const [isDirect, setIsDirect] = useState(false);

  const [onboardObject, setOnboardObject] = useState({
    activationCode: '',
    resellerId: null,
    activationState: false,
    discount: false,
    companyName: '',
    street: '',
    city: '',
    state: '',
    zip: '',
    firstName: '',
    lastName: '',
    phoneNum: '',
    cardNumber: '',
    cardExpirationDate: '',
    cardCVC: 'CVC',
    zipCode: '',
    platformImageLocation: '',
    faviconImageLocation: '',
    lat: '',
    lng: '',
    autoRenew: true,
  });

  const [errorState, setErrorState] = useState({
    activationCode: false,
    street: false,
    city: false,
    state: false,
    zip: false,
    companyName: false,
    firstName: false,
    lastName: false,
    number: false,
    expirationDate: false,
    cvv: false,
    postalCode: false,
  });

  const [clientToken, setClientToken] = useState('');

  const handleError = (error = {}) => {
    log('Handling braintree error: ', error);
    let fieldError = {
      number: false,
      expirationDate: false,
      cvv: false,
      postalCode: false,
    };
    if (error.details && error.details.invalidFieldKeys) {
      error.details.invalidFieldKeys.forEach(field => {
        fieldError[field] = true;
      });
    } else if (
      error.message ===
        'All fields are empty. Cannot tokenize empty card fields.' &&
      error.code === 'HOSTED_FIELDS_FIELDS_EMPTY'
    ) {
      let fields = ['number', 'expirationDate', 'cvv', 'postalCode'];
      fields.forEach(field => {
        fieldError[field] = true;
      });
    }

    setErrorState(prevState => {
      return {
        ...prevState,
        ...fieldError,
        firstName: !onboardObject.firstName,
        lastName: !onboardObject.lastName,
      };
    });
    snack('Something went wrong. Please double check all fields.', 'error');
  };

  const handleChange = e => {
    if (!e) return; //FOR TESTING
    const object = {
      ...onboardObject,
    };
    object[e.currentTarget.name] = e.currentTarget.value;
    setOnboardObject(object);
  };

  const handleChangeActivationState = async () => {
    if (onboardObject.activationCode === '') {
      snack('Activation code is required.', 'error');
      return setErrorState({ ...errorState, activationCode: true });
    }

    try {
      const actCode = onboardObject.activationCode.split('-').join('');
      const isValidResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/validate-activation?code=${actCode}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      const json = await isValidResponse.json();

      let activationErrorState = false;
      let discount = false;
      if (!json.success) {
        activationErrorState = true;
        snack('No activation code in our system', 'error');
        json.errors.forEach(err => {
          if (err.type === 'token') {
            snack(err.msg, 'error');
            setLogoutState(true);
          }
        });
      } else {
        setIsDirect(actCode.length > 6 ? false : true);
        activationErrorState = false;
        if (json.data.discount) {
          discount = true;
        }
      }
      setOnboardObject({
        ...onboardObject,
        resellerId: json.data.reseller_id,
        discount,
      });
      setErrorState({ ...errorState, activationCode: activationErrorState });
      return json.success;
    } catch (err) {
      snack('Network Error', 'error');
    }
  };

  const handleCompanyInfo = async () => {
    log('About to run handleCompanyInfo');
    setErrorState({
      ...errorState,
      companyName: !onboardObject.companyName,
      street: !onboardObject.street,
      city: !onboardObject.city,
      state: !onboardObject.state,
      zip: !onboardObject.zip,
    });
    // Don't continue if there are missing info
    if (
      !onboardObject.street ||
      !onboardObject.city ||
      !onboardObject.state ||
      !onboardObject.zip ||
      !onboardObject.companyName
    ) {
      snack('Something went wrong. Please double check all fields.', 'error');
      return false;
    }
    // else if (onboardObject.state.length !== 2) {
    //   snack('Please Use Two Letter State Code', 'error');
    //   return false;
    // }
    else {
      const checked = await checkLatLng();
      if (checked) return true;
      else return false;
    }
  };

  const checkLatLng = async () => {
    try {
      log('Runnning the here api request');

      let street = onboardObject.street;
      if (onboardObject.street.includes('#')) {
        street = onboardObject.street.split('#').join('%23');
      }

      const response = await fetch(
        `https://geocoder.ls.hereapi.com/6.2/geocode.json?apiKey=${process.env.REACT_APP_HERE_API_KEY}&searchtext=${street}+${onboardObject.city}+${onboardObject.state}+${onboardObject.zip}`,
      );

      const json = await response.json();

      if (json.Response.View.length === 0) {
        snack('Not a valid address', 'error');
        return false;
      } else {
        let lat =
          json.Response.View[0].Result[0].Location.DisplayPosition.Latitude;
        let lng =
          json.Response.View[0].Result[0].Location.DisplayPosition.Longitude;
        setOnboardObject({
          ...onboardObject,
          lat,
          lng,
        });
        return true;
      }
    } catch (hereApiError) {
      snack('Network error', 'error');
      return false;
    }
  };

  const verifyPayment = async () => {
    try {
      const payload = await tokenize();
      if (!onboardObject.firstName || !onboardObject.lastName) {
        throw new Error('Error found with verifying payment');
      }

      if (payload.nonce) {
        return payload.nonce;
      }
    } catch (err) {
      handleError(err);
    }
    return false;
  };

  const handlePaymentSubmit = async nonce => {
    try {

      if (nonce || isDirect) {
        //Submit to braintree
        const formattedPhoneNum = onboardObject.phoneNum
          .split('(')
          .join('')
          .split(')')
          .join('')
          .split(' ')
          .join('')
          .split('-')
          .join('');

        const actCode = onboardObject.activationCode.split('-').join('');

        const response = await fetch(
          process.env.REACT_APP_API_URL + '/payment/create-account',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              nonce,
              fname: onboardObject.firstName,
              lname: onboardObject.lastName,
              userToken: token,
              street: onboardObject.street,
              city: onboardObject.city,
              state: onboardObject.state,
              zipcode: onboardObject.zip,
              resellerCode: actCode,
              resellerId: onboardObject.resellerId,
              company: onboardObject.companyName,
              logo: onboardObject.platformImageLocation,
              favicon: onboardObject.faviconImageLocation,
              phoneNum: formattedPhoneNum,
              discount: onboardObject.discount,
              autoRenew: onboardObject.autoRenew,
              isDirect: isDirect,
            }),
          },
        );

        const json = await response.json();
        // const delay = (ms) => new Promise((res) => setTimeout(res, ms));
        // await delay(3000);

        log('Looking at json response ', json);
        if (json.success) {
          const { token, userInfo } = json.data;
          setToken(token);
          setUserInfo(userInfo);

          snack('Account created successfully', 'success');

          // //Add the user location into the system
          try {
            const data = {
              street: onboardObject.street,
              city: onboardObject.city,
              state: onboardObject.state,
              zipcode: onboardObject.zip,
              name: onboardObject.companyName,
              primary: false,
              lat: onboardObject.lat,
              lng: onboardObject.lng,
            };

            log('Running the location add api reqeust');
            const response = await fetch(
              `${process.env.REACT_APP_API_URL}/location/add`,
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: token,
                },
                body: JSON.stringify(data),
              },
            );
            const json = await response.json();

            log('Looking at json ', json);

            if (json.success) {
              log('Returning true');
              return true;
            } else {
              json.errors.forEach(err => {
                snack(err.msg, 'error');
                if (err.type === 'token') setLogoutState(true);
              });
              log('Returning false');
              return false;
            }
          } catch (apiError) {
            snack('Unable to complete request', 'error');
          }
        } else {
          for (const item of json.errors) {
            if (item.type === 'token') {
              snack(
                'Oops! Your Signup link has expired. Please start over.',
                'error',
              );
              return navigate('/signup', true);
            }
          }
          snack(json.errors[0].msg || json.errors[0], 'error');
        }
      }
      // Reset error state;
      setErrorState({
        ...errorState,
        firstName: false,
        lastName: false,
        number: false,
        expirationDate: false,
        cvv: false,
        postalCode: false,
      });
      return false;
    } catch (err) {
      handleError(err);
      return false;
    }
  };

  useEffect(() => {
    if (clientToken) return;
    let isCancelled = false;
    let count = 0;
    const getToken = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + '/payment/token',
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );
        const json = await response.json();
        if (json.success) {
          !isCancelled && setClientToken(json.data.token);
        } else {
          count++;
          if (count <= 3) {
            getToken();
          } else {
            snack(
              'Unable able to get token. Please refresh the page.',
              'error',
            );
            json.errors.forEach(err => {
              if (err.type === 'token') {
                snack(err.msg, 'error');
                setLogoutState(true);
              }
            });
          }
        }
      } catch (err) {
        log('Something went wrong getting client token', err);
      }
    };

    getToken();

    return () => {
      isCancelled = true;
    };
  }, []);

  const navigateAndOpenOnboard = () => {
    setAppState({
      ...appState,
      auth: {
        loggedIn: true,
        token,
        userInfo,
        isDirect
      },
    });
    setOpenOnboardModal(true);
    handleClose();
    navigate('/');
  };

  const brainTreeSteps = [
    {
      label: 'ACTIVATION CODE',
      nextFunction: handleChangeActivationState,
      backFunction: handleClose,
      showProgress: true,
      validator: true,
      child: (
        <ActivationCode
          handleChange={handleChange}
          activationCode={onboardObject.activationCode}
          activationCodeErr={errorState.activationCode}
        />
      ),
    },
    {
      label: 'LOADING',
      child: (
        <Loading
          header='Activating your account'
          desc='MiSensors is currently configuring your activation code into the platform. This should take less than a minute.'
        />
      ),
    },
    {
      label: 'COMPANY INFO',
      nextFunction: handleCompanyInfo,
      child: (
        <CompanyInfo
          errorState={errorState}
          onboardObject={onboardObject}
          setOnboardObject={setOnboardObject}
        />
      ),
    },
    {
      label: 'COMPANY LOGO', // Because is the finish
      child: (
        <CompanyLogo
          token={token}
          onboardObject={onboardObject}
          setOnboardObject={setOnboardObject}
        />
      ),
    },
    {
      label: 'PAYMENT INFORMATION',
      nextFunction: handlePaymentSubmit,
      verifyBeforeNextPageRender: verifyPayment,
      showProgress: true,
      validator: true,
      nextButtonName: 'SUBMIT',
      saveButtonStep: true,
      child: (
        <PaymentInfo
          errorState={errorState}
          clientToken={clientToken}
          handleChange={handleChange}
          onboardObject={onboardObject}
          setErrorState={setErrorState}
          setTokenizeFunc={setTokenizeFunc}
          setOnboardObject={setOnboardObject}
        />
      ),
    },
    {
      label: 'LOADING',
      child: (
        <Loading
          header='Activating your account'
          desc='MiSensors is currently configuring your activation code into the platform. This should take less than a minute.'
        />
      ),
    },
    {
      label: 'COMPLETE', // Because is the finish
      child: (
        <Complete
          header='Success! You have completed your account setup'
          desc='Please click Finish and login to your account.'
          completeButtons={[]}
        />
      ),
    },
  ];

  const directSteps = [
    {
      label: 'ACTIVATION CODE',
      nextFunction: handleChangeActivationState,
      backFunction: handleClose,
      showProgress: true,
      validator: true,
      child: (
        <ActivationCode
          handleChange={handleChange}
          activationCode={onboardObject.activationCode}
          activationCodeErr={errorState.activationCode}
        />
      ),
    },
    {
      label: 'LOADING',
      child: (
        <Loading
          header='Activating your account'
          desc='MiSensors is currently configuring your activation code into the platform. This should take less than a minute.'
        />
      ),
    },
    {
      label: 'COMPANY INFO',
      nextFunction: handleCompanyInfo,
      child: (
        <CompanyInfo
          errorState={errorState}
          onboardObject={onboardObject}
          setOnboardObject={setOnboardObject}
        />
      ),
    },
    {
      label: 'COMPANY LOGO', // Because is the finish
      child: (
        <CompanyLogo
          token={token}
          onboardObject={onboardObject}
          setOnboardObject={setOnboardObject}
        />
      ),
    },
    {
      label: 'User Information',
      nextFunction: handlePaymentSubmit,
      showProgress: true,
      validator: true,
      nextButtonName: 'SUBMIT',
      saveButtonStep: true,
      child: (
        <PersonalInfo
          errorState={errorState}
          clientToken={clientToken}
          handleChange={handleChange}
          onboardObject={onboardObject}
          setErrorState={setErrorState}
          setTokenizeFunc={setTokenizeFunc}
          setOnboardObject={setOnboardObject}
        />
      ),
    },
    {
      label: 'LOADING',
      child: (
        <Loading
          header='Activating your account'
          desc='MiSensors is currently configuring your activation code into the platform. This should take less than a minute.'
        />
      ),
    },
    {
      label: 'COMPLETE', // Because is the finish
      child: (
        <Complete
          header='Success! You have completed your account setup'
          desc='Please click Finish and login to your account.'
          completeButtons={[]}
        />
      ),
    },
  ];

  return (
    <div>
      <ResponsiveDialog
        openState={openState}
        handleCancel={handleClose}
        handleFinish={() => {
          navigateAndOpenOnboard();
        }}
        title='WELCOME TO MISENSORS'
        stepsArray={isDirect ? directSteps : brainTreeSteps} //TODO: do this better, just rewrite this whole file as container/presentation
      />
    </div>
  );
}
