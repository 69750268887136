import React, { useEffect } from 'react';
import grey from '@material-ui/core/colors/grey';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, CardActions } from '@material-ui/core';

/**
 * returns the typography color based in the
 * theme dark mode and the tone given for the colors
 * @param {Object, int, int} theme, lightTone=700, darkTone=300
 */
const textColor = ({ theme, lightTone = 700, darkTone = 300 }) => {
  if (theme.palette.type === 'light') {
    return grey[lightTone];
  } else {
    return grey[darkTone];
  }
};

const useStyles = makeStyles(theme => ({
  leftButton: {
    fontSize: '13px',
    padding: '10px 12px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  rightButton: {
    fontSize: '13px',
    padding: '10px 12px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: '1rem',
    },
  },
  cardText: {
    textAlign: 'center',
    color: textColor({ theme, lightTone: 500, darkTone: '#FFF' }),
    marginBottom: 0,
    fontSize: 12,
  },
  actionContainer: {
    margin: '14px 110px 0 110px',
    backgroundColor:
      theme.palette.type === 'light'
        ? '#F4F5F5'
        : theme.palette.background.default,
  },
}));

/**
 * Account Settings Form Modal
 * @param {Object} props
 * @props
 * openState: Boolean
 * handleClose: Function
 * handleSave: Function
 */
export function ActionButtons(props) {
  const {
    handleClose,
    selSubLevel,
    submitState,
    handleSubmit,
    selectedPlan,
    currentSubscription,
  } = props;

  const classes = useStyles();

  useEffect(() => {
    checkDisabled();
  }, [submitState]);

  const checkDisabled = () => {
    let disabled = false;

    if (currentSubscription.planInfo) {
      const selPlanId = selectedPlan.id.split('-')[0];

      const planInfoArr = currentSubscription.planInfo.id.split('-');
      const currentSensorPlan = planInfoArr[0];
      let currentSubLevel = planInfoArr[1];
      if (!currentSubLevel) currentSubLevel = 'BASIC';

      // Disable if current = selected and there is no pending
      // Pending may mean the user would want to undo changes
      if (
        currentSensorPlan === selPlanId &&
        currentSubLevel === selSubLevel &&
        !currentSubscription.pendingSubscription
      ) {
        disabled = true;
      }

      // Disable if user chooses the same options that are pending
      if (currentSubscription.pendingSubscription) {
        const pendPlanInfoArr = currentSubscription.pendingSubscription?.planId?.split(
          '-',
        );
        const pendSensorPlan = pendPlanInfoArr[0];
        let pendSubLevel = pendPlanInfoArr[1];
        if (!pendSubLevel) pendSubLevel = 'BASIC';
        if (pendSensorPlan === selPlanId && selSubLevel === pendSubLevel) {
          disabled = true;
        }
      }
    }

    if (submitState) disabled = true;
    if (!selectedPlan.id) disabled = true;

    return disabled;
  };

  return (
    <CardActions className={classes.actionContainer}>
      <Grid
        container
        style={{
          justifyContent: 'space-between',
        }}
        spacing={1}
      >
        <Grid item xs={12} sm={5}>
          <Button
            className={classes.leftButton}
            onClick={handleClose}
            size='medium'
            variant='outlined'
            color='primary'
            fullWidth={true}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Button
            data-testid='complete_purchase'
            className={classes.rightButton}
            onClick={handleSubmit}
            disabled={checkDisabled()}
            size='medium'
            variant='contained'
            color='primary'
            fullWidth={true}
          >
            COMPLETE YOUR PURCHASE
          </Button>
        </Grid>
        <Grid item xs={12} container justify='center' style={{ padding: 0 }}>
          <p className={classes.cardText}>
            When you hit the button above your payment method on file will be
            billed the total shown.
          </p>
        </Grid>
      </Grid>
    </CardActions>
  );
}
