/*eslint-disable*/

import React, { useState, useLayoutEffect, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import { AppContext, AppProvider } from './../../AppContext';
import { Card, Box, CardContent, CardHeader } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import fetchAll from '../common/api/fetchAll';
import { A, navigate } from 'hookrouter';
import locationIcon from '../../img/place-24px.svg';
import locationIconRed from '../../img/place-24px - red.svg';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
const MapLayout = props => {
  const isMobileSize = isWidthDown('xs', props.width);
  const log = window.log('MapLayout');

  const {
    appState,
    setLogoutState,
    contextLocationCount,
    contextGatewayCount,
    contextSensorCount,
    contextAlertCount,
    snack,
  } = useContext(AppContext);

  const {
    title,
    mapCenter,
    loading,
    mapStyle = { height: '579px', width: '100%' },
    mapContainerStyle,
    navigationPrefixUri = 'location/',
  } = props;
  const useStyles = makeStyles(theme => ({
    card: {
      borderRadius: 10,
    },
    cardHeader: {
      // boxShadow: "0 1px 2px 0px rgba(0, 0, 0, .1)",
      paddingLeft: 17,
      borderBottom: '.5px solid #CCC',
    },
    headerTitle: {
      fontSize: 14,
      fontWeight: 600,
      color:
        theme.palette.type === 'light'
          ? theme.palette.grey[600]
          : theme.palette.text.primary,
    },
    margin: {
      margin: theme.spacing(1),
    },
  }));

  const classes = useStyles();
  const mapRef = React.useRef(null);
  const [state, setState] = useState(null);
  const [locations, setLocations] = useState([]);
  const [openLocations, setOpenLocations] = useState([]);
  const getLocations = async () => {
    try {
      const json = await fetchAll(
        'location',
        appState.auth.userInfo.companyId,
        appState.auth.token,
      );

      if (json.success) {
        log(json.data.locations);
        let mapLocationsArray = json.data.locations.map(location => {
          return {
            ...location,
            coords: {
              lat: location.lat,
              lng: location.lng,
            },
          };
        });
        log('Looking at the map Locations Array ', mapLocationsArray);
        setLocations(mapLocationsArray);
      } else {
        json.errors.forEach(err => {
          snack(err.msg, 'error');
          if (err.type === 'token') setLogoutState(true);
        });
      }
    } catch (err) {
      log('------: getLocations -> err', err);
      snack('Network Error', 'error');
    }
  };

  useEffect(() => {
    !loading && getLocations();
  }, [
    contextLocationCount,
    contextSensorCount,
    contextAlertCount,
    contextGatewayCount,
    loading,
  ]);

  // const locationsArray = [
  //   { name: "DCS Location", coords: { lat: 33.02085, lng: -117.09543 } },
  //   { name: "LA Location", coords: { lat: 34.052235, lng: -118.243683 } },
  //   {
  //     name: "Santa Ana Location",
  //     coords: { lat: 33.745472, lng: -117.867653 },
  //   },
  //   { name: "Corona Location", coords: { lat: 33.875671, lng: -117.56884 } },
  //   { name: "Temecula Location", coords: { lat: 33.493641, lng: -117.148361 } },
  //   {
  //     name: "San Clemente Location",
  //     coords: { lat: 33.423641, lng: -117.617653 },
  //   },
  // ];

  const handleNavigate = locationId => {
    log(locationId);
    navigate(`${navigationPrefixUri}${locationId}`);
  };

  useLayoutEffect(() => {
    // `mapRef.current` will be `undefined` when this hook first runs; edge case that
    if (mapRef && !mapRef.current) return;
    const H = window.H;

    if (H && H.service) {
      const platform = new H.service.Platform({
        apikey: process.env.REACT_APP_HERE_API_KEY,
      });

      function startClustering(map, locations) {
        // First we need to create an array of DataPoint objects,
        // for the ClusterProvider
        var dataPoints = locations.map(function (location) {
          // locations.forEach((location, index) => {
          //   // Create an icon, an object holding the latitude and longitude, and a marker:
          //   addDomMarker(group, location, index);
          // });
          // let bbox = group.getBoundingBox();
          // map.getViewPort().setPadding(250, 250, 250, 250);

          // if (locations.length === 1) {
          //   map.getViewModel().setLookAtData({
          //     zoom: 11,
          //     bounds: bbox,
          //   });
          // } else {
          //   map.getViewModel().setLookAtData({
          //     bounds: bbox,
          //   });
          // }

          var dataPoint = new H.clustering.DataPoint(
            location.coords.lat,
            location.coords.lng,
            null,
            location,
          );

          return dataPoint;
        });

        var clusteredDataProvider = new H.clustering.Provider(dataPoints, {
          clusteringOptions: {
            eps: 32,
            minWeight: 2,
          },
        });

        // assuming map & clusteringProvider have been instantiated before

        //default theme
        var defaultTheme = clusteredDataProvider.getTheme();
        // log(defaultTheme);

        const getDataPoints = cluster => {
          var dataPoints = [];

          // Iterate through all points which fall into the cluster and store references to them
          cluster.forEachDataPoint(dataPoints.push.bind(dataPoints));

          // Randomly pick an index from [0, dataPoints.length) range
          // Note how we use bitwise OR ("|") operator for that instead of Math.floor
          return dataPoints;
        };

        // Custom clustering theme description object.
        // Object should implement H.clustering.ITheme interface
        var clusterSvgTemplate =
          '<svg xmlns="http://www.w3.org/2000/svg" class="map_marker" height="50px" width="50px"><circle cx="25px" cy="25px" r="20" fill="{COLOR}" stroke-opacity="0.5" />' +
          '<text x="24" y="32" font-size="14pt" font-family="arial" font-weight="bold" text-anchor="middle" fill="white">{text}</text>' +
          '</svg>';
        var CUSTOM_THEME = {
          getClusterPresentation: function (cluster) {
            // Get cluster locations
            var dataPoints = getDataPoints(cluster);

            //Iterate locations and find if there is an alert
            let activeAlert = false;

            dataPoints.forEach(location => {
              if (location.getData().alertCount > 0) {
                activeAlert = true;
              }
            });

            // Use cluster weight to change icon size:
            var svgString = clusterSvgTemplate.replace(
              '{radius}',
              cluster.getWeight() * 5,
            );
            svgString = svgString
              .replace('{text}', +cluster.getWeight())
              .replace('{COLOR}', activeAlert ? '#FF6A6A' : '#0B9EDE');

            var w, h;
            var weight = cluster.getWeight();

            //Set cluster size depending on the weight
            if (weight <= 6) {
              w = 50;
              h = 50;
            } else if (weight <= 12) {
              w = 75;
              h = 75;
            } else {
              w = 100;
              h = 100;
            }

            var clusterIcon = new H.map.Icon(svgString, {
              size: { w: w, h: h },
              anchor: { x: w / 2, y: h / 2 },
            });

            // Create a marker for clusters:
            var clusterMarker = new H.map.Marker(cluster.getPosition(), {
              icon: clusterIcon,
              // Set min/max zoom with values from the cluster, otherwise
              // clusters will be shown at all zoom levels:
              min: cluster.getMinZoom(),
              max: cluster.getMaxZoom(),
            });

            // Bind cluster data to the marker:
            clusterMarker.setData(cluster);
            //Keep the default theme for clusters
            // var clusterMarker = defaultTheme.getClusterPresentation.call(
            //   defaultTheme,
            //   cluster,
            // );
            // add a listener to the cluster which triggers on a tap event
            clusterMarker.addEventListener(
              'tap',
              function (evt) {
                var position = cluster.getPosition();
                map.setCenter(position, true);

                // Show cluster info
                if (map.getZoom() > 19) {
                  const clusterPops = dataPoints.map(data => {
                    const {
                      id,
                      name,
                      street,
                      city,
                      state,
                      zip,
                      alertCount,
                      gatewayCount,
                      sensorCount,
                    } = data.a.data;
                    const width = isMobileSize ? 309 : 275;
                    return `<div class="pop-container" data-id=${id} style="background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 0px 0px 6px #00000040;opacity: 1;margin-bottom: 4px;border-radius: 10px;padding-top: 11px;padding-bottom: 13px;padding-left: 20px;padding-right: 13px;flex-direction: row;display: flex;width: ${width}px;justify-content: space-between;">
                    <div class="left-container">
                        <p class="title" style="text-align: left;color: #747474;opacity: 1;font-family: 'Open Sans';margin: 0px;font: normal normal bold 14px Open Sans;">
                        ${name}
                        </p>
                        <p class="subtitle" style="text-align: left;color: #747474;opacity: 1;font-family: 'Open Sans';margin: 0px;font: normal normal normal 12px Open Sans;">
                        ${street}
                        </p>
                        <p class="subtitle" style="text-align: left;color: #747474;opacity: 1;font-family: 'Open Sans';margin: 0px;font: normal normal normal 12px Open Sans;">
                        ${city}, ${state} ${zip}
                        </p>
                        </div>
                      <div class="right-container" style="display: flex;flex-direction: row;margin-left: 20px;">
                      <div class="icon-container" style="display: flex;flex-direction: column;align-items: center;justify-content: space-between;">
                      <img class="image" src="https://misensors-images.s3.amazonaws.com/App+Image+Resources/alert-icon.svg" style="width: 25px;height: 25px;">
                          <p class="number" style="text-align: center;color: #747474;opacity: 1;font-family: 'Open Sans';margin: 0px;font: normal normal 600 14px Open Sans;letter-spacing: 0px;">
                          ${alertCount}
                          </p>
                          </div>
                        <div class="divider" style="height: 100%;border: 0.3px solid #CCCCCC;margin-left: 15px;margin-right: 15px;"></div>
                        <div class="icon-container" style="display: flex;flex-direction: column;align-items: center;justify-content: space-between;">
                        <img class="image" src="https://misensors-images.s3.amazonaws.com/App+Image+Resources/gateway-icon.svg" style="width: 25px;height: 25px;">
                        <p class="number" style="text-align: center;color: #747474;opacity: 1;font-family: 'Open Sans';margin: 0px;font: normal normal 600 14px Open Sans;letter-spacing: 0px;">
                            ${gatewayCount}
                            </p>
                            </div>
                            <div class="divider" style="height: 100%;border: 0.3px solid #CCCCCC;margin-left: 15px;margin-right: 15px;"></div>
                            <div class="icon-container" style="display: flex;flex-direction: column;align-items: center;justify-content: space-between;">
                          <img class="image" src="https://misensors-images.s3.amazonaws.com/App+Image+Resources/sensor-icon.svg" style="width: 25px;height: 25px;">
                          <p class="number" style="text-align: center;color: #747474;opacity: 1;font-family: 'Open Sans';margin: 0px;font: normal normal 600 14px Open Sans;letter-spacing: 0px;">
                            ${sensorCount}
                            </p>
                        </div>
                      </div>
                      </div>`;
                  });
                  let bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
                    content: `<div class="principal-container" style="display: flex;align-items: center;transform: translate(-15px, -20px);">
                              <div class="triangle" style="transform: rotate(45deg);align-self: flex-start;background-color: #FFFFFF;width: 15px;height: 15px;margin-right: -8px;margin-top: 42px;"></div>
                              <div class="clusterPop-container" style="display: block;max-height: 300px;overflow-y: scroll;">
                                ${clusterPops.join('')}
                              </div>
                            </div>`,
                  });
                  ui.addBubble(bubble);

                  bubble.getElement().addEventListener('click', function (e) {
                    handleNavigate(e.path[2].getAttribute('data-id'));
                  });

                  bubble.addEventListener('statechange', function (evt) {
                    if (
                      evt.target.getState() === H.ui.InfoBubble.State.CLOSED
                    ) {
                    }
                  });
                }
                // increase the zoom level by an amount which fits your needs
                // again "true" is to have a smooth transition
                setTimeout(() => {
                  map.setZoom(map.getZoom() + 3, true);
                }, 500);
                // }
              },
              false,
            );
            return clusterMarker;
          },
          getNoisePresentation: function (noisePoint) {
            //get the default noise markers
            // var noiseMarker = defaultTheme.getNoisePresentation.call(
            //   defaultTheme,
            //   noisePoint
            // );
            var data = noisePoint.getData(),
              //modify them here..
              noiseMarker = new H.map.Marker(noisePoint.getPosition(), {
                // Use min zoom from a noise point
                // to show it correctly at certain zoom levels:
                min: noisePoint.getMinZoom(),
                icon: new H.map.Icon(
                  data.alertCount > 0 ? locationIconRed : locationIcon,
                  {
                    size: { w: 50, h: 50 },
                    anchor: { x: 25, y: 50 },
                  },
                ),
              });
            //Commented out for now

            noiseMarker.addEventListener(
              'tap',
              event => {
                const array = openLocations;
                if (array.includes(data.id)) {
                  return;
                }
                openLocations.push(data.id);
                setOpenLocations(array);
                let bubble = new H.ui.InfoBubble(event.target.getGeometry(), {
                  content: `<div class="principal-container" style="display: flex;align-items: center;transform: translate(-15px, -41px);">
                        <div class="triangle" style="transform: rotate(45deg);background-color: #FFFFFF;width: 15px;height: 15px;margin-right: -8px;">
                        </div>
                        <div class="pop-container" style="background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 0px 0px 6px #00000040;opacity: 1;border-radius: 10px;padding-top: 11px;padding-bottom: 13px;padding-left: 20px;padding-right: 13px;flex-direction: row;display: flex;width: 309px;justify-content: space-between;">
                          <div class="left-container">
                            <p class="title" style="text-align: left;color: #747474;opacity: 1;font-family: 'Open Sans';margin: 0px;font: normal normal bold 14px Open Sans;">
                              ${data.name}
                            </p>
                            <p class="subtitle" style="text-align: left;color: #747474;opacity: 1;font-family: 'Open Sans';margin: 0px;font: normal normal normal 12px Open Sans;">
                              ${data.street}
                            </p>
                            <p class="subtitle" style="text-align: left;color: #747474;opacity: 1;font-family: 'Open Sans';margin: 0px;font: normal normal normal 12px Open Sans;">
                              ${data.city}, ${data.state} ${data.zip}
                            </p>
                          </div>
                          <div class="right-container" style="display: flex;flex-direction: row;margin-left: 20px;">
                            <div class="icon-container" style="display: flex;flex-direction: column;align-items: center;justify-content: space-between;">
                              <img class="image" src="https://misensors-images.s3.amazonaws.com/App+Image+Resources/alert-icon.svg" style="width: 25px;height: 25px;">
                              <p class="number" style="text-align: center;color: #747474;opacity: 1;font-family: 'Open Sans';margin: 0px;font: normal normal 600 14px Open Sans;letter-spacing: 0px;">
                                ${data.alertCount}
                              </p>
                            </div>
                            <div class="divider" style="height: 100%;border: 0.3px solid #CCCCCC;margin-left: 15px;margin-right: 15px;"></div>
                            <div class="icon-container" style="display: flex;flex-direction: column;align-items: center;justify-content: space-between;">
                              <img class="image" src="https://misensors-images.s3.amazonaws.com/App+Image+Resources/gateway-icon.svg" style="width: 25px;height: 25px;">
                              <p class="number" style="text-align: center;color: #747474;opacity: 1;font-family: 'Open Sans';margin: 0px;font: normal normal 600 14px Open Sans;letter-spacing: 0px;">
                                ${data.gatewayCount}
                              </p>
                            </div>
                            <div class="divider" style="height: 100%;border: 0.3px solid #CCCCCC;margin-left: 15px;margin-right: 15px;"></div>
                            <div class="icon-container" style="display: flex;flex-direction: column;align-items: center;justify-content: space-between;">
                              <img class="image" src="https://misensors-images.s3.amazonaws.com/App+Image+Resources/sensor-icon.svg" style="width: 25px;height: 25px;">
                              <p class="number" style="text-align: center;color: #747474;opacity: 1;font-family: 'Open Sans';margin: 0px;font: normal normal 600 14px Open Sans;letter-spacing: 0px;">
                                ${data.sensorCount}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>`,
                });
                ui.addBubble(bubble);
                // window.addEventListener("click", () => {
                //   ui.getBubbles().forEach((bub) => ui.removeBubble(bub));
                // });
                bubble.getElement().addEventListener('click', function (e) {
                  handleNavigate(data.id);
                });

                bubble.addEventListener('statechange', function (evt) {
                  if (evt.target.getState() === H.ui.InfoBubble.State.CLOSED) {
                    const array = openLocations;
                    const index = array.indexOf(data.id);
                    array.splice(index, 1);
                    setOpenLocations(array);
                  }
                });
              },
              true,
            );
            //and return the custom marker (H.map.Marker)
            return noiseMarker;
          },
        };
        // Helper function for getting a random point from a cluster object
        // function getRandomDataPoint(cluster) {
        //   var dataPoints = [];

        //   // Iterate through all points which fall into the cluster and store references to them
        //   cluster.forEachDataPoint(dataPoints.push.bind(dataPoints));

        //   // Randomly pick an index from [0, dataPoints.length) range
        //   // Note how we use bitwise OR ("|") operator for that instead of Math.floor
        //   return dataPoints[(Math.random() * dataPoints.length) | 0];
        // }
        // Create a clustering provider with custom options for clusterizing the input
        clusteredDataProvider = new H.clustering.Provider(dataPoints, {
          theme: CUSTOM_THEME,
        });

        // Create a layer tha will consume objects from our clustering provider
        var clusteringLayer = new H.map.layer.ObjectLayer(
          clusteredDataProvider,
        );

        clusteredDataProvider.addEventListener('update', function (evt) {
          // target is Provider itself after clustering is completed
          if (locations.length === 1) {
            if (evt.target === this) {
              map.getViewModel().setLookAtData({
                // bounds: clusteredDataProvider.getRootGroup().getBoundingBox(),
                // zoom: 11,
              });
            }
          } else {
            if (evt.target === this) {
              map.getViewModel().setLookAtData({
                // bounds: clusteredDataProvider.getRootGroup().getBoundingBox(),
              });
            }
          }
        });

        // To make objects from clustering provder visible,
        // we need to add our layer to the map

        map.addLayer(clusteringLayer);
      }

      const defaultLayers = platform.createDefaultLayers();

      // Create an instance of the
      const map = new H.Map(mapRef.current, defaultLayers.vector.normal.map, {
        // This map is centered over California
        // zoom: 4,
        // zoom: 4,
        // center: new H.geo.Point(30.789, 33.79),
        center: mapCenter,
        zoom: 2.1,
        pixelRatio: window.devicePixelRatio || 1,
        padding: { top: 100, left: 100, bottom: 100, right: 100 },
      });

      // add a resize listener to make sure that the map occupies the whole container
      window.addEventListener('resize', () => map.getViewPort().resize());

      // MapEvents enables the event system
      // Behavior implements default interactions for pan/zoom (also on mobile touch environments)
      // This variable is unused and is present for explanatory purposes

      // Create the default UI components to allow the user to interact with them
      // This variable is unused

      // / Step 3: make the map interactive
      // MapEvents enables the event system
      // Behavior implements default interactions for pan/zoom (also on mobile touch environments)
      const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
      const ui = H.ui.UI.createDefault(map, defaultLayers);

      function addDomMarker(group, location) {
        log('location:', location);
        var outerElement = document.createElement('div'),
          innerElement = document.createElement('div');

        outerElement.style.borderRadius = '50%';
        outerElement.style.width = '58px';
        outerElement.style.height = '58px';
        outerElement.style.padding = '2px';
        outerElement.style.border = '1px solid #051F34';
        outerElement.style.cursor = 'pointer';
        outerElement.style.display = 'none';

        innerElement.style.borderRadius = '50%';
        innerElement.style.width = '54px';
        innerElement.style.height = '54px';
        innerElement.style.padding = '10px';
        innerElement.style.background = '#051F34';

        innerElement.style.color = '#fff';
        innerElement.style.textAlign = 'center';
        innerElement.style.font = '32px Arial, sans-serif';
        innerElement.style.color = 'white';
        innerElement.style.top = '50%';
        innerElement.style.left = '50%';
        innerElement.style.transform = 'translate(-50%, -50%)';
        innerElement.style.margin = '0';
        innerElement.style.position = 'absolute';
        innerElement.style.display = 'none';

        outerElement.appendChild(innerElement);

        // Add text to the DOM element
        innerElement.innerHTML = 1;

        function changeOpacity(evt) {
          evt.target.style.opacity = 0.6;
        }

        function changeOpacityToOne(evt) {
          evt.target.style.opacity = 1;
        }

        //create dom icon and add/remove opacity listeners
        var domIcon = new H.map.DomIcon(outerElement, {
          // the function is called every time marker enters the viewport
          onAttach: function (clonedElement, domIcon, domMarker) {
            clonedElement.addEventListener('mouseover', changeOpacity);
            clonedElement.addEventListener('mouseout', changeOpacityToOne);
          },
          // the function is called every time marker leaves the viewport
          onDetach: function (clonedElement, domIcon, domMarker) {
            clonedElement.removeEventListener('mouseover', changeOpacity);
            clonedElement.removeEventListener('mouseout', changeOpacityToOne);
          },
        });

        // Marker for locations
        let locationMarker = new H.map.DomMarker(
          { lat: location.coords.lat, lng: location.coords.lng },
          {
            icon: domIcon,
          },
        );
        locationMarker.addEventListener(
          'tap',
          event => {
            let bubble = new H.ui.InfoBubble(event.target.getGeometry(), {
              content: `<div class="principal-container" style="display: flex;align-items: center;">
                  <div class="triangle" style="transform: rotate(45deg);background-color: #FFFFFF;width: 15px;height: 15px;margin-right: -8px;">
                  </div>
                  <div class="pop-container" style="background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 0px 0px 6px #00000040;opacity: 1;border-radius: 10px;padding-top: 11px;padding-bottom: 13px;padding-left: 20px;padding-right: 13px;flex-direction: row;display: flex;width: 290px;height: 55px;justify-content: space-between;">
                    <div class="left-container">
                      <p class="title" style="text-align: left;color: #747474;opacity: 1;font-family: 'Open Sans';margin: 0px;font: normal normal bold 14px Open Sans;">
                        Location Name
                      </p>
                      <p class="subtitle" style="text-align: left;color: #747474;opacity: 1;font-family: 'Open Sans';margin: 0px;font: normal normal normal 12px Open Sans;">
                        12345 River Road
                      </p>
                      <p class="subtitle" style="text-align: left;color: #747474;opacity: 1;font-family: 'Open Sans';margin: 0px;font: normal normal normal 12px Open Sans;">
                        San Diego, CA 92129
                      </p>
                    </div>
                    <div class="right-container" style="display: flex;flex-direction: row;margin-left: 20px;">
                      <div class="icon-container" style="display: flex;flex-direction: column;align-items: center;justify-content: space-between;">
                        <img class="image" src="https://misensors-images.s3.amazonaws.com/App+Image+Resources/alert-icon.svg" style="width: 25px;height: 25px;">
                        <p class="number" style="text-align: center;color: #747474;opacity: 1;font-family: 'Open Sans';margin: 0px;font: normal normal 600 14px Open Sans;letter-spacing: 0px;">
                          6
                        </p>
                      </div>
                      <div class="divider" style="height: 100%;border: 0.3px solid #CCCCCC;margin-left: 15px;margin-right: 15px;"></div>
                      <div class="icon-container" style="display: flex;flex-direction: column;align-items: center;justify-content: space-between;">
                        <img class="image" src="https://misensors-images.s3.amazonaws.com/App+Image+Resources/gateway-icon.svg" style="width: 25px;height: 25px;">
                        <p class="number" style="text-align: center;color: #747474;opacity: 1;font-family: 'Open Sans';margin: 0px;font: normal normal 600 14px Open Sans;letter-spacing: 0px;">
                          3
                        </p>
                      </div>
                      <div class="divider" style="height: 100%;border: 0.3px solid #CCCCCC;margin-left: 15px;margin-right: 15px;"></div>
                      <div class="icon-container" style="display: flex;flex-direction: column;align-items: center;justify-content: space-between;">
                        <img class="image" src="https://misensors-images.s3.amazonaws.com/App+Image+Resources/sensor-icon.svg" style="width: 25px;height: 25px;">
                        <p class="number" style="text-align: center;color: #747474;opacity: 1;font-family: 'Open Sans';margin: 0px;font: normal normal 600 14px Open Sans;letter-spacing: 0px;">
                          3
                        </p>
                      </div>
                    </div>
                  </div>
                </div>`,
            });
            ui.addBubble(bubble);
          },
          false,
        );
        group.addObject(locationMarker);
      }

      if (locations.length !== 0) {
        var group = new H.map.Group();
        map.addObject(group);

        startClustering(map, locations);

        locations.forEach((location, index) => {
          // Create an icon, an object holding the latitude and longitude, and a marker:
          addDomMarker(group, location, index);
        });
        let bbox = group.getBoundingBox();
        map.getViewPort().setPadding(250, 250, 250, 250);

        if (locations.length === 1) {
          map.getViewModel().setLookAtData({
            zoom: 11,
            bounds: bbox,
          });
        } else {
          map.getViewModel().setLookAtData({
            bounds: bbox,
          });
        }
      }
      // addDomMarker(map);
      map.addEventListener(
        'pointermove',
        function (event) {
          if (event.target instanceof H.map.Marker) {
            map.getViewPort().element.style.cursor = 'pointer';
          } else {
            map.getViewPort().element.style.cursor = 'auto';
          }
        },
        false,
      );
      map.addEventListener(
        'tap',
        function (event) {
          if (event.target instanceof H.map.Marker) {
          } else {
            changeLocation();
            ui.getBubbles().forEach(bub => ui.removeBubble(bub));
          }
        },
        false,
      );
      map.addEventListener(
        'statechange',
        function (evt) {
          if (evt.target.getState() === H.ui.InfoBubble.State.CLOSED) {
            const array = openLocations;
            setOpenLocations(array);
          }
        },
        false,
      );
      map.addEventListener('statechange', function (evt) {
        if (evt.target.getState() === H.ui.InfoBubble.State.CLOSED) {
          const array = openLocations;
          setOpenLocations(array);
        }
      });
      setState({ map });

      // Cleanup after the map to avoid memory leaks when this component exits the page
      return () => {
        map.dispose();
      };
    }
  }, [mapRef, locations]);

  setTimeout(() => {
    if (state && state.map.O) {
      window.addEventListener('resize', () => state.map.getViewPort().resize());

      // NOTE removed, was getting error: "Uncaught TypeError: Cannot read property 'offsetWidth' of null"
      // window.addEventListener('click', () => state.map.getViewPort().resize());
    }
  }, 250);

  function debounce(func) {
    var timer;
    return function (event) {
      if (timer) clearTimeout(timer);
      timer = setTimeout(func, 250, event);
    };
  }

  function changeLocation() {
    const array = openLocations;
    while (array.length) {
      array.pop();
    }
    setOpenLocations(array);
  }

  return (
    <Card className={classes.card}>
      {title && (
        <Box
          fontWeight='fontWeightMedium'
          fontSize={16}
          className={classes.cardHeader}
        >
          <CardHeader
            className={classes.headerTitle}
            title={title.toUpperCase()}
            disableTypography={true}
          />
        </Box>
      )}
      <CardContent style={mapContainerStyle}>
        <div id='mapView' ref={mapRef} style={mapStyle} />
      </CardContent>
    </Card>
  );
};

export default MapLayout;
