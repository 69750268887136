import { navigate } from 'hookrouter';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import LiveHelpOutlinedIcon from '@material-ui/icons/LiveHelpOutlined';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import React, { useContext, useState, useRef } from 'react';
import {
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  IconButton,
  ListItemIcon,
  ListItemText,
  ClickAwayListener,
  useMediaQuery,
} from '@material-ui/core';

import { AppContext } from '../../../AppContext';

const useStyles = makeStyles(() => ({
  menuItem: {
    paddingLeft: 5,
  },
}));

export default function HeaderProfile(props) {
  const log = window.log('Header');
  const classes = useStyles();

  const { logout, dividerStyle } = props;

  const { appState, setOpenSettingsModal } = useContext(AppContext);

  const [openUserMenu, setOpenUserMenu] = useState(false);

  const anchorRef = useRef(null);
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));

  // Theme menu open and close functions
  const handleColorDialogOpen = () => {
    handleCloseUserMenu();
    setOpenSettingsModal(true);
  };

  const handleToggleUserMenu = () => {
    setOpenUserMenu(prevOpenUserMenu => !prevOpenUserMenu);
  };

  const handleCloseUserMenu = e => {
    setOpenUserMenu(false);
  };

  function handleListKeyDown(e) {
    if (e.key === 'Tab') {
      e.preventDefault();
      setOpenUserMenu(false);
    }
  }

  const handleNavigateToProfile = () => {
    navigate(`/current/user/configuration`);
    handleCloseUserMenu();
  };
  const handleNavigateToSupportPage = () => {
    window.open('https://www.misensors.com/support/');
    handleCloseUserMenu();
  };

  const goToUpdateDevices = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        'scanDevices|1.7|https://misensors-images.s3.amazonaws.com/MiSensor+Firmware/dcs_mitag_1.7_dfu_package.zip',
      );
      setOpenUserMenu(false);
    }
  };

  return [
    <div className={dividerStyle} key='profile_divider' />,
    <div key='profile_icon'>
      <IconButton
        id='settings_gear'
        onClick={handleToggleUserMenu}
        aria-controls={openUserMenu ? 'menu-list-grow' : undefined}
        aria-haspopup='true'
        ref={anchorRef}
      >
        <SettingsIcon style={{ color: 'white' }} />
      </IconButton>
      <Popper
        open={openUserMenu}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement='top'
        style={{zIndex: 200}}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCloseUserMenu}>
                <MenuList
                  autoFocusItem={openUserMenu}
                  id='menu-list-grow'
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem
                    className={classes.menuItem}
                    onClick={handleNavigateToProfile}
                  >
                    <ListItemIcon>
                      <IconButton>
                        <AccountBoxIcon />
                      </IconButton>
                    </ListItemIcon>
                    <ListItemText>Profile</ListItemText>
                  </MenuItem>
                  {appState.auth.loggedIn && (
                    <MenuItem
                      className={classes.menuItem}
                      onClick={handleColorDialogOpen}
                    >
                      <ListItemIcon>
                        <IconButton>
                          <SettingsIcon />
                        </IconButton>
                      </ListItemIcon>
                      <ListItemText>Settings</ListItemText>
                    </MenuItem>
                  )}
                  {window.ReactNativeWebView && (
                    <MenuItem
                      className={classes.menuItem}
                      onClick={goToUpdateDevices}
                    >
                      <ListItemIcon>
                        <IconButton>
                          <SystemUpdateAltIcon />
                        </IconButton>
                      </ListItemIcon>
                      <ListItemText>Update Devices</ListItemText>
                    </MenuItem>
                  )}
                  <MenuItem
                    className={classes.menuItem}
                    onClick={handleNavigateToSupportPage}
                  >
                    <ListItemIcon>
                      <IconButton>
                        <LiveHelpOutlinedIcon />
                      </IconButton>
                    </ListItemIcon>
                    <ListItemText>Support</ListItemText>
                  </MenuItem>
                  <MenuItem className={classes.menuItem} onClick={logout}>
                    <ListItemIcon>
                      <IconButton>
                        <ExitToAppIcon />
                      </IconButton>
                    </ListItemIcon>
                    <ListItemText>Logout</ListItemText>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>,
  ];
}
