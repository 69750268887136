import React, { useState } from 'react'

import {
  Box,
  TextField,
  InputAdornment,
} from "@material-ui/core";

import { makeStyles, withStyles } from "@material-ui/core/styles";

import Search from "@material-ui/icons/Search";

import grey from "@material-ui/core/colors/grey";

const CssTextField = withStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#FFf',
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
      }
    },
  },
}))(TextField);

export default function MobileSearchBar(props) {
  const {
    searchCallback,
    suffixComponent = (<div></div>)
  } = props;

  const [searchWord, setSearchWord] = useState("");

  const handleChange = (event) => {
    setSearchWord(event.target.value);
    searchCallback(event.target.value);
  };

  const useStyles = makeStyles((theme) => ({
    searchInput: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: "5px",
      flexGrow: 1,
      margin: "5px 15px"
    }
  }))

  const classes = useStyles();

  return (
    <Box flexGrow={1} display="flex" justifyContent="flex-end">
      <CssTextField
        variant="outlined"
        placeholder="Search"
        value={searchWord}
        onChange={handleChange}
        className={classes.searchInput}
        margin="dense"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search style={{ color: grey[500] }} />
            </InputAdornment>
          ),
        }}
      />
      { suffixComponent }
    </Box>
  )
};
