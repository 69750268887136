import React, { forwardRef, useState, useImperativeHandle } from 'react';
import {
  Box,
  Grid,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Button,
  MobileStepper,
  withWidth,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core/';
import { Alert, AlertTitle } from '@material-ui/lab';

import logo from '../../img/MiSensorsLogo.svg';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';

// import withWidth from "@material-ui/core/withWidth";

const isSmall = _width => {
  switch (_width) {
    case 'sm':
      return true;
    case 'xs':
      return true;
    default:
      return false;
  }
};

/**
 * 
 * @param {Object} props
 * @props
 * stepsArray: Array of Objects, [{
  label: String,
  child: ReactComponent,
  nextFunction: Function,
  backFunction: Function,
  isCountlessStep: Boolean, if this is true the child will be shown but it wont be counted in the stepper
  validator: Boolean,
  skipStep: Boolean,
  nextStepCallback: Function, a function that executes after nextFunction , and before the next step
  saveButtonStep: Optional Boolean, if set to true, "Next" Button will say "Save"
}]
* title: String
* finishCallback: Function
*/
const StepperForm = forwardRef((props, ref) => {
  const log = window.log('StepperForm');

  useImperativeHandle(ref, () => ({
    nextStep() {
      setActiveStep(activeStep + 1);
    },
  }));
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeStep, setActiveStep] = useState(0);

  const [stepperVisualStep, setStepperVisualStep] = useState(0);

  const [alertModal, setAlertModal] = useState(false);

  // const [warningAnimatedPlace, setWarningAnimatedPlace] = useState('translate(0, 0)')

  // // centinel if the warnign animation has been played
  // const [playedWarningAnimation, setPlayedWarningAnimation] = useState(false)

  const {
    width, // withWidth MaterialUI property
    stepsArray, // Array of objects that have te label and the components for the step
    title, // Title of the form
    finishCallback, // Callback when finished
    cancelCallback, // Callback when cancel
    handleFinish,
  } = props;
  // log(activeStep);
  // log(stepsArray[activeStep]);
  const buttonContainerHeight = stepsArray[activeStep]?.showSkipButton
    ? 130
    : 80;

  const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      height: '85vh',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        height: '100vh',
      },
    },
    stepperContainer: {
      backgroundColor: theme.palette.background.paper,
      minWidth: '280px',
      height: '100%',
      marginLeft: 30,
      [theme.breakpoints.down('sm')]: {
        height: '110px',
        marginLeft: 0,
      },
    },
    formContainer: {
      backgroundColor:
        theme.palette.type === 'light'
          ? '#F4F5F5'
          : theme.palette.background.default,
      overflow: 'auto',
      // minWidth: "50vw",
      width: '100vw',
      [theme.breakpoints.down('sm')]: {
        height: 'calc(100% - 110px)',
      },
    },
    mobileStepper: {
      backgroundColor: theme.palette.background.paper,
    },
    mobileStepTitle: {},
    stepperWrapper: {
      height: '80%',
      maxHeight: '550px',
      overflow: 'auto',
      [theme.breakpoints.down('sm')]: {
        height: '60px !important',
      },
    },
    childContent: {
      width: '100%',
      height: `calc(100% - ${buttonContainerHeight}px - 58px - ${
        stepsArray[activeStep].showWarning ? 40 : 0
      }px)`,
      padding: 70,
      overflowY: 'auto',
      transform: 'translateY(-33px)',
      [theme.breakpoints.down('sm')]: {
        height: '65vh',
      },
      [theme.breakpoints.down('xs')]: {
        padding: 29,
        transform: 'translateY(-12px)',
      },
    },
    buttonsContainer: {
      padding: theme.spacing(1, 0),
      maxHeight: `${buttonContainerHeight}px`,
      // height: `${buttonContainerHeight}px`,
      width: '100%',
      // transform: activeStep !== 0 ? 'translateY(-30px)' : 'translateY(22px)',
      [theme.breakpoints.down('xs')]: {
        transform: 'translateY(0px)',
      },
    },
    stepButtons: {
      padding: theme.spacing(0, 4),
      width: '100%',
      [theme.breakpoints.down('xs')]: {},
    },
    skipStepButtons: {
      padding: theme.spacing(0, 4),
      // marginBottom: theme.spacing(1),
    },
    title: {
      marginTop: 30,
      marginLeft: theme.spacing(2),
      color:
        theme.palette.type === 'light'
          ? theme.palette.grey[600]
          : theme.palette.text.primary,
      fontWeight: 'bold',
      [theme.breakpoints.down('xs')]: {
        marginTop: 15,
        textAlign: 'center',
        marginLeft: theme.spacing(0),
      },
    },
    subtitle1: {
      color:
        theme.palette.type === 'light'
          ? theme.palette.grey[600]
          : theme.palette.text.primary,
    },
    imageContainer: {
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        flexGrow: 0,
      },
    },
    imageLogo: {
      objectFit: 'contain',
      width: '160px',
      margin: theme.spacing(2),
      marginBottom: 49,
      marginRight: 37,
      [theme.breakpoints.down('sm')]: {
        marginBottom: 45,
      },
    },
    button: {
      padding: theme.spacing(1, 3),
    },
    stepperLabel: {
      color: theme.palette.primary.main,
      fontSize: 14,
    },
    rightButton: {
      width: 125,
      marginRight: 30,
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    leftButton: {
      width: 125,
      marginLeft: 36,
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    cancelButton: {
      color: theme.palette.error.main,
      width: 125,
      marginLeft: 36,
      // [theme.breakpoints.up('sm')]: {
      //   padding: theme.spacing(1, 3),
      // },
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
      },
    },
    warningTitle: {
      fontWeight: 700,
      color: '#663C00',
    },
    warningContainer: {
      marginTop: theme.spacing(3),
      transition: '0.1s transform',
      display: 'flex',
      flexDirection: 'flex-end',
    },
  }));

  const classes = useStyles();

  // const animateWarning = () => {
  //   setWarningAnimatedPlace('translate(5px, 0)')
  //   setTimeout(() => {
  //     setWarningAnimatedPlace('translate(-5px, 0)')
  //   }, 300)
  //   setTimeout(() => {
  //     setWarningAnimatedPlace('translate(5px, 0)')
  //   }, 300);
  //   setTimeout(() => {
  //     setWarningAnimatedPlace('translate(0, 0)')
  //   }, 300);
  // }

  // if(stepsArray[activeStep]?.showWarning){
  //   setTimeout(() => {
  //     animateWarning()
  //   }, 1500)
  // }

  /**
   * Function to handle the change of a Step so it can have steps that dont appear in the
   * stepper side bar but are still shown in the view
   * @param {integer} stepNumber, value of the next step
   */
  const handleActiveStep = stepNumber => {
    //  calculates if we are adding or substracting steps
    const difference = stepNumber - activeStep;
    setActiveStep(activeStep + difference);

    if (title === 'WELCOME TO MISENSORS') {
      if (difference > 0 && !stepsArray[stepNumber].isCountlessStep) {
        setStepperVisualStep(stepperVisualStep + 1);
      } else if (
        difference < 0 &&
        !stepsArray[stepNumber + 1].isCountlessStep
      ) {
        setStepperVisualStep(stepperVisualStep - 1);
      }
    } else {
      //this else block does not work for the onboarding modal.
      if (difference > 0) {
        if (!stepsArray[stepNumber].isCountlessStep) {
          setStepperVisualStep(stepperVisualStep + difference);
        }
      } else if (difference < 0) {
        if (!stepsArray[stepNumber + 1].isCountlessStep) {
          setStepperVisualStep(stepperVisualStep + difference);
        }
      }
    }
  };

  const handleNext = async () => {
    log(
      'StepperForm: ActiveStep:StepArrayLength\t' +
        activeStep +
        ':' +
        stepsArray.length,
    );

    //If you're on the last step
    if (activeStep === stepsArray.length - 1) {
      log('StepperForm: Last step');
      if (handleFinish) {
        //only for onboard modal
        handleFinish();
      } else if (finishCallback) {
        finishCallback();
      }
    } else {
      log('StepperForm: Not the last step');
      // log(
      //   "StepperForm: Showing condtional value nextFunction\t" +
      //     stepsArray[activeStep].nextFunction
      // );
      log(
        'StepperForm: Showing condtional value validator\t' +
          stepsArray[activeStep].validator,
      );
      log(
        'StepperForm: Showing condtional value show progress\t' +
          stepsArray[activeStep].showProgress,
      );
      if (
        stepsArray[activeStep].nextFunction &&
        stepsArray[activeStep].validator &&
        stepsArray[activeStep].showProgress
      ) {
        let verifyResult;
        //Do something before page renders to next page
        if (stepsArray[activeStep].verifyBeforeNextPageRender) {
          verifyResult = await stepsArray[
            activeStep
          ].verifyBeforeNextPageRender();
        }

        handleActiveStep(activeStep + 1);
        log('StepperForm: Inside of if statement Active Step:\t' + activeStep);

        let result;
        if (verifyResult === undefined || verifyResult) {
          result = await stepsArray[activeStep].nextFunction(verifyResult);
        }
        log('StepperForm: Looking at the result\t', result);

        // Check if api call was successful, move to next step if true else go back
        if (result) {
          log('StepperForm: Result true incrementing step');
          // setTimeout(() => {
          handleActiveStep(activeStep + 2);
          // handleActiveStep(activeStep + 1);
          // }, 3000);
        } else {
          log('StepperForm: Result false decrementing step');
          // setTimeout(() => {
          if (stepsArray[activeStep].label === 'PAYMENT INFORMATION') {
            handleActiveStep(activeStep);
            setStepperVisualStep(activeStep - 1);
          } else {
            handleActiveStep(activeStep);
            setStepperVisualStep(activeStep);
          }

          // handleActiveStep(activeStep - 1);
          // }, 3000);
        }
      } else if (
        stepsArray[activeStep].nextFunction &&
        !stepsArray[activeStep].showProgress
      ) {
        log('StepperForm: Inside of else if Active Step:\t' + activeStep);

        let result = await stepsArray[activeStep].nextFunction();
        // Check if api call was successful, move to next step if true else go back
        if (result) {
          if (stepsArray[activeStep + 1].skipStep) {
            // setActiveStep((prevActiveStep) => prevActiveStep + 2);
            handleActiveStep(activeStep + 2);
          } else {
            // setActiveStep((prevActiveStep) => prevActiveStep + 1);
            handleActiveStep(activeStep + 1);
          }
        } else {
          log('test failure');
        }
      } else {
        log('StepperForm: Inside of else Active Step:\t' + activeStep);
        if (stepsArray[activeStep + 1].skipStep) {
          // setActiveStep((prevActiveStep) => prevActiveStep + 2);
          handleActiveStep(activeStep + 2);
        } else {
          // setActiveStep((prevActiveStep) => prevActiveStep + 1);
          handleActiveStep(activeStep + 1);
        }
      }
    }
    //To allow steps to have a callback method
    if (stepsArray[activeStep].nextStepCallback != null) {
      log('StepperForm: Inside of callback if');
      stepsArray[activeStep].nextStepCallback();
    }
    log('StepperForm: Exiting handleNext function. ActiveStep\t' + activeStep);
  };

  const handleBack = () => {
    log(
      'StepperForm: start handleBack----------------------------------------------',
    );

    if (
      stepsArray[activeStep].backFunction &&
      !stepsArray[activeStep].validator
    ) {
      stepsArray[activeStep].backFunction();
    } else {
      if (activeStep === 0) {
        if (cancelCallback) {
          cancelCallback();
        }
      } else if (stepsArray[activeStep - 1].label === 'LOADING') {
        // setActiveStep((prevActiveStep) => prevActiveStep - 2);
        handleActiveStep(activeStep - 2);
      } else if (stepsArray[activeStep - 1].skipStep) {
        // setActiveStep((prevActiveStep) => prevActiveStep - 2);
        handleActiveStep(activeStep - 2);
      } else {
        // setActiveStep((prevActiveStep) => prevActiveStep - 1);
        handleActiveStep(activeStep - 1);
      }

      if (stepsArray[activeStep].callback) {
        stepsArray[activeStep].callback();
      }
    }
  };

  const stepLabels = stepsArray.reduce(function (filtered, step) {
    if (step.label !== '' && step.isCountlessStep !== true) {
      filtered.push(step);
    }
    return filtered;
  }, []);

  const closeStepAlerModal = () => {
    setAlertModal(false);
  };
  const openStepAlerModal = () => {
    setAlertModal(true);
  };

  const continueAlertModal = () => {
    setAlertModal(false);
    handleNext();
  };

  const handleNextWrapper = () => {
    /** verify to show the alert dialog before calling handle next */
    if (stepsArray[activeStep]?.showNextStepAlert) {
      openStepAlerModal();
    } else {
      handleNext();
    }
  };

  const cancelButtonvalidator = () => {
    return !(
      activeStep === 0 ||
      stepsArray[activeStep]?.label === 'LOADING' ||
      stepsArray[activeStep]?.label === 'COMPLETE' ||
      stepsArray[activeStep]?.label === 'EDIT COMPLETE'
    );
  };
  return (
    // Left section with steps and app logo
    <Box className={classes.container} spacing={0}>
      {/* Left section with steps and app logo */}
      <Box className={classes.stepperContainer}>
        <Box
          display='flex'
          flexDirection='column'
          style={{
            height: '100%',
          }}
        >
          <Typography variant='h6' component='div' className={classes.title}>
            {title}
          </Typography>
          <Box className={classes.stepperWrapper}>
            {fullScreen ? (
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
              >
                {/* Stepper when in small screens */}

                <MobileStepper
                  variant='dots'
                  steps={stepsArray.length}
                  position='static'
                  activeStep={stepperVisualStep}
                  className={classes.mobileStepper}
                />
                <Typography
                  component='div'
                  variant='subtitle1'
                  className={classes.subtitle1}
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {stepsArray[activeStep].label}
                </Typography>
              </Box>
            ) : (
              <Stepper activeStep={stepperVisualStep} orientation='vertical'>
                {stepLabels.map(({ label }) => {
                  if (label !== 'LOADING') {
                    return (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    );
                  }
                  return null;
                })}
              </Stepper>
            )}
          </Box>

          <Box
            justifyContent='center'
            display='flex'
            alignItems='flex-end'
            style={{ width: '100%' }}
            className={classes.imageContainer}
          >
            {isSmall ? null : <div></div>}
          </Box>
        </Box>
      </Box>{' '}
      {/* End Left section with steps and app logo */}
      {/* Right section of the stepper with the steps of the form */}
      <Box className={classes.formContainer}>
        {/* Current step child component */}
        <Box
          className={classes.childContent}
          style={{
            height:
              stepsArray[activeStep].showWarning && isSmall() ? 'auto' : '',
          }}
        >
          {stepsArray[activeStep]?.child}
          {/* If there is a warning in the step */}
          {stepsArray[activeStep]?.showWarning ? (
            <Grid
              item
              container
              xs={8}
              alignItems='center'
              justify='center'
              style={{ float: 'right' }}
            >
              <Alert severity='warning' className={classes.warningContainer}>
                <AlertTitle className={classes.warningTitle}>
                  {stepsArray[activeStep].warning.title}
                </AlertTitle>
                {stepsArray[activeStep].warning.message}
              </Alert>
            </Grid>
          ) : null}
        </Box>

        {/* Container of buttons  */}
        <Box
          // item container xs={12}
          className={classes.buttonsContainer}
        >
          {/* Optional skip step button */}
          {/* {stepsArray[activeStep].showSkipButton ? (
            <Grid
              item
              container
              xs={12}
              alignItems="center"
              justify="start"
              className={classes.skipStepButtons}
            >
              <Button
                size="large"
                variant="outlined"
                color="primary"
                onClick={handleNext}
                className={classes.button}
              >
                SKIP THIS STEP
              </Button>
            </Grid>
          ) : null} */}
          <Grid container item xs={12}>
            {/* Cancel Button after first step */}
            {activeStep === 0 ||
            stepsArray[activeStep]?.label === 'LOADING' ||
            stepsArray[activeStep]?.label === 'COMPLETE' ||
            stepsArray[activeStep]?.label === 'EDIT COMPLETE' ? (
              <Grid item xs={4}></Grid>
            ) : (
              <Grid
                item
                container
                xs={4}
                alignItems='center'
                justify='flex-start'
                className={classes.skipStepButtons}
              ></Grid>
            )}
          </Grid>
          {/* navigation buttons */}

          <Box
            display='flex'
            alignItems='flex-end'
            justifyContent='space-between'
            className={classes.stepButtons}
          >
            <Grid container>
              <Grid
                item
                xs={5}
                style={{
                  display:
                    cancelButtonvalidator() && !fullScreen ? 'block' : 'none',
                }}
              >
                <Button
                  size='large'
                  variant='outlined'
                  onClick={() => cancelCallback()}
                  className={classes.cancelButton}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={cancelButtonvalidator() && !fullScreen ? 7 : 12}
              >
                <Box
                  display='flex'
                  alignItems='flex-end'
                  justifyContent='space-between'
                  style={{ width: '100%' }}
                >
                  {cancelButtonvalidator() && fullScreen && (
                    <Button
                      size='small'
                      variant='outlined'
                      onClick={() => cancelCallback()}
                      className={classes.cancelButton}
                    >
                      Cancel
                    </Button>
                  )}
                  {stepsArray[activeStep]?.label === 'LOADING' ||
                  stepsArray[activeStep]?.label === 'COMPLETE' ||
                  activeStep === stepsArray.length - 1 ? null : (
                    <Button
                      size={fullScreen ? 'small' : 'large'}
                      variant='outlined'
                      color='primary'
                      onClick={handleBack}
                      className={classes.leftButton}
                    >
                      {activeStep === 0 ? 'Cancel' : 'Previous'}
                    </Button>
                  )}
                  {stepsArray[activeStep]?.label === 'LOADING' ||
                  (stepsArray[activeStep]?.nextButtonActive !== undefined &&
                    !stepsArray[activeStep]?.nextButtonActive) ? null : (
                    <Button
                      size={fullScreen ? 'small' : 'large'}
                      variant='contained'
                      color='primary'
                      onClick={handleNextWrapper}
                      // onClick={handleNext}
                      // onClick={() => { setAlertModal(true) }}

                      className={classes.rightButton}
                      id='closeModalButton'
                    >
                      {stepsArray[activeStep]?.nextButtonName
                        ? stepsArray[activeStep]?.nextButtonName
                        : stepsArray[activeStep]?.label === 'EDIT COMPLETE'
                        ? 'Close'
                        : activeStep === stepsArray.length - 1 ||
                          stepsArray[activeStep]?.label === 'COMPLETE'
                        ? 'Finish'
                        : stepsArray[activeStep]?.saveButtonStep
                        ? 'Save'
                        : 'Next'}
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
          {/* /end navigation buttons  */}
          {/* Dialog to show an alert before the next step */}
          <Dialog
            open={alertModal}
            maxWidth={'xs'}
            onClose={() => {
              setAlertModal(false);
            }}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>
              {stepsArray[activeStep]?.nextStepAlert?.title}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                {stepsArray[activeStep]?.nextStepAlert?.message}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={closeStepAlerModal}
                className={classes.cancelButton}
                size='large'
                variant='outlined'
              >
                Return
              </Button>
              <Button
                onClick={continueAlertModal}
                size='large'
                variant='contained'
                color='primary'
                className={classes.rightButton}
              >
                Continue
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>{' '}
      {/* End Right section of the stepper with the steps of the form */}
    </Box>
  );
});

export default StepperForm;
