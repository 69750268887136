import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import sensor from '../../../img/settings_remote-24px.svg';
import DecoratedImageButton from '../../Generic/DecoratedImageButton';

/**
 * Sensor Plan
 * @param {Object} props
 */
export function SensorPlan(props) {
  const {
    plan,
    selSubLevel,
    selectedPlan,
    setSelectedPlan,
    currentSubscription,
    isMobile
  } = props;

  const theme = useTheme();
  const [title, setTitle] = useState('');
  const [titleColor, setTitleColor] = useState('#59C754');
  const [color, setColor] = useState(theme.palette.background.paper);

  useEffect(() => {
    determineTitle();
  }, [selectedPlan, currentSubscription]);

  const rgbColorToOpacity = (color, opacity = 0.3) => {
    return color.replace('rgb', 'rgba').replace(')', `, ${opacity})`);
  };

  const handlePlanChange = async selPlan => {
    const clonedPlan = _.clone(selPlan);
    clonedPlan.price = selPlan.price[selSubLevel];
    setSelectedPlan(clonedPlan);
  };

  const determineTitle = () => {
    let titleText = '';

    if (currentSubscription.planInfo?.id.split('-')[0] === plan.id) {
      titleText = 'Current Subscription';
    }

    if (
      currentSubscription.pendingSubscription?.planId.split('-')[0] ===
        plan.id &&
      currentSubscription.pendingSubscription?.planId.split('-')[0] !==
        currentSubscription.planInfo.id.split('-')[0]
    ) {
      titleText = `Pending (${currentSubscription.nextBillingDate})`;
      setTitleColor('#0B9EDE');
    }

    if (
      selectedPlan.id === plan.id &&
      selectedPlan.id !== currentSubscription.planInfo?.id.split('-')[0] &&
      selectedPlan.id !==
        currentSubscription.pendingSubscription?.planId.split('-')[0]
    ) {
      setColor(rgbColorToOpacity(theme.palette.primary.light, 0.3));
    } else {
      setColor(theme.palette.background.paper);
    }

    setTitle(titleText);
  };
  return (
    <Grid
      data-testid={`subButtons_${plan.id}`}
      key={plan.id}
      item
      xs={12}
      container
      style={{
        flexBasis: '25%',
        justifyContent: 'center',
        alignItems: 'flex-end',
      }}
    >
      <DecoratedImageButton
        onClick={() => {
          handlePlanChange(plan);
        }}
        billingModal={{
          width: isMobile ? 90 : 100,
          height: 123,
          textSize: 11,
          current: currentSubscription.planInfo?.id.split('-')[0] === plan.id,
          selected: selectedPlan.id.split('-')[0] === plan.id,
          pending:
            currentSubscription.pendingSubscription?.planId.split('-')[0] ===
            plan.id,
        }}
        image={sensor}
        action={`Up to ${plan.sensors}`}
        text='SENSORS'
        title={title}
        titleColor={titleColor}
        color={color}
      />
    </Grid>
  );
}
