import React from "react";
import { Card, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { autoPlay } from "react-swipeable-views-utils";

/**
 * GenericListCard component
 * @param {Object} props
 * @props
 * Children: Array[Component]
 */
function GenericListCard(props) {
  const {
    height = "100%",
    children, //Array of children component
    sensorCard,
    singleGatewayCard,
    userCard,
    smallDevice = false,
    resellerCard = false,
    singleSensorCard,
    backgroundColor
  } = props;

  const useStyles = makeStyles((theme) => ({
    rootCard: {
      padding: sensorCard ? "8px 0px 8px 0px" : theme.spacing(1),
      height: height,
      backgroundColor: backgroundColor,
    },
    cardContent: {
      height: height,
      marginLeft: (sensorCard || singleGatewayCard || userCard || smallDevice || singleSensorCard) ? 0 : 45,
      paddingLeft: singleGatewayCard && 10,
      paddingBottom: resellerCard && '18px !important',
      [theme.breakpoints.down('sm')]: {
        marginLeft: (sensorCard || singleGatewayCard || userCard || smallDevice) ? 0 : 15,
      },
      [theme.breakpoints.down('sm')]: {
        transform: (singleGatewayCard ) && "translateX(-45px)",
      },
      [theme.breakpoints.down('xs')]: {
        transform: (singleGatewayCard ) && "translateX(0)",
      },
    },
  }));
  const classes = useStyles();

  return (
    <Card
      elevation={2}
      className={classes.rootCard}
    >
      <CardContent className={classes.cardContent}>{children}</CardContent>
    </Card>
  );
}

export default GenericListCard;
