import _ from 'lodash';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import grey from '@material-ui/core/colors/grey';
import { Grid, Typography } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import React, { memo, useContext, useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';

import { AlertContext } from '../AlertContext';
import { DisableSwitch } from './DisableSwitch';
import { EditAction } from '../ManageAlertModalComp/EditAction';
import GenericList from '../../Dashboard/GenericList';
import ListElement from '../../Generic/ListElement';

function GenericAssignedContent({
  listType,
  userArr = [],
  enabledSensorArr = [],
  disabledSensorArr = [],
}) {
  const theme = useTheme();
  const [sensorArr, setSensorArr] = useState([]);

  const { modalState, setModalState, saveValueChange } =
    useContext(AlertContext);

  useEffect(() => {
    if (listType === 'sensor') {
      const totalArr = _.concat(enabledSensorArr, disabledSensorArr);
      setSensorArr(_.sortBy(totalArr, ['sensorName']));
    }
  }, [enabledSensorArr, disabledSensorArr]);

  function deleteItem(index) {
    if (!_.isEmpty(userArr)) {
      _.pullAt(userArr, index);
      saveValueChange('user', userArr);
    } else if (!_.isEmpty(sensorArr)) {
      _.pullAt(sensorArr, index);
      const sensorIdArr = _.map(sensorArr, sensor => sensor.sensorId);
      saveValueChange('sensor', sensorIdArr);
    }
  }

  function handleUserCheck(index) {
    userArr[index]['disabledNotifcations'] =
      !userArr[index]['disabledNotifcations'];
    saveValueChange('user', userArr);
  }

  function checkDisabled(index) {
    const found = _.find(disabledSensorArr, {
      sensorId: sensorArr[index].sensorId,
    });
    if (found) return true;
    else return false;
  }

  function handleSensorCheck(index) {
    const clonedDisabled = _.clone(disabledSensorArr);
    const clonedEnabled = _.clone(enabledSensorArr);
    const sensor = sensorArr[index];
    const disabledSensorIndex = _.findIndex(clonedDisabled, {
      sensorId: sensor.sensorId,
    });
    const enabledSensorIndex = _.findIndex(clonedEnabled, {
      sensorId: sensor.sensorId,
    });

    // if disabled, make enabled; vice versa
    if (disabledSensorIndex > -1) {
      _.pullAt(clonedDisabled, disabledSensorIndex);
      clonedEnabled.push(sensor);
    } else {
      _.pullAt(clonedEnabled, enabledSensorIndex);
      clonedDisabled.push(sensor);
    }
    saveValueChange('disabledSensors', [
      clonedDisabled,
      _.map(clonedEnabled, sensor => sensor.sensorId),
    ]);
  }

  const leftSideItems = index => (
    <div>
      {listType === 'user' ? (
        <DisableSwitch
          checked={!userArr[index]['disabledNotifcations']} // checked == not disabling notifications
          onChange={() => handleUserCheck(index)}
          color='primary'
          name='checkedB'
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      ) : (
        <DisableSwitch
          checked={!checkDisabled(index)} // checked == not disabled sensors
          onChange={() => handleSensorCheck(index)}
          color='primary'
          name='checkedB'
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      )}
      <IconButton data-testid='sensors_edit_button' id='sensors_edit_button'>
        <DeleteForeverIcon onClick={() => deleteItem(index)} />
      </IconButton>
    </div>
  );

  return (
    <Grid item xs={12} style={{ flexGrow: 1 }}>
      <GenericList
        showActions={false}
        manageAlertsPage={true}
        action={
          <EditAction
            manageAlertsPage={true}
            click={() =>
              setModalState({
                ...modalState,
                editType: listType,
                isOpen: true,
              })
            }
          />
        }
        title={listType === 'sensor' ? 'ASSIGNED SENSORS' : 'ASSIGNED USERS'}
        data={!_.isEmpty(sensorArr) ? sensorArr : userArr}
        emptyBuilder={() => (
          <ListElement
            index={0}
            title={
              <Typography
                style={{
                  justifyContent: 'center',
                  display: 'flex',
                  fontWeight: 'bold',
                  fontSize: 16,
                  color: theme.palette.type === 'light' ? grey[500] : '#FFF',
                }}
              >
                {listType === 'sensor'
                  ? ' No Matching Sensors'
                  : 'No Users Assigned'}
              </Typography>
            }
          />
        )}
        itemBuilder={(item, index) => {
          if (listType === 'sensor') {
            return (
              <ListElement
                key={index}
                index={index}
                title={item.sensorName}
                subtitle={item.locationName}
                leftSideItems={leftSideItems(index)}
              />
            );
          } else {
            return (
              <ListElement
                key={index}
                index={index}
                title={`${item.fname} ${item.lname}`}
                subtitle={item.email}
                leftSideItems={leftSideItems(index)}
              />
            );
          }
        }}
        useItemBuilder={true}
      />
    </Grid>
  );
}

export const GenericAssigned = memo(GenericAssignedContent);
