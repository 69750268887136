import React, { useState, useContext } from 'react';
import { AppContext } from '../../AppContext';
import {
  Box,
  Grid,
  InputAdornment,
  LinearProgress,
  makeStyles,
  TextField,
} from '@material-ui/core';
import ResponsiveDialog from '../Generic/GenericDialog';
import ImageButton from '../Generic/ImageButton';
import marker from '../../img/place-24px.svg';
import gateway from '../../img/router-24px.svg';
import LocationForm from '../common/LocationForm/LocationForm';
import MuiPhoneNumber from 'material-ui-phone-number';

const useStyles = makeStyles(theme => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
  },
  fieldPadding: {
    paddingBottom: theme.spacing(3),
    color:
      theme.palette.type === 'light'
        ? theme.palette.grey[600]
        : theme.palette.text.primary,
  },
  fontColor: {
    color:
      theme.palette.type === 'light'
        ? theme.palette.grey[600]
        : theme.palette.text.primary,
  },
  imageButtonSize: {
    width: 175,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 150,
      marginBottom: 10,
    },
  },
}));

/**
 * Location Form Modal
 * @param {Object} props
 * @props
 * openState: Boolean
 * handleClose: Functions
 */
export default function AddResellerFormModal(props) {
  const log = window.log('AddResellerFormModal');
  const classes = useStyles();
  const {
    appState,
    setLogoutState,
    openEditResellerModal,
    setOpenEditResellerModal,
    snack,
  } = useContext(AppContext);

  const { info, refreshInfo } = props;

  const [resellerInfo, setResellerInfo] = useState({
    id: info.id,
    companyName: info.company_name,
    street: info.address1,
    city: info.city,
    state: info.state,
    zip: info.zip,
    employeeName: info.employee_full_name,
    fname: info.first_name,
    lname: info.last_name,
    email: info.email,
    phone: info.phone,
    upgradeCommissionRate: info.upgrade_commission_rate,
    renewalCommissionRate: info.renewal_commission_rate,
  });

  const [resellerInfoError, setResellerInfoError] = useState({
    companyName: false,
    street: false,
    city: false,
    state: false,
    zip: false,
    fname: false,
    lname: false,
    email: false,
    phone: false,
    upgradeCommissionRate: false,
    renewalCommissionRate: false,
  });

  //Submit USER state hook
  const [submitState, setSubmitState] = useState(false);

  const resetResellerInfo = () => {
    setResellerInfo({
      id: info.id,
      companyName: info.company_name,
      street: info.address1,
      city: info.city,
      state: info.state,
      zip: info.zip,
      fname: info.first_name,
      lname: info.last_name,
      email: info.email,
      phone: info.phone,
      upgradeCommissionRate: info.upgrade_commission_rate,
      renewalCommissionRate: info.renewal_commission_rate,
    });
    setResellerInfoError({
      companyName: false,
      street: false,
      city: false,
      state: false,
      zip: false,
      fname: false,
      lname: false,
      email: false,
      phone: false,
      upgradeCommissionRate: false,
      renewalCommissionRate: false,
    });
  };

  // Submit function to api
  const handleSubmitEdit = async () => {
    if (
      !resellerInfo.companyName ||
      !resellerInfo.street ||
      !resellerInfo.city ||
      !resellerInfo.state ||
      !resellerInfo.zip ||
      !resellerInfo.fname ||
      !resellerInfo.lname ||
      !resellerInfo.email ||
      !resellerInfo.phone ||
      !resellerInfo.upgradeCommissionRate ||
      !resellerInfo.renewalCommissionRate
    ) {
      setResellerInfoError({
        companyName: !resellerInfo.companyName,
        street: !resellerInfo.street,
        city: !resellerInfo.city,
        state: !resellerInfo.state,
        zip: !resellerInfo.zip,
        fname: !resellerInfo.fname,
        lname: !resellerInfo.lname,
        email: !resellerInfo.email,
        phone: !resellerInfo.phone,
        upgradeCommissionRate: !resellerInfo.upgradeCommissionRate,
        renewalCommissionRate: !resellerInfo.renewalCommissionRate,
      });

      snack('Something went wrong. Please double check all fields.', 'error');
      return false;
    }

    // if (resellerInfo.state.length !== 2) {
    //   snack('Please Use Two Letter State Code', 'error');
    //   return false;
    // }

    if (
      resellerInfo.companyName === info.company_name &&
      resellerInfo.street === info.address1 &&
      resellerInfo.city === info.city &&
      resellerInfo.state === info.state &&
      resellerInfo.zip === info.zip &&
      resellerInfo.fname === info.first_name &&
      resellerInfo.lname === info.last_name &&
      resellerInfo.email === info.email &&
      resellerInfo.phone === info.phone &&
      resellerInfo.upgradeCommissionRate === info.upgrade_commission_rate &&
      resellerInfo.renewalCommissionRate === info.renewal_commission_rate
    ) {
      snack('No changes were made.', 'warning');
      return false;
    }

    let formattedPhoneNum = resellerInfo.phone
      .split('(')
      .join('')
      .split(')')
      .join('')
      .split(' ')
      .join('')
      .split('-')
      .join('');

    if (formattedPhoneNum.length <= 5) {
      formattedPhoneNum = '';
    }

    resellerInfo.phone = formattedPhoneNum;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/edit-reseller`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
          body: JSON.stringify(resellerInfo),
        },
      );

      const json = await response.json();

      const delay = ms => new Promise(res => setTimeout(res, ms));
      await delay(1500);

      if (json.success) {
        refreshInfo();
        return true;
      } else {
        if (json.errors.length) {
          json.errors.forEach(err => {
            snack(err.msg, 'error');
            if (err.type === 'token') setLogoutState(true);
          });
        } else {
          snack('Failed editing reseller information.', 'error');
        }
        return false;
      }
    } catch (err) {
      log(err);
      snack('Something went wrong. Please contact support if error persists.');
      return false;
    }
  };

  const handleClose = () => {
    setOpenEditResellerModal(false);
    resetResellerInfo();
  };

  const handleRestart = async () => {
    setOpenEditResellerModal(false);
    resetResellerInfo();
    setTimeout(() => {
      setOpenEditResellerModal(true);
    }, 500);
  };

  return (
    <div>
      <ResponsiveDialog
        openState={openEditResellerModal}
        handleClose={handleClose}
        handleCancel={handleClose}
        title='EDIT RESELLER'
        stepsArray={[
          {
            label: 'RESELLER INFO',
            // nextFunction: handleSubmitUser,
            // validator: !submitState,
            showProgress: true,
            child: (
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Box
                    className={classes.fontColor}
                    fontWeight='fontWeightBold'
                    fontSize={20}
                  >
                    Enter reseller information
                  </Box>
                </Grid>
                <Grid item xs={12} fontWeight='fontWeightLight' fontSize={16}>
                  <Box className={classes.fontColor}>
                    Enter the Name, Street, City, State and Zip Code information
                    for your reseller below.
                  </Box>
                </Grid>
                <LocationForm
                  addCompanyField={true}
                  removeUseCurrentLocation={true}
                  companyName={resellerInfo.companyName}
                  setCompanyName={value => {
                    setResellerInfo(prevState => {
                      return { ...prevState, companyName: value };
                    });
                  }}
                  companyNameError={resellerInfoError.companyName}
                  street={resellerInfo.street}
                  setStreet={value => {
                    setResellerInfo(prevState => {
                      return { ...prevState, street: value };
                    });
                  }}
                  streetError={resellerInfoError.street}
                  city={resellerInfo.city}
                  setCity={value => {
                    setResellerInfo(prevState => {
                      return { ...prevState, city: value };
                    });
                  }}
                  cityError={resellerInfoError.city}
                  state={resellerInfo.state}
                  setState={value => {
                    setResellerInfo(prevState => {
                      return { ...prevState, state: value };
                    });
                  }}
                  stateError={resellerInfoError.state}
                  zipcode={resellerInfo.zip}
                  setZipcode={value => {
                    setResellerInfo(prevState => {
                      return { ...prevState, zip: value };
                    });
                  }}
                  zipcodeError={resellerInfoError.zip}
                  classes={classes}
                />
              </Grid>
            ),
          },
          {
            label: 'CONTACT INFO',
            showProgress: true,
            child: (
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Box
                    className={classes.fontColor}
                    fontWeight='fontWeightBold'
                    fontSize={20}
                  >
                    Enter contact information
                  </Box>
                </Grid>
                <Grid item xs={12} fontWeight='fontWeightLight' fontSize={16}>
                  <Box className={classes.fontColor}>
                    Enter full name, email and phone number
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name='First Name'
                    className={classes.textField}
                    fullWidth
                    variant='outlined'
                    placeholder='First Name*'
                    label='First Name*'
                    error={resellerInfoError.fname}
                    value={resellerInfo.fname}
                    onChange={e =>
                      setResellerInfo({
                        ...resellerInfo,
                        fname: e.currentTarget.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name='Last Name'
                    className={classes.textField}
                    fullWidth
                    variant='outlined'
                    placeholder='Last Name*'
                    label='Last Name*'
                    error={resellerInfoError.lname}
                    value={resellerInfo.lname}
                    onChange={e =>
                      setResellerInfo({
                        ...resellerInfo,
                        lname: e.currentTarget.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name='email'
                    className={classes.textField}
                    fullWidth
                    variant='outlined'
                    placeholder='Email*'
                    label='Email*'
                    error={resellerInfoError.email}
                    value={resellerInfo.email}
                    onChange={e =>
                      setResellerInfo({
                        ...resellerInfo,
                        email: e.currentTarget.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <MuiPhoneNumber
                    fullWidth
                    className={classes.textField}
                    variant='outlined'
                    placeholder='Phone Number*'
                    label='Phone Number*'
                    defaultCountry={'us'}
                    preferredCountries={['us']}
                    disableAreaCodes={true}
                    autoFormat={true}
                    error={resellerInfoError.phone}
                    value={resellerInfo.phone}
                    onChange={e =>
                      setResellerInfo({ ...resellerInfo, phone: e })
                    }
                    countryCodeEditable={false}
                    inputProps={{
                      autoComplete: 'new-password',
                    }}
                  />
                </Grid>
              </Grid>
            ),
          },
          {
            label: 'COMMISSION',
            nextFunction: handleSubmitEdit,
            validator: !submitState,
            showProgress: true,
            saveButtonStep: true,
            child: (
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Box
                    className={classes.fontColor}
                    fontWeight='fontWeightBold'
                    fontSize={20}
                  >
                    Enter commission rate
                  </Box>
                </Grid>
                <Grid item xs={12} fontWeight='fontWeightLight' fontSize={16}>
                  <Box className={classes.fontColor}>
                    Enter the commission percentages, for upgrade and renewals
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name='upgradeComissionRate'
                    className={classes.textField}
                    fullWidth
                    variant='outlined'
                    placeholder='Upgrade Commission Rate (ex. 30%)'
                    label='Upgrade Commission Rate (ex. 30%)'
                    error={resellerInfoError.upgradeCommissionRate}
                    value={resellerInfo.upgradeCommissionRate}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>%</InputAdornment>
                      ),
                    }}
                    onChange={e => {
                      if (!isNaN(e.currentTarget.value)) {
                        if (e.currentTarget.value.includes('.')) {
                          const split = e.currentTarget.value.split('.');
                          if (split[1].length > 2) return;
                        }
                        setResellerInfo({
                          ...resellerInfo,
                          upgradeCommissionRate: e.currentTarget.value,
                        });
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name='renewalComissionRate'
                    className={classes.textField}
                    fullWidth
                    variant='outlined'
                    placeholder='Renewal Commission Rate (ex. 30%)'
                    label='Renewal Commission Rate (ex. 30%)'
                    error={resellerInfoError.renewalCommissionRate}
                    value={resellerInfo.renewalCommissionRate}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>%</InputAdornment>
                      ),
                    }}
                    onChange={e => {
                      if (!isNaN(e.currentTarget.value)) {
                        if (e.currentTarget.value.includes('.')) {
                          const split = e.currentTarget.value.split('.');
                          if (split[1].length > 2) return;
                        }
                        setResellerInfo({
                          ...resellerInfo,
                          renewalCommissionRate: e.currentTarget.value,
                        });
                      }
                    }}
                  />
                </Grid>
              </Grid>
            ),
          },
          {
            label: 'LOADING',
            child: (
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Box
                    className={classes.fontColor}
                    fontWeight='fontWeightBold'
                    fontSize={20}
                  >
                    Updating Reseller into Platform
                  </Box>
                </Grid>
                <Grid item xs={12} fontWeight='fontWeightLight' fontSize={16}>
                  <Box className={classes.fontColor}>
                    MiSensors is currently updating the reseller's information
                    into the platform. This should take less than a minute.
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <LinearProgress />
                </Grid>
              </Grid>
            ),
          },
          {
            label: 'EDIT COMPLETE', // Because is the finish
            child: (
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Box
                    className={classes.fontColor}
                    fontWeight='fontWeightBold'
                    fontSize={20}
                  >
                    Success! The reseller has been updated.
                  </Box>
                  <Box
                    className={classes.fontColor}
                    style={{ marginTop: '1rem' }}
                  >
                    Select Finish to return to the page.
                  </Box>
                </Grid>
              </Grid>
            ),
          },
        ]}
      />
    </div>
  );
}
