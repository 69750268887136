import React from 'react';
import { Box, Button, ButtonBase, Grid, makeStyles } from '@material-ui/core';
import ImageButton from './ImageButton';
import OnBoardModal from '../GeneralModals/OnBoardModal';
/**
 * Image Button
 * @param {Object} props
 * @props
 * id: Integer
 * image: Image
 * action: String,
 * onClick: String,
 * text: String
 */
export default function DecoratedImageButton(props) {
  const {
    title, // Title For the button
    titleColor, // TitleColor
    border, // Bool taht determine if have border
    image, // Image in the button
    action, // Action of the button
    onClick, // onClick Handle
    text, // Title in the button
    color, // Color of the background of the button
    uploadHandler, // Function - Add file type input field
    component,
    isComponent, // For component images
    uploadedImg = true,
    disabled, // Disable button
    buttonWithText, // For onboard tile sizing
    onboardModal,
    billingModal,
  } = props;

  const useStyles = makeStyles(theme => ({
    margin: {
      width: buttonWithText ? '125%' : '100%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      // marginRight: theme.spacing(2),
      backgroundColor: color,
      textAlign: 'center',
      border: border && `3px solid ${titleColor}`,
    },
    container: {
      width: '75%',
    },
    img: {
      width: '100%',
      height: 45,
      margin: 0,
    },
    uploadedImg: {
      height: 45,
      margin: 0,
    },
    textMargin: {
      width: buttonWithText
        ? '125%'
        : billingModal
        ? billingModal.width
        : '100%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    imageButtonContainer: {
      width: '100%',
      paddingLeft: billingModal ? 10 : '1em',
      paddingRight: billingModal ? 10 : '1em',
      height: onboardModal && 250,
    },
    button: {
      width: billingModal && billingModal.width,
      height: billingModal && billingModal.height,
      border: billingModal?.current
        ? `3px ${titleColor} solid !important`
        : billingModal?.selected
        ? '1px #08B3FF solid !important'
        : billingModal?.pending
        ? `3px ${titleColor} solid !important`
        : '0px #FFF solid !important',
      boxShadow: '0 0 5px #CCCCCC',
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.imageButtonContainer}>
      <p
        data-testid='button_title'
        style={{
          textAlign: 'center',
          color: titleColor,
          fontSize: billingModal && 9,
        }}
        className={classes.textMargin}
      >
        {title}
      </p>
      <Button
        disabled={disabled}
        variant='outlined'
        color='primary'
        size='small'
        component={component || 'button'}
        className={`${classes.margin} ${classes.button}`}
        onClick={onClick}
      >
        {uploadHandler ? (
          <input
            type='file'
            style={{ display: 'none' }}
            onChange={uploadHandler}
          />
        ) : null}
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            {isComponent ? (
              image
            ) : (
              <img
                src={image}
                alt='button'
                className={uploadedImg ? classes.img : classes.uploadedImg}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Box fontSize={billingModal ? billingModal.textSize : 16}>
              {action}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box fontSize={billingModal ? billingModal.textSize : 16}>
              {text}
            </Box>
          </Grid>
        </Grid>
      </Button>
    </div>
  );
}
