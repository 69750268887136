/**
 * @typedef Profile
 * @type { object }
 * @property { boolean } addCompanyLogo - If true, display company logo button on profile page
 * @property { boolean } addPaymentButton - If true, display payment button on profile page
 * @property { boolean } addBillingButton - If true, display billing button on profile page
 * @property { boolean } editCurrentUser
 */

/**
 * @namespace
 * @property { string } title - Permission Title
 * @property { array<number> } permission - List of permission levels that a user is authorized to manage ( add/edit )
 * @property { boolean } addButton - If true, add button on HeaderBar will be displayed
 * @property { Profile } profile - Display buttons on profile setting
 * @property { boolean } displayManageAlert - Display Manage Alert on alert page
 */
export const permissions = {
  0: {
    title: 'Account Admin',
    permission: [0, 1, 2],
    addButton: true,
    profile: {
      addCompanyLogo: true,
      addPaymentButton: true,
      addBillingButton: true,
      editCurrentUser: true,
    },
    displayManageAlert: true,
  },
  1: {
    title: 'Account Basic User',
    permission: [],
    addButton: false,
    profile: {
      addCompanyLogo: false,
      addPaymentButton: false,
      addBillingButton: false,
      editCurrentUser: false,
    },
    displayManageAlert: false,
  },
  2: {
    title: 'Account Advanced User ',
    permission: [1, 2],
    addButton: true,
    profile: {
      addCompanyLogo: true,
      addPaymentButton: false,
      addBillingButton: false,
      editCurrentUser: true,
    },
    displayManageAlert: true,
  },
  //Parent Reseller Account
  50: {
    title: 'DEMO - Administrator',
    permission: [51, 52, 53],
    addButton: true,
    profile: {
      addCompanyLogo: true,
      addPaymentButton: false,
      addBillingButton: false,
      editCurrentUser: true,
    },
    displayManageAlert: true,
  },
  //Similar to Administrator
  51: {
    title: 'DEMO - Account Admin',
    permission: [51, 52, 53],
    addButton: true,
    profile: {
      addCompanyLogo: true,
      addPaymentButton: false,
      addBillingButton: false,
      editCurrentUser: true,
    },
    displayManageAlert: true,
  },
  //Similar to Advanced User
  52: {
    title: 'DEMO - Account Advanced User',
    permission: [52, 53],
    addButton: true,
    profile: {
      addCompanyLogo: true,
      addPaymentButton: false,
      addBillingButton: false,
      editCurrentUser: true,
    },
    displayManageAlert: true,
  },
  53: {
    title: 'DEMO - Account Basic User',
    permission: [],
    addButton: false,
    profile: {
      addCompanyLogo: false,
      addPaymentButton: false,
      addBillingButton: false,
      editCurrentUser: false,
    },
    displayManageAlert: false,
  },
  99: {
    title: 'System Master Admin',
    permission: [0, 1, 2, 99],
    addButton: true,
    profile: {
      addCompanyLogo: true,
      addPaymentButton: true,
      addBillingButton: true,
      editCurrentUser: true,
    },
    displayManageAlert: true,
  },
};
