import React from 'react';
import { Edit } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

export const EditAction = props => {
  const { click, manageAlertsPage } = props;
  const classes = useStyles();
  return (
    <IconButton
      data-testid='sensors_edit_button'
      id='sensors_edit_button'
      className={classes.iconButton}
      onClick={click}
    >
      {manageAlertsPage ? (
        <AddCircleOutlineIcon className={classes.editIcon} />
      ) : (
        <Edit className={classes.editIcon} />
      )}
    </IconButton>
  );
};

const useStyles = makeStyles(() => ({
  iconButton: {
    height: 'fit-content',
    padding: '0 16px',
    marginBottom: -14,
  },
  editIcon: { fontSize: 28 },
}));
