import React from 'react';
import {
  Box,
  Grid,
  makeStyles,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';

import CheckboxCard from '../../Generic/CheckboxCard';
import ModalFormHeader from '../../common/ModalComponents/ModalFormHeader';

const useStyles = makeStyles(theme => ({
  fontColor: {
    color:
      theme.palette.type === 'light'
        ? theme.palette.grey[600]
        : theme.palette.text.primary,
  },
  fontDangerColor: {
    fontSize: 12,
    color: theme.palette.error.main,
  },
  select: {
    backgroundColor: theme.palette.background.paper,
  },
  formSelect: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
}));

/**
 * NotificationSettings
 * @param {*} props
 */
export default function NotificationSettings(props) {
  const classes = useStyles();

  const {
    notificationSettings,
    notificationAlertTime,
    notificationAlertType,
    notificationAlertNumber,
    setNotificationSettings,
    setNotificationAlertTime,
    setNotificationAlertType,
    setNotificationAlertNumber,
  } = props;

  const notificationTypeOptions = [
    {
      name: 'Single Notification',
      value: 'single',
      isDefault: true,
    },
    {
      name: 'Continuous Notification',
      value: 'continuous',
    },
  ];

  const notificationTimeOptions = [
    {
      name: 'Select time between notifications',
      value: 'none',
      isDefault: true,
    },
    {
      name: '5 minutes',
      value: 5,
    },
    {
      name: '10 minutes',
      value: 10,
    },
    {
      name: '15 minutes',
      value: 15,
    },
    {
      name: '30 minutes',
      value: 30,
    },
    {
      name: '45 minutes',
      value: 45,
    },
    {
      name: '60 minutes',
      value: 60,
    },
  ];

  const numberNotificationsOptions = [];

  for (let j = 0; j < 19; j++) {
    numberNotificationsOptions.push(j + 2);
  }

  return (
    <Grid key={6} item xs={12}>
      <ModalFormHeader
        header='Notification Settings'
        desc={`Choose SMS or Email as the notification method.`}
      />
      <Grid item container xs={12} style={{ marginTop: 20 }}>
        {notificationSettings.map(({ icon, value, title }, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <CheckboxCard
              icon={icon}
              value={value}
              title={title}
              toggleEvent={() => {
                let arrayCopy = notificationSettings.slice();
                arrayCopy[index].value = !value;
                setNotificationSettings(arrayCopy);
              }}
            />
          </Grid>
        ))}
      </Grid>
      <Grid
        item
        xs={12}
        fontWeight='fontWeightLight'
        fontSize={16}
        style={{ marginTop: '20px' }}
      >
        <Box className={classes.fontColor}>Select the Notification Type:</Box>
      </Grid>
      {/* Select to choose the time frame between alerts */}
      <Grid item xs={12}>
        <FormControl variant='outlined' className={classes.formSelect}>
          <Select
            labelId='notification-type-interval'
            id='notification-type-interval-select'
            className={classes.select}
            inputProps={{
              name: 'notificationType',
              id: 'range-type-selector',
            }}
            onChange={event => {
              const value = event.target.value;
              setNotificationAlertType(value);
              if (value === 'single') {
                setNotificationAlertNumber(1);
              } else {
                setNotificationAlertNumber(2);
                setNotificationAlertTime(5);
              }
            }}
            value={notificationAlertType}
          >
            {notificationTypeOptions.map(
              ({ name, value, isDefault }, index) => {
                const label = isDefault ? <em>{name}</em> : name;

                return (
                  <MenuItem
                    value={value}
                    key={`notification__setting__option__type__${index}`}
                  >
                    {label}
                  </MenuItem>
                );
              },
            )}
          </Select>
        </FormControl>
      </Grid>
      {notificationAlertType === 'continuous' && (
        <Grid
          item
          xs={12}
          fontWeight='fontWeightLight'
          fontSize={16}
          style={{ marginTop: '20px' }}
        >
          <Box className={classes.fontColor}>Alert Frequency:</Box>
        </Grid>
      )}
      {/* Select to choose the time frame between alerts */}
      {notificationAlertType === 'continuous' && (
        <Grid item xs={12}>
          <FormControl variant='outlined' className={classes.formSelect}>
            <Select
              labelId='notification-time-interval'
              id='notification-time-interval-select'
              className={classes.select}
              inputProps={{
                name: 'notificationTime',
                id: 'alert-time-selector',
              }}
              onChange={event => {
                const value = event.target.value;
                setNotificationAlertTime(value);
              }}
              value={notificationAlertTime}
              disabled={notificationAlertType !== 'continuous'}
            >
              {notificationTimeOptions.map(
                ({ name, value, isDefault }, index) => {
                  const label = isDefault ? <em>{name}</em> : name;

                  return (
                    <MenuItem
                      value={value}
                      key={`notification__setting__option__time__${index}`}
                    >
                      {label}
                    </MenuItem>
                  );
                },
              )}
            </Select>
          </FormControl>
        </Grid>
      )}
      {notificationAlertType === 'continuous' && (
        <Grid
          item
          xs={12}
          fontWeight='fontWeightLight'
          fontSize={16}
          style={{ marginTop: '20px' }}
        >
          <Box className={classes.fontColor}>Max Alerts (2-20):</Box>
        </Grid>
      )}
      {notificationAlertType === 'continuous' && (
        <Grid item xs={12}>
          <FormControl variant='outlined' className={classes.formSelect}>
            <Select
              labelId='notification-time-interval'
              id='notification-time-interval-select'
              className={classes.select}
              inputProps={{
                name: 'notificationNumber',
                id: 'alert-Number-selector',
              }}
              onChange={event => {
                const value = event.target.value;
                setNotificationAlertNumber(value);
              }}
              value={notificationAlertNumber}
              disabled={notificationAlertType !== 'continuous'}
            >
              {numberNotificationsOptions.map((value, index) => {
                return (
                  <MenuItem
                    value={value}
                    key={`notification__setting__option____number${index}`}
                  >
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        fontWeight='fontWeightLight'
        fontSize={16}
        style={{ marginTop: '30px' }}
      >
        {notificationAlertType === 'single' && (
          <Box className={classes.fontDangerColor}>
            If Single Notification selected, “You will receive a single
            notification at each occurrence.”
          </Box>
        )}
        {notificationAlertType === 'continuous' && (
          <Box className={classes.fontDangerColor}>
            {`If Continuous Notification selected, “You will receive a maximum of ${notificationAlertNumber} notifications, up to ${notificationAlertTime} minutes apart”.`}
          </Box>
        )}
      </Grid>
    </Grid>
  );
}
