import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect, useContext } from 'react';

import { AppContext } from '../../AppContext';

const useStyles = makeStyles((theme) => ({
  pendingContainer: {
    position: 'absolute',
    top: -8,
    right: -8,
    border: '1px solid #FFA10A',
    borderRadius: 4,
    padding: '2px 12px',
    [theme.breakpoints.down('sm')]: {
      right: 0
    }
  },
  text: {
    margin: 0,
    fontSize: 10,
    color: '#FFA10A',
  },
  pendingText: {
    '&:hover': {
      cursor: 'default',
    },
  },
  resendText: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

/**
 * The pending tag is displayed on the UserCard on the all users page
 * It is shown when the sub user has been sent an inviation, but hasn't accepted it yet
 */
export function PendingTag(props) {
  const { pendingUser, id } = props;
  const { snack, setLogoutState, appState } = useContext(AppContext);
  const classes = useStyles();

  const [expired, setExpired] = useState(false);
  const [pendingTime, setPendingTime] = useState(pendingUser);

  useEffect(() => {
    // Test to to see if the pending tag is over an hour old
    // user will need to resend invitation if expired
    const now = moment.utc(Date.now());
    const pendingUserTime = moment.utc(pendingTime);
    // const isExpired = now.subtract(pendingUserTime).format('ss'); // for testing
    const isExpired = now.subtract(pendingUserTime).format('HH'); // for reals

    if (isExpired >= 1) setExpired(true);
    else setExpired(false);
  }, [pendingTime]);

  const handleResend = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/user/resendInvite`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: appState.auth.token,
        },
        body: JSON.stringify({ id }),
      },
    );
    const json = await response.json();
    if (json.success) {
      setPendingTime(json.data.newTime);
    } else {
      snack(json.errors[0], 'error');
      json.errors.forEach(err => {
        if (err.type === 'token') {
          snack(err.msg, 'error');
          setLogoutState(true);
        }
      });
    }
  };

  return (
    <div className={classes.pendingContainer}>
      {expired ? (
        <p
          className={`${classes.resendText} ${classes.text}`}
          onClick={handleResend}
        >
          RESEND
        </p>
      ) : (
        <p className={`${classes.pendingText} ${classes.text}`}>PENDING</p>
      )}
    </div>
  );
}
