import _ from 'lodash';
import React from 'react';

import { SettingsCheckBoxes } from './SettingsCheckBoxes';

export function ReportsTab({
  weeklyReports,
  nightlyReports,
  setWeeklyReports,
  setNightlyReports,
}) {
  const reportBoxes = [
    {
      name: 'nightlyReports',
      check: nightlyReports,
      label: 'Nightly',
    },
    {
      name: 'weeklyReports',
      check: weeklyReports,
      label: 'Weekly',
    },
  ];

  const singleSet = e => {
    if (e.type === 'nightlyReports') setNightlyReports(!nightlyReports);
    if (e.type === 'weeklyReports') setWeeklyReports(!weeklyReports);
  };

  return (
    <SettingsCheckBoxes
      setCurrentValue={singleSet}
      checkBoxData={reportBoxes}
      label='Reports'
      single={true}
    />
  );
}
