import React from 'react'
import { withStyles, makeStyles } from "@material-ui/core/styles";

import {
  Tabs,
  Tab,
} from "@material-ui/core";

/**
 * Tabs for the mobile view of the app
 */

const MobileCustomTabs = withStyles((theme) => ({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '&:focus': {
      maxWidth: 1,
      transform: 'translate(0,-22px)',
      borderLeft: '6px solid transparent',
      borderRight: '6px solid transparent',
      borderTop: '12px solid transparent',
      borderBottom: `12px solid ${theme.palette.primary.main}`,
    },
  },
}))((props) => <Tabs {...props} />);

const MobileCustomTab = withStyles((theme) => ({
  root: {
    fontWeight: 600,
    fontSize: 9,
    bacgrkoundColor: '#F4F5F5 !important',
    '&:focus': {
      opacity: 1,
      color: theme.palette.primary.main,
    },
  },
}))((props) => <Tab {...props} />);

const MobileSnapshotTab = withStyles((theme) => ({
  root: {
    fontWeight: 700,
    fontSize: 11,
    bacgrkoundColor: '#F4F5F5 !important',
    '&:focus': {
      opacity: 1,
      color: theme.palette.primary.main,
    },
  },
}))((props) => <Tab {...props} />);

const useStyles = makeStyles((theme) => ({
  mobileTabsContainer: {
    boxShadow: theme.palette.type === "light" ? '0 4px 6px rgba(0,0,0,0.25), 0 -1px 0px #FFFFFF' : '',
    borderRadius: "0px 0px 15px 15px",
    transform: 'translate(0,1px)',
    backgroundColor: theme.palette.type === 'light'? 'white' : '#424242',
    position: 'relative',
    zIndex: 2,
  },
  mobileTableContainer: {
    zIndex: 1,
    
  },
}));

const MobileTabsContainer = (props) => {
  const classes = useStyles();

  const { children } = props;

  return <div
    className={classes.mobileTabsContainer}
  >
    {children}
  </div>

}

const MobileTabPanelContainer = (props) => {
  const classes = useStyles();

  const { children } = props;

  return <div
    className={classes.mobileTableContainer}
  >
    {children}
  </div>
}

export {
  MobileCustomTab,
  MobileCustomTabs,
  MobileSnapshotTab,
  MobileTabsContainer,
  MobileTabPanelContainer
}