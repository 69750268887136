import React, { Fragment } from 'react';
import { Edit } from '@material-ui/icons';
import grey from '@material-ui/core/colors/grey';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  Grid,
  Badge,
  IconButton,
  Avatar,
  Box,
  Typography,
  Paper,
  makeStyles,
  withStyles,
} from '@material-ui/core';

import UserImage from '../../img/icons/user.svg';
import CardInfoIcons from '../Generic/CardInfoIcons';
import GenericListCard from '../Generic/GenericListCard';
import CardInfoIconsMobile from '../Generic/CardInfoIconsMobile';
import { autoPlay } from 'react-swipeable-views-utils';

/**
 * Device Card
 * @param {Object} props
 * @props
 * id: Integer
 * trailingItems: Object Array [{ icon: React Component , value: String }]
 * name: String,
 * sensorId: String,
 * locationName: String
 */
export default function GatewayCard(props) {
  const {
    id, // Id of the device
    trailingItems, // Trailing Items for the CardInfoIcons
    isItemsDict = false,
    name, // Name of the sensor (Title of the Card)
    // sensorId, // Message of the Card (Address)
    subtitle,
    subtitle2, //
    subtitle3,
    showOnlyItems = [],
    avatar,
    showAvatar = true,
    onClick = () => {},
    wrap,
    displayDivider,
    buttons,
    enableZero,
    resize,
    gatewayCard, // if for gateways
    sensorCard, // if for sensors
    singleGatewayCard,
    singleAlertCard,
    singleLocationCard,
    showStatus,
    // onlineStatus,
    defaultAvatar,
    handleEditImage,
    lastMessageTime,
    isTileLayout = false, // Boolean to show the element as a Tile and not a card
    backgroundColor = 'white', // Card background color
  } = props;
  const subTitles = [subtitle, subtitle2, subtitle3];

  const theme = useTheme();
  const smallDevice = useMediaQuery(theme.breakpoints.down('xs'));

  if (smallDevice) {
    trailingItems.push({
      icon: '',
    });
  }
  /**
   * Event to send the browser to the user page
   */
  // const showDevice = () => {
  //   navigate(`/device/${id}`);
  // };
  const useStyles = makeStyles(theme => ({
    cardInfoIcons: {
      maxWidth: sensorCard && '72%',
      transform: gatewayCard && 'translateY(-4px)',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('xs')]: {
        overflow: 'hidden',
        position: 'absolute',
        right: '0px',
      },
      [theme.breakpoints.down('815')]: {
        transform: singleAlertCard && 'translate(80%, -86%)',
      },
      [theme.breakpoints.down('705')]: {
        transform: singleAlertCard && 'translate(74%, -86%)',
      },
    },
    title: {
      fontSize: 14,
      fontWeight: 700,
      color: theme.palette.type === 'light' ? '#747474' : '#FFF',
      whiteSpace: 'nowrap',
    },
    subTitle: {
      fontSize: 12,
      color: theme.palette.type === 'light' ? '#747474' : '#FFF',
      whiteSpace: 'nowrap',
    },
    avatarContainerGrid: {
      transform: singleGatewayCard && 'translateY(10px)',
      [theme.breakpoints.down('md')]: {
        transform: singleGatewayCard && 'translate(35px,10px)',
      },
    },
    avatarContainer: {
      marginTop: sensorCard && 5,
      [theme.breakpoints.down('md')]: {
        transform: (sensorCard || singleGatewayCard) && 'translateX(-40px)',
      },
      [theme.breakpoints.down('sm')]: {
        transform: sensorCard
          ? 'translate(149px,-5px)'
          : singleGatewayCard && 'translateX(0px)',
        marginTop: sensorCard && 0,
      },
      [theme.breakpoints.down('865')]: {
        transform: sensorCard && 'translate(93px,-5px)',

        marginTop: sensorCard && 0,
      },
      [theme.breakpoints.down('720')]: {
        transform: sensorCard && 'translate(36px,-5px)',

        marginTop: sensorCard && 0,
      },
      [theme.breakpoints.down('xs')]: {
        transform: sensorCard && 'translate(0px,-5px)',

        marginTop: sensorCard && 0,
      },
    },
    avatarLarge: {
      width: 74,
      height: 'auto',
      border: '1px solid #CCC',
      [theme.breakpoints.down('xs')]: {
        width: 50,
      },
    },
    textAndTrailingContainer: {
      [theme.breakpoints.up('sm')]: {
        marginTop: singleGatewayCard && 20,
      },
      [theme.breakpoints.down('md')]: {
        transform: singleGatewayCard && 'translateX(35px)',
      },
    },
    cardContentTextContainer: {
      marginLeft:
        gatewayCard && !singleGatewayCard
          ? 37
          : singleGatewayCard
          ? 14
          : sensorCard && 15,
      height: (gatewayCard || singleLocationCard) && 'fit-content',
      [theme.breakpoints.up('xs')]: {
        transform: singleGatewayCard && 'translateY(-4px)',
      },
      [theme.breakpoints.down('md')]: {
        transform: sensorCard
          ? 'translateX(-64px)'
          : singleGatewayCard && 'translate(-64px, -4px)',
      },
      [theme.breakpoints.down('sm')]: {
        transform: singleGatewayCard && 'translate(12px,0px)',
        display: sensorCard && 'grid',
        marginLeft: gatewayCard && 0,
      },
      [theme.breakpoints.down('xs')]: {
        transform: sensorCard && 'translateX(0px)',
        display: sensorCard && 'grid',
        // transform: "translate(0px,0px)"
      },
      [theme.breakpoints.down('380')]: {
        display: 'grid',
        width: 145,
        whiteSpace: 'nowrap',
      },
      [theme.breakpoints.down('1300')]: {
        display: !singleGatewayCard && 'block',
        width: gatewayCard && 100,
        maxWidth: 350,
        whiteSpace: 'nowrap',
      },
    },
    statusCircle: {
      right: '0%',
      bottom: '49%',
    },
    avatarImage: {
      objectFit: defaultAvatar && 'none',
      transform: defaultAvatar && gatewayCard && 'translateY(-5px)',
    },
    editImageButton: {
      '& svg': {
        fontSize: 14,
      },
    },
    editImageButtonContainer: {
      [theme.breakpoints.down('md')]: {
        transform: singleGatewayCard && 'translateX(-40px)',
      },
      [theme.breakpoints.down('sm')]: {
        transform: singleGatewayCard && 'translateX(0px)',
      },
    },
    tileStyles: {
      backgroundColor: backgroundColor,
      padding: theme.spacing(2),
      borderBottom: `1px solid ${grey[300]}`,
    },
  }));

  const classes = useStyles();

  const StyledBadge = withStyles(theme => ({
    anchorOriginBottomRightCircle: '', // To remove error from console
    badge: {
      width: 16,
      height: 16,
      borderRadius: '50%',
      backgroundColor: lastMessageTime > 0 ? '#59C754' : '#FF6A6A',
      color: lastMessageTime > 0 ? '#59C754' : '#FF6A6A',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: '$ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': lastMessageTime > 0 && {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }))(Badge);

  const content = (
    <Grid item container>
      <Grid
        container
        item
        xs={12}
        onClick={onClick}
        spacing={smallDevice ? 0 : 3}
        style={{
          cursor: (singleGatewayCard) ? 'auto' : 'pointer',
        }}
      >
        {showAvatar && showStatus ? (
          <Grid
            className={classes.avatarContainerGrid}
            item
            xs={3}
            sm={3}
            md={2}
            lg={1}
          >
            <Box
              className={classes.avatarContainer}
              display='flex'
              justifyContent='center'
            >
              <Avatar
                classes={{ img: classes.avatarImage }}
                alt='avatar'
                src={avatar ? avatar : UserImage}
                className={classes.avatarLarge}
              />
              <StyledBadge
                classes={{
                  anchorOriginBottomRightCircle: classes.statusCircle,
                }}
                overlap='circle'
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                variant='dot'
              ></StyledBadge>
            </Box>
            {singleGatewayCard && (
              <Grid
                item
                xs={12}
                display='flex'
                className={classes.editImageButtonContainer}
                style={{
                  marginTop: -10,
                  backgroundColor: grey,
                  textAlign: 'center',
                }}
              >
                <IconButton
                  style={{ color: '#ffffff', backgroundColor: grey[400] }}
                  size='small'
                  className={classes.editImageButton}
                  onClick={handleEditImage}
                >
                  <Edit></Edit>
                </IconButton>
              </Grid>
            )}
          </Grid>
        ) : showAvatar ? (
          <Grid item xs={12} sm={gatewayCard ? 12 : 5} md={2} lg={1}>
            <Box
              className={classes.avatarContainer}
              display='flex'
              justifyContent='center'
            >
              <Avatar
                classes={{ img: classes.avatarImage }}
                alt='avatar'
                src={avatar ? avatar : UserImage}
                className={classes.avatarLarge}
              />
            </Box>
          </Grid>
        ) : null}
        {/* name and sensors container */}
        <Grid
          item
          container
          xs={12}
          sm={gatewayCard || singleLocationCard ? 9 : 7}
          md={showAvatar ? 10 : 12}
          lg={showAvatar ? 11 : 12}
          className={classes.textAndTrailingContainer}
          style={{
            flexWrap: (gatewayCard || singleLocationCard) && 'nowrap',
            position: 'relative',
          }}
        >
          <Grid
            item
            container
            direction='column'
            justify={smallDevice ? 'flex-start' : 'center'}
            xs={gatewayCard || singleLocationCard ? 10 : 12}
            sm={gatewayCard || singleLocationCard ? 12 : 12}
            md={resize ? 9 : 3}
            className={classes.cardContentTextContainer}
          >
            <Box component='div' textOverflow='ellipsis' overflow='hidden'>
              <Box
                component='div'
                textOverflow='ellipsis'
                overflow='hidden'
                className={classes.title}
              >
                {name}
              </Box>
              {subTitles.map((subtitle, i) => (
                <Box
                  key={i}
                  component='div'
                  textOverflow='ellipsis'
                  overflow='hidden'
                  className={classes.subTitle}
                >
                  {subtitle}
                </Box>
              ))}
            </Box>
          </Grid>
          {/* {smallDevice ?
          (
            <Grid
              item
              xs={3}
              align="center"
              alignContent="center"
              className={classes.mobileInfoIcons}
            >
              <CardInfoIconsMobile
                singleLocationCard={singleLocationCard}
                singleAlertCard={singleAlertCard}
                singleGatewayCard={singleGatewayCard}
                sensorCard={sensorCard}
                gatewayCard={gatewayCard}
                wrapItems={wrap}
                displayDivider={displayDivider}
                isDict={isItemsDict}
                items={trailingItems}
                showOnlyItems={showOnlyItems}
                buttons={buttons}
                enableZero={enableZero}
              />
            </Grid>
          ) : (
            <Grid
              item
              container
              xs={gatewayCard || singleLocationCard ? 5 : 12}
              sm={gatewayCard || singleLocationCard ? 5 : 12}
              md={resize ? 3 : 9}
              direction="row"
              className={classes.cardInfoIcons}
            >
              <CardInfoIcons
                singleLocationCard={singleLocationCard}
                singleAlertCard={singleAlertCard}
                singleGatewayCard={singleGatewayCard}
                sensorCard={sensorCard}
                gatewayCard={gatewayCard}
                wrapItems={wrap}
                displayDivider={displayDivider}
                isDict={isItemsDict}
                items={trailingItems}
                showOnlyItems={showOnlyItems}
                buttons={buttons}
                enableZero={enableZero}
              />
            </Grid>
          )} */}
          <Grid
            item
            container
            xs={gatewayCard || singleLocationCard ? 2 : 12}
            sm={gatewayCard || singleLocationCard ? 5 : 12}
            md={resize ? 3 : 9}
            direction='row'
            className={classes.cardInfoIcons}
          >
            <CardInfoIcons
              singleLocationCard={singleLocationCard}
              singleAlertCard={singleAlertCard}
              singleGatewayCard={singleGatewayCard}
              sensorCard={sensorCard}
              gatewayCard={gatewayCard}
              wrapItems={wrap}
              displayDivider={displayDivider}
              isDict={isItemsDict}
              items={trailingItems}
              showOnlyItems={showOnlyItems}
              buttons={buttons}
              enableZero={enableZero}
              isSmall={smallDevice}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Fragment>
      {isTileLayout ? (
        <Box className={classes.tileStyles}>{content}</Box>
      ) : (
        <GenericListCard
          sensorCard={sensorCard}
          singleGatewayCard={singleGatewayCard}
        >
          {content}
        </GenericListCard>
      )}
    </Fragment>
  );
}
