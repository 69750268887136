import React from 'react';
import GenericList from '../Dashboard/GenericList';

import { TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

const useStyles = makeStyles(theme => ({
  columnHeader: {
    backgroundColor: theme.palette.background.paper,
  },
  tableBody: {
    '& tr:nth-of-type(odd)': {
      backgroundColor: theme.palette.type === 'light' ? grey[100] : grey[900],
    },
    '& tr:nth-of-type(even)': {
      backgroundColor: theme.palette.type === 'light' ? 'white' : grey[800],
    },
  },
}));

/**
 * ItemList for
 * the list in the location
 *
 * @props
 * items: Array[Object]
 * collumns
 * actions
 * checklist: boolean
 * title: String
 *
 *
 */
export default function ItemList(props) {
  const { title, items, columns, action, checklist } = props;
  const classes = useStyles();

  return (
    <GenericList
      title={title}
      onClick={() => {}}
      showActions={false}
      action={action}
      noData
      noHeight
      data={items}
      columnHead
      itemBuilder={(u, index) => {
        return (
          <TableRow style={{ height: '20px' }} className={classes.columnHeader}>
            <TableCell align='left' style={{ height: 'auto !important' }}>
              {u.name}
            </TableCell>
            <TableCell
              align='left'
              style={{ height: 'auto !important' }}
              className={classes.tableBody}
            >
              {u.MAC}
            </TableCell>
          </TableRow>
        );
      }}
    ></GenericList>
  );
}
